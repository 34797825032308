import React from 'react';
import { Grid } from '@material-ui/core';
import { getValue } from '@ourbranch/lookups';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { getPolicyType } from 'core/helpers/policy-type';
import getDetailedPrice from 'offer/helpers/detailed-price';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { numberFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Loading } from 'core/components/loading';
import { Button } from 'core/components/button';
import { PriceDetail } from './components/price-details-card/price-detail';
import { Additionals } from './components/additionals';
import { Downloads } from './components/downloads';
import useStyles from './sidebar.styles';

const CheckoutSidebar = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { offer: offerStore } = useStore();

  const { offer } = offerStore;
  const { option: selectedOptionPolicyType } = useParams();

  if (!offerStore?.offer) {
    return <Loading />;
  }

  const selectedOption = getQuoteFriendlySelectedOption(selectedOptionPolicyType);
  const { hasHome, hasAuto, isRenters } = getPolicyType(
    selectedOption,
    offer?.quote?.noBindHome,
    offer?.quote?.noBindAuto
  );
  const isHomeMonthly = offer?.quote?.global?.homeownersPaymentType === 'I';
  const isAutoMonthly = offer?.quote?.global?.autoPaymentType === 'I';
  const prices = getDetailedPrice(offer, selectedOption, isHomeMonthly, isAutoMonthly);
  let option;

  if (offer?.options) {
    option = offer?.options?.find((opt) => opt.type === selectedOption);
  }

  return (
    <Grid>
      <Card className={classes.checkoutContainer}>
        {hasHome && hasAuto && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Home and Auto
          </Label>
        )}
        {hasAuto && isRenters && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Auto and Renters
          </Label>
        )}
        {hasHome && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Home
            </Label>
            <PriceDetail
              name="Home"
              type="Home"
              isMonthly={isHomeMonthly}
              downPaymentDate={prices.homeDownPaymentDate}
              remainingPayments={prices.homeRemainingPayments}
              remainingPaymentsAmount={prices.homeRemainingPaymentsAmount}
              payment={prices.homePrice}
              paymentMethod={getValue('homeownersPaymentMethod', offer?.quote?.global?.homeownersPaymentMethod)}
              paymentType={getValue('paymentType', offer?.quote?.global?.homeownersPaymentType)}
              termTotal={option.homeTotal}
              policyType={option.type}
              nextPayments={prices.homeNextPayments}
            />
          </div>
        )}
        {hasAuto && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Auto
            </Label>
            <PriceDetail
              name="Auto"
              type="Auto"
              isMonthly={isAutoMonthly}
              downPaymentDate={prices.autoDownPaymentDate}
              remainingPayments={prices.autoRemainingPayments}
              remainingPaymentsAmount={prices.autoRemainingPaymentsAmount}
              payment={prices.autoPrice}
              paymentMethod={getValue('homeownersPaymentMethod', offer?.quote?.global?.autoPaymentMethod)}
              paymentType={getValue('paymentType', offer?.quote?.global?.autoPaymentType)}
              termTotal={option.autoTotal}
              policyType={option.type}
              autoTotalAR={isRenters ? numberFormatter(prices.autoTotalAR, 2) : null}
              rentersTotalAR={isRenters ? numberFormatter(prices.rentersTotalAR, 2) : null}
              nextPayments={prices.autoNextPayments}
            />
          </div>
        )}
        <Button className={classes.backLink} onClick={() => history.push(`/offer/${offer.id}`)}>
          Back to modify payment options
        </Button>
      </Card>
      {offerStore.shouldShowAdditionalPaymentsSection && <Additionals />}
      <Downloads />
    </Grid>
  );
});

export default CheckoutSidebar;
