const styles = () => ({
  button: {
    textDecoration: 'none'
  },
  dateTime: {
    display: 'inline-block',
    marginRight: 4
  },
  icon: {
    padding: 4
  },
  editIcon: {
    display: 'block',
    height: 32,
    width: 32
  }
});

export default styles;
