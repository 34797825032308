export const styles = (theme) => ({
  sendButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    height: 'auto',
    marginLeft: 24,
    '&:hover': {
      background: 'transparent'
    }
  },
  sendIcon: {
    width: 24,
    height: 24,
    marginRight: 6
  },
  disabledIcon: {
    opacity: 0.5
  },
  card: {
    backgroundColor: theme.colorPalette.white_30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    padding: '0 24px',
    marginBottom: 8
  },
  buttons: {
    display: 'flex'
  }
});
