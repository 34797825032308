export default (theme) => ({
  myCommunityCard: {
    position: 'relative'
  },
  noData: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: theme.colorPalette.orange_10_op_10
  },
  root: {
    cursor: 'pointer',
    height: 48,
    '&$hover:hover': {
      backgroundColor: theme.colorPalette.orange_10_op_10
    }
  },
  hover: {},
  hName: {
    color: '#000000'
  }
});
