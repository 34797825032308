import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { openFrontChatPopup } from 'core/components/front-chat';

import { Label } from 'core/components/label';
import { withToast } from 'core/components/toast';

import useStyles from './cancelled-policy-footer.styles';

const CancelledPolicyFooter = ({ title, isAgency }) => {
  const classes = useStyles();

  const footerClasses = classNames({
    [classes.footerContainer]: true,
    [classes.footerAlert]: true
  });

  const openFrontChat = () => {
    if (isAgency) {
      openFrontChatPopup();
    }
  };

  return (
    <div className={classes.floatingContainer}>
      <div className={footerClasses}>
        <Grid container justify="space-between" alignItems="center" className={classes.footerContent}>
          <Label className={classes.title} type="subtitleLight">
            {title}
          </Label>

          <Label className={classes.subtitle}>
            To reinstate it, please contact your supervisor or{' '}
            <span onClick={openFrontChat} className={isAgency ? classes.subtitleLink : classes.subtitle}>
              Branch Support.
            </span>
          </Label>
        </Grid>
      </div>
    </div>
  );
};

export default withToast(CancelledPolicyFooter);
