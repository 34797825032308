import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import editIcon from './edit-icon.svg';
import useStyles from './edit-referral.styles';
import { useStore } from 'core/store';

const EditReferral = ({ inviter }) => {
  const classes = useStyles();
  const {
    referralSearch: { setSearchString, setShowReferralMatches, searchCustomersAction }
  } = useStore();

  const hasInviter = inviter && inviter.firstName && inviter.lastName;

  const onEditClick = () => {
    if (hasInviter) {
      setSearchString(`${inviter.firstName} ${inviter.lastName}`);
      searchCustomersAction();
    }
    setShowReferralMatches(true);
  };

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Label type="noteSmall" className={classes.label}>
        REFERRED BY{' '}
      </Label>
      <Label className={classes.link} type="infoSubtitleOrange" onClick={onEditClick}>
        {hasInviter && (
          <>
            {inviter.firstName} {inviter.lastName} <img src={editIcon} alt="square-and-pencil" />
          </>
        )}
        {!hasInviter && 'Add a referral'}
      </Label>
    </Grid>
  );
};

EditReferral.propTypes = {
  inviter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  })
};

EditReferral.defaultProps = {
  inviter: null
};

export default EditReferral;
