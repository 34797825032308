import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import flowRight from 'lodash-es/flowRight';
import { FormField } from 'core/components/form';
import withSpacing from 'core/components/form/with-spacing';
import styles from 'core/components/form/form.styles';
import lienHolders from './lien-holders.json';

const LienHolderAutoComplete = ({ setValues, currentValues }) => {
  const options = useMemo(
    () => (lienHolders ? lienHolders.map(({ name, rn, ...suggestion }) => ({ text: name, id: rn, suggestion })) : []),
    []
  );

  const handleSelection = useCallback(
    (_, selection) => {
      const { suggestion, text } = selection;
      if (!suggestion) return;

      const { address, addressLine2, city, state, zip } = suggestion;

      setValues({
        ...currentValues,
        name: text || '',
        address: address || '',
        address2: addressLine2 || '',
        city: city || '',
        state: state || '',
        zip: zip || ''
      });
    },
    [setValues, currentValues]
  );

  return (
    <FormField
      type="autocomplete"
      name="name"
      id="name"
      mode="light"
      label="Company Name"
      options={options}
      allowUserInput
      fast={false}
      onSelection={handleSelection}
      permissions={{ isLicensedAction: false }}
    />
  );
};

LienHolderAutoComplete.propTypes = {
  setValues: PropTypes.func.isRequired,
  currentValues: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withSpacing)(LienHolderAutoComplete);
