import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import orderBy from 'lodash-es/orderBy';
import uuid from 'uuid/v4';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Table } from 'core';
import { AuthContext } from 'core/components/auth';
import { withStore } from 'core/store';
import { Card } from 'core/components/card';
import { Loading } from 'core/components/loading';
import Section from 'core/components/section';
import styles from './incidents.styles';
import Footer from '../footer';
import IncidentTableRows, { IncidentTableHeader } from './incidents-table';

const formatViolation = (violation, options) => ({
  type: violation.class,
  id: uuid(),
  source: violation.source,
  ...options
});

const getHomeViolations = (violations) => {
  if (!violations) return [];

  const homeViolations = JSON.parse(violations);

  // Selects a single incident per date
  return Object.entries(homeViolations).reduce(
    (acc, [date, [homeViolation]]) =>
      homeViolation
        ? [
            ...acc,
            formatViolation(homeViolation, {
              date
            })
          ]
        : acc,
    []
  );
};

const getHomeSingleViolationDates = (violations) => {
  if (!violations) return [];

  const homeViolations = JSON.parse(violations);
  // TODO: clarify type to be used for major and minor violations

  return [
    ...Object.entries(homeViolations.MIN).map(([date, violation]) => formatViolation(violation, { date, type: 'MIN' })),
    ...Object.entries(homeViolations.MAJ).map(([date, violation]) => formatViolation(violation, { date, type: 'MAJ' }))
  ];
};

const getHomeIncidents = (policy) =>
  policy?.segments?.reduce(
    (acc, segment) => [
      ...acc,
      ...getHomeViolations(segment.homeViolationDates),
      ...getHomeSingleViolationDates(segment.homeSingleViolationDates)
    ],
    []
  );

const getAutoViolation = (autoViolationDates, driver) => {
  if (!autoViolationDates) return [];

  const autoViolations = JSON.parse(autoViolationDates);

  return Object.entries(autoViolations).reduce(
    (acc, [date, [violation]]) => [
      ...acc,
      formatViolation(violation, {
        date,
        driver: `${driver.firstName} ${driver.lastName}`
      })
    ],
    []
  );
};

const getAutoPolicySegmentIncidents = (segment) =>
  segment?.drivers?.reduce((acc, driver) => [...acc, ...getAutoViolation(driver.autoViolationDates, driver)], []) || [];

const getAutoPolicyIncidents = (policy) =>
  policy?.segments?.reduce((acc, segment) => [...acc, ...getAutoPolicySegmentIncidents(segment)], []) || [];

const Incidents = ({
  loadingPreview,
  handleChangePolicy,
  classes,
  store: {
    account: {
      policies: {
        policy: { loading, policy }
      }
    }
  }
}) => {
  const session = useContext(AuthContext);
  const [sortDirection, setSortDirection] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedIncidents, setSelectedIncidents] = useState({});

  const showPreviewIncident = useMemo(() => session?.isService && !!Object.values(selectedIncidents).length, [
    selectedIncidents,
    session
  ]);

  const canScrub = session?.canScrubIncidents;

  const isAutoPolicyType = policy.policyType === 'A';

  const rawIncidents = useMemo(() => {
    if (!policy) return [];

    return policy.policyType === 'A' ? getAutoPolicyIncidents(policy) : getHomeIncidents(policy);
  }, [policy]);

  const incidents = useMemo(() => orderBy(rawIncidents, ['date'], [sortDirection]), [rawIncidents, sortDirection]);

  const handleChangePage = (_event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sort = (_columnId, _active, direction) => setSortDirection(direction);

  const selectIncident = (incident, checked) => {
    setSelectedIncidents((prev) => {
      if (checked) return { ...prev, [incident.id]: incident };

      const { [incident.id]: currentIncident, ...others } = prev;

      return others;
    });
  };

  const handleChange = useCallback(
    async (values) => {
      const immediateBillOrRefundAmount = values.immediateBillOrRefundAmount || false;

      handleChangePolicy(
        { immediateBillOrRefundAmount, id: policy.id },
        Object.values(selectedIncidents).map((item) => item.date)
      );
    },
    [selectedIncidents, policy, handleChangePolicy]
  );

  return (
    <Formik
      initialValues={{
        checkbox: []
      }}
      onSubmit={handleChange}
    >
      {({ submitForm }) => (
        <>
          <div>
            <Section title="Incidents & Violations">
              {loading ? (
                <Loading type="secondary" />
              ) : (
                <Card className={classes.tableContainer} type="secondary">
                  {incidents.length > 0 ? (
                    <Table
                      mode={'dark'}
                      dataCy="incidents"
                      selfContained={false}
                      header={
                        <IncidentTableHeader
                          canScrub={canScrub}
                          isAutoPolicy={isAutoPolicyType}
                          onSort={sort}
                          classes={classes}
                        />
                      }
                      body={
                        <IncidentTableRows
                          canScrub={canScrub}
                          selectedIncidents={selectedIncidents}
                          incidents={incidents}
                          classes={classes}
                          page={page}
                          isAutoPolicy={isAutoPolicyType}
                          onSelect={selectIncident}
                          rowsPerPage={rowsPerPage}
                        />
                      }
                      paginationEnabled={incidents.length > rowsPerPage}
                      count={incidents.length}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      currentPage={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  ) : (
                    <p> No Incidents</p>
                  )}
                </Card>
              )}
            </Section>
            {showPreviewIncident && <Footer loading={loadingPreview} title="Save Changes" onClick={submitForm} />}
          </div>
        </>
      )}
    </Formik>
  );
};

Incidents.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  loadingPreview: PropTypes.bool.isRequired,
  handleChangePolicy: PropTypes.func.isRequired
};

export default flowRight(withStyles(styles), withStore)(Incidents);
