import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  card: {
    backgroundColor: theme.colorPalette.green_20,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  header: {
    padding: theme.spacing(6, 6, 0)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7),
    padding: theme.spacing(0, 6, 2)
  },
  button: {
    fontWeight: 600,
    fontSize: 16
  },
  switchContainer: {
    margin: 0,
    minHeight: 'unset',
    width: '100%'
  },
  switch: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%'
  },
  footer: {
    padding: theme.spacing(2, 6)
  }
});

export default makeStyles(styles);
