import { useObserver } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { Loading } from 'core';
import Section from 'core/components/section';
import { useStore } from 'core/store';

import BillingDetails from '../billing-details';
import useStyles from './payment-details.styles';
import EquityBreakdown from '../equity-breakdown';

function PaymentDetails() {
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const classes = useStyles();

  return useObserver(() => (
    <Section title="Payment Details" type="SubSection">
      {store.policyEquityStatus.status !== 'done' ? (
        <Loading className={classes.loadingWheel} type="secondary" />
      ) : (
        <EquityBreakdown />
      )}
      <BillingDetails />
    </Section>
  ));
}

PaymentDetails.propTypes = {
  billingHoldUntil: PropTypes.string
};

PaymentDetails.defaultProps = {
  billingHoldUntil: null
};

export default PaymentDetails;
