import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { generateFriendlyName } from '@ourbranch/docs-handler';
import { Storage } from 'aws-amplify';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';

import Section from 'core/components/section';
import { withToast } from 'core/components/toast';
import { withStore } from 'core/store';
import withDatePicker from 'core/components/with-date-picker';
import { CopyInternalDocToPolicyDocsModal } from './components/copy-internal-doc-to-policy-docs-modal';
import { RecreateApplication } from './components/recreate-application';
import { RegularDocuments } from './components/regular-documents';
import { InternalDocuments } from './components/internal-documents';
import styles from './documents.styles';

function downloadDocument(path) {
  Storage.get(path, {
    customPrefix: { public: '' },
    level: 'public'
  }).then((data) => {
    window.open(data, '_blank');
  });
}

// @TODO most of these could be moved to mobx computed properties
function getDocName(path) {
  const fileName = path.substring(path.lastIndexOf('/') + 1, path.length);

  return generateFriendlyName(fileName);
}

const sortDocuments = (documents) => {
  return documents.sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());
};

class Documents extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    toast: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    showPolicyIdColumn: PropTypes.bool,
    showRecreateApplication: PropTypes.bool
  };

  static defaultProps = {
    showPolicyIdColumn: false,
    showRecreateApplication: false
  };

  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      uploaded: [],
      deleting: false,
      error: undefined,
      file: undefined,
      copyDocsModalOpen: false,
      selectedDocToCopy: undefined
    };
  }

  fetchDocuments = () => {
    const account = this.props.store.account;
    account.policies.getDocuments(account.id);
  };

  progressHandler = ({ loaded, total }) => {
    const { percent: prevPercent } = this.state;
    const percent = (loaded / total) * 100;
    if (prevPercent < percent) {
      this.setState({
        percent
      });
    }
  };

  docExists = (name) => {
    const { documents } = this.props.store.account.policies;
    const { uploaded } = this.state;
    const parsedName = getDocName(name);
    return documents.internal.concat(uploaded).find((doc) => getDocName(doc.path) === parsedName);
  };

  removeDocument = ({ path, removeUrl }) => {
    const { uploaded } = this.state;
    const fileName = getDocName(path);
    this.setState({
      error: undefined,
      deleting: true
    });
    fetch(removeUrl, {
      method: 'PUT'
    })
      .then((res) => res.json())
      .then(() => {
        const newUploaded = [...uploaded]; // Just need shallow copy
        const index = newUploaded.findIndex((doc) => getDocName(doc.path) === fileName);
        if (index >= 0) {
          newUploaded.splice(index, 1);
          this.setState({
            uploaded: newUploaded,
            deleting: false
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err.message,
          deleting: false
        });
      });
  };

  uploadComplete = (file, removeUrl) => {
    const { lastModified, path } = file;
    const {
      account: { id: accountId }
    } = this.props.store;
    this.setState({
      percent: 0,
      error: undefined,
      file: undefined
    });
    const doc = {
      path: `internal/${accountId}/${path}`, // @TODO use store
      lastModified,
      removeUrl
    };
    this.setState(({ uploaded }) => ({
      uploaded: [...uploaded, doc]
    }));
  };

  uploadFailed = (file) => {
    this.setState({
      percent: 0,
      file: undefined,
      error: `Couldn't upload ${file.path}`
    });
  };

  handleFetch = (url, file) => {
    const ajax = new XMLHttpRequest();
    ajax.upload.onprogress = this.progressHandler;
    ajax.upload.onload = this.uploadComplete.bind(this, file);
    ajax.upload.onerror = this.uploadFailed.bind(this, file);
    ajax.open('PUT', url);
    ajax.setRequestHeader('Content-Type', file.type);
    ajax.send(file);
  };

  uploadFile = async (files) => {
    const [file] = files;
    const { account } = this.props.store;
    const exists = this.docExists(file.path);
    if (exists) {
      this.setState({
        error: 'You can’t upload this document, it already exists.'
      });
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 3000);
      return;
    }
    this.setState({
      percent: 0.1,
      file,
      error: undefined
    });
    try {
      const { data } = await account.policies.getUploadUrl(account.id, file.name, file.type); // @TODO use Store
      if (data && data.uploadUrl) {
        const { url } = data.uploadUrl;
        this.handleFetch(url, file);
      } else {
        this.setState({
          percent: 0,
          file: undefined,
          error: `Couldn't upload ${file.path}`
        });
      }
    } catch (error) {
      this.setState({
        percent: 0,
        file: undefined,
        error: `Couldn't upload ${file.path}`
      });
    }
    if (this.state.error) {
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 3000);
    }
  };

  onRegenerateDocsError = (toast, policyType) => {
    toast.notify({
      type: 'error',
      message: `There was an error regenerating ${policyType} policy documents`
    });
  };

  onRecreateApplication = async (policyId) => {
    const { toast } = this.props;
    const {
      account: {
        policies: {
          policy: { recreateApplication }
        }
      }
    } = this.props.store;

    const pdfUrl = await recreateApplication(policyId);

    if (!pdfUrl) {
      return toast.notify({
        type: 'error',
        message: `There was an error recreating the policy application`
      });
    }

    toast.notify({
      type: 'success',
      message: 'The regenerated Application has been added to the Internal Documents section on the Customer Profile'
    });
  };

  onCopyDocClick = (document) => {
    this.setState({ copyDocsModalOpen: true });
    this.setState({ selectedDocToCopy: document });
  };

  render() {
    const { uploaded, error, deleting, percent, file } = this.state;
    // @NOTE this type of destructuring code will break observability because we are inside a new function scope
    const {
      classes,
      store: {
        account: {
          policies: { list: policiesList, policy: policyStore }
        }
      },
      showRecreateApplication
    } = this.props;

    const policyId = policyStore.policy?.id;
    const showPolicyIdColumn = this.props.showPolicyIdColumn;

    return (
      <>
        <RegularDocuments
          getDocName={getDocName}
          sortDocuments={sortDocuments}
          showPolicyIdColumn={showPolicyIdColumn}
          downloadDocument={downloadDocument}
          classes={classes}
          fetchDocuments={this.fetchDocuments}
          onRegenerateDocsError={this.onRegenerateDocsError}
          toast={this.props.toast}
        />
        {showRecreateApplication && (
          <Section className={classNames({ [classes.docSection]: !policyId })} title="Application">
            <RecreateApplication
              documentPolicyId={policyId}
              policies={policiesList}
              onRecreate={this.onRecreateApplication}
            />
          </Section>
        )}
        {showPolicyIdColumn && (
          <>
            <InternalDocuments
              sortDocuments={sortDocuments}
              classes={classes}
              getDocName={getDocName}
              onCopyDocClick={this.onCopyDocClick}
              downloadDocument={downloadDocument}
              uploaded={uploaded}
              uploadFile={this.uploadFile}
              percent={percent}
              error={error}
              deleting={deleting}
              file={file}
            />
            {this.state.copyDocsModalOpen && (
              <CopyInternalDocToPolicyDocsModal
                open={this.state.copyDocsModalOpen}
                getDocName={getDocName}
                document={this.state.selectedDocToCopy}
                refetchDocuments={this.fetchDocuments}
                onClose={() => {
                  this.setState({ copyDocsModalOpen: false });
                }}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default flowRight(withStyles(styles), withToast, withStore, withDatePicker)(Documents);
