import React from 'react';
import { Grid } from '@material-ui/core';
import { createBixConversionApplicationLink } from '@ourbranch/policy-utils';

import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store';
import useStyles from './signed-documents-notification.styles';

const SignedDocumentsNotification = () => {
  const {
    account: { policies, sendEventAsMember, id: accountId }
  } = useStore();
  const { policy: policyStore, list: allPolicies } = policies;
  const { policy: selectedPolicy, billingDetails } = policyStore;
  const classes = useStyles();
  const toast = useToast();
  const bixConversionDocsInfo = policies.getUnsignedBixConversion(selectedPolicy.id);
  const allMemberPolicies = allPolicies.map(({ id, premium, policyType, segments, endDate, effectiveDate, fees }) => ({
    id,
    premium,
    policyType,
    segments,
    endDate,
    effectiveDate,
    fees
  }));

  const gsnicInfo = allMemberPolicies.find((policy) => {
    const now = new Date();
    return (
      policy.policyType === selectedPolicy.policyType &&
      policy.id.includes('SC') &&
      // active policy check
      now > new Date(policy.effectiveDate) &&
      now < new Date(policy.endDate)
    );
  });
  const gsnicPrice = gsnicInfo?.premium + gsnicInfo?.fees?.reduce((acc, curr) => acc + curr.amount, 0);
  const bixPrice =
    selectedPolicy.id.includes('BX') &&
    selectedPolicy.premium + selectedPolicy.fees.reduce((acc, curr) => acc + curr.amount, 0);

  const signedDate = bixConversionDocsInfo.signedTimestamp ? new Date(bixConversionDocsInfo.signedTimestamp) : null;

  return (
    <Grid container alignItems="center" justify="space-between">
      {signedDate ? (
        <Label className={classes.styledText}>
          BIX conversion documents successfully signed on {signedDate.toDateString()} at {signedDate.toTimeString()}.
          Please allow a few hours for the signed documents to appear.
        </Label>
      ) : (
        <Label className={classes.styledText}>This customer has not yet signed their BIX conversion documents.</Label>
      )}

      {!signedDate && (
        <Button
          className={classes.button}
          onClick={async () => {
            const { data, error } = await sendEventAsMember({
              eventName: 'Resend BIX Conversion Link',
              userId: accountId,
              properties: JSON.stringify({
                gsnicRenewalPremium: gsnicInfo?.segments[0].premium,
                priorTermBlendedPremium: gsnicInfo?.segments[0].premium,
                priorTermFinalSegmentPremium: gsnicInfo?.segments[0].premium,
                bixPrice,
                gsnicClarionDoorId: gsnicInfo?.segments[0].clarionDoorId,
                bixClarionDoorId: selectedPolicy.segments[0].clarionDoorId,
                gsnicPrice,
                gsnicPriceDescription: 'current term final price',
                id: selectedPolicy.id,
                renewalDate: selectedPolicy.effectiveDate,
                policyType: selectedPolicy.policyType,
                paymentDate: billingDetails.nextPaymentDate,
                billAmount: billingDetails.nextPaymentAmount.toLocaleString('en-us', {
                  currency: 'USD',
                  style: 'currency'
                }),
                paymentDescription: selectedPolicy.renewalPaymentType,
                signURL: createBixConversionApplicationLink(selectedPolicy.id)
              })
            });
            if (data?.sendEventAsMember) {
              toast.notify({
                type: 'success',
                message: 'Bix conversion documents have been sent'
              });
            } else {
              toast.notify({
                type: 'error',
                message: `Error sending conversion docs ${error}`
              });
            }
          }}
        >
          Resend
        </Button>
      )}
    </Grid>
  );
};

export default SignedDocumentsNotification;
