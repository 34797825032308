import gql from 'graphql-tag';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Auth as AwsAuth, Hub } from 'aws-amplify';

import { withToast } from 'core/components/toast';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { AuthContext } from './auth.context';

const allStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

function getUserFromPayload(payload) {
  return {
    username: payload['cognito:username'],
    groups: payload['cognito:groups'],
    email: payload.email,
    phoneNumber: payload.phone_number,
    affinityCode: payload['custom:affinity_code'],
    licenseNumber: payload['custom:license_number']
  };
}

function parseCustomAttribute(attribute) {
  try {
    return JSON.parse(attribute);
  } catch (e) {
    return [];
  }
}

function permissions(payload) {
  if (!payload['cognito:groups'] || !payload['cognito:groups'].length) {
    return {
      isService: false,
      isSales: false,
      canEdit: false
    };
  }
  const isService = payload['cognito:groups']?.includes('Service');
  const isSales =
    payload['cognito:groups']?.includes('InternalSales') || payload['cognito:groups']?.includes('ExternalSales');
  const isInternalSales = payload['cognito:groups']?.includes('InternalSales');
  const isExternalSales = payload['cognito:groups']?.includes('ExternalSales');
  const isTeamLeader = payload['cognito:groups']?.includes('TeamLeaders');
  const canReinstate = payload['cognito:groups']?.includes('Reinstatement');
  const canSeeRatePriceDetails = payload['cognito:groups']?.includes('RatePriceDetails');
  const canScrubIncidents = payload['cognito:groups']?.includes('ScrubIncidents');
  const canModifyAffinityAndLeadSource = payload['cognito:groups']?.includes('ModifyAffinityCodes');
  const canBackDate = payload['cognito:groups']?.includes('CanBackDate');
  const canModifyBillingId = payload['cognito:groups']?.includes('ModifyBillingID');
  const canViewClarionDoorData = payload['cognito:groups']?.includes('ViewClarionDoorData');
  const canAddCarsManually = payload['cognito:groups']?.includes('AddCarsManually');
  const canAddRemoveInstallments = payload['cognito:groups']?.includes('CanAddRemoveInstallments');
  const canAutoRenew = payload['cognito:groups']?.includes('CanAutoRenew');
  const canAddHoldCards = payload['cognito:groups']?.includes('CanAddHoldCards');
  const canChangeExclusions = payload['cognito:groups']?.includes('CanChangeExclusions');
  const hasUnlicensedGroup = payload['cognito:groups']?.includes('Unlicensed');
  const canToggleEmployeeDiscount = payload['cognito:groups']?.includes('CanToggleEmployeeDiscount');
  const canClearUDRs = payload['cognito:groups']?.includes('CanClearUDRs');

  const isAgency = !!parseCustomAttribute(payload['custom:affinity_groups']).length;
  const parsedAllowedStates = parseCustomAttribute(payload['custom:allowed_states']);
  const parsedUnlicensedStates = parseCustomAttribute(payload['custom:unlicensed_states']);
  // Users that are not licensed in any states and therefore should only have a populated list of unlicensed states alongside the Unlicensed cognito group
  const isUnlicensed = hasUnlicensedGroup && Boolean(parsedUnlicensedStates?.length && !parsedAllowedStates.length);

  return {
    // allows quoting and editing offers
    [CognitoPermissionGroups.canQuote]: isTeamLeader || isSales || isService,

    // allows purchasing offers
    [CognitoPermissionGroups.canBind]: isTeamLeader || isSales || isService,

    // allows editing policies and/or offers
    // if opening policy/offer for state not licensed for, the code should only allow unlicensed actions
    // which is determined via the permissions prop and useFormFieldPermissionHelpers used in FormField
    [CognitoPermissionGroups.canEdit]: isTeamLeader || isSales || isService || isUnlicensed,

    [CognitoPermissionGroups.isTeamLeader]: isTeamLeader,
    [CognitoPermissionGroups.isSales]: isSales,
    [CognitoPermissionGroups.isService]: isService,
    [CognitoPermissionGroups.isAgency]: isAgency,
    [CognitoPermissionGroups.isInternalAgent]: !isAgency,
    [CognitoPermissionGroups.isUnlicensed]: isUnlicensed,
    [CognitoPermissionGroups.canReinstate]: canReinstate,
    [CognitoPermissionGroups.canSeeRatePriceDetails]: canSeeRatePriceDetails,
    [CognitoPermissionGroups.isInternalSales]: isInternalSales,
    [CognitoPermissionGroups.isExternalSales]: isExternalSales,
    [CognitoPermissionGroups.canScrubIncidents]: canScrubIncidents,
    [CognitoPermissionGroups.canModifyAffinityAndLeadSource]: canModifyAffinityAndLeadSource,
    [CognitoPermissionGroups.canBackDate]: canBackDate && !isAgency,
    [CognitoPermissionGroups.canModifyBillingId]: canModifyBillingId && !isAgency,
    [CognitoPermissionGroups.canViewClarionDoorData]: canViewClarionDoorData,
    [CognitoPermissionGroups.canAddCarsManually]: canAddCarsManually && !isAgency,
    [CognitoPermissionGroups.canAddRemoveInstallments]: canAddRemoveInstallments,
    [CognitoPermissionGroups.canAutoRenew]: canAutoRenew && !isAgency,
    [CognitoPermissionGroups.canAddHoldCards]: canAddHoldCards,
    [CognitoPermissionGroups.canToggleEmployeeDiscount]: canToggleEmployeeDiscount && !isAgency,
    [CognitoPermissionGroups.canChangeExclusions]: canChangeExclusions,
    [CognitoPermissionGroups.canClearUDRs]: canClearUDRs && isTeamLeader
  };
}

const defaultState = {
  user: null,
  isLoggedIn: false,
  logout: () => AwsAuth.signOut(),
  changePassword: (oldPassword, newPassword) => {
    return AwsAuth.currentAuthenticatedUser().then((user) => {
      return AwsAuth.changePassword(user, oldPassword, newPassword);
    });
  },
  loading: true,
  userPreferences: null
};

const GET_USER_PREFERENCES = gql`
  query {
    getUserPreferences {
      username
      algoliaKey
    }
  }
`;

function getAllowedStates({ parsedAllowedStates, permissionGroups }) {
  if (permissionGroups.isInternalAgent) {
    return allStates;
  }
  if (parsedAllowedStates?.length) {
    return parsedAllowedStates;
  }
  return [];
}

class Auth extends React.Component {
  static contextType = MobXProviderContext; // This sets `this.context` to be the MobX store.
  static propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.object.isRequired,
    toast: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    Hub.listen('auth', (capsule) => {
      const { channel, payload } = capsule;
      if (channel === 'auth') {
        const { event, data } = payload;
        if (event === 'signIn') {
          this.setAuthStateFromSession(data.signInUserSession.idToken.payload, data.signInUserSession.idToken.jwtToken);
        } else if (event === 'signOut') {
          window.location.reload();
        }
        if (event === 'signIn_failure') {
          const spaceInUsernameError =
            'Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+';
          if (capsule.payload.data.message.includes(spaceInUsernameError)) {
            props.toast.notify({
              type: 'error',
              message: 'Double check if the username has a space. If so, please remove and try logging in again.'
            });
          }
        }
      }
    });
    this.state = defaultState;
  }

  async componentDidMount() {
    try {
      const session = await AwsAuth.currentSession();
      await this.setAuthStateFromSession(session.idToken.payload, session.getIdToken().getJwtToken());
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error getting session from AwsAuth: ', e);
      this.setState(defaultState);
    }
  }

  async getUserPreferences(payload) {
    const { client } = this.props;

    try {
      // if user has no algolia key OR user is agent, get algolia key fom userpreferences table
      // for internal users, we use the cognito algolia key, but for agencies we need to use the key in userpreferences dynamo table
      if (!payload['custom:algolia_api_key'] || !!parseCustomAttribute(payload['custom:affinity_groups']).length) {
        const {
          data: { getUserPreferences }
        } = await client.query({ query: GET_USER_PREFERENCES });

        payload['custom:algolia_api_key'] = getUserPreferences.algoliaKey;
      }
      return { algoliaKey: payload['custom:algolia_api_key'] };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Try deploying dynamoDB to have a valid algolia key');
      return { algoliaKey: undefined };
    }
  }

  async setAuthStateFromSession(payload, token) {
    const userPreferences = await this.getUserPreferences(payload);
    if (this.context.store) {
      this.context.store.userPreferences.setUserPreferences(userPreferences);
    }

    const permissionGroups = permissions(payload);
    const parsedAllowedStates = parseCustomAttribute(payload['custom:allowed_states']);
    const parsedUnlicensedStates = parseCustomAttribute(payload['custom:unlicensed_states']);

    this.setState({
      user: getUserFromPayload(payload),
      isLoggedIn: true,
      ...permissionGroups,
      token,
      loading: false,
      allowedStates: getAllowedStates({ parsedAllowedStates, permissionGroups }),
      unlicensedStates: parsedUnlicensedStates?.length ? parsedUnlicensedStates : [],
      userPreferences
    });
  }

  render() {
    const { children } = this.props;

    return <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>;
  }
}

export default withToast(Auth);
