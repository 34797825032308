import config from '../../aws-exports';
import Cookies from 'js-cookie';

/**
 * @param {string} phoneNumber
 * @returns {string | null} E.164 formatted phone number (+11234567890) or null
 */
const tryConvertingPhoneToE164Number = (phoneNumber) => {
  // Remove all non-numeric characters
  let e164PhoneNumber = String(phoneNumber).replace(/\D/g, '');

  if (e164PhoneNumber.length > 10) {
    e164PhoneNumber = e164PhoneNumber.slice(-10);
  }

  if (e164PhoneNumber.length === 10) {
    return `+1${e164PhoneNumber}`;
  }

  return null;
};

export const identify = (data, trackUrl = true) => {
  if (config.trackingEnabled && window.analytics) {
    if (data && data.phone) {
      data.phone = tryConvertingPhoneToE164Number(data.phone) || data.phone;
    }

    if (data && data.phoneNumber) {
      data.phoneNumber = tryConvertingPhoneToE164Number(data.phoneNumber) || data.phoneNumber;
    }

    const payload = trackUrl ? { ...data, mostRecentClusterUrl: window.location.href.substring(0, 1000) } : data;
    import('logrocket').then(({ default: LogRocket }) => {
      if (data.username) {
        LogRocket.identify(`staff-${data.username}`, payload);
        window.analytics.identify(`staff-${data.username}`, payload);
      }
    });
  }
};

export const track = (event, data) => {
  if (config.trackingEnabled && window.analytics) {
    if (data && data.phone) {
      data.phone = tryConvertingPhoneToE164Number(data.phone) || data.phone;
    }

    if (data && data.phoneNumber) {
      data.phoneNumber = tryConvertingPhoneToE164Number(data.phoneNumber) || data.phoneNumber;
    }

    const sessionId = Cookies.get('ampSessionId') || Date.now();
    window.analytics.track(event, data, { integrations: { Amplitude: { sessionId } } });
    import('logrocket').then(({ default: LogRocket }) => LogRocket.track(event));
    Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 }); // session expires after 30 min
  }
};
