import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  checkboxContainer: {
    paddingTop: 25,
    paddingBottom: 50,
    textAlign: 'left'
  }
});

export default useStyles;
