import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  policy: {
    color: theme.colorPalette.white_30,
    fontWeight: 300,
    fontSize: 10
  },
  policyContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  paymentContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1)
  },
  payment: {
    fontWeight: 300,
    lineHeight: 1.4
  },
  disabledPayment: {
    composes: '$payment',
    textDecoration: 'line-through'
  },
  paymentSchedules: {
    marginBottom: theme.spacing(2)
  },
  tooltip: {
    fontWeight: 300,
    fontSize: 18
  },
  extendedInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(6, 0),
    marginTop: theme.spacing(6)
  },
  coverageValue: {
    fontSize: 14,
    marginTop: theme.spacing(1)
  },
  remainingPayments: {
    composes: '$paymentContainer',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));

export default useStyles;
