import { lookupsJson } from '@ourbranch/lookups';

const affinityPartners = lookupsJson.affinityPartners;
const states = lookupsJson.usStates;

export function validUSState(name) {
  return this.test({
    name: `${name}.validUSState`,
    message: 'Please enter a valid US state',
    test: (val) => !val || states.some((state) => state.id === val),
    exclusive: false
  });
}

export function requiredString(label) {
  return this.ensure().required(label || 'Required');
}

export function validAffinityCode(name) {
  return this.test({
    name: `${name}.validAffinityCode`,
    message: 'Please enter a valid affinity partner name or code',
    test: (val) =>
      !val ||
      affinityPartners.some((affinityPartner) => `${affinityPartner.value} - ${affinityPartner.id}` === val) ||
      affinityPartners.some((affinityPartner) => affinityPartner.id === val) ||
      affinityPartners.some((affinityPartner) => {
        if (val.includes('_')) {
          // some large agencies will have an underscore separating the prefix and the number ex: SE0001_38
          const affinityPrefix = val.split('_')[0];
          return affinityPartner.id.startsWith(affinityPrefix);
        }
        return true;
      }),
    exclusive: false
  });
}
