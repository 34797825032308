import React, { useCallback, useMemo, useEffect } from 'react';
import { connect } from 'formik';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson, mortgageLookups, paymentMethod } from '@ourbranch/lookups';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import RemoveButton from 'core/components/remove-button';
import { mortgageStyles } from './mortgage-details.styles';
import MortgageRadio from './mortgage-radio';

const mortgageList = mortgageLookups?.map((mortgage, index) => ({
  id: index,
  text: mortgage.correctName
}));

const MortgageItem = ({ classes, fieldId: id, fieldName, formik, index, onRemove, item, removeFromList }) => {
  const { values, setFieldValue, setFieldTouched } = formik;
  const { home } = values;
  const { mortgageDetails } = home;
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { policy } = policyStore;

  useEffect(() => {
    const mortgage = mortgageDetails[index];
    if (mortgage.primary) {
      setFieldValue(`primaryMortgageDetail.mortgageHolderAddress`, mortgage.mortgageHolderAddress);
      setFieldValue(`primaryMortgageDetail.mortgageHolderName`, mortgage.mortgageHolderName);
      setFieldValue(`primaryMortgageDetail.mortgageHolderId`, mortgage.id);
      setFieldValue(`primaryMortgageDetail.loanNumber`, mortgage.loanNumber);
    }
  }, [mortgageDetails, index, setFieldValue]);

  const onRadioChange = useCallback(() => {
    const foundIndex = mortgageDetails.findIndex((mgItem) => mgItem.primary);
    if (foundIndex >= 0) {
      setFieldValue(`${id}.${foundIndex}.primary`, false);
    }
    setFieldValue(`${fieldName}.primary`, !mortgageDetails[index].primary);
    setFieldTouched(id);
  }, [mortgageDetails, fieldName, id, setFieldTouched, setFieldValue, index]);

  const handleSelection = useCallback(
    (_, { text, id }) => {
      const mortgage = mortgageLookups.find((mgItem) => mgItem.correctName === text);
      if (mortgage) {
        const { address, address2, city, state, zip, correctName, id: mortgageID } = mortgage;
        setFieldTouched(id);
        setFieldValue(fieldName, {
          ...item,
          mortgageHolderAddress: {
            address,
            address2,
            city,
            state,
            zip
          },
          mortgageHolderName: correctName,
          id: mortgageID || id
        });
      }
    },
    [item, fieldName, setFieldTouched, setFieldValue]
  );

  const preventRemoveMortgage = useMemo(() => {
    // can't remove mortgage if payment type is mortgage with only one mortgage
    return (
      policy?.paymentMethod === paymentMethod.Escrow && mortgageDetails[index].primary && mortgageDetails.length <= 1
    );
  }, [mortgageDetails, policy, index]);

  return (
    <div className={classes.itemContainer}>
      <div className={classes.header}>
        <div className={classes.identifier}>
          <Label type="darkSmall">MORTGAGE #{mortgageDetails[index].loanNumber}</Label>
          {preventRemoveMortgage && (
            <Label type="infoLabel">Change the payment method, or add a mortgage to eliminate this mortgage.</Label>
          )}
        </div>
        <RemoveButton
          mode="big"
          actionType="button"
          className={classes.button}
          disabled={preventRemoveMortgage}
          onClick={() => onRemove(index, removeFromList)}
        />
      </div>
      <Grid container spacing={4}>
        <FormField
          name={`${fieldName}.loanNumber`}
          id={`${fieldName}.loanNumber`}
          type="string"
          label="Loan Number"
          mode="light"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderName`}
          id={`${fieldName}.mortgageHolderName`}
          options={mortgageList}
          onSelection={handleSelection}
          label="Mortgage Holder Name"
          type="autocomplete"
          xs={4}
          allowUserInput
          fast={false}
          permissions={{ isLicensedAction: false }}
        />
        <Grid item xs={4} container>
          <MortgageRadio checked={mortgageDetails[index].primary || false} onChange={onRadioChange} classes={classes} />
        </Grid>
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.address`}
          id={`${fieldName}.mortgageHolderAddress.address`}
          type="string"
          label="Mortgage Holder Address"
          xs={8}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.address2`}
          id={`${fieldName}.mortgageHolderAddress.address2`}
          type="string"
          label="Address 2"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.city`}
          id={`${fieldName}.mortgageHolderAddress.city`}
          type="string"
          label="City"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.state`}
          id={`${fieldName}.mortgageHolderAddress.state`}
          type="autocomplete"
          label="State"
          options={lookupsJson.usStates}
          xs={4}
          stateSelectForm
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.zip`}
          id={`${fieldName}.mortgageHolderAddress.zip`}
          type="string"
          label="Zip code"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
      </Grid>
    </div>
  );
};

MortgageItem.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  removeFromList: PropTypes.func.isRequired
};

export default flowRight(withStyles(mortgageStyles), observer, connect)(MortgageItem);
