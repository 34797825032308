import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  container: {
    width: '100%',
    marginTop: theme.spacing(4),
    backgroundColor: theme.colorPalette.green_20,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: theme.spacing(4)
  },
  collapseHeader: {
    height: 72,
    padding: theme.spacing(0, 2)
  },
  collapseContainer: {
    paddingLeft: theme.spacing(14),
    paddingTop: theme.spacing(6)
  },
  collapseContent: {
    paddingTop: theme.spacing(3)
  },
  inputLabel: {
    color: theme.colorPalette.white_10
  },
  inputLabelDisabled: {
    color: theme.colorPalette.beige_10_op_60
  },
  iconButtonRoot: {
    position: 'absolute',
    color: theme.colors.button__background_secondary,
    right: theme.spacing(2),
    padding: 0
  },
  iconButtonLabel: {
    height: 32,
    width: 32
  },
  iconFold: {
    display: 'block',
    height: 32,
    width: 32,
    transition: 'transform 200ms'
  },
  icon: {
    composes: '$iconFold',
    transform: 'rotate(180deg)'
  },
  loaderContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    minHeight: 450
  },
  loader: {
    color: theme.palette.secondary.main
  },
  autoContainer: {
    marginTop: theme.spacing(6)
  },
  coverageContainer: {
    paddingBottom: theme.spacing(6)
  },
  columnHeader: {
    display: 'flex',
    height: 55,
    alignItems: 'center'
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  disabledPayment: {
    textDecoration: 'line-through'
  },
  paymentDate: {
    paddingLeft: theme.spacing(8)
  },
  checkoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(4),
    backgroundColor: theme.colorPalette.green_20,
    marginBottom: theme.spacing(4),
    gap: theme.spacing(4),
    padding: theme.spacing(8, 8, 5)
  },
  checkoutHeader: {
    textTransform: 'uppercase'
  },
  downloadHeader: {
    height: 30,
    margin: theme.spacing(10, 0, 4),
    fontSize: 24,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: theme.colorPalette.green_20
  },
  checkoutPolicySection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0)
  },
  policyHeader: {
    textTransform: 'uppercase'
  },
  selectedContent: {
    paddingLeft: theme.spacing(14)
  },
  remainingPayments: {
    paddingBottom: theme.spacing(3)
  },
  backLink: {
    color: theme.colorPalette.orange_10
  }
}));

export default useStyles;
