export default (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24,
    borderRadius: theme.sizes.roundCorners
  },
  coverageAmount: {
    marginTop: 6
  },
  coverageLabel: {
    paddingTop: 4
  },
  umbrellaLabel: {
    color: theme.colorPalette.beige_10
  },
  umbrellaContent: {
    marginTop: 28
  },
  coverageContainer: {
    paddingTop: '24px !important' // override the grid spacing
  },
  errorMessage: {
    color: '#f44336',
    textTransform: 'none',
    fontSize: 10
  },
  noPaddingTop: {
    marginTop: 0,
    position: 'relative',
    top: -10
  },
  link: {
    color: theme.colorPalette.orange_10,
    textDecorationLine: 'underline'
  },
  button: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colorPalette.white_10,
    minWidth: 150
  },
  buttonLink: {
    color: theme.colorPalette.white_10
  },
  purchasedContainer: {
    backgroundColor: theme.colorPalette.white_10_op_15,
    padding: 16,
    borderRadius: 6
  }
});
