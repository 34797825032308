import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    padding: 32,
    position: 'relative',
    overflowWrap: 'anywhere'
  }
}));

export default useStyles;
