import { OFFER_FRAGMENT } from 'core/fragments/offer';
import gql from 'graphql-tag';

export const REQUEST_QUOTE = gql`
  query (
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $unit: String
    $isApartment: Boolean
    $fname: String!
    $lname: String!
    $email: AWSEmail
    $phone: String
    $additionalPhoneNumbers: [AdditionalPhoneNumberInput]
    $leadSource: String
    $rep: String!
    $affinity: String
    $dateOfBirth: AWSDate
    $priorAddress: AddressDetailsInput
    $currentAutoCarrier: String
    $currentAutoLimitBIPD: String
    $continuousAutoCoverageYears: Int
    $driversLicenseState: String
    $driversLicenseNumber: String
    $insuranceInPast31Days: Boolean
    $fromStaff: Boolean
    $VIN: String
    $home: HomeDetailsInput
    $connectedHome: ConnectedHomeDetailsInput
    $global: GlobalDetailsInput
    $policyType: String
  ) {
    offer: requestQuote(
      userInput: {
        address: $address
        city: $city
        state: $state
        zip: $zip
        unit: $unit
        isApartment: $isApartment
        fname: $fname
        lname: $lname
        email: $email
        phone: $phone
        additionalPhoneNumbers: $additionalPhoneNumbers
        leadSource: $leadSource
        rep: $rep
        affinity: $affinity
        priorAddress: $priorAddress
        dateOfBirth: $dateOfBirth
        currentAutoCarrier: $currentAutoCarrier
        currentAutoLimitBIPD: $currentAutoLimitBIPD
        insuranceInPast31Days: $insuranceInPast31Days
        continuousAutoCoverageYears: $continuousAutoCoverageYears
        driversLicenseState: $driversLicenseState
        driversLicenseNumber: $driversLicenseNumber
        fromStaff: $fromStaff
        VIN: $VIN
        home: $home
        connectedHome: $connectedHome
        global: $global
        policyType: $policyType
      }
    ) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;

export const SEND_CLUSTER_LINK = gql`
  mutation ($offerId: ID!, $email: String!, $revisedQuoteDetails: QuoteDetailsInput!, $agent: AgentInput) {
    cluster: sendClusterLink(
      offerId: $offerId
      email: $email
      revisedQuoteDetails: $revisedQuoteDetails
      agent: $agent
    ) {
      id
    }
  }
`;
