import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Label } from '../../../../../core/components/label';
import styles from './payment-method-value.styles';

function Value({ classes, children, className }) {
  return (
    <Label type="infoLabel" className={classNames(className, classes.value)}>
      {children}
    </Label>
  );
}

Value.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Value.defaultProps = {
  className: null
};

export default withStyles(styles)(Value);
