import { newCustomersDefaultDevices, advancedConnectedHomeStates, bixStates, affinityGroups } from '@ourbranch/lookups';
import { NEW_CUSTOMER_HOME_SECURITY, CONNECTED_HOME_MONITORED_TYPES } from 'core/helpers/constants';

export const updateUserInputWithConnectedHomeValues = (values) => {
  const { affinity, state, global, signUpForNewMonitoringService } = values;

  const isAdvancedConnectedHome = bixStates[state]
    ? advancedConnectedHomeStates[state].isBix
    : advancedConnectedHomeStates[state].isNotBix;

  const monitored = isAdvancedConnectedHome
    ? CONNECTED_HOME_MONITORED_TYPES.ADVANCED.MONITORED_BY_PARTNER
    : CONNECTED_HOME_MONITORED_TYPES.REGULAR.CENTRAL;

  const providerName = affinityGroups[affinity].name;

  const deviceCounts = signUpForNewMonitoringService
    ? newCustomersDefaultDevices[providerName]
    : {
        moistureDevices: 0,
        theftPreventionDevices: 0,
        motionDetectingDevices: 0,
        smokeDetectingDevices: 0,
        autoWaterShutoffDevices: false
      };

  return {
    ...values,
    connectedHome: {
      ...deviceCounts,
      monitored,
      providerName
    },
    global: {
      ...global,
      homeSecurityPartnerCustomerType: signUpForNewMonitoringService
        ? NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE
        : NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE
    }
  };
};
