import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  cardContainer: {
    width: 720,
    margin: '0 auto',
    padding: 32,
    backgroundColor: `#eae2d5`,
    border: 'solid 2px white'
  },
  content: {
    fontSize: 14,
    lineHeight: 1.43,
    margin: '8px 0px!important'
  },
  link: {
    color: theme.palette.secondary.main
  },
  checklistContainer: {
    maxWidth: 'none'
  }
});

export default makeStyles(styles);
