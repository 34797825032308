import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useObserver } from 'mobx-react';
import React from 'react';

import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';

import useStyles from './equity-breakdown.styles';

function EquityBreakdown() {
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const { policyEquityStatus: equity } = store;
  const classes = useStyles();

  return useObserver(() => (
    <Card type="secondary" className={classes.container}>
      <TableContainer>
        <Table className={classes.equitySummaryTable}>
          <TableBody>
            <TableRow>
              <TableCell>
                <ValueField
                  className={classes.valueField}
                  formatter={currencyFormatter}
                  label="Blended Daily Rate"
                  value={equity.blendedDailyRate}
                  mode="dark"
                />
              </TableCell>
              <TableCell>
                <ValueField
                  className={classes.valueField}
                  label="Remaining Payments"
                  value={store.remainingPayments}
                  mode="dark"
                />
              </TableCell>
              <TableCell>
                <ValueField
                  className={classes.valueField}
                  label={store.nextPaymentLabel}
                  value={store.nextPaymentValue}
                  mode="dark"
                />
              </TableCell>
              <TableCell>
                <ValueField className={classes.valueField} label="Policy Days" value={equity.policyDays} mode="dark" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ValueField
                  className={classes.valueField}
                  label="Earned Premium Days"
                  value={store.earnedPremiumDays}
                  mode="dark"
                />
              </TableCell>
              <TableCell>
                <ValueField
                  className={classes.valueField}
                  label="Billed Premium Days"
                  value={store.billedPremiumDays}
                  mode="dark"
                />
              </TableCell>
              <TableCell>
                <ValueField
                  className={classes.valueField}
                  label="Paid Premium Days"
                  value={store.paidPremiumDays}
                  mode="dark"
                />
              </TableCell>
              <TableCell>
                <ValueField
                  className={classNames(classes.valueField, { [classes.red]: store.paidEquityDaysRemaining < 0 })}
                  label="Paid Equity Days Left"
                  value={store.paidEquityDaysRemaining}
                  mode="dark"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  ));
}

export default EquityBreakdown;
