import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import _find from 'lodash-es/find';
import _pickBy from 'lodash-es/pickBy';
import _uniq from 'lodash-es/uniq';
import flat from 'flat';

import { Table, TableHeaderCell } from 'core';
import { awsDateToDateFormatter } from 'core/helpers/formatters';
import { Label } from 'core/components/label';
import { Link } from 'react-router-dom';
import NoResults from '../no-results';
import SelectableRow from './row';
import useStyles from './policies-results.styles';

const ATTRIBUTE_FRIENDLY_NAME = {
  firstName: 'first name',
  lastName: 'last name',
  cars: 'Car VIN',
  dateOfBirth: 'DOB',
  accountId: 'Account ID',
  id: 'Policy ID',
  state: 'State',
  mortgageHolderName: 'Mortgage Lender'
};

const PoliciesResults = ({
  loading,
  hits,
  totalHits,
  page,
  onChangePage,
  rowClickHandler,
  columnsToUse,
  showTitle,
  mode
}) => {
  const classes = useStyles({ mode });
  const handleChangePage = useCallback((_, newPage) => onChangePage(newPage), [onChangePage]);

  const policyLink = useCallback((hit) => {
    return `/customer/${hit.accountId}/policy/${hit.id}`;
  }, []);

  const getPrimaryApp = useCallback((people) => {
    return _find(people, (person) => person.isPrimary);
  }, []);

  const getMatchedAttributes = useCallback((hit) => {
    /* Flatten the highlight result object from algolia so this:
          { policyDetails: { cars: [ { VIN: { value: 1234567, matchLevel: "full" }} ] } }
        turns into this:
          { 'policyDetails.cars.0.VIN.matchLevel': "full", 'policyDetails.cars.0.VIN.value': 1234567 }
    */
    const matchedAttributes = [];
    // eslint-disable-next-line no-underscore-dangle
    const flattenedFullResults = flat(hit._highlightResult);
    const flattenedOnlyMatches = _pickBy(flattenedFullResults, (match) => match === 'full' || match === 'partial');

    Object.keys(flattenedOnlyMatches).forEach((fullAttributePath) => {
      Object.keys(ATTRIBUTE_FRIENDLY_NAME).forEach((attribute) => {
        if (fullAttributePath.includes(attribute)) {
          let friendlyAttributeName = ATTRIBUTE_FRIENDLY_NAME[attribute];
          if (attribute === 'firstName' || attribute === 'lastName') {
            friendlyAttributeName = fullAttributePath.includes('drivers')
              ? `Driver ${friendlyAttributeName}`
              : `Person ${friendlyAttributeName}`;
          }
          matchedAttributes.push(friendlyAttributeName);
        }
      });
    });

    return _uniq(matchedAttributes).join(', ');
  }, []);

  const headerTypes = {
    id: (
      <TableHeaderCell key="id" columnId="id">
        ID
      </TableHeaderCell>
    ),
    policyType: (
      <TableHeaderCell key="policyType" columnId="policyType">
        Policy Type
      </TableHeaderCell>
    ),
    firstName: (
      <TableHeaderCell key="firstName" columnId="firstName">
        Primary First Name
      </TableHeaderCell>
    ),
    lastName: (
      <TableHeaderCell key="lastName" columnId="lastName">
        Primary Last Name
      </TableHeaderCell>
    ),
    matchedOn: (
      <TableHeaderCell key="matchedOn" columnId="matchedOn">
        Matched On
      </TableHeaderCell>
    ),
    effectiveDate: (
      <TableHeaderCell key="effectiveDate" columnId="effectiveDate">
        Effective Date
      </TableHeaderCell>
    ),
    endDate: (
      <TableHeaderCell key="endDate" columnId="endDate">
        End Date
      </TableHeaderCell>
    ),
    state: (
      <TableHeaderCell key="state" columnId="state">
        State
      </TableHeaderCell>
    )
  };

  const columnTypes = {
    id: (hit) => <TableCell key="id">{hit.id}</TableCell>,
    policyType: (hit) => <TableCell key="policyType">{hit.policyType === 'A' ? 'Auto' : 'Home'}</TableCell>,
    firstName: (hit) => (
      <TableCell key="firstName">
        {getPrimaryApp(hit.policyDetails.drivers || hit.policyDetails.people)?.firstName}
      </TableCell>
    ),
    lastName: (hit) => (
      <TableCell key="lastName">
        {getPrimaryApp(hit.policyDetails.drivers || hit.policyDetails.people)?.lastName}
      </TableCell>
    ),
    matchedOn: (hit) => <TableCell key="matchedOn">{getMatchedAttributes(hit)}</TableCell>,
    effectiveDate: (hit) => (
      <TableCell key="effectiveDate">{awsDateToDateFormatter(new Date(hit.effectiveDate))}</TableCell>
    ),
    endDate: (hit) => <TableCell key="endDate">{awsDateToDateFormatter(new Date(hit.endDate))}</TableCell>,
    state: (hit) => <TableCell key="state">{hit.state}</TableCell>
  };

  const Header = () =>
    columnsToUse.map((name) => {
      return headerTypes[name];
    });

  const Row = ({ hit }) =>
    columnsToUse.map((name) => {
      return columnTypes[name](hit);
    });

  const handleRowClick = () => {
    // no-op
  };
  return (
    <>
      {showTitle && (
        <Label type="subtitle" style={{ marginBottom: 16 }}>
          Policies
        </Label>
      )}
      <Paper
        square={mode === 'dropdown'}
        elevation={mode === 'dropdown' ? 0 : null}
        className={classes.resultsContainer}
      >
        {Boolean(loading || hits.length) && (
          <Table
            selfContained={false}
            loading={loading}
            paginationEnabled={mode !== 'dropdown'}
            count={totalHits}
            rowsPerPage={hits.length}
            currentPage={page}
            onChangePage={handleChangePage}
            header={
              mode !== 'dropdown' ? (
                <>
                  <Header />
                </>
              ) : null
            }
            body={
              <>
                {Boolean(hits && hits.length) &&
                  hits.map((hit) => (
                    <Link to={policyLink(hit)} key={hit.id} disabled>
                      <SelectableRow
                        hover
                        id={hit.id}
                        onClick={rowClickHandler || handleRowClick}
                        data-cy={`customer-${hit.id}`}
                      >
                        <Row hit={hit} />
                      </SelectableRow>
                    </Link>
                  ))}
              </>
            }
          />
        )}
        {Boolean(!loading && hits.length === 0) && <NoResults />}
      </Paper>
    </>
  );
};

PoliciesResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  hits: PropTypes.array,
  totalHits: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  rowClickHandler: PropTypes.func,
  columnsToUse: PropTypes.arrayOf(
    PropTypes.oneOf(['id', 'firstName', 'lastName', 'effectiveDate', 'endDate', 'state', 'matchedOn'])
  ),
  showTitle: PropTypes.bool,
  mode: PropTypes.string
};

PoliciesResults.defaultProps = {
  hits: [],
  page: 1,
  onChangePage: null,
  totalHits: 0,
  rowClickHandler: null,
  columnsToUse: ['id', 'firstName', 'lastName', 'effectiveDate', 'endDate', 'state', 'matchedOn'],
  showTitle: true,
  mode: ''
};

export default PoliciesResults;
