import { useMemo } from 'react';
import lodashGroupBy from 'lodash-es/groupBy';
import { umUimCoverageArray } from '@ourbranch/lookups';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { currencyFormatter } from 'core/helpers/formatters';

const carsFieldTypeMapper = {
  COLL: 'deductibleCollision',
  COMP: 'deductibleComprehensive',
  TRNSP: 'limitRental',
  ROAD: 'roadsideAssistance',
  LOAN: 'coverageLoan',
  UMPD: 'limitUMPD'
};

const initialPremiumAutoCoverage = {
  policyLimitBIPD: 0,
  policyLimitMedicalPayments: 0,
  policyLimitUMBI: 0,
  policyLimitPIP: 0,
  policyLimitUMPD: 0,
  policyLimitUIMBI: 0
};

const getUmpd = (umpd) => (['UMUIMPD', 'UMPD'].includes(umpd) ? 'policyLimitUMPD' : 'policyLimitUMBI');
// implementation similar to quote-fe/src/review/auto-policy
const globalAutoLimits = (state) => {
  const limits = {
    BI: 'policyLimitBIPD',
    PD: 'policyLimitBIPD',
    OPS_EXP: 'policyLimitBIPD',
    ACQ_EXP: 'policyLimitBIPD',
    MED: 'policyLimitMedicalPayments',
    PIP: 'policyLimitPIP',
    UM_UIM: 'policyLimitUIMBI',
    UM: 'policyLimitUMBI',
    UIM: 'policyLimitUIMBI',
    UMUIMBI: 'policyLimitUMBI',
    UMUIMPD: 'policyLimitUMPD'
  };

  return (umUimCoverageArray[state] || []).reduce((acc, uim) => ({ ...acc, [uim]: getUmpd(uim) }), limits);
};

const accumulatePremiumAutoCoverage = (rates, autoLimits, premiumCoverage = initialPremiumAutoCoverage) =>
  rates.reduce(
    (acc, rate) => ({ ...acc, [autoLimits[rate.type]]: acc[autoLimits[rate.type]] + rate.amount }),
    premiumCoverage
  );

const getCarPremiumBreakdown = (rates, initialBreakdown) =>
  rates.reduce((breakdown, rate) => {
    if (carsFieldTypeMapper[rate.type]) {
      return { ...breakdown, [carsFieldTypeMapper[rate.type]]: currencyFormatter(rate.amount) };
    }

    return breakdown;
  }, initialBreakdown);

const formatPremiumCoverage = (premiumCoverage) => {
  if (!premiumCoverage) {
    return 0;
  }
  return Object.entries(premiumCoverage).reduce(
    (acc, [key, coverage]) => ({ ...acc, [key]: currencyFormatter(coverage) }),
    {}
  );
};

const parsePremiumBreakdown = (premiumBreakdown, carCount) => {
  const retObj = {};
  for (let i = 1; i <= carCount; i += 1) {
    retObj[i] = Object.entries(premiumBreakdown)
      .filter(([key, value]) => key.toLowerCase().startsWith(`it${i}-`))
      .reduce((coverages, [key, value]) => {
        return coverages.concat({ type: key.replace(/^it[0-9]-/i, '').toUpperCase(), amount: value });
      }, []);
  }
  return retObj;
};

const useAutoPremiumBreakdown = (offer, policyType, premiumBreakdown, cars) =>
  useMemo(() => {
    const { state } = offer?.quote?.correctedAddress || {};
    const { autoCoverages } = offer?.options?.find((o) => o.type === getQuoteFriendlySelectedOption(policyType)) || {};
    const autoLimits = globalAutoLimits(state);
    const groupedByIteration = Object.keys(premiumBreakdown || {}).length
      ? parsePremiumBreakdown(premiumBreakdown, cars.length)
      : lodashGroupBy(autoCoverages || [], 'iteration');
    const { premiumAutoCoverage, premiumCarsCoverages } = Object.entries(groupedByIteration).reduce(
      (acc, [key, rates]) => {
        const { premiumAutoCoverage: iterationAutoCoverage, premiumCarsCoverages: iterationCarCoverages } = acc;
        const car = cars[key - 1];
        if (!car) {
          return {};
        }

        const carPremiumBreakdown = getCarPremiumBreakdown(rates, {
          rideSharing: car.rideSharing ? 'Included' : 'Not included'
        });

        return {
          premiumCarsCoverages: {
            ...iterationCarCoverages,
            [car.VIN]: carPremiumBreakdown
          },
          premiumAutoCoverage: accumulatePremiumAutoCoverage(rates, autoLimits, iterationAutoCoverage)
        };
      },
      { premiumCarsCoverages: {}, premiumAutoCoverage: initialPremiumAutoCoverage }
    );

    return { premiumAutoCoverage: formatPremiumCoverage(premiumAutoCoverage), premiumCarsCoverages };
  }, [offer, policyType, premiumBreakdown, cars]);

export default useAutoPremiumBreakdown;
