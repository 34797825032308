import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store/';
import BaseDialog from 'core/components/base-dialog';
import { ConnectedHomeSettings } from 'common/components/discounts/connected-home-settings/index';
import { Label } from 'core/components/label';
import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';

const ConnectedHomeDiscountModal = observer(() => {
  const { offer } = useStore();

  const { setIncludeConnectedHome, setShowConnectedHomeModal, isAdvancedConnectedHome } = offer;

  const { values, setFieldValue } = useFormikContext();
  const connectedHomeRef = useRef(values.connectedHome);

  const onClose = () => {
    // if user did not fill in provider name, then form is likely not filled in
    if (values.connectedHome.providerName === undefined) {
      // clear the form, set connectedHome to null
      setFieldValue('connectedHome', null);
      setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
      setIncludeConnectedHome(false);
    } else if (connectedHomeRef.current.providerName) {
      // reset to last saved values
      setFieldValue('connectedHome', connectedHomeRef.current);
    }
    setShowConnectedHomeModal(false);
  };

  return (
    <BaseDialog onClose={onClose} open>
      <Label marginBottom="5px" type="subtitle">
        Connected Home Discount
      </Label>
      <ConnectedHomeSettings isAdvancedConnectedHome={isAdvancedConnectedHome} comingFromModal />
    </BaseDialog>
  );
});

export default ConnectedHomeDiscountModal;
