import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Card } from 'core/components/card';
import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import useStyles from './settings.styles';

const LeadSourceSettings = ({ leadSourceOptions, affinityOptions }) => {
  const classes = useStyles();

  const { canModifyAffinityAndLeadSource, canToggleEmployeeDiscount } = useContext(AuthContext);
  if (!canModifyAffinityAndLeadSource && !canToggleEmployeeDiscount) {
    return null;
  }

  return (
    <Section title="Offer Source" type="SubSection">
      <Card className={classes.container} type="secondary">
        <Grid container item xs={12} className={classes.content}>
          <Grid container xs={12}>
            {canToggleEmployeeDiscount && (
              <Grid container className={canModifyAffinityAndLeadSource ? classes.withBottomLine : null}>
                <FormField label="Employee Discount" xs={8} type="switch" name="global.employeeDiscount" mode="dark" />
              </Grid>
            )}
            {canModifyAffinityAndLeadSource && (
              <Grid container spacing={2}>
                <FormField
                  label="Lead Source"
                  xs={8}
                  type="select"
                  name="leadSource"
                  options={leadSourceOptions}
                  mode="dark"
                />
                <FormField
                  label="Affinity Code"
                  xs={4}
                  type="autocomplete"
                  name="global.affinity"
                  options={affinityOptions}
                  mode="dark"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Section>
  );
};

LeadSourceSettings.propTypes = {
  leadSourceOptions: PropTypes.array.isRequired,
  affinityOptions: PropTypes.array.isRequired
};

export default LeadSourceSettings;
