import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import Section from 'core/components/section';
import RepeatField from 'core/components/repeat-field';

function People({
  person: Person,
  addForm: AddForm,
  addLabel,
  title,
  disabled,
  id,
  singular,
  plural,
  children,
  type,
  classes,
  enableApplicantSwap,
  hasAuto,
  ...rest
}) {
  const [folded, setFolded] = useState([]);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const list = getIn(values, id) ?? [];

  const toggleFold = (i, foldedStatus) =>
    setFolded((prevFolded) => [...prevFolded.slice(0, i), foldedStatus, ...prevFolded.slice(i + 1, prevFolded.length)]);

  const pushItem = (val) => {
    const item = val;

    Object.keys(item).forEach((key) => {
      if (item[key] === '') {
        item[key] = null;
      }
    });
    setFieldValue(id, [...list, item]);
    setFieldTouched(id);
  };

  const removeItem = (i) => {
    const newList = list.slice();
    newList.splice(i, 1);
    setFieldValue(id, newList);
    setFieldTouched(id);
  };

  const swapPrimaryApplicant = () => {
    const newList = list.map((item) => {
      const { isPrimary, isCoApplicant } = item;

      const isApplicant = isPrimary || isCoApplicant;

      if (isCoApplicant) {
        setFieldValue('fname', item.firstName);
        setFieldValue('lname', item.lastName);
        setFieldTouched('fname');
        setFieldTouched('lname');
      }

      return {
        ...item,
        isPrimary: isApplicant && !isPrimary,
        isCoApplicant: isApplicant && !isCoApplicant
      };
    });

    setFieldValue(id, newList);
    setFieldTouched(id);
  };

  return (
    <Section
      classes={classes}
      title={title}
      type={type || 'SubSection'}
      rightLabel="Total"
      rightValue={`${list.length} ${list.size === 1 ? singular : plural}`}
    >
      {children}
      <RepeatField
        initialLength={list.length}
        key={list.length}
        maxSize={40}
        control={() => <AddForm id={id} disabled={disabled} onAdd={pushItem} label={addLabel} />}
      >
        {({ onRemove, focus }, i) => {
          const p = list[i];
          return (
            <Person
              fieldName={`${id}[${i}]`}
              fieldId={id}
              key={p.id || i}
              index={i}
              item={p}
              onRemove={removeItem}
              removeFromList={onRemove}
              focus={focus}
              initialFold={!!folded[i]}
              onFold={toggleFold}
              disabled={disabled}
              previous={list[i - 1]}
              count={list.length}
              swapPrimaryApplicant={swapPrimaryApplicant}
              enableApplicantSwap={enableApplicantSwap}
              hasAuto={hasAuto}
              {...rest}
            />
          );
        }}
      </RepeatField>
    </Section>
  );
}

People.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  person: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  addForm: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  addLabel: PropTypes.string,
  disabled: PropTypes.bool,
  singular: PropTypes.string,
  plural: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  classes: PropTypes.object,
  enableApplicantSwap: PropTypes.bool,
  hasAuto: PropTypes.bool
};

People.defaultProps = {
  title: 'People',
  disabled: false,
  singular: 'person',
  plural: 'people',
  addLabel: undefined,
  children: undefined,
  classes: {},
  type: 'SubSection',
  enableApplicantSwap: false,
  hasAuto: false
};

export default memo(People);
