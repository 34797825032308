import gql from 'graphql-tag';
import { OFFER_FRAGMENT } from 'core/fragments/offer';

export const GET_OFFER = gql`
  query ($offerId: ID!) {
    offer: getOffer(id: $offerId) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;

export const RECALCULATE_QUOTE = gql`
  query ($offerId: ID!, $revisedQuoteDetails: QuoteDetailsInput!) {
    offer: recalculateQuote(offerId: $offerId, revisedQuoteDetails: $revisedQuoteDetails) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;

export const RECALCULATE_QUOTE_IN_CLUSTER = gql`
  query ($clusterId: ID!, $offerId: ID!, $revisedQuoteDetails: QuoteDetailsInput!) {
    cluster: recalculateQuoteInCluster(
      clusterId: $clusterId
      offerId: $offerId
      revisedQuoteDetails: $revisedQuoteDetails
    ) {
      id
      offers {
        ...OfferFragment
      }
    }
  }
  ${OFFER_FRAGMENT}
`;

export const RECALCULATE_QUOTE_TO_CLUSTER = gql`
  query ($offerId: ID!, $revisedQuoteDetails: QuoteDetailsInput!) {
    cluster: recalculateQuoteToCluster(offerId: $offerId, revisedQuoteDetails: $revisedQuoteDetails) {
      id
      offers {
        ...OfferFragment
      }
    }
  }
  ${OFFER_FRAGMENT}
`;

export const REQUEST_BIND = gql`
  mutation ($request: BindRequestInput!) {
    requestBind(request: $request) {
      id
      systemId
    }
  }
`;

export const ADD_REFERRAL = gql`
  mutation addReferral($referralDetails: ReferralDetailsInput) {
    friendBuyLink: addReferral(referralDetails: $referralDetails)
  }
`;

export const DOWNLOAD_QUOTE = gql`
  mutation generateApplicationDraft($details: QuotePdfInput) {
    quotePdf: generateApplicationDraft(details: $details)
  }
`;

export const DOWNLOAD_OFFER = gql`
  query getOfferPdf($documentUrl: String!, $documentId: String!, $bypassCache: Boolean) {
    offerPdf: getDocraptorDocument(documentUrl: $documentUrl, documentId: $documentId, bypassCache: $bypassCache)
  }
`;

export const GET_POLICY_FROM_OFFER_ID = gql`
  query ($offerId: ID!) {
    getPolicyFromOfferId(offerId: $offerId) {
      accountId
      id
    }
  }
`;

export const ADD_DRIVERS_CARS_AND_RECALCULATE = gql`
  mutation (
    $offerId: ID!
    $newDrivers: [PersonDetailsInput]
    $newVins: [CarDetailsInput]
    $newTrailerVins: [TrailerDetailsInput]
    $revisedQuoteDetails: QuoteDetailsInput
  ) {
    cluster: addCarsAddDriversAndRecalculateQuoteToCluster(
      offerId: $offerId
      newDrivers: $newDrivers
      newVins: $newVins
      newTrailerVins: $newTrailerVins
      revisedQuoteDetails: $revisedQuoteDetails
    ) {
      id
      offers {
        ...OfferFragment
      }
    }
  }
  ${OFFER_FRAGMENT}
`;

export const ADD_TO_PRE_BIND_UW_REJECTIONS = gql`
  mutation ($webUserId: ID!, $ineligibleForHome: Boolean!, $ineligibleForAuto: Boolean!) {
    addToPreBindUWRejections(
      webUserId: $webUserId
      ineligibleForHome: $ineligibleForHome
      ineligibleForAuto: $ineligibleForAuto
    ) {
      webUserId
      ineligibleForHome
      ineligibleForAuto
    }
  }
`;

export const GET_PRE_BIND_UW_REJECTIONS_BY_WEB_USER_ID = gql`
  query ($webUserId: ID!) {
    getPreBindUWRejectionsByWebUserId(webUserId: $webUserId) {
      webUserId
      ineligibleForHome
      ineligibleForAuto
    }
  }
`;

export const RESET_COMMUNITY_DRIVE_LOGIN = gql`
  mutation resetCommunityDriveLogin($branchAccountId: ID!, $driverId: ID!) {
    reset: resetCommunityDriveLogin(branchAccountId: $branchAccountId, driverId: $driverId)
  }
`;

export const GET_CAR_DRIVER_SUGGESTIONS = gql`
  query getCarDriverSuggestions($offerId: ID!) {
    driverSuggestions: getDriverSuggestions(offerId: $offerId) {
      id
      firstName
      lastName
      birthDate
      gender
      maritalStatus
      driversLicenseState
      ageFirstLicensed
      age
    }

    carSuggestions: getCarSuggestions(offerId: $offerId) {
      VIN
      year
      make
      model
    }
  }
`;

export const CLEAR_REJECT_CODES = gql`
  mutation clearRejectCodes($offerId: ID!, $invokedFromStaff: Boolean) {
    offer: clearRejectCodes(offerId: $offerId, invokedFromStaff: $invokedFromStaff) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;
