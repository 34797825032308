import React, { useEffect, useCallback, useState, useContext } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { paymentType, lookupsJson } from '@ourbranch/lookups';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash-es';
import _range from 'lodash-es/range';
import { Formik, connect } from 'formik';

import { FormField } from 'core/components/form';
import { withToast } from 'core/components/toast';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { withStore } from 'core/store';
import { FormikCtxPropTypes } from 'core/components/form/propTypes';
import routingNumberSvg from 'common/assets/routing-number.svg';
import accountNumberSvg from 'common/assets/account-number.svg';
import { AuthContext } from 'core/components/auth';
import { bankInfoValidation } from './validation';
import styles from '../../policy-settings/policy-settings.styles';

const daysOfMonth = _range(1, 29).map((day) => ({ id: day, value: day.toString() }));

const BankWithdrawal = ({
  classes,
  formik,
  frequencyOptions,
  toast,
  store: {
    account: {
      policies: { policy }
    }
  }
}) => {
  const stripe = useStripe();
  const { setFieldValue, values, setFieldTouched } = formik;
  const { achAccountType } = lookupsJson;

  const allPaymentMethods = policy.billingDetails.allPaymentMethods || [];
  const bankAccounts = allPaymentMethods
    .filter((method) => method.id.startsWith('ba'))
    .map((account) => {
      return {
        id: account.id,
        value: account.last4.slice(-4).padStart(account.last4.length * 1.5, '*')
      };
    });

  const [showAccountField, setShowAccountField] = useState(!bankAccounts.length);
  const { canModifyBillingId } = useContext(AuthContext);

  useEffect(() => {
    if (formik.values.paymentType === 'E') {
      formik.setFieldValue('paymentType', paymentType.OneTime);
    }
  });

  const toggleAccountFields = (visible) => {
    setShowAccountField(visible);
  };

  const [checkImageClass, setCheckImageClass] = useState('routingNumber');

  // @ TODO Fix this
  const updateCheckImageClass = useCallback(
    ({ target }) => {
      setCheckImageClass(target.name === 'accountNumber' ? 'accountNumber' : 'routingNumber');
    },
    [setCheckImageClass]
  );

  const getAccountType = (type) => {
    return achAccountType.find((accountType) => accountType.id === type)?.value || type;
  };

  const createACHStripeToken = async (values) => {
    const accountTypeMap = { P: 'individual', B: 'company' };

    const { token, error } = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: values.routingNumber,
      account_number: values.accountNumber,
      account_holder_name: values.accountHolder,
      account_holder_type: accountTypeMap[values.accountType]
    });
    return { token, error };
  };

  const addAccount = async (values) => {
    if (
      !policy.billingDetails?.allPaymentMethods?.find(
        (paymentMethod) => values.accountNumber.endsWith(paymentMethod.last4) && paymentMethod.id.startsWith('ba')
      )
    ) {
      const { token, error } = await createACHStripeToken(values);
      if (!error) {
        // rename to match the GQL schema
        const accountData = {
          stripeToken: token.id,
          accountType: token.bank_account.account_holder_type,
          accountHolder: token.bank_account.account_holder_name,
          routingNumber: token.bank_account.routing_number,
          ...token.bank_account
        };
        setFieldValue('defaultBankAccount', accountData);
        policy.addBankAccount(accountData);
        setFieldTouched('stripeCustomerId', values.stripeCustomerId !== policy.stripeCustomerId);
        setFieldValue('stripeCustomerId', values.stripeCustomerId);
        setShowAccountField(false);
      } else {
        await toast.notify({
          type: 'error',
          message: `An error occurred, ${error.message}`
        });
      }
    } else {
      await toast.notify({
        type: 'error',
        message: 'Account already exists'
      });
    }
  };

  const StripeCustomerId = () => {
    return (
      canModifyBillingId && <FormField name="stripeCustomerId" xs={4} type="string" mode="dark" label="Billing ID" />
    );
  };

  return (
    <>
      <FormField
        name="paymentType"
        type="select"
        label="Payment Frequency"
        mode="dark"
        xs={4}
        options={frequencyOptions}
        permissions={{ isLicensedAction: false }}
      />
      <FormField
        name="billingDayOfMonth"
        type="select"
        label="Billing Day"
        mode="dark"
        icon="calendar"
        xs={4}
        options={daysOfMonth}
        permissions={{
          edit: {
            groups: [CognitoPermissionGroups.isService]
          }
        }}
      />
      {!showAccountField && (
        <Grid container key="account-selected" justify="space-between" className={classes.bankAccounts} spacing={4}>
          <Grid item xs={4}>
            <FormField
              name="defaultBankAccount.id"
              type="select"
              mode="dark"
              options={bankAccounts}
              label="Account Number"
              permissions={{ isLicensedAction: false }}
            />
            <Button
              style={{ paddingLeft: 0 }}
              onClick={() => toggleAccountFields(true)}
              variant="text"
              color="secondary"
            >
              Add new Account
            </Button>
          </Grid>
          <FormField
            name="renewalPaymentType"
            type="select"
            label="Renewal Payment Frequency"
            mode="dark"
            xs={4}
            options={frequencyOptions}
            permissions={{ isLicensedAction: false }}
          />
          <StripeCustomerId />
        </Grid>
      )}
      {showAccountField && (
        <Formik
          onSubmit={addAccount}
          initialValues={{
            accountType: '',
            accountNumber: '',
            routingNumber: '',
            accountHolder: '',
            stripeCustomerId: values.stripeCustomerId || ''
          }}
          validationSchema={bankInfoValidation}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container item key="account-info" justify="flex-start" spacing={4} alignItems="center">
                <FormField
                  name="accountHolder"
                  type="string"
                  label="Account Holder"
                  xs={4}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  name="accountType"
                  type="select"
                  options={achAccountType}
                  label="Account Type"
                  xs={4}
                  permissions={{ isLicensedAction: false }}
                />
                <StripeCustomerId canModifyBillingId={canModifyBillingId} />
              </Grid>
              <Grid container item key="bank-info" justify="flex-start" spacing={4} alignItems="center">
                <FormField
                  name="routingNumber"
                  type="string"
                  label="Routing Number"
                  xs={4}
                  InputProps={{
                    inputProps: {
                      maxLength: 9
                    }
                  }}
                  onBlur={(e) => {
                    e.persist();
                    updateCheckImageClass(e);
                  }}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  name="accountNumber"
                  type="string"
                  label="Account Number"
                  xs={4}
                  onBlur={(e) => {
                    e.persist();
                    updateCheckImageClass(e);
                  }}
                  permissions={{ isLicensedAction: false }}
                />
                <img
                  key="routing"
                  alt="routing number"
                  src={checkImageClass === 'routingNumber' ? routingNumberSvg : accountNumberSvg}
                  className={classes.checkImage}
                />
              </Grid>
              <Grid container item xs={12} key="addAccountButton">
                <Button
                  style={{ paddingLeft: 0 }}
                  variant="text"
                  color="secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Add account
                </Button>
                <Button onClick={() => toggleAccountFields(false)} variant="text" color="secondary">
                  Close
                </Button>
              </Grid>
              <Grid container key="payment-option" justify="flex-start" spacing={4} alignItems="center">
                <FormField
                  name="renewalPaymentType"
                  type="select"
                  label="Renewal Payment Frequency"
                  mode="dark"
                  xs={4}
                  options={frequencyOptions}
                  permissions={{ isLicensedAction: false }}
                />
              </Grid>
            </>
          )}
        </Formik>
      )}

      {!showAccountField && (
        <Grid container>
          <FormField
            name="defaultBankAccount.accountHolder"
            type="value"
            label="Account Holder"
            mode="dark"
            xs={4}
            value={values.defaultBankAccount.accountHolder}
          />
          <FormField
            name="defaultBankAccount.accountType"
            value={getAccountType(values.defaultBankAccount?.accountType)}
            type="value"
            label="Account Type"
            xs={4}
            mode="dark"
          />
          <FormField
            name="defaultBankAccount.routingNumber"
            type="value"
            label="Routing Number"
            xs={4}
            mode="dark"
            value={values.defaultBankAccount.routingNumber}
          />
        </Grid>
      )}
    </>
  );
};

BankWithdrawal.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: FormikCtxPropTypes.isRequired,
  store: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired,
  frequencyOptions: PropTypes.array.isRequired
};

export default flowRight(withToast, withStyles(styles), connect, withStore)(BankWithdrawal);
