import { Button, FormControl, Grid, LinearProgress, withTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Label } from '../../../../../core';
import { ActionButton } from '../../../../../core/components/action-button';
import styles from './credit-card-form.styles';

const CreditCardForm = memo(({ classes, onSubmit, theme, close, loading, buttonType = 'confirm' }) => {
  const [errors, setErrors] = useState(undefined);
  const [completed, setCompleted] = useState(undefined);

  const handleChange = useCallback(({ error, complete }) => {
    setErrors((currentError) => {
      if (currentError !== Boolean(error)) {
        return Boolean(error);
      }
    });

    setCompleted((currentCompleted) => {
      if (currentCompleted !== complete) {
        return complete;
      }
    });
  }, []);

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '14px',
        fontFamily: '"Libre Franklin", sans-serif',
        lineHeight: '36px',
        color: theme.colorPalette.white_10,
        '&$.CardBrandIcon-container': {
          display: 'none'
        },
        ':focus': {
          color: theme.colorPalette.white_10
        },
        '::placeholder': {
          color: theme.colorPalette.beige_10
        }
      }
    }
  };

  return (
    <Grid direction="column" container>
      <Grid container item alignItems="center" className={classes.container}>
        <FormControl className={classes.cardContainer}>
          <Label type="coverageLabel" className={classes.label}>
            Credit Card
          </Label>
          <CardElement className={classes.cardComponent} options={cardElementOptions} onChange={handleChange} />
        </FormControl>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="secondary"
          className={classes.submit}
          disabled={loading || errors || !completed}
        >
          {buttonType === 'confirm' ? 'Confirm' : 'Add Card'}
        </Button>
        <ActionButton type="close" alt="close form" onClick={close} disabled={loading} />
      </Grid>
      {loading && <LinearProgress color="secondary" className={classes.loadingBar} />}
    </Grid>
  );
});

CreditCardForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonType: PropTypes.string
};

CreditCardForm.defaultProps = {
  buttonType: 'confirm'
};

export default flowRight(withTheme, withStyles(styles))(CreditCardForm);
