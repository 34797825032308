import React from 'react';
import { HomeOffer } from 'common/components/home';
import useStyles from './home-details.styles';

const HomeDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HomeOffer />
    </div>
  );
};

export default HomeDetails;
