import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { observer } from 'mobx-react';
import flowRight from 'lodash-es/flowRight';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { setState, limitedBranchWindhailCoverage, windHailDeductibleCanVaryByCounty } from '@ourbranch/lookups';

import { useCurrentState } from 'common/hooks/useCurrentState';
import Field from 'core/components/form/form.v2';
import { NotificationCard } from 'core/components/notification-card';
import { useStore } from 'core/store';
import PurchaseOffSite from '../purchase-off-site';
import styles from '../styles';

const id = 'homeCoverage';

const getDisclaimers = () => ({
  AL: {
    firstPart: 'Branch does not cover for wind or hail damage in this zipcode. This coverage can be purchased through',
    link: 'https://aiua.org/',
    linkText: 'AIUA',
    thirdPart: 'as an additional purchase.'
  },
  GA: {
    firstPart:
      'Unfortunately, Branch does not cover damage caused by wind or hail at this address due to its proximity to the coastline. Coverage for this type of loss can be purchased through the',
    link: 'https://www.georgiaunderwriting.com/',
    linkText: 'GUA'
  }
});

const WindHailFlow = observer(
  ({
    classes,
    formik: {
      values: { homeCoverage, home, ...formikValues }
    },
    fromPolicy
  }) => {
    const state = useCurrentState(formikValues);
    const {
      account: {
        policies: { policy: policyStore }
      }
    } = useStore();
    const externalPolicyId = policyStore.policy?.policyDetails?.homeCoverage?.externalPolicyId;
    const { interestedInSeparateWindHail } = homeCoverage;
    const disclaimer = getDisclaimers()[state];
    const showDisclaimer =
      disclaimer &&
      (!limitedBranchWindhailCoverage || !limitedBranchWindhailCoverage[state]) &&
      ((windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][home.county]) ||
        (state === 'AL' && !fromPolicy));
    setState(state);

    return (
      <>
        {showDisclaimer && (
          <NotificationCard type="quaternary">
            <Grid container alignItems="center">
              {disclaimer.firstPart}{' '}
              <a rel="noreferrer noopener" target="_blank" href={disclaimer.link} className={classes.link}>
                {disclaimer.linkText}
              </a>{' '}
              {disclaimer.thirdPart}
            </Grid>
          </NotificationCard>
        )}
        {state === 'AL' && (
          <>
            <Field
              id={`${id}.interestedInSeparateWindHail`}
              name={`${id}.interestedInSeparateWindHail`}
              type="switch"
              label="Purchase Wind & Hail coverage separately"
              mode="dark"
              xs={12}
              fast={false}
            />
            {fromPolicy && interestedInSeparateWindHail && (
              <PurchaseOffSite
                name={`${id}.externalPolicyId`}
                partnerName={disclaimer.linkText}
                purchaseLink={disclaimer.link}
                policyId={externalPolicyId}
              />
            )}
          </>
        )}
      </>
    );
  }
);

WindHailFlow.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  fromPolicy: PropTypes.bool
};

WindHailFlow.defaultProps = {
  fromPolicy: false
};

export default flowRight(withStyles(styles), connect)(WindHailFlow);
