import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  secondary: {
    padding: '24px 32px',
    margin: 0,
    overflow: 'visible'
  },
  innerCheckbox: {
    paddingLeft: 50
  }
}));

export default useStyles;
