import PropTypes from 'prop-types';

export const DialogBodyPropTypes = {
  classes: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  label: PropTypes.string
};

export const ErrorDialogPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  ...DialogBodyPropTypes
};
