import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  noMarginBottom: {
    marginBottom: 0
  },
  suggestionsContainer: {
    position: 'absolute',
    width: 432,
    zIndex: 10
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
});

export default useStyles;
