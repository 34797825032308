import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiSlider from '@material-ui/core/Slider';
import ButtonBase from '@material-ui/core/ButtonBase';
import classNames from 'classnames';
import { numberAbbrFormatter } from '../../helpers/formatters';
import { Label } from '../label';
import styles from './slider.styles';

class Slider extends PureComponent {
  static propTypes = {
    value: PropTypes.number.isRequired,
    options: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    showLabels: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    mode: PropTypes.oneOf(['dark', 'light']),
    label: PropTypes.string
  };

  static defaultProps = {
    showLabels: true,
    className: '',
    label: '',
    mode: 'light',
    disabled: false
  };

  isMin = (val) => this.props.options[0] === val;

  isMax = (val) => this.props.options[this.props.options.length - 1] === val;

  labelPosition = (lbl) => {
    const { options } = this.props;
    const max = options[options.length - 1];
    const min = options[0];
    const eqValue = ((lbl - min) * 100) / (max - min);
    const percent = Math.min(eqValue, 100);
    return { left: `${percent}%` };
  };

  handleClick = (opt) => {
    if (!this.props.disabled && this.props.value !== opt) {
      this.props.onUpdate(opt);
    }
  };

  render() {
    const { classes, value, options, showLabels, className, label, mode, ...props } = this.props;
    return (
      <div className={classNames(classes.sliderContainer, className)} {...props}>
        {label && (
          <Label type="infoLabel" className={classes[mode ? `${mode}-label` : 'label']}>
            {label}
          </Label>
        )}
        <MuiSlider
          disabled
          step={options[options.length - 1] / options[0]}
          value={value}
          min={options[0]}
          max={options[options.length - 1]}
          classes={{
            track: classes.sliderTrack,
            disabled: classes.sliderDisabled,
            thumb: classes.sliderThumb,
            root: classes.sliderRoot
          }}
        />
        <div className={classes.sliderLblsContainer}>
          <div className={classes.sliderLblsContent}>
            {options.map((opt) => {
              const style = this.labelPosition(opt);
              const lbl = numberAbbrFormatter(opt);
              return (
                <div
                  key={`container-${opt}`}
                  onClick={() => this.handleClick(opt)}
                  style={{ ...style, position: 'absolute' }}
                >
                  <ButtonBase key={`thumb-${opt}`} className={classes.labelThumb} />
                  <span
                    key={opt}
                    className={classNames(
                      classes.labels,
                      { [classes.active]: value === opt },
                      { [classes.border]: this.isMin(opt) || this.isMax(opt) }
                    )}
                  >
                    {showLabels || this.isMin(opt) || this.isMax(opt) || value === opt ? `${lbl}` : null}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Slider);
