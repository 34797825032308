import React, { useEffect, useCallback, useContext, useMemo, useState } from 'react';
import { observer, useAsObservableSource } from 'mobx-react';
import { reaction } from 'mobx';
import { Formik, yupToFormErrors } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import { utcToZonedTime } from 'date-fns-tz';
import _differenceWith from 'lodash-es/differenceWith';
import uuidv4 from 'uuid/v4';
import { preBindMVRStates, coverageRSExtendedForcedStates, coverageRSForcedStates } from '@ourbranch/lookups';
import Grid from '@material-ui/core/Grid';

import { stringDateToAwsDate, awsDateToDateObject, dateFormatter, cleanObject } from 'core/helpers/formatters';
import { getValues, formatInput, sortItems } from 'core/helpers/scheduled-pp-helper';
import { affinityCodeToLabel, affinityLabelToCode } from 'core/helpers/affinity-formatters';
import { formatUmbrellaValues } from 'common/components/home/coverages/umbrella/umbrella';
import { haveAnyOfThisCarsSymbolsChanged } from 'common/helpers/car-symbols-helpers';
import {
  formatValues as formatHomeValues,
  getHighestEducation,
  getOldestResident
} from 'common/components/home/detail/helper';
import { types } from 'core/helpers/sanitize';
import { track } from 'core/helpers/analytics';
import { isArrayOfObjectsEqual } from 'core/helpers/equality-checker';
import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import LayoutWithSidebar from 'core/components/layout-with-sidebar';
import { getPrimaryOrBlankMortgageDetails, updatePrimaryMortgageDetails } from 'offer/helpers/mortgage-helpers';
import { AuthContext } from 'core/components/auth';
import { useToast } from 'core/components/toast';
import { buildSchema } from './offer.validation';
import { Offer, OfferSidebar, OfferHeader } from '..';
import VerifyProperty from '../verify-property/verify-property-container';
import { getUWPreBindHomeVerificationInitialValues } from '../verify-property/get-UW-pre-bind-home-verification-initial-values';
import { FormAction } from 'core/store/offer-store';

import useStyles from './provider.styles';

const OfferProvider = observer(({ offerId }) => {
  const history = useHistory();
  const toast = useToast();
  const urlParams = useParams();
  const params = useAsObservableSource(urlParams);
  const session = useContext(AuthContext);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const classes = useStyles();
  const {
    user: { username }
  } = session;
  if (!urlParams.offerId) {
    throw new Error('Invalid URL');
  }
  const {
    offer: store,
    matchSearch: { setShowCustomerMatches }
  } = useStore();

  const UWPreBindHomeVerificationInitialValues = getUWPreBindHomeVerificationInitialValues(store?.offer?.quote?.home);
  const selectedOptionFromQuote = store?.offer?.quote?.selectedOption;
  const queryParamsMap = queryString.parse(window.location.search);
  const primaryMortgageDetail = getPrimaryOrBlankMortgageDetails(store?.offer?.quote?.home?.mortgageDetails);

  const initialValues = useMemo(() => {
    return {
      ...store?.offer?.quote,
      auto: {
        ...store?.offer?.quote?.auto,
        pipAdditionalResidents: store?.offer?.quote?.auto?.pipAdditionalResidents || 0
      },
      selectedOption: selectedOptionFromQuote || queryParamsMap.option || store?.defaultSelectedOption,
      primaryMortgageDetail,
      highestEducation:
        store?.offer?.quote?.people || store?.offer?.quote?.drivers
          ? getHighestEducation(store?.offer?.quote?.people || store?.offer?.quote?.drivers)
          : undefined,
      oldestResident:
        store?.offer?.quote?.people || store?.offer?.quote?.drivers
          ? getOldestResident(store?.offer?.quote?.people || store?.offer?.quote?.drivers)
          : undefined,
      scheduledPersonalProperty: {
        deductible: store?.offer?.quote?.scheduledPersonalProperty?.items
          ? store?.offer?.quote?.scheduledPersonalProperty?.deductible
          : null,
        items: store?.offer?.quote.scheduledPersonalProperty?.items.length
          ? sortItems(store.offer.quote.scheduledPersonalProperty.items.map((item) => getValues(item)))
          : []
      },
      umbrellaCoverage: store?.offer?.quote?.includeUmbrella
        ? {
            watercraftHullLengths: [],
            rentalPropertyAddresses: [],
            otherPropertyAddresses: [],
            numRVs: 0,
            numMotorcyclesScooters: 0,
            numATVs: 0,
            numPersonalWatercraft: 0,
            liabilityCoverageLimit: 0,
            ...cleanObject({ ...store?.offer?.quote?.umbrellaCoverage })
          }
        : '',
      dontOverwriteRep: false,
      global: store?.offer?.quote?.global
        ? {
            ...store.offer.quote.global,
            preBindUWCheck: {
              homeVerification: {
                ...UWPreBindHomeVerificationInitialValues.initialValues,
                ineligibleForHomeDueToUWSelfReport: false,
                attestationAgent: username
              }
            },
            affinity: store.offer.quote.global.affinity && affinityCodeToLabel(store.offer.quote.global.affinity)
          }
        : null
    };
  }, [
    store,
    primaryMortgageDetail,
    queryParamsMap.option,
    selectedOptionFromQuote,
    UWPreBindHomeVerificationInitialValues,
    username
  ]);

  useEffect(() => {
    if (params.offerId !== store?.offer?.id) {
      store.getOffer(params.offerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // might want to .dispose() of this?
  reaction(
    () => store.loading,
    (loading) => {
      if (!loading && !store.offer) {
        history.push('/search/offers');
      }
    }
  );

  const validate = useCallback(
    async (values) => {
      try {
        await buildSchema({
          formAction: store.formAction,
          includeConnectedHome: store.includeConnectedHome,
          isAdvancedConnectedHome: store.isAdvancedConnectedHome,
          needMVRs: preBindMVRStates.includes(store.state) && values.drivers.some((driver) => driver.postBindMVR),
          values,
          session
        }).validate(values, {
          abortEarly: false,
          context: { ...values, initialValues, canAddCarsManually: session.canAddCarsManually }
        });
      } catch (error) {
        const formErrors = yupToFormErrors(error);

        // disabling eslint for the console log so we can monitor validation errors in prod
        // eslint-disable-next-line
        console.log({ formErrors });
        return formErrors;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store.formAction, store.includeConnectedHome, store.isAdvancedConnectedHome, session]
  );

  const submitRecalculate = async (details) => {
    setShowLoadingPage(true);

    if (store.formAction !== FormAction.PreBindUWValidation) {
      // because the preBindUW form renders conditionally
      // we need to grab values from the store when we are not in the PreBindUWValidation form submission
      const { global } = details;
      const newGlobal = {
        ...global,
        preBindUWCheck: store.offer.quote.global.preBindUWCheck
      };
      details.global = newGlobal;
    }

    const formattedMortgageDetails = updatePrimaryMortgageDetails(
      details?.home?.mortgageDetails,
      details.primaryMortgageDetail,
      details?.global?.homeownersPaymentMethod
    );

    if (coverageRSExtendedForcedStates[store.state]) {
      details.homeCoverage.coverageRSExtended = true;
    }
    details.drivers.forEach((d) => {
      if (d?.clearUDRViolation) d.gotDrivingRecord = true;
    });

    if (coverageRSForcedStates[store.state]) {
      details.homeCoverage.coverageRSExtended = false;
    }

    const driversToRecheck = store.driverIdsToRecheck.map((driverId) => {
      const driverToRecheck = details.drivers.find((driver) => driver.id === driverId);
      const oldDriverIndex = details.drivers.indexOf(driverToRecheck);
      driverToRecheck.id = uuidv4();
      details.drivers.splice(oldDriverIndex, 1);

      return types.PersonDetailsInput(driverToRecheck);
    });

    if (details?.global?.affinity) {
      details.global.affinity = affinityLabelToCode(details.global.affinity);
    }

    let formattedDetails = {
      ...details,
      connectedHome: store.includeConnectedHome ? details.connectedHome : null,
      fromStaff: true,
      rep: details.dontOverwriteRep && details.rep?.length > 0 ? details.rep : username,
      umbrellaCoverage: formatUmbrellaValues(details.umbrellaCoverage, details.includeUmbrella),
      scheduledPersonalProperty: formatInput(details.scheduledPersonalProperty),
      home: { ...formatHomeValues(details.home), mortgageDetails: formattedMortgageDetails },
      rentersCoverage: details.includeRenters && details.rentersCoverage ? details.rentersCoverage : undefined,
      autoCoverage: details?.autoCoverage
        ? {
            ...details.autoCoverage,
            policyLimitPIPWL: details?.drivers.some((d) => d.waivedPIPWL) ? 'S/WLW' : 'S'
          }
        : null
    };

    formattedDetails = types.QuoteDetailsInput(formattedDetails);

    track('Staff Modify Offer', { offer: { ...formattedDetails } });

    // check if we need to call rater the event field that also will add drivers/cars
    const shouldAddDrivers =
      !isArrayOfObjectsEqual(details.drivers, store.offer.quote.drivers) &&
      details.drivers.length > store.offer.quote.drivers.length;
    const shouldAddCars =
      !isArrayOfObjectsEqual(details.cars, store.offer.quote.cars) &&
      details.cars.length > store.offer.quote.cars.length;
    const shouldAddTrailers =
      !isArrayOfObjectsEqual(details.trailers, store.offer.quote.trailers) &&
      details.trailers?.length > store.offer.quote.trailers?.length;

    if (shouldAddDrivers || shouldAddCars || shouldAddTrailers || driversToRecheck.length >= 1) {
      // format data to all be same shape so the lodash differenceWith method works properly
      const driversIn = details.drivers.map((driver) => {
        return types.PersonDetailsInput(driver);
      });
      const savedDriversOnOffer = store.offer.quote.drivers.map((driver) => {
        return types.PersonDetailsInput(driver);
      });

      // for new/existing drivers,cars & trailers use the input subtracting the new items, otherwise you won't capture any changes to existing items.

      const newDrivers = _differenceWith(
        driversIn,
        savedDriversOnOffer,
        (driverIn, existingDriver) => driverIn.id === existingDriver.id
      ).map((newDriver) => {
        return types.PersonDetailsInput(newDriver);
      });

      const existingDrivers = _differenceWith(
        details.drivers,
        [...newDrivers, ...driversToRecheck],
        (existingDriver, driverIn) => existingDriver.id === driverIn.id
      ).map((newDriver) => {
        return types.PersonDetailsInput(newDriver);
      });

      const newCars = _differenceWith(details.cars, store.offer.quote.cars, (a, b) => a.VIN === b.VIN).map((newCar) => {
        return types.CarDetailsInput(newCar);
      });

      const existingCars = _differenceWith(details.cars, newCars, (a, b) => a.VIN === b.VIN).map((car) => {
        return types.CarDetailsInput(car);
      });

      const hasSymbols = (car, initialValuesCars) => haveAnyOfThisCarsSymbolsChanged(car, initialValuesCars);
      if (details.cars?.some((car) => hasSymbols(car))) {
        details.cars.forEach((car) => {
          if (hasSymbols(car, initialValues.cars)) {
            car.manuallyAddedSymbols = true;
            car.symbolMake = car.symbolMake?.toUpperCase();
            car.symbolModel = car.symbolModel?.toUpperCase();
            car.symbolStyle = car.symbolStyle?.toUpperCase();
            car.symbolAux = car.symbolAux?.toUpperCase();
          }
        });
      }

      const newTrailers = _differenceWith(details.trailers, store.offer.quote.trailers, (a, b) => a.VIN === b.VIN).map(
        (newTrailer) => {
          return types.TrailerDetailsInput(newTrailer);
        }
      );

      const existingTrailers = _differenceWith(details.trailers, newTrailers, (a, b) => a.VIN === b.VIN).map(
        (newTrailer) => {
          return types.TrailerDetailsInput(newTrailer);
        }
      );

      await store.addDriversAddCarsAndRecalculateCluster({
        newDrivers: [...newDrivers, ...driversToRecheck],
        newVins: newCars,
        newTrailerVins: newTrailers,
        revisedQuoteDetails: {
          ...formattedDetails,
          drivers: existingDrivers,
          cars: existingCars,
          trailers: existingTrailers
        },
        offerId: store.offer.id,
        history
      });

      if (store.hasOneOrMoreUDRs) {
        toast.notify({ type: 'error', message: 'A driver’s driving record could not be verified.' });
      }
    } else {
      /*
      if the effective dates are not today, we calculate new effective date (this operation also updates the rate control date)
      note, we need to do this in the same named function, or else formik will think our form has changed and reset everything
      */
      const { global } = details;
      const autoEffectiveDate = awsDateToDateObject(global.autoEffectiveDate);
      const homeEffectiveDate = awsDateToDateObject(global.homeEffectiveDate);
      const now = utcToZonedTime(new Date(), store.timezone);
      const shouldUpdateAuto = !isSameDay(autoEffectiveDate, now) && !isAfter(autoEffectiveDate, now);
      const shouldUpdateHome = !isSameDay(homeEffectiveDate, now) && !isAfter(homeEffectiveDate, now);

      if (shouldUpdateHome || shouldUpdateAuto) {
        let updatedAutoEffectiveDate = global.autoEffectiveDate;
        let updatedHomeEffectiveDate = global.homeEffectiveDate;

        if (shouldUpdateAuto) {
          updatedAutoEffectiveDate = stringDateToAwsDate(dateFormatter(now));
        }
        if (shouldUpdateHome) {
          updatedHomeEffectiveDate = stringDateToAwsDate(dateFormatter(now));
        }

        const newGlobal = {
          ...global,
          autoEffectiveDate: updatedAutoEffectiveDate,
          homeEffectiveDate: updatedHomeEffectiveDate
        };

        formattedDetails = { ...formattedDetails, global: newGlobal };
      }

      await store.recalculateQuoteToCluster(store.offer.id, formattedDetails, history);
    }

    setShowCustomerMatches(false);
    setShowLoadingPage(false);
  };

  return (
    <Formik
      onSubmit={submitRecalculate}
      initialValues={initialValues}
      validate={validate}
      enableReinitialize
      validateOnChange={!store.showPreBindUWForm}
      validateOnBlur={!store.showPreBindUWForm}
    >
      {!store.showPreBindUWForm ? (
        <LayoutWithSidebar
          content={Offer}
          side={OfferSidebar}
          offerId={offerId}
          history={history}
          header={OfferHeader}
          onBack={() => history.push('/search/offers')}
        />
      ) : showLoadingPage ? (
        <Grid className={classes.loading}>
          <Loading noBackground />
        </Grid>
      ) : (
        <VerifyProperty
          conditions={UWPreBindHomeVerificationInitialValues.conditions}
          submitRecalculate={submitRecalculate}
          initialValues={UWPreBindHomeVerificationInitialValues.initialValues}
        />
      )}
    </Formik>
  );
});

export default OfferProvider;
