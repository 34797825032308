import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import NotificationType from 'core/helpers/notifications';
import { NotificationCard } from 'core/components/notification-card';
import { useStore } from 'core/store';
import { SignedDocumentsNotification } from '../signed-documents-notification';
import { PendingThirdPartyNotification } from '../pending-third-party-notification';
import { CrossSellEligibilityNotification } from 'customer/components/cross-sell-eligibility-notification';

const Notification = {
  [NotificationType.Policy.CrossSellEligibility]: CrossSellEligibilityNotification,
  [NotificationType.Policy.SignedConversionDocuments]: SignedDocumentsNotification,
  [NotificationType.Policy.PendingThirdPartyPurchases]: PendingThirdPartyNotification
};

const PolicyNotifications = observer(({ type, onClick }) => {
  const {
    account: { policies: policiesStore }
  } = useStore();
  return (
    <Grid id="notificationsContainer">
      {policiesStore.notifications.map((notificationType) => {
        const NotificationComponent = Notification[notificationType];
        return (
          <NotificationCard key={notificationType} type={type}>
            <NotificationComponent onClick={onClick} />
          </NotificationCard>
        );
      })}
    </Grid>
  );
});

PolicyNotifications.propTypes = {
  onClick: PropTypes.func
};

PolicyNotifications.defaultProps = {
  onClick: null
};

export default PolicyNotifications;
