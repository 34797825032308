import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttons: {
    padding: '0px 30px',
    marginRight: 20,
    marginBottom: 20
  },
  notificationContent: {
    color: 'black'
  },
  buttonContainer: {
    marginTop: 20
  },
  mainLabel: {
    marginTop: 20
  },
  subLabel: {
    margin: '15px 20px 4px 0px '
  }
});

export default makeStyles(styles);
