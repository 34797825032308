export const getFriendBuyData = (urlParams) => {
  const { fbuy, referralCode: urlReferralCode, campaignName } = urlParams || {};
  return new Promise((resolve, _reject) => {
    if (fbuy && process.browser && window.friendbuyAPI) {
      window.friendbuyAPI.push([
        'getVisitorStatus',
        ({ payload, status }) => {
          if (status === 'success') {
            const { advocate, referralCode, campaignId } = payload || {};
            const { customerId } = advocate || {};
            if (advocate || referralCode) {
              resolve({
                encryptedAttribution: fbuy,
                inviterId: customerId,
                friendBuyReferralCode: referralCode || urlReferralCode,
                firstName: advocate?.firstName,
                lastName: advocate?.lastName,
                campaignId,
                campaignName
              });
            }
            // sometimes the fbuy sdk doesn't give us anything but we still need this data to trigger the referral flow
            resolve({ encryptedAttribution: fbuy, friendBuyReferralCode: urlReferralCode, campaignName });
          }
          resolve(null);
        }
      ]);
    }
  });
};
