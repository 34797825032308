import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useStore } from 'core/store/';
import { Discount } from '../discount';

const Inventory = ({ isActive, ...discount }) => {
  const { setFieldValue, values } = useFormikContext();
  const { offer: store } = useStore();
  const isApplied = values.global ? values.global.discountInventoryScore : isActive;

  const onAddClick = useCallback(() => {
    setFieldValue('global.discountInventoryScore', true);
    store.setIncludeInventory(true);
  }, [setFieldValue, store]);

  const onRemoveClick = useCallback(() => {
    setFieldValue('global.discountInventoryScore', false);
    store.setIncludeInventory(false);
  }, [setFieldValue, store]);

  return <Discount {...discount} isActive={isApplied} onAdd={onAddClick} onRemove={onRemoveClick} />;
};

export default Inventory;
