import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { TableHeaderCell } from 'core';
import { dateFormatter } from 'core/helpers/formatters';
import { ElemType, MapClassesToElem } from 'core/helpers/styles-helpers';
import Checkbox from 'core/components/checkbox';
import { theme } from 'core/components/theme/theme';

const headerStyle = {
  color: theme.colors.light_text,
  opacity: 0.6,
  fontSize: 10,
  cursor: 'pointer'
};

export const IncidentTableHeader = ({ isAutoPolicy, classes, onSort, canScrub }) => {
  return (
    <>
      {canScrub && (
        <TableCell style={headerStyle} className={classes.tableHeader}>
          TO SCRUB
        </TableCell>
      )}
      <TableHeaderCell key={'date'} columnId={'date'} onSort={onSort} className={classes.tableSortLabelRoot}>
        Date
      </TableHeaderCell>
      {isAutoPolicy && <TableCell style={headerStyle}>DRIVER</TableCell>}
      <TableCell style={headerStyle}>TYPE</TableCell>
      {isAutoPolicy && <TableCell style={headerStyle}>DATA SOURCE</TableCell>}
    </>
  );
};

IncidentTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  isAutoPolicy: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  canScrub: PropTypes.bool.isRequired
};

const IncidentTableRows = ({
  incidents,
  classes,
  page,
  rowsPerPage,
  isAutoPolicy,
  onSelect,
  selectedIncidents,
  canScrub
}) => {
  return (
    <>
      {incidents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((incident, index) => (
        <TableRow key={index} hover classes={MapClassesToElem(`dark-${ElemType.TableRow}`, classes)}>
          {canScrub && (
            <TableCell classes={MapClassesToElem(`dark-${ElemType.TableCell}`, classes)}>
              <Checkbox
                mode="dark"
                id={`checkbox-${index}`}
                label=""
                onChange={({ target: { checked } }) => onSelect(incident, checked)}
                value={incident.id in selectedIncidents}
              />
            </TableCell>
          )}
          <TableCell classes={MapClassesToElem(`dark-${ElemType.TableCell}`, classes)}>
            {dateFormatter(incident.date)}
          </TableCell>
          {isAutoPolicy && (
            <TableCell classes={MapClassesToElem(`dark-${ElemType.TableCell}`, classes)}>{incident.driver}</TableCell>
          )}
          <TableCell classes={MapClassesToElem(`dark-${ElemType.TableCell}`, classes)}>{incident.type}</TableCell>
          {isAutoPolicy && (
            <TableCell classes={MapClassesToElem(`dark-${ElemType.TableCell}`, classes)}>{incident.source}</TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

IncidentTableRows.propTypes = {
  classes: PropTypes.object.isRequired,
  incidents: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  selectedIncidents: PropTypes.object.isRequired,
  isAutoPolicy: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  canScrub: PropTypes.bool.isRequired
};

export default IncidentTableRows;
