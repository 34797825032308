import { Formik } from 'formik';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Yup from 'yup';
import { add } from 'date-fns';

import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import PriceDetails from './price-details';
import PaymentDetails from './payment-details';
import awsExports from '../../../../aws-exports';
import Footer from '../footer';
import { localToUtcTime } from '../dates';
import { validationSchema } from './payment-tab.validation';

const stripePromise = loadStripe(awsExports.stripeKey);

const PaymentTab = observer(({ loadingPreview, handleChangePolicy }) => {
  const session = useContext(AuthContext);
  const { isTeamLeader, canEdit } = session;
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const onSubmit = (values) => {
    handleChangePolicy({
      ...policyStore?.policy,
      fees: values.fees,
      billingAddInstallments: values.billingAddInstallments,
      billingHoldUntil: values.billingHoldUntil,
      skipImmediateBillOrRefund: values.skipImmediateBillOrRefund
    });
    policyStore.setChanged(false);
  };

  const showFooter = canEdit && policyStore.changed;

  if (policyStore.loading) {
    return <Loading type="secondary" />;
  }

  const determineMaxBillingHoldDate = () => {
    // billing holds should only last up to 7 days after billingDayOfMonth
    const billingDayOfMonth = policyStore?.policy?.billingDayOfMonth;

    // start with the billingDayOfMonth without adding anything yet
    const maxBillingHoldDate = localToUtcTime(
      new Date().setDate(billingDayOfMonth),
      policyStore?.geographicState
    ).setHours(0, 0, 0, 0);

    // need to add a month and a week if today is after billing date
    if (new Date().getDate() > billingDayOfMonth) {
      return add(maxBillingHoldDate, { weeks: 1, months: 1 });
    } // otherwise, just add a week
    return add(maxBillingHoldDate, { weeks: 1 });
  };

  return (
    <Elements stripe={stripePromise}>
      <Formik
        initialValues={{
          billingAddInstallments: 0,
          billingHoldUntil: policyStore.policy ? policyStore.policy.billingHoldUntil : undefined,
          fees: policyStore.policy.fees,
          maxBillingHoldDate: isTeamLeader ? null : determineMaxBillingHoldDate()
        }}
        validationSchema={() => {
          return Yup.lazy((values) =>
            validationSchema({
              values,
              isTeamLeader,
              geographicState: policyStore?.geographicState
            })
          );
        }}
        onSubmit={onSubmit}
      >
        <>
          <PriceDetails />
          <PaymentDetails />
          {showFooter && <Footer title="Save changes" loadingPreview={loadingPreview} />}
        </>
      </Formik>
    </Elements>
  );
});

PaymentTab.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  handleChangePolicy: PropTypes.func.isRequired
};

export default PaymentTab;
