import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subSection: {
    borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`,
    padding: '24px 32px',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  loadingWheel: {
    boxShadow: 'none'
  }
}));

export default useStyles;
