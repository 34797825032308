export default (theme) => ({
  title: {
    color: theme.colorPalette.green_10,
    marginBottom: 32
  },
  titleSecondary: {
    color: theme.colorPalette.green_20,
    fontSize: 20,
    lineHeight: 1.2,
    marginBottom: 20
  },
  titleSecondaryBolded: {
    color: theme.colorPalette.green_20,
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 'bold',
    display: 'inline-block',
    marginLeft: 5
  },
  textarea: {
    fontSize: 16,
    minHeight: 100,
    lineHeight: '30px',
    width: 450
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  btn: {
    width: '50%'
  }
});
