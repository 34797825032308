export const selectOptions = (from = 0, to = 5) => {
  if (from > to) {
    throw new Error('"To" must be higher than from');
  }
  const opts = [];
  for (let i = from; i <= to; i += 1) {
    opts.push({ id: i, value: i.toString() });
  }
  return opts;
};
