import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import emailIcon from 'core/assets/svg/email.svg';
import { Card } from 'core/components/card';
import { withToast } from 'core/components/toast';
import { withStore } from 'core/store';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { styles } from './application-url-form.styles';

const ApplicationUrlForm = ({ classes, unsignedApplications, store, toast }) => {
  const [loading, setLoading] = useState(false);
  const accountId = store.account.id;
  const unsigned = useMemo(() => {
    if (unsignedApplications && unsignedApplications.length && store.account.policies.list.length) {
      const [application] = unsignedApplications;
      const policy = store.account.policies.list.find((p) => p.id === application.policyId);
      return policy ? application : false;
    }
  }, [unsignedApplications, store.account.policies.list]);
  const sendApplicationUrl = useCallback(() => {
    setLoading(true);
    store.account.policies
      .sendApplicationLink(accountId, unsigned.policyId)
      .then(({ data }) => {
        setLoading(false);
        if (data && data.sendApplicationLink) {
          store.account.policies.getDocuments(accountId);
          toast.notify({
            type: 'success',
            message: `Email was sent to ${data.sendApplicationLink.email}`
          });
        } else {
          throw new Error('Error sending the application url email');
        }
      })
      .catch((e) => {
        toast.notify({
          type: 'error',
          message: 'Oops! we couldn’t send the email. Please try again.'
        });
        setLoading(false);
      });
  }, [setLoading, unsigned, toast, store.account.policies, accountId]);

  const copyToClipboard = useCallback(() => {
    const copied = copy(unsigned.applicationUrl);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'Quote’s link is copied to your clipboard.'
      });
    } else {
      toast.notify({
        type: 'error',
        message: `We could not copy the link, but here it is: ${unsigned.applicationUrl}`
      });
    }
  }, [unsigned, toast]);

  if (!unsigned) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <span>The application is not signed yet!</span>
      <div className={classes.buttons}>
        <Button
          disabled={loading}
          disableFocusRipple
          className={classes.sendButton}
          onClick={sendApplicationUrl}
          variant="text"
          color="secondary"
        >
          <img
            alt="email icon"
            className={classNames(classes.sendIcon, { [classes.disabledIcon]: loading })}
            src={emailIcon}
          />
          Send link to customer
        </Button>
        <Button
          disabled={loading}
          disableFocusRipple
          className={classes.sendButton}
          onClick={copyToClipboard}
          variant="text"
          color="secondary"
        >
          Copy to clipboard
        </Button>
      </div>
    </Card>
  );
};

ApplicationUrlForm.propTypes = {
  classes: PropTypes.object.isRequired,
  unsignedApplications: PropTypes.array.isRequired,
  store: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withToast, withStore)(ApplicationUrlForm);
