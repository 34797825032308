import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  resultsContainer: ({ mode }) => {
    if (mode === 'dropdown') {
      return {
        backgroundColor: theme.colorPalette.white_30,
        boxShadow: '0px 20px 40px -10px rgba(0,0,0,0.5)'
      };
    }
    if (mode === 'modal') {
      return {
        backgroundColor: theme.colorPalette.white_30,
        boxShadow: 'none'
      };
    }
  },
  tableRow: ({ mode }) => {
    if (mode === 'dropdown') {
      return {
        height: 48,
        '& > td': {
          color: theme.colorPalette.green_20
        },
        '& > td:first-child': {
          paddingLeft: '2.5%',
          width: '12%'
        },
        '& > td:last-child': {
          paddingRight: '2.5%',
          width: '12%'
        }
      };
    }
    return {
      height: 48
    };
  }
}));
