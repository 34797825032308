import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import downloadIcon from '../../assets/download.svg';

const DownloadButton = ({ loading, classes, downloadText, onClick }) => {
  return (
    <>
      {loading ? (
        <Label variant="body3" className={classes.downloadLabel}>
          Preparing PDF to download <CircularProgress size={24} className={classes.downloadLoader} />
        </Label>
      ) : (
        <Button className={classes.downloadButton} onClick={onClick} type="action">
          <img src={downloadIcon} alt="download" />
          <Label variant="body3" className={classes.downloadLabel}>
            {downloadText}
          </Label>
        </Button>
      )}
    </>
  );
};

DownloadButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  downloadText: PropTypes.string.isRequired
};

export default DownloadButton;
