import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import styles from './screen-header.styles';
import backIcon from './go-to.svg';
import { Label } from '../label';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';

function ScreenHeader({
  classes,
  title,
  subtitles,
  children,
  onBack,
  rightLabel,
  rightValue,
  policy,
  rightLink,
  options
}) {
  return (
    <Grid container className={classes.header} justify="space-between">
      <Grid item className={classes.container}>
        {!options.hideBackButton && (
          <IconButton classes={MapClassesToElem(ElemType.IconButton, classes)} onClick={onBack}>
            <img src={backIcon} className={classes.icon} alt="back" />
          </IconButton>
        )}
        {subtitles ? (
          <Grid container direction="column">
            <Label type="title">{title}</Label>
            {subtitles}
          </Grid>
        ) : (
          <Label type="title">{title}</Label>
        )}
      </Grid>
      <Grid item>
        {rightLabel && rightValue && (
          <div className={classes.rightContainer}>
            <Label type="infoLabel" className={classes.rightLabel}>
              {rightLabel}
            </Label>
            <Label type="infoValue" className={classes.rightValue}>
              {rightLink ? (
                <a className={classes.link} href={rightLink} target="_blank" rel="noopener noreferrer">
                  {rightValue}
                </a>
              ) : (
                rightValue
              )}
            </Label>
            {policy && policy.fromStaff ? (
              <Label type="infoLabel" className={classes.madeByStaff}>
                Made by staff
              </Label>
            ) : null}
          </div>
        )}
        {rightValue && !rightLabel && (
          <div className={classes.rightContainerNoLabel}>
            <Label type="infoValue" className={classes.rightValue}>
              {rightValue}
            </Label>
          </div>
        )}
        {children}
      </Grid>
    </Grid>
  );
}

ScreenHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.node.isRequired,
  subtitles: PropTypes.node,
  onBack: PropTypes.func.isRequired,
  rightLabel: PropTypes.string,
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  policy: PropTypes.object,
  rightLink: PropTypes.string,
  options: PropTypes.object
};

ScreenHeader.defaultProps = {
  children: null,
  rightLabel: null,
  rightValue: null,
  policy: undefined,
  rightLink: undefined,
  subtitles: undefined,
  options: { hideBackButton: false }
};

export default withStyles(styles)(ScreenHeader);
