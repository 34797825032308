const styles = (theme) => ({
  label: {
    color: theme.colors.info__label_primary,
    display: 'inline'
  },
  'dark-label': {
    color: `${theme.colors.light_text} !important`
  },
  'dark-colorSecondary': {
    '&:.Mui-disabled': {
      color: `${theme.colorPalette.orange_10_op_70} !important`
    }
  },
  container: {
    marginTop: 0,
    minHeight: 45
  }
});

export default styles;
