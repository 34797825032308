import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { useObserver } from 'mobx-react';
import React from 'react';

import { Label } from 'core/components/label';
import { useStore } from 'core/store';

import useStyles from './price-breakdown.styles';
import Row from './row';

function PriceBreakdown() {
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const classes = useStyles();

  return useObserver(() => (
    <>
      <Label type="coverageValue">Price Breakdown</Label>
      {process.env.NODE_ENV !== 'production' && (
        <p className={classes.inaccurateValueNote}>
          INFO: &quot;Price Breakdown&quot; values are accurate only in a production enviroment.
        </p>
      )}
      <TableContainer>
        <Table className={classes.priceBreakdownTable}>
          <TableBody>
            {store.priceBreakdownRows.map((row, index) => (
              <Row key={index} {...row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ));
}

export default PriceBreakdown;
