import React, { useCallback } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Label } from 'core';
import { useStore } from 'core/store/store.mobx';
import styles from '../regenerate-document/regenerate-document.styles';

const RecreateApplication = observer(({ classes, onRecreate, documentPolicyId }) => {
  const regenerateCallback = useCallback(() => {
    onRecreate(documentPolicyId);
  }, [onRecreate, documentPolicyId]);

  const {
    account: {
      policies: {
        policy: { recreatingApplication }
      }
    }
  } = useStore();

  return (
    <>
      <div className={classNames(classes.regenerateDocContainer, { [classes.withDocumentPolicy]: documentPolicyId })}>
        <Label type="greenSmall">Need to Recreate application?</Label>
        <div className={classNames(classes.actions, documentPolicyId && classes.withDocumentAction)}>
          <div
            className={classNames(classes.buttonContainer, {
              [classes.regeneratingDocs]: recreatingApplication
            })}
          >
            <Button
              disabled={!documentPolicyId || recreatingApplication}
              key="addButton"
              onClick={regenerateCallback}
              mode="big"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Recreate Application
            </Button>
          </div>
        </div>
      </div>
      {recreatingApplication && <LinearProgress color="secondary" />}
    </>
  );
});

RecreateApplication.propTypes = {
  onRecreate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  recreating: PropTypes.bool,
  documentPolicyId: PropTypes.string
};

RecreateApplication.defaultProps = {
  recreating: false,
  documentPolicyId: null
};

export default withStyles(styles)(RecreateApplication);
