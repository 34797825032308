import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { tooltipHoverTexts } from 'core/helpers/constants';
import Checkbox from 'core/components/checkbox';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import useStyles from './driver-cards.styles';

const DriverCards = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  return (
    <Grid>
      {values?.drivers?.length && (
        <Section type="InnerSection" title="Drivers" rightLabel="Total" rightValue={`${values.drivers.length} drivers`}>
          {values.drivers.map((driver, index) => {
            const disablePipOpOut = !driver.pipHasQHC;
            if (!driver.pipHasQHC && driver.pipOptOut) {
              setFieldValue(`drivers[${index}].pipOptOut`, false);
            }
            return (
              <Card key={driver.id} type="secondary" className={classes.card}>
                <Label type="coverageValue" className={classes.label}>
                  {driver.firstName} {driver.lastName}
                </Label>
                <Label type="whiteSmall">{driver.age} years</Label>
                <Grid className={classes.spacingTop}>
                  <Grid item>
                    <FormField
                      type="checkbox"
                      id={`drivers[${index}].pipHasQHC`}
                      name={`drivers[${index}].pipHasQHC`}
                      label="Qualified Health Coverage"
                      className={classes.checkbox}
                      mode="dark"
                      tooltipText={tooltipHoverTexts.pipResidentsWithQHC}
                      tooltipLabel="More Info"
                    />
                  </Grid>
                  <Grid item>
                    <FormField
                      type="checkbox"
                      id={`drivers[${index}].pipOptOut`}
                      name={`drivers[${index}].pipOptOut`}
                      label="Exclude from PIP"
                      className={classes.checkbox}
                      disabled={disablePipOpOut}
                      mode="dark"
                      fast={false}
                      tooltipText={tooltipHoverTexts.pipExcludedResidents}
                      tooltipLabel="More Info"
                    />
                  </Grid>
                  <Grid item>
                    <Checkbox
                      id="includePIPWL"
                      type="checkbox"
                      mode="dark"
                      value={!values.drivers[index].waivedPIPWL}
                      label="Work Loss Coverage"
                      disabled={driver.age < 60}
                      className={classes.checkbox}
                      tooltipText={tooltipHoverTexts.waivedPIPWL}
                      tooltipLabel="More Info"
                      onChange={(e) => {
                        setFieldValue(`drivers[${index}].waivedPIPWL`, !e.target.checked);
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </Section>
      )}
    </Grid>
  );
};

export default DriverCards;
