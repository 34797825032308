import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Field from 'core/components/form/form.v2';
import DatePicker from 'core/components/date-picker';
import withDatePicker from 'core/components/with-date-picker';
import { CognitoPermissionGroups, PermissionRequireTypes } from 'core/helpers/cognito-permission-groups';

import styles from './segment-form.styles';

const SegmentForm = memo(({ onChange, startDate: from, minDate, maxDate, error, classes }) => {
  return (
    <Grid container alignItems="flex-end" className={classes.container}>
      <Grid item>
        <DatePicker
          name="startDate"
          onChange={onChange}
          value={from}
          error={error}
          helperText={error}
          mode="light"
          disableFuture={false}
          label="Start date"
          className={classes.datePicker}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>
      <Grid item>
        <Field
          type="checkbox"
          id="underwritingChanges"
          name="underwritingChanges"
          mode="light"
          label="Underwriting Changes"
          permissions={{
            edit: {
              groups: [CognitoPermissionGroups.isService, CognitoPermissionGroups.isTeamLeader],
              type: PermissionRequireTypes.AtLeastOne
            }
          }}
        />
      </Grid>
    </Grid>
  );
});

SegmentForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired,
  error: PropTypes.string,
  classes: PropTypes.object.isRequired
};
SegmentForm.defaultProps = {
  error: undefined
};

export default withDatePicker(withStyles(styles)(SegmentForm));
