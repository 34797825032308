import React from 'react';

import { AutoOffer } from 'common/components/auto';
import useStyles from './auto-details.styles';

const AutoDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <AutoOffer />
    </div>
  );
};

export default AutoDetails;
