import React, { useMemo, useContext } from 'react';
import { getOptions } from '@ourbranch/lookups';
import { flowRight } from 'lodash-es';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

import withDatePicker from 'core/components/with-date-picker';
import { formattedAffinityOptions } from 'core/helpers/lookups';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store/store.mobx';
import { useOfferAvailability } from 'offer/hooks/use-offer-availability';
import useBillingDayOfMonthOptions from 'core/hooks/use-billing-day-of-month-options';
import HomeSettings from './home-settings';
import AutoSettings from './auto-settings';
import LeadSourceSettings from './lead-source-settings';

const get10DaysFromToday = () => {
  return format(addDays(new Date(), 10), 'MM/dd/yyyy');
};

const Settings = () => {
  const { offer: store } = useStore();

  const { isAgency } = useContext(AuthContext);

  const { auto: showAuto, home: showHome } = useOfferAvailability(store.offer);
  const homeBillingDayOptions = useBillingDayOfMonthOptions(store.offer, showHome);
  const autoBillingDayOptions = useBillingDayOfMonthOptions(store.offer, showAuto);
  const {
    paymentTypeOptions,
    autoPaymentTypeOptions,
    hPaymentMethodOptions,
    aPaymentMethodOptions,
    leadSourceOptions,
    affinityOptions
  } = useMemo(() => {
    return {
      paymentTypeOptions: getOptions('paymentType', store.state),
      autoPaymentTypeOptions: getOptions('autoPaymentType', store.state),
      hPaymentMethodOptions: getOptions('homeownersPaymentMethod', store.state),
      aPaymentMethodOptions: getOptions('autoPaymentMethod'),
      leadSourceOptions: getOptions('leadSources'),
      affinityOptions: formattedAffinityOptions()
    };
  }, [store]);

  return (
    <>
      {store.shouldShowHomeTab && (
        <HomeSettings
          hPaymentMethodOptions={hPaymentMethodOptions}
          billingDaysOptions={homeBillingDayOptions}
          paymentTypeOptions={paymentTypeOptions}
          get10DaysFromToday={get10DaysFromToday}
          affinityOptions={affinityOptions}
          leadSourceOptions={leadSourceOptions}
          isAgency={isAgency}
        />
      )}
      {store.shouldShowAutoTab && (
        <AutoSettings
          aPaymentMethodOptions={aPaymentMethodOptions}
          billingDaysOptions={autoBillingDayOptions}
          paymentTypeOptions={autoPaymentTypeOptions}
          get10DaysFromToday={get10DaysFromToday}
          affinityOptions={affinityOptions}
          leadSourceOptions={leadSourceOptions}
          isAgency={isAgency}
        />
      )}
      <LeadSourceSettings leadSourceOptions={leadSourceOptions} affinityOptions={affinityOptions} />
    </>
  );
};

Settings.propTypes = {};

export default flowRight(withDatePicker)(Settings);
