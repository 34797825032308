import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import { AmountField } from 'core/components/amount-field';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';

import useStyles from './price-summary.styles';

function PriceSummary({ premium, fees, surplus }) {
  const classes = useStyles();

  const totalPrice = premium + fees + surplus;

  return (
    <Grid className={classes.wrapper} alignItems="center" justify="flex-start" container>
      <AmountField xs={3} first item>
        <ValueField
          className={classes.valueField}
          label="Total Premium"
          formatter={currencyFormatter}
          value={premium}
          mode="dark"
        />
      </AmountField>
      <AmountField xs={3} item>
        <ValueField
          className={classes.valueField}
          label="Total Fees"
          formatter={currencyFormatter}
          value={fees}
          mode="dark"
        />
      </AmountField>
      <AmountField xs={3} item>
        <ValueField
          className={classes.valueField}
          label="Surplus Contrib."
          formatter={currencyFormatter}
          value={surplus}
          mode="dark"
        />
      </AmountField>
      <AmountField xs={3} item last>
        <ValueField
          className={classes.valueField}
          label="Total Price"
          formatter={currencyFormatter}
          value={totalPrice}
          mode="dark"
        />
      </AmountField>
    </Grid>
  );
}

PriceSummary.propTypes = {
  fees: PropTypes.number.isRequired,
  premium: PropTypes.number.isRequired,
  surplus: PropTypes.number.isRequired
};

export default PriceSummary;
