import { lookupsJson } from '@ourbranch/lookups';

// "AD1" => "ADT - AD1"
export const affinityCodeToLabel = (code) => {
  const affinityPartner = lookupsJson.affinityPartners.find((partner) => code === partner.id);
  return `${affinityPartner?.value} - ${affinityPartner?.id}`;
};

// "ADT - AD1" => "AD1"
export const affinityLabelToCode = (label) => {
  if (label.includes('-')) {
    const splitLabel = label.split('-');
    return splitLabel[splitLabel.length - 1].trim();
  }
  return label;
};
