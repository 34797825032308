export const styles = (theme) => ({
  container: {
    marginBottom: 130
  },
  side: {
    width: theme.sizes.sideColumn
  },
  content: {
    width: 720
  }
});
