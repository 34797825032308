import React, { useCallback, useMemo, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, yupToFormErrors } from 'formik';
import { v4 as uuid4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ABODE_PRO_PLAN_AFFINITY, ABODE_PRO_PLAN_PROVIDER_NAME } from 'core/helpers/constants';
import { AuthContext } from 'core/components/auth';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store';
import { CheckoutStatus } from 'core/store/offer-store';
import { awsDateTimeFormatter, phoneNumberFormatter } from 'core/helpers/formatters';
import { track } from 'core/helpers/analytics';
import { OfferDialog } from 'offer/components/dialog';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { useStripeToken } from '../../hooks/use-stripe-token';
import { getSchema } from '../../checkout.validation-schema';
import { getPaymentMethods } from '../../helper';
import { BillingInformation } from '../billing-information';
import { CancelCurrentPolicy } from '../cancel-current-policy';
import { PaymentsDisclosures } from '../payments-disclosures';
import { PreSaleChecklist } from '../pre-sale-checklist';
import useFormInitialValues from './use-initial-form-values';
import useStyles from './checkout-form.styles';

const CheckoutForm = observer(({ optionFromRoute, toast }) => {
  const classes = useStyles();
  const { getStripeACHToken, getStripeCCToken } = useStripeToken();
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();
  const session = useContext(AuthContext);
  const {
    offer: {
      offer,
      bindRequest,
      status,
      checkoutFormData,
      saveCheckoutFormData,
      openDialog,
      triggerOfferDialog,
      getIsLicensedForState
    }
  } = useStore();
  const { isAgentSold } = offer.quote;

  // if updates were done to the offer in staff, the selected option will be on the offer.quote
  // otherwise, use the option sent in the checkout url
  const selectedOption = getQuoteFriendlySelectedOption(offer.quote.selectedOption || optionFromRoute);
  const initialValues = useFormInitialValues(offer, selectedOption);
  const isLicensedToPurchase = getIsLicensedForState(session);

  const { hasCreditCard, hasACH } = useMemo(() => {
    const { hasCreditCard, hasACH } = getPaymentMethods({
      paymentMethods: offer.quote.global,
      policyType: selectedOption,
      noBindHome: offer?.quote?.noBindHome,
      noBindAuto: offer?.quote?.noBindAuto,
      hasAbodeProPlan:
        offer?.quote?.global?.affinity === ABODE_PRO_PLAN_AFFINITY &&
        offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME
    });
    return { hasCreditCard, hasACH };
  }, [offer, selectedOption]);

  const validate = useCallback(
    async (values) => {
      try {
        await getSchema(selectedOption, offer.quote, values).validate(values, {
          abortEarly: false,
          context: values
        });
      } catch (error) {
        const formErrors = yupToFormErrors(error);
        if (showToast) {
          toast.notify({
            type: 'success',
            message: 'Please fix the form errors before continuing.'
          });
        }
        setShowToast(false);
        // disabling eslint for the console log so we can monitor validation errors in prod
        // eslint-disable-next-line
        console.log({ formErrors });
        return formErrors;
      }
    },
    [offer.quote, selectedOption, toast, showToast]
  );

  const getCreditCardData = useCallback(
    async (values) => {
      if (!hasCreditCard) {
        return null;
      }

      // token was created upon entering cc data automatically, so check that first
      const card = values.recoveredPaymentData?.creditCard || checkoutFormData?.creditCardToken;

      // somehow, token wasn't created properly, try to create again
      if (!card) {
        const { error, token } = await getStripeCCToken(values);
        if (token) {
          saveCheckoutFormData({ id: values.address, creditCardToken: token, error });
        }
        if (error) {
          throw error;
        }
      }
      return card;
    },
    [checkoutFormData, hasCreditCard, getStripeCCToken, saveCheckoutFormData]
  );

  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      let hasStripeError = false;
      // Format input variables input to shape it's expecting
      const option = offer.options.find((o) => o.type === selectedOption);
      const phoneNumber = phoneNumberFormatter({ phoneNumber: values.phone.toString() });
      const formattedAdditionalPhoneNumbers = values.additionalPhoneNumbers
        ?.filter((phoneDetail) => phoneDetail.phoneNumber)
        .map((phoneDetail) => ({
          phoneNumber: phoneNumberFormatter({ phoneNumber: phoneDetail.phoneNumber }),
          note: phoneDetail.note,
          canText: phoneDetail.canText
        }));
      const ccToken = await getCreditCardData(values).catch((error) => {
        hasStripeError = true;
        setFieldError('completeCardData', error.message);
      });
      const achToken = hasACH
        ? await getStripeACHToken(values).catch((error) => {
            hasStripeError = true;
            setFieldError(
              error.param === 'bank_account[routing_number]' ? 'routingNumber' : 'accountNumber',
              error.message
            );
          })
        : null;

      const email = values.email.trim();
      const bindRequestInput = {
        id: uuid4(),
        offerId: offer.id,
        optionId: option.id,
        bindDetails: {
          emails: [{ email }],
          phoneNumbers: [{ number: phoneNumber }],
          additionalPhoneNumbers: formattedAdditionalPhoneNumbers,
          mailingAddress: { ...values.address, address2: values.address.address2 || null },
          drivers: values.drivers?.length
            ? values.drivers.map((driver) => ({
                id: driver.id,
                driversLicenseState: driver.driversLicenseState,
                driversLicenseNumber: driver.driversLicenseNumber
              }))
            : undefined,
          billingDayOfMonth: values.billingDayOfMonth,
          homeBillingDayOfMonth: values.homeBillingDayOfMonth,
          autoBillingDayOfMonth: values.autoBillingDayOfMonth,
          homeDownPayment: values.homeDownPayment,
          autoDownPayment: values.autoDownPayment,
          creditCardToken: ccToken?.id,
          stripeAchToken: achToken?.id,
          salesRep: session.user.username,
          attestationsHomeAccepted: values.attestationsHomeAccepted ? awsDateTimeFormatter(new Date()) : null,
          attestationsAutoAccepted: values.attestationsAutoAccepted ? awsDateTimeFormatter(new Date()) : null,
          breakupWithAuto: values.breakupWithAuto,
          breakupWithHomeowners: values.breakupWithHomeowners,
          breakupESignature: values.breakupWithAuto || values.breakupWithHomeowners ? values.breakupESignature : null,
          currentAutoCarrier: values.breakupWithAuto ? values.currentAutoCarrier : null,
          currentAutoCarrierPolicyNumber: values.breakupWithAuto ? values.currentAutoCarrierPolicyNumber : null,
          currentHomeownersCarrier: values.breakupWithHomeowners ? values.currentHomeownersCarrier : null,
          currentHomeownersCarrierPolicyNumber: values.breakupWithHomeowners
            ? values.currentHomeownersCarrierPolicyNumber
            : null,
          purchasedFromStaff: true
        }
      };
      track('Staff Purchase Offer', { bindRequestInput });
      if (!hasStripeError) {
        const { id, systemId } = await bindRequest(bindRequestInput);
        if (id && systemId) {
          history.push({ pathname: `/offer/${offer.id}/purchased`, state: { email } });
        }
      }
    },
    [offer, getCreditCardData, hasACH, getStripeACHToken, session, selectedOption, bindRequest, history]
  );

  return (
    <Formik validateOnChange={false} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      <Form>
        <BillingInformation className={classes.content} />
        <PaymentsDisclosures policyType={selectedOption} offer={offer} />
        {!isAgentSold && <CancelCurrentPolicy className={classes.content} offer={offer} />}
        <PreSaleChecklist offer={offer} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.purchaseButton}
          loading={status === CheckoutStatus.Purchasing}
          onClick={() => {
            setShowToast(true);
          }}
          disabled={!isLicensedToPurchase}
        >
          Purchase
        </Button>
        {openDialog && <OfferDialog onClose={() => triggerOfferDialog(false)} fromCheckout />}
      </Form>
    </Formik>
  );
});

CheckoutForm.propTypes = {
  optionFromRoute: PropTypes.string.isRequired
};

export default withToast(CheckoutForm);
