import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { AuthContext } from '../auth';
import styles from './change-password.styles';

class ChangePwd extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired
  };

  state = {
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
    error: '',
    success: false
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  submit(event) {
    event.preventDefault();

    // Reset success/error state on submission
    this.setState({ error: '', success: false });
    if (this.state.newPassword1 !== this.state.newPassword2) {
      this.setState({ error: `New passwords don't match` });
    } else {
      this.props.session
        .changePassword(this.state.currentPassword, this.state.newPassword1)
        .then(() => {
          this.setState({
            success: true,
            currentPassword: '',
            newPassword1: '',
            newPassword2: ''
          });
        })
        .catch((e) => {
          this.setState({ error: e.message });
        });
    }
  }

  render() {
    const { props, state } = this;
    const { classes } = props;
    const { error, success, currentPassword, newPassword1, newPassword2 } = state;
    return (
      <form onSubmit={this.submit} className={classes.form}>
        <Card>
          <CardHeader title="Change password" />
          <CardContent>
            <div className={classes.line}>
              <TextField
                name="currentPassword"
                label="Current password"
                type="password"
                onChange={this.handleChange}
                value={currentPassword}
                required
                fullWidth
              />
            </div>
            <div className={classes.line}>
              <TextField
                name="newPassword1"
                label="New password"
                type="password"
                onChange={this.handleChange}
                value={newPassword1}
                required
                fullWidth
              />
            </div>
            <div className={classes.line}>
              <TextField
                name="newPassword2"
                label="Re-type new password"
                type="password"
                onChange={this.handleChange}
                value={newPassword2}
                required
                fullWidth
              />
            </div>
          </CardContent>
          <CardActions>
            <div className={classes.actions}>
              {error.length > 0 && <span className={classes.error}>{error}</span>}
              {success && <span className={classes.success}>Password updated correctly</span>}
              <Button type="submit" color="primary">
                <span>UPDATE PASSWORD</span>
              </Button>
            </div>
          </CardActions>
        </Card>
      </form>
    );
  }
}

const ChangePassword = withStyles(styles)(ChangePwd);

export default (props) => (
  <AuthContext.Consumer>
    {(session) => <ChangePassword {...props} session={session} />}
  </AuthContext.Consumer>
);
