const styles = (theme) => ({
  containerDark: {
    backgroundColor: theme.colors.card_background_primary_editable,
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3)
  },
  container: {
    width: '100%',
    marginBottom: 0
  },
  mortgagesContainer: {
    padding: theme.spacing(0, 6, 3, 8),
    paddingTop: 0,
    backgroundColor: theme.colors.card_background_primary_editable,
    borderTop: '1px solid #fff'
  },
  windstormDetailsContainer: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(3, 6, 6)
  },
  windstormDetailsSectionLabel: {
    marginBottom: theme.spacing(3)
  },
  sectionRightValue: {
    fontWeight: 'normal'
  },
  linkAddres: {
    color: theme.colorPalette.green_20
  },
  leftItem: {
    paddingRight: theme.spacing(5)
  },
  rightItem: {
    paddingLeft: theme.spacing(5)
  },
  streetMapText: {
    color: `${theme.colorPalette.orange_10} !important`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 12
  },
  windstormMitigationLevel: {
    marginTop: theme.spacing(1)
  },
  infoIconImage: {
    width: '45%',
    paddingRight: 0
  },
  enhancedRoofContainer: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(0, 6, 6, 8)
  },
  enhancedRoofItem: {
    paddingTop: theme.spacing(4)
  },
  enhancedRoofField: {
    minHeight: 'unset'
  }
});

export default styles;
