// @NOTE this way to set the styles no longer works for components,
// since material changed the way it handles disabled and errored states
// If we need to support light and dark colors/background for texts we'll need to handle
// dark and light themes, and render components on the theme context. I'm patching fow now,
// to avoid showing orange colors to our users. But this is far from the best/correct solution.

const styles = (theme) => ({
  'light-textFieldSelectMenu': {
    color: theme.colors.text_secondary
  },
  'light-textFieldIcon': {
    color: theme.colors.text_secondary
  },
  'light-labelRoot': {
    color: theme.colors.text_secondary,
    whiteSpace: 'nowrap',
    zIndex: 1
  },
  'light-labelFocused': {
    color: `${theme.colors.text_secondary} !important`
  },
  'dark-labelRoot': {
    whiteSpace: 'nowrap',
    left: 'inherit',
    zIndex: 1
  },
  'light-inputRoot': {
    backgroundColor: theme.colorPalette.white_10
  },
  'light-textFieldDisabled': {
    color: `${theme.colorPalette.green_10} !important`
  },
  'dark-textFieldDisabled': {
    color: `${theme.colorPalette.white_10} !important`
  },
  'dark-inputRoot': {
    backgroundColor: theme.colorPalette.white_10_op_10
  },
  'dark-textFieldIcon': {
    color: theme.colors.button__background_secondary
  },
  caret: {
    width: 24,
    cursor: 'pointer',
    height: 24,
    position: 'absolute',
    top: 7,
    right: 6
  },
  select: {
    backgroundColor: 'inherit',
    '&$selected:focus': {
      backgroundColor: 'inherit'
    },
    '&.Mui-disabled': {
      color: theme.colors.black
    }
  },
  selected: {
    backgroundColor: 'inherit',
    '&:focus': {
      backgroundColor: 'inherit'
    }
  },
  selectIcon: {
    width: 32,
    height: 32,
    marginRight: 4
  },
  empty: {
    color: theme.colorPalette.grey_10_op_38
  }
});

export default styles;
