const styles = (theme) => ({
  container: {
    position: 'absolute',
    top: 4,
    right: 4
  },
  button: {
    color: theme.colors.button__background_secondary,
    fontSize: 18,
    fontWeight: '600',
    padding: 0
  },
  'icon-small': {
    width: 24,
    height: 24
  },
  'icon-medium': {
    width: 32,
    height: 32
  },
  'icon-big': {
    width: 48,
    height: 48
  }
});

export default styles;
