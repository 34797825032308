import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import styles from './action-button.styles';
import crossIcon from './cross.svg';
import editIcon from './edit.svg';
import deleteIcon from './delete.svg';

const icons = {
  edit: editIcon,
  delete: deleteIcon,
  close: crossIcon
};

const ActionButton = ({ onClick, type, icon, size, alt, cyIdentifier, className, classes, ...props }) => (
  <IconButton
    data-cy={cyIdentifier}
    size="small"
    className={classNames(classes.button, className)}
    onClick={onClick}
    {...props}
  >
    <img src={icon || icons[type]} className={classes[`icon-${size}`]} alt={alt} />
  </IconButton>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['delete', 'close', 'edit']),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  alt: PropTypes.string,
  icon: PropTypes.string,
  cyIdentifier: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

ActionButton.defaultProps = {
  alt: '',
  className: null,
  cyIdentifier: null,
  icon: null,
  size: 'medium',
  type: 'edit'
};

export default withStyles(styles)(ActionButton);
