import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import styles from './paper.styles';

const ExtendedPaper = ({ className, isLoading, classes, ...props }) => (
  <div className={classes.container}>
    <Paper className={classNames(classes.paper, className)}>
      {isLoading && (
        <div className={classes.loading}>
          <LinearProgress variant="indeterminate" />
        </div>
      )}
      {props.children}
    </Paper>
  </div>
);

ExtendedPaper.defaultProps = {
  className: '',
  isLoading: false
};

ExtendedPaper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

export default withStyles(styles)(ExtendedPaper);
