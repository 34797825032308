import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import styles from '../hold-cards.styles';

const TableHeadCell = withStyles(styles)(({ classes, children }) => (
  <TableCell className={classes.bigTableHeading}>{children}</TableCell>
));

const ListHeader = () => {
  return (
    <>
      <TableHeadCell>#</TableHeadCell>
      <TableHeadCell>Created Date</TableHeadCell>
      <TableHeadCell>By</TableHeadCell>
      <TableHeadCell>Reason</TableHeadCell>
    </>
  );
};

export default ListHeader;
