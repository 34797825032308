import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mContent: {
    minWidth: 720,
    minHeight: 290,
    padding: 48,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  redBack: {
    backgroundColor: theme.colorPalette.red_20,
    '& *': {
      color: '#fff'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  link: {
    marginRight: 40
  },
  copyLink: {
    marginRight: 22
  },
  btn: {
    minWidth: 288
  },
  btnSmall: {
    minWidth: 188
  },
  content: {
    marginTop: 32
  },
  innerContent: {
    marginBottom: 20
  },
  bottom: {
    marginTop: 32
  },
  topInfo: {
    color: theme.colorPalette.red_20
  },
  errorTitle: {
    color: theme.colorPalette.red_20
  },
  errorMessage: {
    color: theme.colorPalette.red_20
  },
  errorContent: {
    marginTop: 8
  },
  searchDialog: {
    minWidth: 720,
    minHeight: 290,
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  searchFooter: {
    height: 125
  },
  linkButton: {
    backgroundColor: theme.colorPalette.orange_10,
    color: theme.colors.white,
    width: 296
  },
  buttonContainer: {
    marginRight: 48
  },
  searchHeader: {
    padding: '28px 0 0 48px'
  },
  searchBar: {
    padding: '24px 48px'
  },
  searchSubtitle: {
    paddingTop: 12
  },
  contentWrapper: {
    padding: 20
  },
  title: {
    padding: '30px 0px 20px 0px'
  },
  mvrDriversCard: {
    marginTop: 5,
    padding: '20px 20px 0',
    minHeight: 154
  },
  driverCard: {
    marginTop: 5,
    minHeight: 64
  }
}));

export default useStyles;
