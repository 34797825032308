import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { Loading } from '../../../../core';
import { dateFormatter } from '../../../../core/helpers/formatters';
import BaseDialog from '../../../../core/components/base-dialog';
import { Label } from '../../../../core/components/label';
import { TextField } from '../../../../core/components/text-field';
import { Button } from '../../../../core/components/button';
import styles from './hold-card.styles';

const AddHoldCard = ({ classes, onClose, onHoldCardAddition, policy, open }) => {
  const [submitting, setSubmitting] = useState(false);
  const onClick = useCallback(
    (reason) => {
      setSubmitting(true);
      onHoldCardAddition({ reason, policyId: policy.id });
      onClose();
    },
    [onHoldCardAddition, setSubmitting, policy, onClose]
  );

  return (
    <BaseDialog open={open} onClose={onClose} size="md">
      <Label type="title" className={classes.title}>
        Add Hold Card
      </Label>
      <Label type="titleSecondary" className={classes.titleSecondary}>
        A Hold card will be added from <span className={classes.titleSecondaryBolded}>{dateFormatter(new Date())}</span>
      </Label>
      <Formik initialValues={{ reason: '' }}>
        {(formik) => {
          return (
            <>
              {submitting ? (
                <Loading />
              ) : (
                <>
                  <TextField
                    id="reason"
                    mode="light"
                    label="Reason"
                    multiline
                    rows="5"
                    classes={{ 'light-textFieldInput': classes.textarea }}
                    onChange={formik.handleChange}
                    disabled={submitting}
                  />
                  <div className={classes.btnContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={() => onClick(formik.values.reason)}
                      disabled={submitting || !formik.values.reason}
                    >
                      <Label>Add Hold Card</Label>
                    </Button>
                  </div>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </BaseDialog>
  );
};

AddHoldCard.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  policy: PropTypes.object.isRequired,
  onHoldCardAddition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(AddHoldCard);
