/* eslint-disable */
const overrideFormLabel = (theme) => {
  theme.overrides.MuiFormLabel = {
    root: {
      whiteSpace: 'nowrap',
      fontWeight: 500,
      color: theme.colorPalette.white_40_op_70,
      '&$disabled': {
        color: theme.colorPalette.white_40_op_70,
        opacity: 0.4
      },
      '&$focused': {
        fontWeight: 500,
        color: theme.colorPalette.white_40_op_70
      }
    }
  };

  // this should be fixed by material on newer versions, we can also create a pull requests otherwise
  theme.overrides.MuiFormControlLabel = {
    root: {
      marginRight: 0,
      marginLeft: 0,
      '&$disabled': {
        color: theme.colorPalette.white_40_op_70,
        opacity: 0.4
      }
    },
    disabled: {
      color: theme.colorPalette.white_40_op_70
    },
    label: {
      fontSize: 14,
      color: theme.colorPalette.green_20
    }
  };
};

export default overrideFormLabel;
