export default () => ({
  cardComponent: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    marginRight: 32,
    padding: '0 8px'
  },
  cardContainer: {
    flexGrow: 1,
    marginBottom: 18
  },
  submit: {
    width: 168,
    marginRight: 24
  },
  container: {
    padding: '7px 30px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  label: {
    lineHeight: '14px',
    marginBottom: 4
  },
  loadingBar: {
    height: 2
  }
});
