import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import VersionHistoryTable from '../shared';
import xIcon from '../../../../../core/assets/svg/x.svg';
import styles from '../versions.styles';
import Body from './body';
import ListHeader from './header';

const ModalBody = ({ versions, classes, onChangePage, count, page, onClose, state }) => {
  return (
    <>
      <div className={classes.dialogHeader}>
        <IconButton className={classes.dialogCloseButton} onClick={onClose}>
          <img src={xIcon} alt="Close" className={classes.dialogCloseButtonIcon} />
        </IconButton>
      </div>
      <div className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitle}>Version History</div>
      </div>
      <VersionHistoryTable
        state={state}
        versions={versions}
        classes={{ table: classes.bigTable }}
        body={Body}
        header={ListHeader}
        onChangePage={onChangePage}
        count={count}
        page={page}
      />
    </>
  );
};

ModalBody.propTypes = {
  versions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired
};

class Modal extends Component {
  static propTypes = {
    versions: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { page: 0 };
  }

  onChangePage = (e, page) => {
    this.setState({
      page
    });
  };

  render() {
    const { versions, open, onClose, classes, state } = this.props;
    const { page } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          root: classes.dialogRoot,
          container: classes.backdrop,
          paper: classes.dialogPaper,
          scrollPaper: classes.scrollPaper
        }}
      >
        {open && (
          <ModalBody
            state={state}
            versions={versions.slice(page * 5, page * 5 + 5)}
            classes={classes}
            onChangePage={this.onChangePage}
            count={versions.length}
            page={page}
            onClose={onClose}
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(Modal);
