import _pickBy from 'lodash-es/pickBy';

import { toCapitalized, getAutoMPD } from 'core/helpers/formatters';
import { homeMultiPolicyDiscountOptions, autoMultiPolicyDiscountOptions } from 'core/helpers/lookups';

const getMPDFriendlyName = (multiPolicyDiscount) => {
  if (!multiPolicyDiscount) {
    return 'None';
  }
  const getMPD = (option) => option.id === multiPolicyDiscount;
  const opt = homeMultiPolicyDiscountOptions.find(getMPD) || autoMultiPolicyDiscountOptions.find(getMPD);
  return opt?.value || 'None';
};

export const getCorrectedMPDDescription = ({ oldValue, newValue }) => {
  // we mistakenly bind with the home version of the MPD on auto policies, so if that is the correction
  // we are doing, then don't show that because it doesn't affect rating
  const correctedOldValue = getAutoMPD(oldValue);
  if (correctedOldValue === newValue) {
    return;
  }
  return `Multi policy discount was changed from ${getMPDFriendlyName(oldValue)} to ${getMPDFriendlyName(newValue)}`;
};

export const getMismatchedMPDDescription = ({ multiPolicyDiscount, calculatedMultiPolicyDiscount }) => {
  return `Multi policy discount was set to  ${getMPDFriendlyName(
    multiPolicyDiscount
  )} but we calculated it should be ${getMPDFriendlyName(
    calculatedMultiPolicyDiscount
  )}. If this was not intentional, close out of this Preview and re-do the endorsement with the corrected data.`;
};

// we don't want to show the keys that don't matter to rating (even if we toggle the value)
// so we can minimize confusion
const keysToShowByPolicyType = {
  A: ['attachedHomeowners', 'attachedRenters'],
  H: ['attachedAuto', 'includeUmbrella']
};

export const getCorrectedAttachmentsDescription = ({ oldValues, newValues, policyType }) => {
  const diff = _pickBy(
    oldValues,
    (value, key) => value !== newValues[key] && keysToShowByPolicyType[policyType].includes(key)
  );
  if (Object.keys(diff).length > 0) {
    return Object.keys(diff)
      .map((key) => `${toCapitalized(key)} was changed from ${oldValues[key]} to ${newValues[key]}`)
      .join('. ');
  }
  return undefined;
};

export const getMismatchedAttachedEndorsementsFromMPD = ({
  multiPolicyDiscount,
  calculatedAttachments,
  incomingAttachments,
  policyType
}) => {
  const diff = _pickBy(
    incomingAttachments,
    (value, key) => value !== calculatedAttachments[key] && keysToShowByPolicyType[policyType].includes(key)
  );

  if (Object.keys(diff).length > 0) {
    const description = Object.keys(diff).map(
      (key) =>
        `${toCapitalized(key)} should have been changed from ${incomingAttachments[key]} to ${
          calculatedAttachments[key]
        }`
    );
    return `The multi policy discount, ${getMPDFriendlyName(
      multiPolicyDiscount
    )}, does not match the attached endorsements. We expected the following changes: ${description.join(', ')}.`;
  }
  return undefined;
};
