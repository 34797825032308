import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  feeBreakdownTable: {
    '& td, th': {
      borderBottom: 'none',
      padding: 15,
      whiteSpace: 'nowrap'
    },
    '& td:last-child': {
      paddingRight: 30
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`
    }
  },
  columnLabel: {
    color: theme.colors.light_text,
    opacity: 0.6
  },
  checkbox: {
    color: '#ccc4b8'
  },
  feeDescription: {
    color: `${theme.colorPalette.beige_10}`,
    width: '100%'
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    }
  },
  feeSummary: {
    alignItems: 'center',
    display: 'flex',
    height: 73,
    justifyContent: 'flex-end',
    padding: '0 30px'
  },
  totalFeesLabel: {
    extend: 'columnLabel',
    marginRight: 32
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  formControl: {
    minWidth: 320
  },
  feeSelectorContainer: {
    alignItems: 'center',
    display: 'flex'
  }
}));

export default useStyles;
