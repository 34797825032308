import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modalHeader: {
    marginBottom: 12
  },
  modalContent: {
    lineHeight: 1,
    display: 'block'
  },
  infoContainer: {
    marginTop: 10
  }
}));

export default useStyles;
