import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { differenceInYears } from 'date-fns';

import { tooltipHoverTexts } from 'core/helpers/constants';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Checkbox from 'core/components/checkbox';
import { LabelTooltip } from 'core/components/label-tooltip';
import { usePIPMECoverageOptionsHelper, usePIPACROptionsHelper } from '../helpers/hooks';
import useStyles from '../pip-mi.styles';

const getDriverAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  return differenceInYears(today, birthDate);
};

const CoverageQuestions = () => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const PIPMEOptions = usePIPMECoverageOptionsHelper();
  const PIPACROptions = usePIPACROptionsHelper();

  const driversAndResidents = [...values.drivers, ...(values?.nonDrivers || [])];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LabelTooltip
            label="PIP Coverage"
            tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitPIPME }}
            mode="light"
          >
            <FormField
              id="autoCoverage.policyLimitPIPME"
              name="autoCoverage.policyLimitPIPME"
              type="select"
              options={PIPMEOptions}
              mode="light"
              fast={false}
              defaultValue=""
            />
          </LabelTooltip>
        </Grid>
        <Grid item xs={6}>
          <LabelTooltip
            label="Attendant Care"
            tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitPIPACR }}
            mode="light"
          >
            <FormField
              id="autoCoverage.policyLimitPIPACR"
              name="autoCoverage.policyLimitPIPACR"
              type="select"
              options={PIPACROptions}
              mode="light"
              fast={false}
            />
          </LabelTooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.section}>
        <Grid item xs={3}>
          <LabelTooltip
            label="Work Loss Coverage"
            tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.waivedPIPWL }}
            mode="light"
          />
        </Grid>
      </Grid>
      {driversAndResidents.map((person, index) => {
        return (
          <Card type="primary" className={classes.driverCard} key={person.id}>
            <Grid container justify="space-between">
              <Grid item>
                <Label>
                  {person.firstName} {person.lastName}
                </Label>
                <Label type="infoLabel">{person.age || getDriverAge(person.dateOfBirth)} years</Label>
              </Grid>
              <Checkbox
                id="includePIPWL"
                type="checkbox"
                mode="light"
                value={!person.waivedPIPWL}
                label="Include"
                disabled={(person.age || getDriverAge(person.dateOfBirth)) < 60}
                onChange={(e) => {
                  const driverIndex = values?.drivers?.findIndex((d) => d.id === person.id);
                  if (driverIndex > -1) {
                    setFieldValue(`drivers[${driverIndex}].waivedPIPWL`, !e.target.checked);
                    setFieldTouched(`drivers[${driverIndex}].waivedPIPWL`);
                  } else {
                    const residentIndex = values?.nonDrivers?.findIndex((p) => p.id === person.id);
                    setFieldValue(`nonDrivers[${residentIndex}].waivedPIPWL`, !e.target.checked);
                    setFieldTouched(`nonDrivers[${residentIndex}].waivedPIPWL`);
                  }
                }}
              />
            </Grid>
          </Card>
        );
      })}
    </>
  );
};

export default CoverageQuestions;
