import React from 'react';
import PropTypes from 'prop-types';

import { SelectableRow } from 'core/components/table/selectable-row';
import useStyles from './policies-results.styles';

const Row = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <SelectableRow className={classes.tableRow} {...rest}>
      {children}
    </SelectableRow>
  );
};

Row.propTypes = {
  children: PropTypes.node.isRequired
};

export default Row;
