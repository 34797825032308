exports.stateToTzMap = {
  AL: 'America/Chicago',
  AK: 'America/Anchorage',
  AS: 'America/New_York',
  AZ: 'America/Boise',
  AR: 'America/Chicago',
  CA: 'America/Los_Angeles',
  CO: 'America/Boise',
  CT: 'America/New_York',
  DE: 'America/New_York',
  DC: 'America/New_York',
  FM: 'America/New_York',
  FL: 'America/New_York',
  GA: 'America/New_York',
  GU: 'America/New_York',
  HI: 'Pacific/Honolulu',
  ID: 'America/Boise',
  IL: 'America/Chicago',
  IN: 'America/Chicago',
  IA: 'America/Chicago',
  KS: 'America/Chicago',
  KY: 'America/New_York',
  LA: 'America/Chicago',
  ME: 'America/New_York',
  MH: 'America/New_York',
  MD: 'America/New_York',
  MA: 'America/New_York',
  MI: 'America/Chicago',
  MN: 'America/Chicago',
  MS: 'America/Chicago',
  MO: 'America/Chicago',
  MT: 'America/Boise',
  NE: 'America/Boise',
  NV: 'America/Los_Angeles',
  NH: 'America/New_York',
  NJ: 'America/New_York',
  NM: 'America/Boise',
  NY: 'America/New_York',
  NC: 'America/New_York',
  ND: 'America/Boise',
  MP: 'America/New_York',
  OH: 'America/New_York',
  OK: 'America/Chicago',
  OR: 'America/Los_Angeles',
  PW: 'America/New_York',
  PA: 'America/New_York',
  PR: 'America/New_York',
  RI: 'America/New_York',
  SC: 'America/New_York',
  SD: 'America/Boise',
  TN: 'America/Chicago',
  TX: 'America/Chicago',
  UT: 'America/Boise',
  VT: 'America/New_York',
  VI: 'America/New_York',
  VA: 'America/New_York',
  WA: 'America/Los_Angeles',
  WV: 'America/New_York',
  WI: 'America/Chicago',
  WY: 'America/Boise'
};
