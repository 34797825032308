import React, { useCallback, useMemo, useContext } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import Label from 'core/components/label/label';
import { Loading } from 'core/components/loading';
import AddButton from 'core/components/add-button/add-button';
import useStyles from './suggestion.styles';

const DriverSuggestion = ({ classes, isCoapplicant, driverSuggestions, selectedDrivers, disabled }) => {
  const { setValues, resetForm } = useFormikContext();

  const changeSuggestion = useCallback(
    ({ birthDate: dateOfBirth, ...driver }) => {
      resetForm();
      setValues({
        ...driver,
        dateOfBirth,
        initialOpen: true
      });
    },
    [resetForm, setValues]
  );

  return (
    <>
      {driverSuggestions && driverSuggestions.length > 0 ? (
        driverSuggestions.map((driver, i) => {
          const { firstName, lastName, age, id } = driver;

          return (
            <div
              className={classNames(classes.suggestionsContainer, {
                [classes.disabledSuggestions]: id in selectedDrivers
              })}
              key={i}
            >
              <div>
                <Label type="darkSmall" className={classes.name}>
                  {firstName} {lastName}
                </Label>
                <Label type="greenSmall" className={classes.age}>
                  {age} years
                </Label>
              </div>
              <AddButton
                type="full"
                onClick={() => changeSuggestion(driver)}
                mode="xl"
                actionType="button"
                disabled={disabled}
              />
            </div>
          );
        })
      ) : (
        <Label className={classes.suggestionsContainer}>
          There are currently no {isCoapplicant ? 'co-applicant' : 'drivers'} for this offer.
        </Label>
      )}
    </>
  );
};

const CarSuggestion = ({ classes, carSuggestions, selectedCars, disabled }) => {
  const { setValues, resetForm } = useFormikContext();

  const changeSuggestion = useCallback(
    (car) => {
      resetForm();
      setValues({
        ...car,
        carVIN: car.VIN,
        initialOpen: true
      });
    },
    [setValues, resetForm]
  );
  return (
    <>
      {carSuggestions && carSuggestions.length > 0 ? (
        carSuggestions.map((car, i) => {
          const { year, make, model, VIN } = car;

          return (
            <div
              className={classNames(classes.suggestionsContainer, {
                [classes.disabledSuggestions]: VIN in selectedCars
              })}
              key={i}
            >
              <div>
                <Label type="darkSmall" className={classes.name}>
                  {year} {make} {model}
                </Label>
                <Label type="infoLabel">{VIN}</Label>
              </div>
              <AddButton
                type="full"
                onClick={() => changeSuggestion(car)}
                mode="xl"
                actionType="button"
                disabled={disabled}
              />
            </div>
          );
        })
      ) : (
        <Label className={classes.suggestionsContainer}>There are currently no cars for this offer.</Label>
      )}
    </>
  );
};

const Suggestions = ({ isCoapplicant, isDriver, cars, drivers }) => {
  const classes = useStyles();
  const {
    offer: { loading, carAndDriverSuggestions, offer, getIsLicensedForState }
  } = useStore();
  const session = useContext(AuthContext);

  const selectedDrivers = useMemo(
    () => drivers.reduce((acc, driver) => ({ ...acc, [driver.id]: true }), {}),
    [drivers]
  );
  const selectedCars = useMemo(() => cars.reduce((acc, car) => ({ ...acc, [car.VIN]: true }), {}), [cars]);

  const allowedToModifyOffer = getIsLicensedForState(session);

  if (!offer?.id) return null;

  return (
    <>
      {loading ? (
        <Loading type="secondary" />
      ) : isDriver ? (
        <div>
          <Label type="infoSubtitle" className={classes.addDrivers}>
            Add {isCoapplicant ? 'co-applicant' : 'drivers'}
          </Label>
          <Label type="infoLabel">Identified {isCoapplicant ? 'co-applicant' : 'drivers'}</Label>
          <DriverSuggestion
            driverSuggestions={carAndDriverSuggestions?.driverSuggestions}
            classes={classes}
            selectedDrivers={selectedDrivers}
            isCoapplicant={isCoapplicant}
            disabled={!allowedToModifyOffer}
          />
        </div>
      ) : (
        <div>
          <Label type="infoSubtitle" className={classes.addDrivers}>
            Add Cars
          </Label>
          <Label type="infoLabel">Identified cars</Label>
          <CarSuggestion
            carSuggestions={carAndDriverSuggestions?.carSuggestions}
            classes={classes}
            selectedCars={selectedCars}
            disabled={!allowedToModifyOffer}
          />
        </div>
      )}
    </>
  );
};

CarSuggestion.propTypes = {
  carSuggestions: PropTypes.array,
  selectedCars: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

CarSuggestion.defaultProps = {
  carSuggestions: []
};

DriverSuggestion.propTypes = {
  classes: PropTypes.object.isRequired,
  driverSuggestions: PropTypes.array,
  selectedDrivers: PropTypes.object.isRequired,
  isCoapplicant: PropTypes.bool
};

DriverSuggestion.defaultProps = {
  driverSuggestions: [],
  isCoapplicant: false
};

Suggestions.propTypes = {
  isDriver: PropTypes.bool,
  cars: PropTypes.array,
  drivers: PropTypes.array,
  isCoapplicant: PropTypes.bool
};

Suggestions.defaultProps = {
  cars: [],
  drivers: [],
  isDriver: false,
  isCoapplicant: false
};

export default observer(Suggestions);
