import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: '100%',
    minHeight: 0,
    marginBottom: 0
  }
}));

export default useStyles;
