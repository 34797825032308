import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: 16,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: 16,
    padding: 15,
    minHeight: 60
  },
  primary: {
    backgroundColor: theme.colorPalette.orange_50
  },
  secondary: {
    backgroundColor: '#fff',
    color: theme.colorPalette.green_30
  },
  tertiary: {
    backgroundColor: theme.colorPalette.green_15,
    color: theme.colorPalette.white_10
  },
  quaternary: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colors.light_text
  },
  light: {
    backgroundColor: theme.colorPalette.beige_10,
    color: theme.colorPalette.green_20
  },
  noBackground: {
    display: 'flex',
    color: theme.colors.light_text,
    backgroundColor: 'transparent',
    fontSize: 14,
    margin: 0,
    alignItems: 'center',
    '&> img': {
      marginRight: 0
    }
  },
  icon: {
    marginRight: 16,
    width: 50,
    height: 30
  },
  styledText: {
    display: 'block',
    lineHeight: 1.3
  },
  notificationContentContainer: {
    display: 'flex',
    alignItems: 'top'
  },
  containerSmall: {
    width: '100%',
    height: 32,
    fontSize: 14
  },
  notificationContentContainerSmall: {
    display: 'flex',
    alignItems: 'center'
  },
  iconSmall: {
    margin: '8px 8px 8px 32px',
    height: 16
  }
}));

export default useStyles;
