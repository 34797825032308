const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  paper: {
    marginBottom: 32,
    padding: 24,
    position: 'relative',
    width: '100%'
  },

  loading: {
    height: 5,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  }
});

export default styles;
