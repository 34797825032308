import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

const Options = {
  AddedOn: {
    value: 'addedOn',
    label: 'Added on (standard)',
    tooltipText: `In the event you experience a loss caused by an underinsured motorist, Branch will cover the damages up to
  your selected uninsured/underinsured limits even if the underinsured motorist's insurance covers some of the
  damages. Ultimately, this will provide you with more coverage than selecting "Reduced".`
  },
  Reduced: {
    value: 'reduced',
    label: 'Reduced',
    tooltipText: `In the event you experiece a loss caused by an underinsured motorist, Branch will cover the damages up to
  your selected uninsured/underinsured limits minus whatever the underinsured motorist's insurance pays.
  Ultimately, this will provide you with less coverage than selecting "Standard".`
  }
};

const UIMCoverageType = ({ id, disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { uimAddedOn } = values[id];
  const options = [{ ...Options.AddedOn }, { ...Options.Reduced }];

  const onChange = useCallback(
    (e) => {
      setFieldValue(`${id}.uimAddedOn`, e.target.value === Options.AddedOn.value);
      setFieldTouched(`${id}.uimAddedOn`);
    },
    [id, setFieldValue, setFieldTouched]
  );

  return (
    <Grid item xs={6}>
      <Label type="formLabel">Underinsured Motorist Coverage Type</Label>
      <FormField
        type="radio"
        name={`${id}.uimAddedOn`}
        mode="dark"
        tooltipLabel="More Info"
        value={uimAddedOn ? Options.AddedOn.value : Options.Reduced.value}
        onChange={onChange}
        disabled={disabled}
        options={options}
      />
    </Grid>
  );
};

UIMCoverageType.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

UIMCoverageType.defaultProps = {
  disabled: false
};

export default UIMCoverageType;
