const styles = (theme) => ({
  container: {
    marginBottom: 32
  },
  content: {
    width: 776,
    display: 'flex',
    alignItems: 'flex-end'
  },
  title: {
    width: 348
  },
  button: {
    display: 'block',
    borderRadius: 0,
    height: 'auto',
    padding: '12px 8px',
    width: 320,
    marginBottom: 4,
    '&>span': {
      fontSize: 24,
      lineHeight: '24px',
      fontWeight: 300
    }
  },
  selected: {
    borderLeft: `4px solid ${theme.colors.button__background_secondary}`,
    '&>span': {
      fontWeight: 500
    }
  }
});

export default styles;
