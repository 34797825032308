import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 720,
    margin: '0 auto',
    paddingTop: 30
  },
  subtitle: {
    marginBottom: 8,
    padding: '0 32px'
  },
  subtitleWithoutPadding: {
    marginBottom: 8
  },
  datesContainer: {
    marginBottom: 32,
    padding: '0 32px'
  },
  sectionWithLine: {
    borderTop: `1px solid ${theme.colorPalette.white_40_op_70}`,
    padding: '30px 32px'
  },
  checkbox: {
    paddingTop: 15
  },
  toggle: {
    minHeight: 10,
    padding: 0,
    margin: 0
  },
  loadingBar: {
    height: 2
  },
  checkboxTooltip: {
    paddingLeft: 25
  },
  listBullets: {
    color: theme.colorPalette.beige_10,
    paddingBottom: 8,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  privacyLink: {
    color: theme.colorPalette.orange_10
  },
  agreementCheckbox: {
    height: 'auto',
    margin: '15px 0 !important'
  },
  spacing: {
    paddingTop: 24
  },
  autoWaterShutoff: {
    marginTop: '15px'
  },
  applyDiscountBtn: {
    width: '200px',
    margin: 'auto'
  }
}));

export default useStyles;
