const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    height: 48,
    '&$hover:hover': {
      backgroundColor: theme.colorPalette.orange_10_op_10
    }
  },
  hover: {} // Don't remove, required for $hover to work above, it's ugly i know
});

export default styles;
