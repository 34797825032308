import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import { BodyTableCell } from '../shared';
import { dateFormatter } from '../../../../../core/helpers/formatters';
import styles from '../hold-cards.styles';
import { Label } from '../../../../../core';
import { dateFromPolicy, getTimezone } from '../../../policy/dates';

const TableCell = withStyles(styles)(({ children, classes }) => (
  <BodyTableCell classes={{ tableCell: classes.bigTableCell }}>{children}</BodyTableCell>
));

TableCell.propTypes = {
  children: PropTypes.node.isRequired
};

export const ListBody = ({ createdDate, createdBy, index, reason, classes, state, count, page }) => {
  const date = dateFromPolicy(createdDate, state);
  const label = getTimezone(state);
  return (
    <TableRow>
      <TableCell>{`00${count - page * 5 - index}`}</TableCell>
      <TableCell>
        <span className={classes.dateInfo}>{dateFormatter(date)}</span>
        <Label type="infoLabel">{`${date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit'
        })} ${label}`}</Label>
      </TableCell>
      <TableCell>{createdBy}</TableCell>
      <TableCell>
        <span className={classes.internalNotes}>{reason}</span>
      </TableCell>
    </TableRow>
  );
};

ListBody.propTypes = {
  createdDate: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  reason: PropTypes.string.isRequired,
  state: PropTypes.string,
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired
};

ListBody.defaultProps = {
  state: undefined
};

export default withStyles(styles)(ListBody);
