import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Label } from 'core';
import useStyles from './coverage-sublist.styles';

const CoverageSublist = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <Label type="discount">Deductible</Label>
        <Label type="discount">Annual Cost</Label>
      </div>
      {data.map(({ deductible, deductibleAmount, totalAmount }, ind) => (
        <div key={ind} className={classnames(classes.list, classes.listContent)}>
          <Label type="policyLabel" className={classes.field}>
            {deductible >= 1 ? `$${deductible}` : `${deductible}% ($${deductibleAmount})`}
          </Label>
          <Label type="policyLabel" className={classes.field}>{`$${totalAmount}`}</Label>
        </div>
      ))}
    </div>
  );
};

CoverageSublist.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      deductible: PropTypes.number.isRequired,
      totalAmount: PropTypes.number.isRequired,
      deductibleAmount: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CoverageSublist;
