const styles = (theme) => ({
  'dark-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.light_background
    }
  },
  'dark-tableCellRoot': {
    fontSize: 16,
    color: theme.colors.light_text,
    padding: '4px 20px 4px 20px'
  },
  'red-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    backgroundColor: theme.colors.red_dark,
    '&:hover': {
      backgroundColor: theme.colors.red
    }
  },
  'red-tableCellRoot': {
    fontSize: 16,
    color: theme.colors.light_text,
    padding: '4px 20px 4px 20px'
  },
  'orange-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    backgroundColor: theme.colorPalette.orange_50,
    '&:hover': {
      backgroundColor: theme.colorPalette.orange_50
    }
  },
  'orange-tableCellRoot': {
    fontSize: 16,
    color: theme.colors.light_text,
    padding: '4px 20px 4px 20px'
  }
});

export default styles;
