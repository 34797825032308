import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Label } from 'core';

const LinkedCustomer = ({
  containerClassName,
  classes,
  link,
  label,
  fromCheckout,
  setMatchesResult,
  history,
  offerId
}) => (
  <Grid item className={containerClassName}>
    <Label className={classes.matchLabel}>
      <Link to={link} target="_blank" rel="noreferrer noopener" className={classes.customerLink}>
        {label}
      </Link>
    </Label>
    <Label
      type="action"
      color="primary"
      variant="text"
      className={classes.matchAction2}
      onClick={() => {
        setMatchesResult(true);
        if (fromCheckout) {
          history.push(`/offer/${offerId}`);
        }
      }}
    >
      {fromCheckout ? 'Go back to modify offer to see all matches' : 'See all matches'}
    </Label>
  </Grid>
);

LinkedCustomer.propTypes = {
  containerClassName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fromCheckout: PropTypes.bool.isRequired,
  setMatchesResult: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  offerId: PropTypes.string.isRequired
};

export default LinkedCustomer;
