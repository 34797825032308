const styles = (theme) => ({
  header: {
    minHeight: theme.sizes.headerHeight,
    backgroundColor: theme.colors.header__background_primary,
    justifyContent: 'center'
  },
  logo: {
    height: 48
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  quoteButton: {
    marginRight: 30
  },
  tabs: {
    marginRight: 88,
    display: 'flex',
    alignItems: 'flex-end',
    height: 96
  },
  searchContainer: {
    width: '100%'
  },
  searchBar: {
    width: '100%',
    minHeight: 0,
    marginBottom: 0
  }
});

export default styles;
