import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password should be 8 chars minimum')
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/, 'must contain at least 1 number')
    .required('password is required')
});

export default validationSchema;
