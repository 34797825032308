import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    marginBottom: '150px'
  },
  propertyViewsCard: {
    margin: '-10px 0px 30px 0px',
    width: '100%'
  },
  propertyViewsContainer: {
    padding: '25px 50px 15px 25px',
    display: 'flex',
    flexDirection: 'column'
  },
  propertyViewButton: {
    margin: '15px 20px 5px 0px',
    padding: '0px 20px'
  },
  propertyFormCard: {
    width: '100%'
  },
  propertyFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 50px 25px 35px',
    width: '100%'
  },
  formTitle: {
    fontSize: 20,
    marginBottom: 15
  },
  formItem: {
    marginLeft: 10
  },
  formLabel: {
    margin: '10px 0px 5px'
  },
  attestationContainer: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column'
  },
  attestationCheckbox: {
    margin: '8px 8px'
  },
  icon: {
    transform: 'rotate(90deg)'
  },
  floatingContainer: {
    marginTop: 'auto',
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 99
  },
  footerContainer: {
    height: 98,
    display: 'flex',
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  footerContent: {
    width: '100%',
    maxWidth: 1280,
    height: 98,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 auto'
  },
  footerBtn: {
    marginLeft: '10px',
    padding: '0px 20px'
  },
  priorQuote: {
    marginLeft: 4,
    color: theme.colorPalette.orange_10,
    borderBottom: `1px solid ${theme.colorPalette.orange_10}`
  }
});

export default makeStyles(styles);
