import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';

import { TextField } from '../text-field';
import calendarDark from '../../assets/svg/calendar.svg';
import calendarLight from '../../assets/svg/calendar-light.svg';
import styles from './date-picker.styles';
import { DATE_FORMAT, dateFormatter } from '../../helpers/formatters';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';

const TextFieldComponent = ({ adornmentClasses, ...extraProps }) => {
  return (inputProps) => {
    const props = {
      ...inputProps,
      ...extraProps
    };
    return <TextField {...props} />;
  };
};

const CustomDatePicker = memo((props) => {
  const { classes, inputClasses, error, helperText, minDate, mode, maxDate, inputProps, onChange, ...other } = props;
  const onUpdate = useCallback(
    (value) => {
      if (isValid(value)) {
        onChange({ value, stringValue: dateFormatter(value) });
      }
    },
    [onChange]
  );
  const adornmentClasses = mode ? MapClassesToElem(`${mode}-${ElemType.InputAdornment}`, classes) : {};
  const TextFieldFactory = TextFieldComponent({
    error,
    helperText,
    classes: inputClasses,
    mode,
    adornmentClasses,
    ...inputProps
  });
  return (
    <KeyboardDatePicker
      onChange={onUpdate}
      TextFieldComponent={TextFieldFactory}
      format={DATE_FORMAT}
      minDate={minDate ? dateFormatter(minDate) : dateFormatter(new Date('01/01/1900'))}
      maxDate={maxDate ? dateFormatter(maxDate) : dateFormatter(new Date('01/01/2100'))}
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      keyboardIcon={
        mode === 'dark' ? (
          <img src={calendarDark} alt="date" className={adornmentClasses.caret} />
        ) : (
          <img src={calendarLight} alt="date" className={adornmentClasses.caret} />
        )
      }
      KeyboardButtonProps={{
        classes: {
          root: classes.button
        }
      }}
      {...other}
    />
  );
});

CustomDatePicker.propTypes = {
  inputClasses: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disableFuture: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.any,
  classes: PropTypes.any,
  inputProps: PropTypes.any,
  value: PropTypes.instanceOf(Date)
};

CustomDatePicker.defaultProps = {
  inputClasses: null,
  error: false,
  helperText: '',
  disableFuture: true,
  minDate: null,
  maxDate: null,
  mode: null,
  classes: null,
  inputProps: null,
  value: null
};

export default withStyles(styles)(CustomDatePicker);
