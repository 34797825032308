import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { ActionButton } from 'core/components/action-button';
import x from 'core/assets/svg/times.svg';
import useStyles from '../../system-status.styles';

// graph from this dashboard: https://app.datadoghq.com/dashboard/utg-xba-pfe?from_ts=1645728381110&to_ts=1645731981110&live=true
const iframeURL =
  'https://app.datadoghq.com/graph/embed?token=4a5926e48af68fa8e962f22d02cfaf9c72b51cd0e45894a0824e71e1981dbfed&height=300&width=600&legend=true';

function SystemStatusGraph({ graphOpen, onClose }) {
  const classes = useStyles();
  return (
    <Dialog maxWidth="lg" open={graphOpen}>
      <DialogContent>
        <iframe title="datadogGraph" src={iframeURL} width="600" height="300" frameBorder="0" />
        <ActionButton size="big" type="edit" icon={x} className={classes.closeButton} onClick={onClose} />
      </DialogContent>
    </Dialog>
  );
}

export default SystemStatusGraph;
