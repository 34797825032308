import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { styles } from '../policy-sidebar.styles';
import SegmentItem from './segment-item';

function SegmentList({ segments, classes, isSectionActive, policyType }) {
  const [active, setActive] = useState(0);

  return (
    <div className={classes.segmentsContainer}>
      <ul className={classes.segmentsList}>
        {segments.map((segment, i) => (
          <SegmentItem
            key={segment.segmentId}
            segment={segment}
            policyType={policyType}
            active={i === active && isSectionActive}
            lastChild={i + 1 === segments.length}
            onClick={() => setActive(i)}
            singleVersion
          />
        ))}
      </ul>
    </div>
  );
}

SegmentList.propTypes = {
  segments: PropTypes.array,
  classes: PropTypes.object.isRequired,
  isSectionActive: PropTypes.bool,
  policyType: PropTypes.string.isRequired
};

SegmentList.defaultProps = {
  segments: [],
  isSectionActive: true
};

export default withStyles(styles)(memo(SegmentList));
