import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { Label } from 'core/components/label';

const PreviewClarionDoorRequestDifference = ({ policyPreviewId, policyId }) => {
  const {
    account: {
      policies: {
        policy: { loading: policyStoreLoading, getClarionDoorRequestDiff }
      }
    }
  } = useStore();

  const [cdData, setCdData] = useState();
  const [loading, setLoading] = useState(policyStoreLoading);
  const [gqlError, setGqlError] = useState();

  useEffect(() => {
    getClarionDoorRequestDiff({ policyId, policyPreviewId }).then(({ requests, error }) => {
      if (requests) {
        setCdData(requests);
      }
      if (error) {
        setGqlError(error);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(policyStoreLoading);
  }, [policyStoreLoading]);

  if (loading) {
    return <Loading />;
  }

  if (!cdData?.xmlBeforePreview?.success || !cdData?.xmlAfterPreview?.success || gqlError) {
    return (
      <>
        <Label>Error getting ClarionDoor data.</Label>
        <Label>{cdData?.xmlBeforePreview?.message || cdData?.xmlAfterPreview?.message || gqlError}</Label>
      </>
    );
  }

  return (
    <ReactDiffViewer
      oldValue={cdData.xmlBeforePreview.data}
      newValue={cdData.xmlAfterPreview.data}
      splitView
      compareMethod="diffWords" // full set of possible diff compare methods: https://github.com/praneshr/react-diff-viewer/tree/v3.0.0#text-block-diff-comparison
      leftTitle={`ClarionDoor Data Before Preview - ID: ${cdData?.requestIdBeforePreview}`}
      rightTitle={`ClarionDoor Data After Preview - ID: ${cdData?.requestIdAfterPreview}`}
    />
  );
};

export default PreviewClarionDoorRequestDifference;
