import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttons: {
    padding: '0px 30px',
    marginRight: 20,
    marginBottom: 20
  },
  notificationContent: {
    color: 'black'
  },
  supportChat: {
    marginLeft: 3,
    color: theme.colorPalette.orange_10,
    cursor: 'pointer'
  },
  buttonContainer: {
    marginTop: 15
  },
  mainLabel: {
    marginTop: 20
  },
  priorUWFlagLabel: {
    margin: '15px 20px 4px 0px '
  },
  helpDesk: {
    marginLeft: 3,
    color: 'black'
  }
});

export default makeStyles(styles);
