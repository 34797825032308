export default () => ({
  formControl: {
    justifyContent: 'center'
  },
  controlLabel: {
    marginRight: 10
  },
  container: {
    maxWidth: 450
  },
  datePicker: {
    marginLeft: 32,
    marginBottom: 0,
    maxWidth: 150
  }
});
