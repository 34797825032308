import React, { useCallback, useMemo, useRef, useEffect, useState, useContext } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Form, Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { affinityGroups } from '@ourbranch/lookups';

import flowRight from 'lodash-es/flowRight';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { withStore } from 'core/store';
import { useQueryParams } from 'core/hooks/useQueryParams';
import { phoneNumberFormatter } from 'core/helpers/formatters';
import { affinityLabelToCode } from 'core/helpers/affinity-formatters';
import { AuthContext } from 'core/components/auth';
import { getInitialValues } from './initial-values';
import { updateUserInputWithConnectedHomeValues } from 'quote/helpers/get-connected-home-new-sign-up-values';
import { getNestedErrors } from 'quote/helpers/get-nested-errors';
import { validateQuoteForm } from './quote-forms.validations';
import { AddCar } from '../add-car';
import ApplicantDetails from '../applicant-details/applicant-details';
import { PersonalDetails } from '../personal-details';
import { AutoDetails } from '../auto-details';
import { Footer } from '../footer';
import { PriorAddress } from '../prior-address';
import { PropertyDetail } from '../property-detail';
import { Disclaimer } from '../disclaimer';
import styles from './quote-forms.styles';
import { handleCleanUp, visibleSections } from './quote-forms.clean';

const ScrollToError = ({
  quoteErrors,
  sectionsToShow,
  isNewConstruction,
  setSectionsToShow,
  showAllSectionsClicked
}) => {
  const { errors, isSubmitting, setFieldTouched, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isSubmitting) {
      handleCleanUp(
        quoteErrors,
        sectionsToShow,
        isNewConstruction,
        setFieldValue,
        setSectionsToShow,
        showAllSectionsClicked
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  useEffect(() => {
    if (Object.keys(errors).length && isSubmitting) {
      const smoothScrollToElementById = (id) =>
        document.getElementById(id).scrollIntoView({ block: 'center', behavior: 'smooth' });
      const errorPaths = getNestedErrors(errors);
      // touch all nested paths
      for (const path of errorPaths) {
        path.includes('.') && setFieldTouched(path);
      }
      try {
        if (errorPaths?.length > 1 && errorPaths[0] === 'fcraDisclaimer') {
          smoothScrollToElementById(errorPaths[1]);
        } else {
          smoothScrollToElementById(errorPaths[0]);
        }
      } catch (e) {
        // something went wrong just scroll to top
        smoothScrollToElementById('firstName');
      }
    }
  }, [errors, isSubmitting, setFieldTouched]);
  return null;
};

const formatAdditionalPhoneNumbers = (additionalPhoneNumbers) => {
  return additionalPhoneNumbers
    .filter((phoneDetail) => phoneDetail.phoneNumber)
    .map((phoneDetail) => {
      return {
        phoneNumber: phoneNumberFormatter({ phoneNumber: phoneDetail.phoneNumber }),
        note: phoneDetail?.note ? phoneDetail.note.trim() : '',
        canText: phoneDetail.canText
      };
    });
};

const QuoteForms = ({ classes, store }) => {
  const {
    quote: { requestQuoteAction, errors, offer, loading, hasRejections, prefillData, clearPrefillData }
  } = store;
  const form = useRef(null);
  const params = useQueryParams();
  const { allowedStates, isAgency } = useContext(AuthContext);

  // these states added to remotely collapse / expand two of the collapsible cards based on the
  // value of isNewConstruction
  const [showPriorAddressCallout, setShowPriorAddressCallout] = useState(false);
  const [showPropertyDetailsCallout, setShowPropertyDetailsCallout] = useState(false);
  const [sectionsToShow, setSectionsToShow] = useState([]);
  const [showAllSectionsClicked, setShowAllSectionsClicked] = useState(false);

  const handleSubmit = useCallback(
    async (userInput) => {
      let values = userInput;

      // send in values with connected home toggled on for affinity codes that are home security partners
      if (affinityGroups[values.affinity]?.homeSecurity) {
        values = updateUserInputWithConnectedHomeValues(values);
      }

      if (values.additionalPhoneNumbers?.length) {
        values.additionalPhoneNumbers = formatAdditionalPhoneNumbers(values.additionalPhoneNumbers);
      }

      if (values.phone?.length) {
        values.phone = phoneNumberFormatter({ phoneNumber: values.phone.toString() });
      }
      if (values.affinity) {
        values.affinity = affinityLabelToCode(values.affinity);
      }

      values.isApartment = Boolean(values.policyType?.includes('R'));

      await requestQuoteAction(values, allowedStates, isAgency);
      setSectionsToShow(visibleSections(errors, values.isNewConstruction, sectionsToShow, showAllSectionsClicked));
    },
    [requestQuoteAction, errors, sectionsToShow, showAllSectionsClicked, isAgency, allowedStates]
  );
  const marginBottom = useMemo(() => {
    let num = 32;
    if (hasRejections) {
      num += errors.length * 150;
    }
    if (offer) {
      num += 120;
    }
    return num;
  }, [offer, hasRejections, errors]);

  const validate = useCallback(
    (values) => {
      return validateQuoteForm(
        errors.map(({ code }) => Number(code)),
        values,
        allowedStates,
        isAgency
      )
        .then(() => null)
        .catch((e) => {
          // log so we can quickly see validation issues in production
          // eslint-disable-next-line no-console
          console.log({ FormikErrors: e });
          return e;
        });
    },
    [errors, allowedStates, isAgency]
  );

  const prefill = prefillData || params;
  const initialValues = getInitialValues(prefill);
  // reset the store or else we'll be stuck with the prefill data forever
  useEffect(() => {
    clearPrefillData();
  });

  return (
    <>
      <div className={classes.container} style={{ marginBottom }}>
        <Label type="title" className={classes.title}>
          Create a New Quote
        </Label>
        <Formik
          innerRef={form}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          validateOnBlur={false}
        >
          <>
            <ApplicantDetails
              setShowPriorAddressCallout={setShowPriorAddressCallout}
              setShowPropertyDetailsCallout={setShowPropertyDetailsCallout}
              sectionsToShow={sectionsToShow}
              setSectionsToShow={setSectionsToShow}
              showAllSectionsClicked={showAllSectionsClicked}
              setShowAllSectionsClicked={setShowAllSectionsClicked}
            />
            <PersonalDetails sectionsToShow={sectionsToShow} />
            <PriorAddress showPriorAddressCallout={showPriorAddressCallout} sectionsToShow={sectionsToShow} />
            <AutoDetails sectionsToShow={sectionsToShow} />
            <AddCar sectionsToShow={sectionsToShow} />
            <PropertyDetail showPropertyDetailsCallout={showPropertyDetailsCallout} sectionsToShow={sectionsToShow} />
            <Disclaimer />
            <Form>
              <Button
                loading={loading}
                disabled={loading}
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
              >
                See Price
              </Button>
            </Form>
            <ScrollToError
              quoteErrors={errors}
              sectionsToShow={sectionsToShow}
              isNewConstruction={showPropertyDetailsCallout}
              setSectionsToShow={setSectionsToShow}
              showAllSectionsClicked={showAllSectionsClicked}
            />
            <Footer hasRejections={!!hasRejections} />
          </>
        </Formik>
      </div>
    </>
  );
};

QuoteForms.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withStore)(QuoteForms);
