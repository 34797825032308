import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core';
import { Tooltip } from '../tooltip';
import useStyles from './label-tooltip.styles';
import classNames from 'classnames';

const LabelTooltip = memo(
  ({ labelClassName, tooltipClassName, label, tooltip, children, placement, mode, onClick }) => {
    const classes = useStyles();
    const LabelComponent = (
      <div className={classNames(!onClick && classes.header, labelClassName)}>
        <Label type={mode === 'light' ? 'formLabelLight' : 'formLabel'} className={classes.label}>
          {label}
          <Tooltip
            className={tooltipClassName}
            text={tooltip.onHoverText || 'Click Icon To See Info'}
            label={tooltip.label || 'More Info'}
            placement={placement}
            onClick={onClick}
          />
        </Label>
      </div>
    );

    return (
      // only using the 'container' className if the LabelToolTip has children i.e. is used as a container
      <>
        {children && (
          <div className={classes.container}>
            {LabelComponent}
            {children}
          </div>
        )}
        {!children && LabelComponent}
      </>
    );
  }
);

LabelTooltip.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltip: PropTypes.object,
  children: PropTypes.node,
  placement: PropTypes.string,
  mode: PropTypes.string
};

LabelTooltip.defaultProps = {
  tooltip: { onHoverText: '' },
  labelClassName: '',
  tooltipClassName: '',
  children: null,
  label: '',
  placement: 'top',
  mode: 'dark'
};

export default LabelTooltip;
