import { withStyles } from '@material-ui/core/styles';
import { AuthContext } from 'core/components/auth/auth.context';
import RemoveButton from 'core/components/remove-button';
import { withToast } from 'core/components/toast/context';
import { track } from 'core/helpers/analytics';
import { withStore } from 'core/store';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { dateFromPolicy, getTimezone } from '../../../policy/dates';
import styles from '../hold-cards.styles';

const HoldCard = ({
  createdBy,
  createdDate,
  reason,
  state,
  classes,
  holdCardId,
  policyId,
  toast,
  store: {
    account: {
      policies: {
        policy: { removeHoldCard, allActiveHoldCards }
      }
    }
  }
}) => {
  const date = dateFromPolicy(createdDate, state);
  const label = getTimezone(date);
  const onHoldCardDeletion = useCallback(() => {
    track('Staff Remove Hold Card', { policyId, holdCardId });
    removeHoldCard(policyId, holdCardId)
      .then(() => {
        toast.notify({
          type: 'success',
          message: 'The hold card was removed successfully'
        });
      })
      .catch(() => {
        toast.notify({
          type: 'error',
          message: 'There was an error removing the card'
        });
      });
  }, [holdCardId, policyId, removeHoldCard, toast]);
  const session = useContext(AuthContext);
  const canRemoveHoldCard = session?.canAddHoldCards;

  return (
    <div className={classes.card}>
      <div className={classes.flexContainer}>
        <div className={classes.heading}>Reason </div>
        <div className={classes.headingNumber}>{`#${allActiveHoldCards().length}`}</div>
      </div>
      <div className={classes.content}>{reason}</div>
      <div className={classes.flexContainer}>
        <div className={classes.dateRow}>
          {`Created ${date.toLocaleDateString()} @ ${date.toLocaleTimeString()} ${label} by ${createdBy}`}
        </div>
        <div>{canRemoveHoldCard && <RemoveButton onClick={onHoldCardDeletion} />}</div>
      </div>
    </div>
  );
};

HoldCard.propTypes = {
  createdBy: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  holdCardId: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired
};

export default flowRight(withToast, withStyles(styles), withStore)(HoldCard);
