import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { clarionDoorURL } from '@ourbranch/be-constants';
import { policyType as policyTypeLookup } from '@ourbranch/lookups';
import classNames from 'classnames';
import equalsIcon from 'core/assets/svg/equals.svg';
import timesIcon from 'core/assets/svg/times.svg';
import { Card } from 'core/components/card';
import ValueField from 'core/components/value-field';
import { capitalizeWords, currencyFormatter, dateFormatter } from 'core/helpers/formatters';
import { withStore } from 'core/store';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import AutoBreakdownPreview from 'customer/components/policy/auto-breakdown-preview/preview';
import { AuthContext } from 'core/components/auth';
import { styles } from '../policy-sidebar.styles';

const avoidKeys = ['startDate', 'segmentId', '__typename', 'clarionDoorId'];

const getChanges = (object) => {
  const keys = Object.keys(object);
  return keys
    .filter((key) => !avoidKeys.includes(key) && !!object[key])
    .map((key) => capitalizeWords(key))
    .join(', ');
};

const SegmentItem = ({
  store: {
    account: {
      policies: {
        policy: {
          changeSegment,
          state,
          policy: { offer, policyDetails, premiumBreakdown }
        }
      }
    }
  },
  segment,
  policyType,
  classes,
  active,
  lastChild,
  onClick
}) => {
  const { canEdit } = useContext(AuthContext);
  useEffect(() => {
    if (active) {
      changeSegment(segment.segmentId);
    }
  }, [active, changeSegment, segment.segmentId]);
  const label = `Start Date ${dateFormatter(segment.startDate)}`;
  const changes = useMemo(() => {
    return getChanges(segment);
  }, [segment]);

  const timelineTracker = classNames([
    classes.segmentTimelineTracker,
    { [classes.lastSegmentTimelineTracker]: lastChild }
  ]);
  const listItem = classNames([classes.segmentsListItem, classes.versionsListItem]);
  const circleIcon = classNames([classes.dot, classes.timelineDot, classes.segmentDot]);
  const changePremiumFormatter = useCallback(
    (value) =>
      segment.clarionDoorId ? (
        <a
          className={classes.clarionDoorLink}
          href={`${clarionDoorURL}${segment.clarionDoorId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      ) : (
        value
      ),
    [segment, classes]
  );

  return (
    <li className={listItem}>
      {lastChild ? (
        <div className={classes.segmentsBottomWrapper}>
          <IconButton
            color="secondary"
            onClick={onClick}
            className={classNames(circleIcon, { [classes.activeDot]: active })}
          />
          <span className={classes.segmentsBottomLine} />
        </div>
      ) : (
        <div className={timelineTracker}>
          <IconButton
            color="secondary"
            onClick={onClick}
            className={classNames(circleIcon, { [classes.activeDot]: active })}
          />
        </div>
      )}

      <div className={classes.segmentContent} onClick={onClick}>
        <h4 className={classes.timelineSubtitle}>{label}</h4>
        <Card
          key={segment.segmentId}
          className={classNames([classes.vItemCard, classes.vItemSegment, { [classes.activeCard]: active }])}
        >
          {
            changes ? (
              <ValueField classes={{ value: classes.valueFieldContent }} label="Items Changed" value={changes} />
            ) : (
              'Initial Segment'
            ) // If segment has no changes it is the initial one
          }
          {canEdit && (
            <>
              <ValueField
                classes={{ value: classes.valueFieldContent }}
                label="Policy Premium"
                value={currencyFormatter(segment.premium)}
              />
              <ValueField
                classes={{ container: classes.valueFieldInline, value: classes.valueFieldContent }}
                label="Daily Rate"
                value={currencyFormatter(segment.dailyRate)}
              />
              <img src={timesIcon} alt="Times" className={classes.timesIcon} />
              <ValueField
                classes={{ container: classes.valueFieldInline, value: classes.valueFieldContent }}
                label="Days"
                value={segment.segmentDays}
              />
              <img src={equalsIcon} alt="Equals" className={classes.equalsIcon} />
              <ValueField
                classes={{ container: classes.valueFieldInline, value: classes.valueFieldContent }}
                label="Change Premium"
                formatter={changePremiumFormatter}
                value={currencyFormatter(segment.segmentPremium)}
              />
              {policyType === policyTypeLookup.Auto && policyDetails && policyDetails.cars && (
                <AutoBreakdownPreview
                  triggerText="Premium Breakdown"
                  offer={offer}
                  policyType={policyType}
                  autoCoverage={offer?.quote?.autoCoverage}
                  premiumBreakdown={JSON.parse(premiumBreakdown || '{}')}
                  cars={policyDetails.cars}
                />
              )}
            </>
          )}
        </Card>
      </div>
    </li>
  );
};

SegmentItem.propTypes = {
  segment: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  lastChild: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  policyType: PropTypes.string.isRequired
};
export default flowRight(withStyles(styles), withStore)(SegmentItem);
