const styles = (theme) => ({
  cardBody: {
    background: theme.colorPalette.white_30,
    width: '100%',
    margin: 0,
    padding: '24px 32px'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    width: '100%',
    padding: '24px 32px',
    margin: 0,
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  },
  header: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  headerStatus: {
    paddingTop: 4,
    fontStyle: 'italic',
    textTransform: 'none',
    maxWidth: 570
  },
  inputAdornment: {
    marginLeft: 8,
    '& + input': {
      padding: '0px 2px'
    },
    '& > p': {
      lineHeight: 'initial'
    }
  }
});

export default styles;
