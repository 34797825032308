import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { ReactComponent as EqualsIcon } from 'core/assets/svg/equals-white.svg';
import { ReactComponent as SumIcon } from 'core/assets/svg/sum.svg';

import useStyles from './amount-field.styles';

function AmountField({ first, last, children, ...props }) {
  const classes = useStyles();

  return (
    <Grid alignItems="center" justify="flex-start" container {...props}>
      {!first && !last && <SumIcon className={classes.icon} />}
      {!first && last && <EqualsIcon className={classes.icon} />}
      {children}
    </Grid>
  );
}

AmountField.propTypes = {
  children: PropTypes.node.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool
};

AmountField.defaultProps = {
  first: false,
  last: false
};

export default AmountField;
