import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  info: {
    height: 24,
    width: 24,
    paddingLeft: 2,
    cursor: 'pointer'
  },
  tooltip: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.colorPalette.green_10,
    fontSize: 14
  },
  labelWrapper: {
    zIndex: 100
  },
  desktopLabel: {
    fontSize: 10,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    fontFamily: 'Libre Franklin',
    cursor: 'pointer'
  }
});

export default makeStyles(styles);
