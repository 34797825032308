import { useCallback } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const getStripeAddress = ({ address }) => {
  const addressValues = {
    address_line1: address.address,
    address_city: address.city,
    address_state: address.state,
    address_zip: address.zip,
    currency: 'usd',
    address_country: 'US'
  };
  if (address.address2) {
    addressValues.address_line2 = address.address2;
  }
  return addressValues;
};

export const useStripeToken = () => {
  const elements = useElements();
  const stripe = useStripe();

  const getStripeCCToken = useCallback(
    async (values) => {
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement, {
        ...getStripeAddress(values),
        name: 'coverage_payment'
      });
      return { error, token };
    },
    [stripe, elements]
  );

  const getStripeACHToken = useCallback(
    async (values) => {
      const accountTypeMap = { P: 'individual', B: 'company' };
      const { error, token } = await stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: values.routingNumber,
        account_number: values.accountNumber,
        account_holder_name: values.accountHolder,
        account_holder_type: accountTypeMap[values.accountType]
      });
      if (error) {
        throw error;
      }
      return token;
    },
    [stripe]
  );

  return { getStripeACHToken, getStripeCCToken };
};
