import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { getOptions } from '@ourbranch/lookups';

import People from 'common/components/people';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { getValues, sortItems } from 'core/helpers/scheduled-pp-helper';
import { connect, Form } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AddProperty from './addProperty';
import Property from './property';
import styles from './schedule-pp.styles';

const id = 'scheduledPersonalProperty';

const SchedulePP = ({ disabled, formik, classes }) => {
  const items = formik.values[id] ? formik.values[id].items : [];

  // we display the categories differently than how they are represented in the DB so we need to format when a value is added.
  useEffect(() => {
    formik.setFieldValue(`${id}.items`, sortItems(items.map((item) => getValues(item))));
    // have to do this to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  return (
    <>
      <People
        disabled={disabled}
        id={`${id}.items`}
        person={Property}
        addForm={AddProperty}
        title="Schedule Personal Properties"
        addLabel="Add Personal Property"
        singular="property"
        plural="properties"
      >
        {formik.values[id] && formik.values[id].items && formik.values[id].items.length > 0 && (
          <Form disabled={disabled}>
            <Grid container justify="space-between" alignItems="center" className={classes.deductibleContainer}>
              <Label type="infoSubtitle" key="spp-label" xs={4}>
                Total Deductible for all SPP
              </Label>
              <FormField
                type="select"
                name={`${id}.deductible`}
                options={getOptions('scheduledPersonalPropertyDeductible')}
                className={classes.selectRoot}
                label="Select a Deductible"
                xs={4}
                mode="light"
                fast={false}
              />
            </Grid>
          </Form>
        )}
      </People>
    </>
  );
};

SchedulePP.propTypes = {
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

SchedulePP.defaultProps = {
  disabled: false
};

export default flowRight(connect, withStyles(styles))(SchedulePP);
