import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  cardContainer: {
    width: 720,
    margin: '0 auto',
    padding: 32
  },
  content: {
    padding: 40
  },
  formwithPadding: {
    marginLeft: 10
  },
  sectionWithLine: {
    borderTop: `1px solid ${theme.colorPalette.white_40_op_70}`,
    marginTop: 20,
    padding: '25px 0 65px 0'
  },
  longLabelCheckbox: {}
});

export default makeStyles(styles);
