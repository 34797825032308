import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { ErrorDialog } from '../error-dialog';

export default class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      eventId: null
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  reportFeedback = () => {
    this.setState({
      eventId: null
    });
    Sentry.showReportDialog({ eventId: this.state.eventId });
  };

  render() {
    const { reportFeedback } = this;
    if (this.state.hasError) {
      return (
        <ErrorDialog
          open={!!this.state.eventId}
          errors={[
            'There was an error on our app, we are sending the details to the dev team.',
            'Feel free to add some feedback.'
          ]}
          onClose={() => this.setState({ eventId: null })}
          title="Application Error"
          label="Report Feedback"
          onClick={reportFeedback}
        />
      );
    }
    return this.props.children;
  }
}
