const styles = () => ({
  details: {
    marginTop: 16
  },
  segmentForm: {
    padding: 24,
    paddingBottom: 8
  },
  withFooter: {
    marginBottom: 130
  },
  tab: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: 'unset',
    minWidth: 152
  }
});

export default styles;
