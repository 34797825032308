import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import isToday from 'date-fns/isToday';
import {
  paymentMethod as PaymentMethod,
  paymentType as PaymentType,
  policyType as PolicyType
} from '@ourbranch/lookups';

import { awsDateToStringDate, dateFormatter, numberFormatter } from 'core/helpers/formatters';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const AWS_DATE_FORMAT = 'yyyy-MM-dd';

function getDownPaymentDate(effectiveDateStr) {
  const effectiveDate = new Date(effectiveDateStr);
  return isToday(new Date(effectiveDate)) ? 'TODAY' : dateFormatter(effectiveDate);
}

/**
 * Calculates the downpayments, billing start date and remaining payments for the given policy
 * @param {Object} policyData
 * @param {number} policyData.billingDay - The billing day of the month for the policy
 * @param {number} policyData.total - The total cost of the policy
 * @param {string} policyData.policyType - The policy type we need to calculate (H, A)
 * @param {string} policyData.paymentType
 * @param {string} policyData.paymentMethod
 * @param {string} policyData.effectiveDate - The effective date of the policy
 * @param {number} policyData.premium - The policy premium
 * @param {string} policyData.state - The quote state
 */
export function getMonthlyPayments(policyData) {
  function getMinimumDownPayment(premium, policyType, state) {
    // State regulations in MO require auto insurers to take a down pay of at least 1/6 of the premium
    if (policyType === PolicyType.Auto && state === 'MO') {
      return premium / 6;
    }
    return 0;
  }

  function getNextPayments(billingDay, remainingPayments, remainingMonthly, effectiveDate) {
    const day = effectiveDate.getDate();
    let year = effectiveDate.getFullYear();
    let month;
    const effectiveMonth = effectiveDate.getMonth();
    if (day >= billingDay) {
      month = effectiveMonth + 1;
    } else {
      month = effectiveMonth;
    }
    if (month > 11) {
      month = 0;
      year += 1;
    }
    const billingMonth = month < 9 ? `0${month + 1}` : month + 1;
    const dateToIncrement = new Date(`${billingMonth}/${billingDay}/${year}`);
    const nextPayments = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < remainingPayments; i++) {
      const newDate = addMonths(dateToIncrement, i);
      nextPayments.push({
        date: dateFormatter(newDate),
        remainingMonthly: numberFormatter(remainingMonthly, 2)
      });
    }
    return nextPayments;
  }

  function getDailyRate(total, endDate, effectiveDate) {
    const days = differenceInCalendarDays(endDate, effectiveDate) + 1;
    return total / days;
  }

  const { billingDay, total, policyType } = policyData;

  if (policyData.paymentType !== PaymentType.Monthly || !policyData.premium) {
    return {
      downPayment: 0,
      remainingMonthly: 0
    };
  }

  const minimumDownPayment = getMinimumDownPayment(policyData.premium, policyType, policyData.state);
  const effectiveDate = new Date(policyData.effectiveDate);
  const daysToAdd = policyData.paymentMethod === PaymentMethod.CreditCard ? 10 : 18;
  const months = policyType === PolicyType.Home ? 12 : 6;
  const endDate = addMonths(effectiveDate, months);

  const day = effectiveDate.getDate();
  const shouldMergeDownPaymentAndFirstPayment = day === billingDay;
  let year = effectiveDate.getFullYear();
  let month;
  const effectiveMonth = effectiveDate.getMonth();
  if (day > billingDay) {
    if (effectiveMonth <= 10) {
      month = effectiveMonth + 1;
    } else {
      month = 0;
      year += 1;
    }
  } else {
    month = effectiveMonth;
  }

  const billingDate = addDays(new Date(`${month + 1}/${billingDay}/${year}`), daysToAdd);
  const dailyRate = getDailyRate(total, endDate, effectiveDate);
  const daysBetween = differenceInCalendarDays(billingDate, effectiveDate);

  const calculatedDownPayment = dailyRate * daysBetween;

  let downPayment = calculatedDownPayment < minimumDownPayment ? minimumDownPayment : calculatedDownPayment;

  // make sure down payment is not more than a fifth of the total premium
  if (downPayment > total / 5) {
    downPayment = total / 5;
  }

  let remainingMonthly = null;
  let remainingPayments = null;
  let nextPayments = [];

  // The merge logic refers to this issue:
  // https://github.com/gobranch/branch/issues/3886
  if (shouldMergeDownPaymentAndFirstPayment) {
    remainingPayments = policyType === PolicyType.Home ? 10 : 4;
    downPayment += (total - downPayment) / (months - 1);
    remainingMonthly = (total - downPayment) / remainingPayments;
    nextPayments = getNextPayments(billingDay, remainingPayments, remainingMonthly, effectiveDate);
  } else {
    remainingMonthly = (total - downPayment) / (months - 1);
    remainingPayments = policyType === PolicyType.Home ? 11 : 5;
    nextPayments = getNextPayments(billingDay, remainingPayments, remainingMonthly, effectiveDate);
  }

  return {
    downPayment,
    remainingMonthly,
    remainingPayments,
    downPaymentDate: getDownPaymentDate(effectiveDate),
    nextPayments
  };
}

/**
 * Returns the detailed price object with the downpayments, remaining payments and exact prices
 * @param  {Object} offer
 * @param  {string} policyType
 * @param  {bool} isHomeMonthly
 * @param  {bool} isAutoMonthly
 */
const getDetailedPrice = (offer, policyType, isHomeMonthly, isAutoMonthly) => {
  if (!offer) {
    return {};
  }

  const {
    homeownersPaymentType,
    homeownersPaymentMethod,
    homeEffectiveDate,
    autoPaymentType,
    autoPaymentMethod,
    autoEffectiveDate
  } = offer.quote.global;
  let { homeBillingDayOfMonth, autoBillingDayOfMonth } = offer.quote.global;
  const { homeTotal, homePremium, autoTotal, autoPremium, autoCoverages } =
    offer.options.find((x) => x.type === policyType) || {};

  // we need a billing day to figure out the payment amount in the case of monhtly down payment, so we choose today or at latest
  if (!homeBillingDayOfMonth) {
    homeBillingDayOfMonth = Math.min(new Date().getDate(), 28);
  }

  if (!autoBillingDayOfMonth) {
    autoBillingDayOfMonth = Math.min(new Date().getDate(), 28);
  }

  const prices = {};
  if (isHomeMonthly) {
    const monthlyPayments = getMonthlyPayments({
      billingDay: homeBillingDayOfMonth,
      paymentMethod: homeownersPaymentMethod,
      effectiveDate: awsDateToStringDate(homeEffectiveDate),
      paymentType: homeownersPaymentType,
      total: homeTotal,
      policyType: PolicyType.Home,
      premium: homePremium,
      state: offer.quote.correctedAddress.state
    });

    prices.homePrice = numberFormatter(monthlyPayments.downPayment, 2);
    prices.homePriceNumber = monthlyPayments.downPayment;
    prices.homeRemainingPaymentsAmount = numberFormatter(monthlyPayments.remainingMonthly, 2);
    prices.homeRemainingPayments = monthlyPayments.remainingPayments;
    prices.homeDownPaymentDate = monthlyPayments.downPaymentDate;
    prices.homeNextPayments = monthlyPayments.nextPayments;
  } else {
    prices.homePrice = numberFormatter(homeTotal, 2);
    prices.homePriceNumber = homeTotal;
  }

  if (isAutoMonthly) {
    const monthlyPayments = getMonthlyPayments({
      billingDay: autoBillingDayOfMonth,
      paymentMethod: autoPaymentMethod,
      effectiveDate: awsDateToStringDate(autoEffectiveDate),
      paymentType: autoPaymentType,
      total: autoTotal,
      policyType: PolicyType.Auto,
      premium: autoPremium,
      state: offer.quote.correctedAddress.state
    });

    prices.autoPrice = numberFormatter(monthlyPayments.downPayment, 2);
    prices.autoPriceNumber = monthlyPayments.downPayment;
    prices.autoRemainingPaymentsAmount = numberFormatter(monthlyPayments.remainingMonthly, 2);
    prices.autoRemainingPayments = monthlyPayments.remainingPayments;
    prices.autoDownPaymentDate = monthlyPayments.downPaymentDate;
    prices.autoNextPayments = monthlyPayments.nextPayments;
  } else {
    prices.autoPrice = numberFormatter(autoTotal, 2);
    prices.autoPriceNumber = autoTotal;
    prices.autoDownPaymentDate = getDownPaymentDate(awsDateToStringDate(autoEffectiveDate));
  }

  if (policyType === 'AR' && autoCoverages) {
    const rentersAmount = autoCoverages
      .filter((c) => c.type === 'renters_total')
      .reduce((acc, c) => {
        return acc + c.amount;
      }, 0);
    const autoAmount = autoTotal - rentersAmount;
    prices.rentersTotalAR = isAutoMonthly ? rentersAmount / 6 : rentersAmount;
    prices.autoTotalAR = isAutoMonthly ? autoAmount / 6 : autoAmount;
  }

  return prices;
};

export default getDetailedPrice;
