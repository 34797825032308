const styles = (theme) => ({
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },

  line: {
    width: 400
  },

  actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  error: {
    display: 'flex',
    color: theme.colors.error
  },

  success: {
    display: 'flex',
    color: theme.colors.success
  }
});

export default styles;
