import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { getPreSaleChecklistConditions } from '../../helper';
import useStyles from './pre-sale-checklist.styles';

const buyingHomePolicyText =
  'I have informed the customer that an inspector may come to their home within 2-3 weeks of the effective date as part of our normal due diligence';
const inColoradoText =
  "I have informed the customer that we are occasionally unable to get all the necessary underwriting reports pre-sale, that it doesn't happen often and if it impacts their policy, we'll let them know right away";
const confirmedApplicantsText = "I have listed the member's spouse as the co-applicant";
const enteredPriorInsuranceText =
  "I have confirmed the name of the insured's prior carrier(s) and entered their previous policy numbers for Stress Free Switch if possible";
const confirmDiscountsText =
  'Agent confirmed discounts applied and what member needs to do for each discount to retain discount';
const confirmCoveragesText =
  'Agent has discussed all accepted and declined coverages with the Named Insured and has explained the risks of not carrying certain coverages';

const getNoWindHailInformedText = () => ({
  AL: {
    firstPart:
      'I have informed the member that their home coverage does not cover wind & hail damage in their location and that this coverage must be purchased separately through',
    link: 'https://aiua.org/',
    linkText: 'AIUA'
  },
  GA: {
    firstPart:
      'I have informed the member that their home coverage does not include wind & hail damage due to their home’s proximity to the coastline and that they can purchase this coverage through the',
    link: 'https://www.georgiaunderwriting.com/',
    linkText: 'GUA'
  }
});

const getEarthquakeAndFloodInformedText = (includeEarthquake, includeFlood) => {
  if (!includeEarthquake && !includeFlood) {
    return null;
  }

  let secondPart = '';
  if (includeEarthquake) {
    secondPart = ' earthquake';
    if (includeFlood) {
      secondPart = `${secondPart} and flood coverages are`;
    } else {
      secondPart = `${secondPart} coverage is`;
    }
  } else if (includeFlood) {
    secondPart = ` flood coverage is`;
  }

  return `I have informed the member that their${secondPart} billed and handled separately from their main home and/or auto policies`;
};

const PreSaleChecklist = ({ offer }) => {
  const classes = useStyles();
  const { quote } = offer;
  const { option: selectedOptionPolicyType } = useParams();
  const policyType = quote.selectedOption || selectedOptionPolicyType; // check the store, if not in store, grab from url
  const { values } = useFormikContext();
  const {
    buyingHomePolicyInformed,
    allApplicantsConfirmed,
    inColoradoInformed,
    priorInsuranceEntered,
    noWindHailInformed,
    earthquakeAndFloodInformed
  } = getPreSaleChecklistConditions(quote, policyType, values);
  const noWindHailInformedText = getNoWindHailInformedText()[quote.correctedAddress.state];
  const earthquakeAndFloodInformedText = getEarthquakeAndFloodInformedText(quote.includeEarthquake, quote.includeFlood);

  return (
    <Section title="Pre - Sale Checklist" type="SubSection">
      <Card className={classes.cardContainer} type="primaryEditable">
        <Grid container item xs={12} spacing={4} className={classes.checklistContainer}>
          <FormField
            className={classes.content}
            name="branchCommunicationConsent"
            type="checkbox"
            label={
              <>
                I have informed the customer that they will receive communications (including text messages) from Branch
                according to the{' '}
                <a
                  className={classes.link}
                  href="https://www.ourbranch.com/s/blog/communication-consent/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Branch Communication Consent
                </a>
              </>
            }
            mode="light"
            topAlignCheckbox
          />
          {buyingHomePolicyInformed && (
            <FormField
              className={classes.content}
              name="buyingHomePolicyInformed"
              type="checkbox"
              label={buyingHomePolicyText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {allApplicantsConfirmed && (
            <FormField
              className={classes.content}
              name="allApplicantsConfirmed"
              type="checkbox"
              label={confirmedApplicantsText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {inColoradoInformed && (
            <FormField
              className={classes.content}
              name="inColoradoInformed"
              type="checkbox"
              label={inColoradoText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {priorInsuranceEntered && (
            <FormField
              className={classes.content}
              name="priorInsuranceEntered"
              type="checkbox"
              label={enteredPriorInsuranceText}
              mode="light"
              topAlignCheckbox
            />
          )}
          <FormField
            className={classes.content}
            name="confirmDiscounts"
            type="checkbox"
            label={confirmDiscountsText}
            mode="light"
            topAlignCheckbox
          />
          <FormField
            className={classes.content}
            name="confirmCoverages"
            type="checkbox"
            label={confirmCoveragesText}
            mode="light"
            topAlignCheckbox
          />
          {noWindHailInformed && noWindHailInformedText && (
            <FormField
              className={classes.content}
              name="noWindHailInformed"
              type="checkbox"
              label={
                <>
                  {noWindHailInformedText.firstPart}{' '}
                  <a
                    className={classes.link}
                    href={noWindHailInformedText.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {noWindHailInformedText.linkText}
                  </a>
                </>
              }
              mode="light"
              topAlignCheckbox
            />
          )}
          {earthquakeAndFloodInformed && earthquakeAndFloodInformedText && (
            <FormField
              className={classes.content}
              name="earthquakeAndFloodInformed"
              type="checkbox"
              label={earthquakeAndFloodInformedText}
              mode="light"
              topAlignCheckbox
            />
          )}
        </Grid>
      </Card>
    </Section>
  );
};

PreSaleChecklist.propTypes = {
  offer: PropTypes.object.isRequired
};

export default PreSaleChecklist;
