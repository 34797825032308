import React from 'react';

import { Grid } from '@material-ui/core';
import Field from 'core/components/form/form.v2';
import useStyles from './disclaimer.styles';

const disclaimerText =
  'I, as a Branch sales representative, assert that I have disclosed the following to the client on whose behalf I am obtaining this quote: "To provide your accurate price, we\'ll ask you for information as well as gather information such as your claims, driving, and credit information, including credit report, from other sources for you and your household." FOR TEXAS RESIDENTS ONLY: "A copy of the Texas Use of Credit Disclosure is available at ourbranch.com."';

const Disclaimer = () => {
  const classes = useStyles();

  return (
    <Grid container item className={classes.checkboxContainer}>
      <Field type="checkbox" name="fcraDisclaimer" id="fcraDisclaimer" label={disclaimerText} mode="light" />
    </Grid>
  );
};

export default Disclaimer;
