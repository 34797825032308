import commonStyles from './common.styles';

export default (theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: 32
  },
  deductibleContainer: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: '0 16px',
    borderRadius: 6,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginBottom: 8,
    height: 76
  },
  selectRoot: {
    marginBottom: 14
  },
  header: {
    paddingLeft: 16
  },
  categoryIcon: {
    width: 40,
    height: 40,
    marginRight: 8
  },
  bottom: {
    justifyContent: 'flex-start'
  },
  exTheftContainer: {
    background: theme.colors.card_background_primary_editable,
    padding: '10px 20px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: '0 0 6px 6px',
    marginBottom: 6
  },
  ...commonStyles
});
