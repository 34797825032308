/* eslint-disable */
const overrideSelect = (theme) => {
  theme.overrides.MuiSelect = {
    select: {
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        backgroundColor: 'none'
      }
    },
    selectMenu: {
      height: 36
    },
    root: {
      zIndex: 3
    }
  };
};

export default overrideSelect;
