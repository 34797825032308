const styles = () => ({
  card: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  content: {
    width: '100%',
    padding: '16px 24px 24px 24px'
  },
  textField: {
    padding: '0 16px 0 0',
    flexGrow: 1,
    width: '100%'
  },
  button: {
    width: 200,
    height: 36,
    marginTop: 16,
    marginRight: 16
  },
  search: {
    display: 'flex',
    width: '100%'
  }
});

export default styles;
