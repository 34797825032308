import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import uuidv4 from 'uuid/v4';
import { lookupsJson } from '@ourbranch/lookups';
import { Formik, Form } from 'formik';
import classNames from 'classnames';

import { FormField, FormSubmit } from 'core/components/form';
import { validationSchema } from './add-interested-party.validation';
import useStyles from '../add-form.styles';

function AddInterestedPartyForm({ onAdd, disabled }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        id: uuidv4(),
        name: '',
        address: '',
        relationship: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      }}
      validationSchema={validationSchema}
      onSubmit={({ id, name, relationship, address, address2, city, state, zip }) =>
        onAdd({
          id,
          name,
          relationship,
          address: { address, address2, city, state, zip },
          lossPayee: false
        })
      }
    >
      {() => (
        <Form disabled={disabled} className={classes.container}>
          <Grid
            key="container"
            container
            justify="space-around"
            alignItems="flex-start"
            className={classes.formContainer}
            spacing={4}
          >
            <FormField
              mode="light"
              name="name"
              id="name"
              label="Name or Company"
              type="string"
              xs={8}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="relationship"
              id="relationship"
              label="Relationship"
              type="select"
              options={lookupsJson.additionalPartyRelationship}
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="address"
              id="address"
              label="Address"
              type="string"
              xs={8}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="address2"
              id="address2"
              label="Address 2"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="city"
              id="city"
              label="City"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="state"
              id="state"
              label="State"
              type="autocomplete"
              options={lookupsJson.usStates}
              optional
              xs={4}
              stateSelectForm
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="zip"
              id="zip"
              label="Zip code"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
          </Grid>
          <Grid container justify="flex-end" alignItems="center" spacing={4}>
            <FormSubmit
              mode="big"
              className={classNames(classes.button, classes.addButton)}
              variant="contained"
              color="secondary"
              xs={4}
            >
              Add Interested Party
            </FormSubmit>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

AddInterestedPartyForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
AddInterestedPartyForm.defaultProps = {
  disabled: false
};

export default AddInterestedPartyForm;
