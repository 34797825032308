import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  category: Yup.string().required('Category is required!'),
  value: Yup.number().required('Amount is required!'),
  description: Yup.string().required('Description is required!'),
  subCategory: Yup.mixed().when('category', {
    is: 'Sports',
    then: Yup.string().required('Sub Category is required!'),
    otherwise: Yup.string()
  }),
  gunsUsaWorld: Yup.mixed().when('subCategory', {
    is: 'Sports_Guns',
    then: Yup.string().required('Gun category is required!'),
    otherwise: Yup.string()
  })
});
