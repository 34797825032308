export default (theme) => ({
  flagContainer: {
    position: 'absolute',
    width: 260,
    height: 112,
    left: -300,
    background: theme.colorPalette.white_10,
    padding: 32,
    paddingTop: 24,
    borderRadius: 6,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  flagContent: {
    textAlign: 'left'
  },
  flagMessage: {
    color: theme.colorPalette.green_20,
    marginTop: 8,
    wordBreak: 'break-word'
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '11px solid transparent',
    borderLeft: `16px solid ${theme.colorPalette.white_10}`,
    borderBottom: '11px solid transparent',
    position: 'absolute',
    right: -16
  }
});
