import { localToUtcTime, getEndDate } from 'customer/components/policy/dates';

export const PolicyStatus = {
  Cancelled: 'Cancelled',
  InCancellation: 'In Cancellation',
  Inactive: 'Inactive',
  Future: 'Future',
  Active: 'Active'
};

export const getPolicyStatus = (policy) => {
  const { endDate, effectiveDate, state, policyType } = policy;
  const now = new Date();
  const endDateFromPolicy = localToUtcTime(`${endDate}T23:59:59.99`, state);

  // @FIXME using concat is kind of a hack but getEndDate is converting from a date to a string and the format drops the end of day string
  const originalEndDate = localToUtcTime(getEndDate(effectiveDate, policyType, state).concat('T23:59:59.99'), state);
  const startDateFromPolicy = localToUtcTime(effectiveDate, state);

  if (endDateFromPolicy < originalEndDate) {
    if (endDateFromPolicy < now) {
      return PolicyStatus.Cancelled;
    }
    return PolicyStatus.InCancellation;
  }

  if (endDateFromPolicy < now) {
    return PolicyStatus.Inactive;
  }

  if (startDateFromPolicy > now) {
    return PolicyStatus.Future;
  }

  return PolicyStatus.Active;
};
