import React from 'react';
import PropTypes from 'prop-types';

import People from 'common/components/people';
import Car from './car';
import AddCar from './add-car';

const Cars = ({ disabled, ...rest }) => {
  return (
    <People
      disabled={disabled}
      id="cars"
      person={Car}
      addForm={AddCar}
      title="Cars"
      singular="car"
      plural="cars"
      {...rest}
    />
  );
};

Cars.propTypes = {
  disabled: PropTypes.bool
};

Cars.defaultProps = {
  disabled: false
};

export default Cars;
