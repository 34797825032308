const styles = () => ({
  header: {
    paddingTop: 16,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center'
  },
  rightLabel: {
    fontSize: '14px',
    display: 'inline',
    marginRight: 8
  },
  rightValue: {
    fontSize: '14px',
    display: 'inline'
  },
  container: {
    marginBottom: 8
  }
});

export default styles;
