export default (theme) => ({
  regenerateDocContainer: {
    position: 'relative',
    backgroundColor: theme.colorPalette.white_30,
    padding: '16px 32px 12px'
  },
  withDocumentPolicy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 16
  },
  withDocumentAction: {
    paddingTop: 0
  },
  selectPolicy: {
    width: 288
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 255,
    width: '100%'
  },
  regeneratingDocs: {
    justifyContent: 'center'
  },
  error: {
    color: theme.colorPalette.red_10,
    '&:hover': {
      background: 'none'
    }
  }
});
