import React, { useState, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { lookupsJson } from '@ourbranch/lookups';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import routingNumber from 'common/assets/routing-number.svg';
import accountNumber from 'common/assets/account-number.svg';
import useStyles from './ach-field.styles';

const ACHField = observer(function ACHField() {
  const classes = useStyles();
  const { values } = useFormikContext();
  const {
    offer: { saveCheckoutFormData }
  } = useStore();

  const [checkImageClass, setCheckImageClass] = useState({
    routingNumber: classes.checkImageShown,
    accountNumber: classes.checkImageHidden
  });

  const updateCheckImageClass = useCallback(
    ({ target }) => {
      if (target.name === 'accountNumber') {
        setCheckImageClass({
          routingNumber: classes.checkImageShown,
          accountNumber: classes.checkImageHidden
        });
      } else {
        setCheckImageClass({
          routingNumber: classes.checkImageHidden,
          accountNumber: classes.checkImageShown
        });
      }
    },
    [setCheckImageClass, classes]
  );

  useEffect(() => {
    const { accountHolder, accountType, routingNumber, accountNumber } = values;
    if (accountHolder || accountType || routingNumber || accountNumber) {
      saveCheckoutFormData({
        id: values.address,
        ach: {
          accountHolder,
          accountType,
          routingNumber,
          accountNumber
        }
      });
    }
  }, [values, saveCheckoutFormData]);

  return (
    <Grid container item xs={12} spacing={4} className={classes.paymentBlock}>
      <>
        <FormField type="string" name="accountHolder" label="Account Holder" mode="light" xs={6} />
        <FormField
          type="select"
          name="accountType"
          label="Account Type"
          mode="light"
          xs={6}
          options={lookupsJson.achAccountType}
        />
        <FormField
          type="string"
          name="routingNumber"
          label="Routing Number"
          mode="light"
          xs={4}
          onBlur={(e) => {
            e.persist();
            updateCheckImageClass(e);
          }}
          fast={false}
        />
        <FormField
          type="string"
          name="accountNumber"
          label="Account Number"
          mode="light"
          xs={4}
          onBlur={(e) => {
            e.persist();
            updateCheckImageClass(e);
          }}
          fast={false}
        />
        <img key="routing" alt="routing number" src={routingNumber} xs={4} className={checkImageClass.routingNumber} />
        <img key="account" alt="account number" src={accountNumber} className={checkImageClass.accountNumber} xs={4} />
      </>
    </Grid>
  );
});

export default ACHField;
