import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from './verify-property-container.styles.js';

const VerifyPropertyForm = ({
  noAsbestosCheck,
  homeWiringCheck,
  electricPanelsCheck,
  noGalvanizedPlumbingCheck,
  noPolybutylenePlumbingCheck,
  noOpenFoundationCheck,
  homeNotOnStiltsCheck
}) => {
  const classes = useStyles();

  const showSidingSection = noAsbestosCheck;
  const showElectricSection = homeWiringCheck || electricPanelsCheck;
  const showPlumbingSection = noGalvanizedPlumbingCheck || noPolybutylenePlumbingCheck;
  const showFoundationSection = noOpenFoundationCheck || homeNotOnStiltsCheck;
  // check conditions to see if we need to display anything
  const displayForm = showFoundationSection || showPlumbingSection || showElectricSection || showSidingSection;

  if (!displayForm) {
    return null;
  }

  return (
    <Card type="primary" className={classes.propertyFormCard}>
      <div className={classes.propertyFormContainer}>
        <Grid>
          <Label type="subtitle" className={classes.formTitle}>
            Please clarify the following items with the prospective member before moving forward
          </Label>
        </Grid>

        {showSidingSection && (
          <Grid>
            <Label className={classes.formLabel}>Siding</Label>
            <Grid container direction="column">
              {noAsbestosCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.noAsbestosCheck"
                  name="global.preBindUWCheck.homeVerification.noAsbestosCheck"
                  type="checkbox"
                  label="Home does not have asbestos siding"
                  mode="light"
                />
              )}
            </Grid>
          </Grid>
        )}
        {showElectricSection && (
          <Grid>
            <Label className={classes.formLabel}>Electric</Label>
            <Grid container direction="column">
              {homeWiringCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.homeWiringCheck"
                  name="global.preBindUWCheck.homeVerification.homeWiringCheck"
                  type="checkbox"
                  label="Home does not have knob and tube wiring, aluminum wiring and/or glass fuses"
                  mode="light"
                />
              )}
              {electricPanelsCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.electricPanelsCheck"
                  name="global.preBindUWCheck.homeVerification.electricPanelsCheck"
                  type="checkbox"
                  label="Home does not have stab-lok panels, zinsco panels, sylvania panels and/or challenger panels"
                  mode="light"
                />
              )}
            </Grid>
          </Grid>
        )}
        {showPlumbingSection && (
          <Grid>
            <Label className={classes.formLabel}>Plumbing</Label>
            <Grid container direction="column">
              {noGalvanizedPlumbingCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.noGalvanizedPlumbingCheck"
                  name="global.preBindUWCheck.homeVerification.noGalvanizedPlumbingCheck"
                  type="checkbox"
                  label="Home does not have galvanized plumbing"
                  mode="light"
                />
              )}
              {noPolybutylenePlumbingCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.noPolybutylenePlumbingCheck"
                  name="global.preBindUWCheck.homeVerification.noPolybutylenePlumbingCheck"
                  type="checkbox"
                  label="Home does not have polybutylene plumbing"
                  mode="light"
                />
              )}
            </Grid>
          </Grid>
        )}
        {showFoundationSection && (
          <Grid>
            <Label className={classes.formLabel}>Foundation</Label>
            <Grid container direction="column">
              {noOpenFoundationCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.noOpenFoundationCheck"
                  name="global.preBindUWCheck.homeVerification.noOpenFoundationCheck"
                  type="checkbox"
                  label="Foundation is not open"
                  mode="light"
                />
              )}
              {homeNotOnStiltsCheck && (
                <FormField
                  className={classes.formItem}
                  id="global.preBindUWCheck.homeVerification.homeNotOnStiltsCheck"
                  name="global.preBindUWCheck.homeVerification.homeNotOnStiltsCheck"
                  type="checkbox"
                  label="Home is not on stilts"
                  mode="light"
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </Card>
  );
};
export default VerifyPropertyForm;
