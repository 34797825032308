import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.colorPalette.white_10_op_10,
    padding: '32px'
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    },
    '& > span:last-of-type': {
      fontSize: 22,
      lineHeight: '24px'
    }
  }
}));

export default useStyles;
