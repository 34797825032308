const styles = (theme) => ({
  side: {
    width: theme.sizes.sideColumn
  },
  content: {
    width: theme.sizes.contentColumn
  },
  docTitle: {
    maxWidth: 470,
    paddingLeft: 32
  },
  removeButton: {
    width: 40,
    padding: 6
  },
  removeIcon: {
    width: '100%'
  },
  highlighted: {
    fontWeight: 500
  },
  noDocs: {
    padding: 12,
    textAlign: 'center'
  },
  firstCell: {
    paddingLeft: 32
  },
  docSection: {
    '& td, th': {
      padding: '4px 10px 4px 10px'
    }
  },
  icon: {
    height: 24,
    width: 24
  },
  tooltip: {
    backgroundColor: theme.colorPalette.green_20
  },
  modalText: {
    paddingBottom: 32
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32
  },
  btn: {
    width: '25%'
  },
  iconContainer: {
    display: 'flex'
  },
  regularDocsTableContainer: {
    position: 'relative',
    backgroundColor: theme.colorPalette.white_30,
    padding: '14px 32px 0px'
  },
  datePicker: {
    width: '125px',
    marginLeft: '10px',
    marginRight: '14px'
  },
  filterButton: {
    width: '85px',
    marginRight: '100px'
  }
});

export default styles;
