export default {
  checkboxIcon: {
    marginLeft: -12
  },
  controlLabel: {
    marginLeft: -6,
    marginRight: 12
  },
  checkbox: {
    marginTop: 0,
    display: 'flex',
    maxWidth: 240,
    alignItems: 'center'
  },
  tickIcon: {
    width: 32,
    height: 32,
    marginRight: 8
  },
  textarea: {
    padding: 7,
    fontSize: 16,
    lineHeight: '20px',
    minHeight: 56
  },
  button: {
    marginTop: 10
  }
};
