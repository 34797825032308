import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from './dropzone';
import styles from './dropzone.styles';

const DropzoneContainer = ({ classes, onUpload, progress, error, deleting, uploading }) => {
  return (
    <div className={classes.dropzoneContainer}>
      <Dropzone
        buttonClass={classes.dropzoneButton}
        activeButtonClass={classes.dropzoneButton}
        onDrop={onUpload}
        dragging="Drop your files here"
        percent={progress}
        error={!!error}
        deleting={deleting}
      >
        {!error && !uploading ? 'Drag and Drop your files or Select from Device' : error}
        {uploading && `Uploading ${uploading}`}
      </Dropzone>
    </div>
  );
};

DropzoneContainer.propTypes = {
  progress: PropTypes.number.isRequired,
  onUpload: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  deleting: PropTypes.bool,
  uploading: PropTypes.string
};

DropzoneContainer.defaultProps = {
  error: undefined,
  deleting: false,
  uploading: undefined
};

export default withStyles(styles)(DropzoneContainer);
