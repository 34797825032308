export const isLimitACPEValid = (value, parent) => {
  if (value && value !== 'NONE') {
    const { deductibleCollision, deductibleComprehensive } = parent;
    return (
      !!deductibleCollision &&
      deductibleCollision !== 'NONE' &&
      !!deductibleComprehensive &&
      deductibleComprehensive !== 'NONE'
    );
  }
  return true;
};
