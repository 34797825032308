import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import { MyCommunity, Pledges } from 'customer/components';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store/store.mobx';
import Microdeposits from '../microdeposits';
import AccountDetails from '../account-details';
import AgentDetails from '../agent-details';
import useStyles from './sidebar.styles';

const CustomerSidebar = observer((props) => {
  const {
    account,
    account: {
      policies: { achPolicies }
    }
  } = useStore();

  const classes = useStyles();
  const { canEdit } = useContext(AuthContext);
  return (
    <Grid container>
      <Grid item className={classes.bottomSpacing}>
        <AccountDetails {...props} />
      </Grid>
      {canEdit && !!achPolicies.length && account.id === props.accountId && (
        <>
          {achPolicies?.map((policy) => {
            return (
              <Grid item key={`${policy.id}-microdeposits-grid`} className={classes.bottomSpacing}>
                <Microdeposits
                  key={`${policy.id}-microdeposits`}
                  stripeCustomerId={policy.stripeCustomerId}
                  policyId={policy.id}
                  {...props}
                />
              </Grid>
            );
          })}
        </>
      )}
      <Grid item className={classes.gridItem}>
        <AgentDetails {...props} />
      </Grid>
      <Grid item className={classes.gridItem}>
        <MyCommunity {...props} />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Pledges {...props} />
      </Grid>
    </Grid>
  );
});

export default CustomerSidebar;
