/* eslint-disable */
const overrideDatePicker = (theme) => {
  theme.overrides.MuiPickersToolbar = {
    toolbar: {
      backgroundColor: theme.colorPalette.green_10,
      width: '100%',
      alignItems: 'center'
    }
  };
  theme.overrides.MuiPickersBasePicker = {
    container: {
      alignItems: 'center'
    }
  };
  theme.overrides.MuiPickersModal = {
    dialogRoot: {
      width: 310
    }
  };
};

export default overrideDatePicker;
