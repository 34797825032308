import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24,
    borderRadius: theme.sizes.roundCorners
  },
  table: {
    '& td, th': {
      borderBottom: 'none',
      padding: 15,
      whiteSpace: 'nowrap'
    },
    '& td:last-child': {
      paddingRight: 30
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`
    }
  },
  columnLabel: {
    color: theme.colors.light_text,
    opacity: 0.6
  }
});

export default makeStyles(styles);
