export default ({ sizes }) => ({
  grid: {
    position: 'relative'
  },
  cancelCheckbox: {
    paddingLeft: 0
  },
  changed: {
    marginBottom: sizes.footerHeight + 50
  },
  row: {
    marginBottom: 12,
    padding: '0 32px'
  },
  label: {
    marginBottom: 5,
    marginTop: 5,
    padding: '0px 29px'
  },
  labelWithDivider: {
    borderTop: '1px solid rgba(247,243,239, .3)',
    paddingTop: 16,
    paddingLeft: 32,
    marginBottom: 12
  },
  billingSelector: {
    paddingRight: 6,
    width: 90
  },
  billingWithTooltip: {
    paddingRight: 6
  },
  card: {
    padding: '32px 0'
  },
  toggleRow: {
    minHeight: 40
  },
  button: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: '#f89521',
    fontSize: 16,
    width: 150
  },
  secondaryButton: {
    fontSize: 16
  },
  containerWithToolTip: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  modifyCard: {
    color: '#f89521',
    cursor: 'pointer'
  },
  confirmButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: '#f89521',
    fontSize: 16,
    width: 80,
    margin: '0 20px 2px 20px'
  },
  cardDetails: {
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: 'rgba(255, 255, 255, 0.15)'
  },
  timesIcon: {
    color: '#f89521',
    cursor: 'pointer',
    width: '40px'
  },
  paymentValue: {
    width: 158
  },
  bankAccounts: {
    width: '100%',
    margin: '15px 0 10px 2px'
  },
  creditCardLast4: {
    color: '#f89521'
  },
  checkImage: {
    width: 72,
    height: 36,
    marginTop: 16
  },
  billingDetailsContainer: {
    margin: 'unset',
    marginTop: 12
  }
});
