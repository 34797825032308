const getCurrentCarInitialValue = (initialCars, vin) => {
  return (
    initialCars?.find((car) => car.VIN === vin) || {
      symbolMake: undefined,
      symbolAux: undefined,
      symbolStyle: undefined,
      symbolModel: undefined
    }
  );
};

export const hasMakeModelStyleSymbols = (car) => {
  return car.symbolMake || car.symbolModel || car.symbolStyle;
};

export const haveAnyOfThisCarsSymbolsChanged = (currentCar, initialCars) => {
  const initialCarValue = getCurrentCarInitialValue(initialCars, currentCar.VIN);
  return (
    initialCarValue.symbolMake !== (currentCar.symbolMake?.toUpperCase() || undefined) ||
    initialCarValue.symbolModel !== (currentCar.symbolModel?.toUpperCase() || undefined) ||
    initialCarValue.symbolStyle !== (currentCar.symbolStyle?.toUpperCase() || undefined) ||
    initialCarValue.symbolAux !== (currentCar.symbolAux?.toUpperCase() || undefined)
  );
};
