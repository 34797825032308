import { types } from 'mobx-state-tree';

const PolicyList = types.model({
  effectiveDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  fees: types.array(types.frozen()),
  fromStaff: types.maybeNull(types.boolean),
  id: types.string,
  offer: types.maybeNull(types.frozen()),
  paymentMethod: types.maybeNull(types.string),
  policyDetails: types.maybeNull(types.frozen()),
  policyType: types.maybeNull(types.string),
  premium: types.maybeNull(types.number),
  segments: types.array(types.frozen()),
  state: types.maybeNull(types.string),
  stripeBankInfo: types.maybeNull(
    types.model({
      achStatus: types.maybeNull(types.string),
      stripeBankAccountId: types.maybeNull(types.string),
      last4: types.maybeNull(types.string)
    })
  ),
  stripeCustomerId: types.maybeNull(types.string),
  surplusContribution: types.maybeNull(types.number),
  versionHistory: types.array(types.frozen()),
  salesRep: types.maybeNull(types.string)
});

export default PolicyList;
