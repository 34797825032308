import React, { useState, useCallback } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';

import { withStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { dateFormatter } from 'core/helpers/formatters';
import { Table, SelectableRow } from 'core';
import { Label } from 'core/components/label';
import TicketDetail from './ticket-detail';
import coffeeIcon from '../assets/coffee.svg';
import styles from '../tickets.styles';

const getStatusLabelType = (status) => {
  return status === 'open' ? 'statusOpen' : status === 'pending' ? 'statusWarning' : 'statusClosed';
};

const ZendeskTicketSection = ({
  tickets,
  classes,
  store: {
    account: { fetchTicketDetail }
  }
}) => {
  const [ticketDetailModalOpen, setTicketDetailModalOpen] = useState(false);
  const [selectedTicketId, setSelectedticketId] = useState({});

  /* Zendesk tickets open a detail modal, other vendor tickets link directly to their platform when clicked */
  const onSelectTicket = useCallback(
    (ticket) => {
      setTicketDetailModalOpen(true);
      setSelectedticketId(ticket.id);
    },
    [setTicketDetailModalOpen]
  );
  return (
    <>
      <Section title="Zendesk" rightLabel="total" rightValue={`${tickets ? tickets.length : 0} Item(s)`}>
        {tickets && tickets.length > 0 ? (
          <Card type="scrollable">
            <Table
              selfContained={false}
              header={
                <>
                  <TableCell className={classes.firstCell}>Date</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Status</TableCell>
                </>
              }
              body={
                <>
                  {tickets.map((ticket) => (
                    <SelectableRow key={ticket.id} id={ticket.id} onClick={() => onSelectTicket(ticket)}>
                      <TableCell>{dateFormatter(ticket.date)}</TableCell>
                      <TableCell>{ticket.subject}</TableCell>
                      <TableCell>
                        <Label type={getStatusLabelType(ticket.status)}>{ticket.status}</Label>
                      </TableCell>
                    </SelectableRow>
                  ))}
                </>
              }
            />
          </Card>
        ) : (
          <Card type="noData">
            <div className={classes.noData}>
              <img src={coffeeIcon} alt="No tickets" />
              <Label type="darkGreenMedium">Relax, there are no items here.</Label>
            </div>
          </Card>
        )}
      </Section>
      {ticketDetailModalOpen && (
        <TicketDetail
          fetchTicketDetail={fetchTicketDetail}
          ticketId={selectedTicketId}
          ticketDetailModalOpen={ticketDetailModalOpen}
          setTicketDetailModalOpen={setTicketDetailModalOpen}
        />
      )}
    </>
  );
};

ZendeskTicketSection.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  tickets: PropTypes.array
};

ZendeskTicketSection.defaultProps = {
  tickets: null
};

export default flowRight(withStyles(styles), withStore)(ZendeskTicketSection);
