import { basementTypes } from '@ourbranch/lookups';

const yearToday = new Date().getFullYear();

export const getUWPreBindHomeVerificationInitialValues = (home) => {
  if (!home) {
    return {};
  }
  const propertyUWValues = { initialValues: { signedPropertyUWAttestation: false }, conditions: {} };

  // siding conditions
  if (home.yearBuilt < 1960) {
    propertyUWValues.initialValues.noAsbestosCheck = false;
    propertyUWValues.conditions.noAsbestosCheck = true;
  }

  // electric conditions
  if (home.yearBuilt < 1940) {
    propertyUWValues.initialValues.homeWiringCheck = false;
    propertyUWValues.conditions.homeWiringCheck = true;
  }
  if (home.yearBuilt >= 1950 && home.yearBuilt >= yearToday - 20) {
    propertyUWValues.initialValues.electricPanelsCheck = false;
    propertyUWValues.conditions.electricPanelsCheck = true;
  }

  // plumbing conditions
  if (home.yearBuilt < 1940) {
    propertyUWValues.initialValues.noGalvanizedPlumbingCheck = false;
    propertyUWValues.conditions.noGalvanizedPlumbingCheck = true;
  }
  if (home.yearBuilt >= 1970 && home.yearBuilt < 2010) {
    propertyUWValues.initialValues.noPolybutylenePlumbingCheck = false;
    propertyUWValues.conditions.noPolybutylenePlumbingCheck = true;
  }

  // foundation conditions
  if (home.basementType === basementTypes.Raised) {
    propertyUWValues.initialValues.noOpenFoundationCheck = false;
    propertyUWValues.conditions.noOpenFoundationCheck = true;
  }
  if (home.dtbowLabel || home.dtbowName || home.dtoName) {
    propertyUWValues.initialValues.homeNotOnStiltsCheck = false;
    propertyUWValues.conditions.homeNotOnStiltsCheck = true;
  }

  return propertyUWValues;
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default getUWPreBindHomeVerificationInitialValues;
