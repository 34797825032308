import { useMemo } from 'react';

export const useErrors = (errorCodes, compareFn) => {
  return useMemo(() => {
    const list = [];
    if (!errorCodes) {
      return list;
    }
    errorCodes.forEach((err) => {
      if (compareFn(Number(err.code))) {
        list.push(err);
      }
    });
    return list;
  }, [errorCodes, compareFn]);
};
