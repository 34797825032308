import { getValue } from '@ourbranch/lookups';

export const getCarOptions = (segment) => {
  const { cars, trailers = [] } = segment;

  const carOptions = cars?.length
    ? cars.map((car) => ({
        id: car.VIN,
        value: `${car.year} ${car.make} ${car.model}, VIN #${car.VIN}`
      }))
    : [];

  const trailerOptions = trailers?.length
    ? trailers.map((trailer) => ({
        id: trailer.VIN,
        value: `${getValue('trailerType', trailer.type)}, VIN #${trailer.VIN}`
      }))
    : [];
  return [...carOptions, ...trailerOptions];
};
