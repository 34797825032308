import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';

import { withStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { dateFormatter } from 'core/helpers/formatters';
import { Table, SelectableRow } from 'core';
import { Label } from 'core/components/label';
import coffeeIcon from '../assets/coffee.svg';
import styles from '../tickets.styles';

const SendgridTicketSection = ({ classes, tickets }) => {
  return (
    <>
      <Section title="Sendgrid Emails" rightLabel="total" rightValue={`${tickets ? tickets.length : 0} Item(s)`}>
        {tickets && tickets.length > 0 ? (
          <Card type="scrollable">
            <Table
              selfContained={false}
              header={
                <>
                  <TableCell className={classes.firstCell}>Date</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Click Count</TableCell>
                  <TableCell>Open Count</TableCell>
                  <TableCell>Status</TableCell>
                </>
              }
              body={
                <>
                  {tickets.map((ticket) => (
                    <SelectableRow key={ticket.id} id={ticket.id} onClick={() => window.open(ticket.url, '_blank')}>
                      <TableCell>{dateFormatter(ticket.date)}</TableCell>
                      <TableCell>{ticket.subject}</TableCell>
                      <TableCell>{ticket.clicksCount}</TableCell>
                      <TableCell>{ticket.opensCount}</TableCell>
                      <TableCell>
                        <Label type={ticket.status === 'opened' ? 'statusOpened' : 'statusClosed'}>
                          {ticket.status}
                        </Label>
                      </TableCell>
                    </SelectableRow>
                  ))}
                </>
              }
            />
          </Card>
        ) : (
          <Card type="noData">
            <div className={classes.noData}>
              <img src={coffeeIcon} alt="No tickets" />
              <Label type="darkGreenMedium">Relax, there are no items here.</Label>
            </div>
          </Card>
        )}
      </Section>
    </>
  );
};

SendgridTicketSection.propTypes = {
  classes: PropTypes.object.isRequired,
  tickets: PropTypes.array
};

SendgridTicketSection.defaultProps = {
  tickets: null
};

export default flowRight(withStyles(styles), withStore)(SendgridTicketSection);
