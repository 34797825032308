import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  notes: Yup.string()
    .trim()
    .min(20, 'Internal notes must be 20 characters in length or more.', (value) => {
      return value.length >= 20;
    })
    .required('Internal notes are required!')
});
