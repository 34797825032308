const styles = (theme) => ({
  cardBody: {
    background: theme.colorPalette.white_30,
    width: '100%',
    margin: 0,
    padding: '24px 32px'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    width: '100%',
    padding: '24px 32px',
    margin: 0,
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  },
  header: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  headerStatus: {
    paddingTop: 4,
    fontStyle: 'italic',
    textTransform: 'none',
    maxWidth: 570
  },
  addCarDetailsForm: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconButtonRoot: {
    color: theme.colors.button__background_secondary,
    padding: 0
  },
  iconButtonLabel: {
    height: 32,
    width: 32
  },
  icon: {
    height: 32,
    transition: 'transform 200ms'
  },
  collapse: {
    transform: 'rotate(180deg)'
  },
  addCarDetailsLabel: {
    fontSize: 16,
    cursor: 'pointer'
  },
  addCarDetailsButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.colorPalette.orange_10
  },
  luxuryCheckbox: {
    marginTop: 10,
    paddingLeft: 4
  }
});

export default styles;
