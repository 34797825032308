import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import { openFrontChatPopup } from 'core/components/front-chat';
import { useStore } from 'core/store';

import useStyles from './no-home-modal.styles';

const NoHomeModal = observer(({ setShowNoHomeModal }) => {
  const { handleSubmit, setFieldValue, setFieldTouched } = useFormikContext();
  const classes = useStyles();

  const { isAgency } = useContext(AuthContext);
  const { offer: offerStore } = useStore();

  const onClick = async () => {
    await setFieldValue('global.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport', true);
    await setFieldValue('selectedOption', PolicyType.Auto);

    await setFieldTouched('global.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport');
    await setFieldTouched('selectedOption');

    handleSubmit();
  };

  return (
    <BaseDialog onClose={() => setShowNoHomeModal(false)} open>
      <Label className={classes.mainLabel} type="subtitle">
        This property does not qualify for Home Owners insurance with Branch
      </Label>
      {offerStore.priorQuoteWithPreBindUWRejections?.ineligibleForHome ? (
        <Label className={classes.priorUWFlagLabel}>
          During a prior sales underwriting review, this member reported a home characteristic that does not meet Branch
          underwriting guidelines.
        </Label>
      ) : null}
      <Grid className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowNoHomeModal(false)}
          className={classes.buttons}
        >
          Go back
        </Button>

        <Button className={classes.buttons} variant="contained" color="primary" onClick={() => onClick()}>
          Continue without home
        </Button>
      </Grid>
      <NotificationCard type="secondary" noBackground cardType="noBackground">
        <Label className={classes.notificationContent}>If you need further support, contact </Label>{' '}
        {isAgency ? (
          <Label onClick={() => openFrontChatPopup()} className={classes.supportChat}>
            a Branch Agency representative.
          </Label>
        ) : (
          <Label className={classes.helpDesk}>the Help Desk.</Label>
        )}
      </NotificationCard>
    </BaseDialog>
  );
});

export default NoHomeModal;
