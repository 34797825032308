const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    width: '100%'
  },
  address: {
    width: '100%'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(-3),
    minWidth: '100%',
    [`@media (min-width: ${theme.screens.desktop}px)`]: {
      minWidth: 400,
      width: '100%'
    }
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
});

export default styles;
