import { string } from 'yup';

import { driversLicenseRegexByState } from '@ourbranch/lookups';

const isDriversLicenseNumValid = (licenseNumber = '', state = '') => {
  if (!state || !licenseNumber) {
    return;
  }
  const stateDriversLicenseRegex = driversLicenseRegexByState?.[state.toUpperCase()]?.regex;
  if (!stateDriversLicenseRegex) {
    return;
  }
  return stateDriversLicenseRegex.test(licenseNumber);
};

export const driversLicenseNumberSchema = string().test('is-valid-license', (licenseNum, { parent, createError }) => {
  const licenseState = parent?.driversLicenseState;
  const recheckDrivingRecord = parent?.recheckDrivingRecord;

  if (recheckDrivingRecord && !licenseNum) {
    return createError({
      message: "Please provide a valid driver's license number to re-check driving record."
    });
  }
  if (!licenseNum) {
    return true;
  }
  return (
    isDriversLicenseNumValid(licenseNum, licenseState) ||
    createError({
      message: `Please provide a valid driver's license number. ${
        driversLicenseRegexByState?.[licenseState]?.errorMessage || ''
      }
      `
    })
  );
});
