import { flow, getEnv, getParent, types } from 'mobx-state-tree';

import { GET_POLICY_EQUITY_STATUS } from 'customer/components/policy/policy.queries';

const PolicyEquityStatus = types
  .model({
    blendedPremium: types.maybe(types.number),
    policyDays: types.maybe(types.number),
    status: types.optional(types.enumeration(['loading', 'done', 'error']), 'loading'),
    premiumBilled: types.maybe(types.number),
    premiumReceived: types.maybe(types.number)
  })
  .views((self) => ({
    get policy() {
      return getParent(self);
    },

    get totalBilled() {
      const { surplusContribution, totalFeeCost } = self.policy;

      return self.premiumBilled + surplusContribution + totalFeeCost;
    },

    get totalReceived() {
      return self.policy.totalReceived;
    },

    get feesBilled() {
      return self.policy.totalFeeCost;
    },

    get writeOffTotal() {
      return self.policy.writeOffTotal;
    },

    get feesPaidInFull() {
      const { feesBilled, remainingAfterSurplus, surplusPaidInFull } = self;

      return surplusPaidInFull && remainingAfterSurplus >= feesBilled;
    },

    get premiumPaidInFull() {
      const { feesPaidInFull, premiumBilled, remainingAfterFees } = self;

      return feesPaidInFull && remainingAfterFees >= premiumBilled;
    },

    get surplusBilled() {
      return self.policy.surplusContribution;
    },

    get surplusPaidInFull() {
      const { surplusBilled, totalReceived } = self;

      return totalReceived >= surplusBilled;
    },

    get remainingAfterSurplus() {
      const { surplusBilled, surplusPaidInFull, totalReceived } = self;

      return surplusPaidInFull ? totalReceived - surplusBilled : 0;
    },

    get remainingAfterFees() {
      const { feesBilled, feesPaidInFull, remainingAfterSurplus } = self;

      return feesPaidInFull ? remainingAfterSurplus - feesBilled : 0;
    },

    get billedCostBreakdown() {
      const { feesBilled: fees, premiumBilled: premium, surplusBilled: surplus, totalBilled: total } = self;

      return {
        surplus,
        fees,
        premium,
        total
      };
    },

    get remainingCostBreakdown() {
      const {
        feesBilled,
        feesPaidInFull,
        premiumBilled,
        premiumPaidInFull,
        remainingAfterSurplus,
        remainingAfterFees,
        surplusBilled,
        surplusPaidInFull,
        totalBilled,
        totalReceived
      } = self;

      return {
        surplus: surplusPaidInFull ? 0 : surplusBilled - totalReceived,
        fees: feesPaidInFull ? 0 : feesBilled - remainingAfterSurplus,
        premium: premiumPaidInFull ? 0 : premiumBilled - remainingAfterFees,
        total: totalReceived >= totalBilled ? 0 : totalBilled - totalReceived
      };
    },

    get paidCostBreakdown() {
      const {
        feesBilled,
        feesPaidInFull,
        premiumBilled,
        premiumPaidInFull,
        remainingAfterFees,
        remainingAfterSurplus,
        surplusBilled,
        surplusPaidInFull,
        totalReceived,
        writeOffTotal
      } = self;

      return {
        surplus: surplusPaidInFull ? surplusBilled : totalReceived,
        fees: feesPaidInFull ? feesBilled : remainingAfterSurplus,
        premium: premiumPaidInFull ? premiumBilled + writeOffTotal : remainingAfterFees,
        total: totalReceived
      };
    },

    get blendedDailyRate() {
      return self.blendedPremium / self.policyDays;
    }
  }))
  .actions((self) => ({
    fetchPolicyEquityStatus: flow(function* fetchPolicyEquityStatus() {
      const { client } = getEnv(self);

      const policyId = yield self.policy.policyId;

      try {
        const {
          data: { getPolicyEquityStatus }
        } = yield client.query({
          query: GET_POLICY_EQUITY_STATUS,
          fetchPolicy: 'network-only',
          variables: { policyId }
        });

        const { blendedPremium, policyDays, totalPremiumBilled, totalPremiumReceived } = getPolicyEquityStatus;

        self.blendedPremium = blendedPremium;
        self.policyDays = policyDays;
        self.premiumBilled = totalPremiumBilled;
        self.premiumReceived = totalPremiumReceived;

        self.status = 'done';
      } catch (e) {
        self.status = 'error';
        throw e;
      }
    }),

    afterAttach() {
      self.fetchPolicyEquityStatus();
    }
  }));

export default PolicyEquityStatus;
