import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson } from '@ourbranch/lookups';
import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { withStore } from 'core/store';
import { connect, Form } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { getSnapshot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useRef, useState } from 'react';
import { addDays } from 'date-fns';
import { useErrors } from '../../helpers/error-hooks';
import { Collapsible } from '../collapsible';
import { Errors } from '../errors';
import { NewConstructionFlagItem } from '../new-construction-flag-item';
import { propDetailsCode } from '../quote-forms/quote-forms.constants';

import styles from './property-detail.styles';

const propDetailsCondition = (code) => propDetailsCode.includes(code);

const PropertyDetail = ({
  classes,
  formik: { values, setFieldValue },
  store,
  showPropertyDetailsCallout,
  sectionsToShow
}) => {
  const {
    quote: { errors: quoteErrors }
  } = store;
  // we didn't seem to be using the 'state' variable anymore once I switched over to lookupsJson
  // from the getOptions() function
  // const { state } = values;
  const {
    typeOfHome: tohOptions,
    homeQuality: hqOptions,
    constructionType: ctOptions,
    basementType: btOptions,
    exteriorWallType: ewtOptions,
    garageType: gtOptions,
    roofShape: rsOptions,
    roofType: rtOptions,
    roofOriginal: roOptions,
    numFamiliesInDwelling: nfidOptions
  } = useMemo(() => {
    const typeOfHome = lookupsJson.typeOfHome;
    const homeQuality = lookupsJson.homeQuality;
    const constructionType = lookupsJson.constructionType;
    const basementType = lookupsJson.basementType;
    const exteriorWallType = lookupsJson.exteriorWallType;
    const garageType = lookupsJson.garageType;
    const roofType = lookupsJson.roofType;
    const roofShape = lookupsJson.roofShape;
    const roofOriginal = lookupsJson.roofOriginal;
    // const numFamiliesInDwelling = lookupsJson.numFamiliesInDwelling;

    const numFamiliesInDwelling = [
      {
        id: '1',
        value: '1'
      },
      {
        id: '2',
        value: '2'
      },
      {
        id: '3',
        value: '3'
      },
      {
        id: '4',
        value: '4'
      }
    ];

    return {
      typeOfHome,
      homeQuality,
      constructionType,
      basementType,
      exteriorWallType,
      garageType,
      roofShape,
      roofType,
      roofOriginal,
      numFamiliesInDwelling
    };
  }, []);

  const errors = useErrors(getSnapshot(quoteErrors), propDetailsCondition);
  const propertyErrors = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (errors.length && propertyErrors.current) {
      propertyErrors.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (values.isNewConstruction === true) {
      setFieldValue('home.roofOriginal', 'true');
    }
    if (values.isNewConstruction === false) {
      setFieldValue('home.roofOriginal', undefined);
    }
  }, [values.isNewConstruction, setFieldValue]);

  useEffect(() => {
    const shouldBeShown = propDetailsCode.every((element) => sectionsToShow.includes(element));
    setShouldShow(shouldBeShown);
  }, [sectionsToShow]);

  return (
    <Form>
      {values.isNewConstruction && <NewConstructionFlagItem />}
      {(errors.length > 0 || values.isNewConstruction || shouldShow) && (
        <>
          <Errors errors={errors} ref={propertyErrors} />
          <Collapsible title="Property Detail" forceOpen={showPropertyDetailsCallout}>
            <>
              <Field xs={3} type="numeric" id="home.yearBuilt" name="home.yearBuilt" mode="light" label="Year Built" />
              <Field
                xs={3}
                type="date"
                id="home.purchaseDate"
                name="home.purchaseDate"
                mode="light"
                label="Purchase Date"
                defaultToEmpty
                disableFuture={false}
                maxDate={addDays(new Date(), 60)}
              />
              <Field
                xs={3}
                type="select"
                options={tohOptions}
                id="home.typeOfHome"
                name="home.typeOfHome"
                mode="light"
                label="Type of home"
              />
              <Field
                xs={3}
                type="select"
                options={nfidOptions}
                id="home.numFamiliesInDwelling"
                name="home.numFamiliesInDwelling"
                mode="light"
                label="Families living"
              />
              <Field
                xs={3}
                type="select"
                options={hqOptions}
                id="home.homeQuality"
                name="home.homeQuality"
                mode="light"
                label="Home quality"
              />
              <Field xs={6} type="numeric" id="sqFt" name="home.sqFt" mode="light" label="Above-ground sqft" />
              <Field
                xs={3}
                type="select"
                id="home.constructionType"
                options={ctOptions}
                name="home.constructionType"
                mode="light"
                label="Construction"
              />
              <Field xs={3} type="numeric" id="numStories" name="home.numStories" mode="light" label="Stories" />
              <Field
                xs={3}
                type="numeric"
                id="home.numHalfBathrooms"
                name="home.numHalfBathrooms"
                mode="light"
                label="Half Bathrooms"
              />
              <Field
                xs={3}
                type="numeric"
                id="home.numFullBathrooms"
                name="home.numFullBathrooms"
                mode="light"
                label="Full Bathrooms"
              />
              <Field
                xs={3}
                type="numeric"
                id="numFireplaces"
                name="home.numFireplaces"
                mode="light"
                label="Fireplaces"
              />
              <Field
                xs={3}
                type="numeric"
                id="home.numWoodBurningStoves"
                name="home.numWoodBurningStoves"
                mode="light"
                label="Wood Burning Stoves"
              />
              <Field
                xs={3}
                type="select"
                id="home.basementType"
                options={btOptions}
                name="home.basementType"
                mode="light"
                label="Basment Type"
              />
              <Field
                xs={6}
                type="numeric"
                id="home.basementSqFt"
                name="home.basementSqFt"
                mode="light"
                label="Basement finished sqft"
              />
              <Field
                xs={6}
                type="select"
                id="home.exteriorWallType"
                options={ewtOptions}
                name="home.exteriorWallType"
                mode="light"
                label="Exterior wall type"
              />
              <Field
                xs={3}
                type="select"
                id="home.garageType"
                options={gtOptions}
                name="home.garageType"
                mode="light"
                label="Type of Garage"
              />
              <Field
                xs={3}
                type="numeric"
                id="home.garageCarCapacity"
                name="home.garageCarCapacity"
                mode="light"
                label="Garage's Car space"
              />
              <Grid item xs={3} className={classes.checkboxContainer}>
                <Field
                  type="select"
                  id="home.roofOriginal"
                  name="home.roofOriginal"
                  options={roOptions}
                  mode="light"
                  label="Original Roof?"
                />
              </Grid>
              <Field xs={3} type="numeric" id="home.roofYear" name="home.roofYear" mode="light" label="Roof Year" />
              <Field
                xs={3}
                type="select"
                id="home.roofType"
                options={rtOptions}
                name="home.roofType"
                mode="light"
                label="Roof type"
              />
              <Field
                xs={3}
                type="select"
                id="home.roofShape"
                options={rsOptions}
                name="home.roofShape"
                mode="light"
                label="Roof shape"
              />
            </>
          </Collapsible>
        </>
      )}
    </Form>
  );
};

PropertyDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  showPropertyDetailsCallout: PropTypes.bool.isRequired
};

export default flowRight(withStyles(styles), withDatePicker, connect, withStore)(PropertyDetail);
