import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

export async function copyDocFromInternalToCustomer({ internalDocPath, customerDocPath, customerDocName }) {
  const credentials = await Auth.currentCredentials();
  const s3Client = new AWS.S3({ region: 'us-east-1', credentials: Auth.essentialCredentials(credentials) });
  const ssmClient = new AWS.SSM({ region: 'us-east-1', credentials: Auth.essentialCredentials(credentials) });

  const getParametersResponse = await new Promise((resolve) => {
    ssmClient.getParameters(
      {
        Names: ['POLICY_DOCS_S3_BUCKET'],
        WithDecryption: true
      },
      (err, data) => {
        resolve(data);
      }
    );
  });

  const bucket = getParametersResponse && getParametersResponse.Parameters && getParametersResponse.Parameters[0].Value;
  try {
    const data = await new Promise((resolve, reject) => {
      s3Client.copyObject(
        {
          Bucket: bucket,
          CopySource: `${bucket}/${internalDocPath}`,
          Key: `${customerDocPath}/${customerDocName}`
        },
        (err, copyData) => {
          if (err) {
            reject(err);
          } else {
            resolve(copyData);
          }
        }
      );
    });
    return { data, success: true };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return { success: false };
  }
}
