import React, { useEffect, useState } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Button } from 'core/components/button';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import LeavePageDialog from 'core/components/leave-page-dialog';
import ResidentsQuestions from './residents';
import CoverageQuestions from './coverages';
import DriverAssignment from './driver-assignment';
import useStyles from './pip-mi.styles';

/**
 * Common component used in both Offer and Policy for PIP coverages.
 * Currently, only rendered for Michigan.
 *
 * @param {boolean} fromPolicy true if this component is being rendered on Policy. false if being rendered from Offer.
 */
const PipMI = ({ fromPolicy }) => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched, resetForm, touched } = useFormikContext();
  const [driverAssignmentOpen, setDriverAssignmentOpen] = useState(false);
  const [leaveModalWarningOpen, setLeaveModalWarningOpen] = useState(false);

  const allowPipAssignment = fromPolicy;

  /**
   * Allowing editing residents values without entering the PIP assignment modal.
   * Only allowed if PIP assignment has not been completed yet.
   */
  const allowEditingResidentsValues = !values?.auto?.hasSetPip;

  useEffect(() => {
    if (values.auto.pipAllResidents === 1 && !values?.auto?.pipEveryoneOnSamePlan) {
      setFieldValue('auto.pipEveryoneOnSamePlan', true);
      setFieldTouched('auto.pipEveryoneOnSamePlan');
    }
  }, [values, setFieldValue, setFieldTouched]);

  const openPIPAssignment = () => {
    setDriverAssignmentOpen(true);
  };

  const closePIPAssignmentOrShowWarning = () => {
    if (touched && Object.keys(touched).length) {
      setLeaveModalWarningOpen(true);
    } else {
      setDriverAssignmentOpen(false);
    }
  };

  const goBackToPipAssignment = () => {
    setLeaveModalWarningOpen(false);
    setDriverAssignmentOpen(true);
  };

  const confirmClosePIPAssignmentWithoutSaving = () => {
    resetForm();
    setLeaveModalWarningOpen(false);
    setDriverAssignmentOpen(false);
  };

  const closeAndSave = () => {
    setFieldValue('auto.hasSetPip', true);
    setFieldTouched('auto.hasSetPip');
    setDriverAssignmentOpen(false);
  };

  return (
    <Section title="Personal injury protection">
      <Card type="primaryEditable" className={classes.card}>
        <ResidentsQuestions editable={allowEditingResidentsValues} />
        {allowPipAssignment && (
          <Grid container justify="center">
            <Button
              key="pipAssignment"
              mode="big"
              variant="contained"
              color="secondary"
              className={classes.topSpacing}
              onClick={openPIPAssignment}
            >
              {values?.auto?.hasSetPip ? 'Edit PIP Assignment' : 'Complete PIP Assignment'}
            </Button>
          </Grid>
        )}
        <Divider className={classes.divider} />
        <CoverageQuestions />
        <DriverAssignment open={driverAssignmentOpen} onClose={closePIPAssignmentOrShowWarning} onSave={closeAndSave} />
        <LeavePageDialog
          open={leaveModalWarningOpen}
          onClose={goBackToPipAssignment}
          cb={confirmClosePIPAssignmentWithoutSaving}
          title="Close PIP Assignment"
        />
      </Card>
    </Section>
  );
};

export default PipMI;
