const styles = (theme) => ({
  tableContainer: {
    boxShadow: 'none',
    color: theme.colors.light_text,
    textAlign: 'center'
  },
  'dark-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.light_background
    }
  },
  'dark-tableCellRoot': {
    fontSize: 16,
    color: theme.colors.light_text,
    padding: '4px 20px 4px 20px'
  },
  checkbox: {
    color: '#ccc4b8'
  },
  tableSortLabelRoot: {
    color: `${theme.colors.light_text} !important`,
    opacity: 0.6,
    '&:hover': {
      color: theme.colors.light_text,
      opacity: 1
    },
    '&:focus': {
      color: theme.colors.light_text
    }
  }
});

export default styles;
