import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import flat from 'flat';
import { Grid } from '@material-ui/core';

import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';

import useStyles from './validation-errors-modal.styles';

const camelCaseToWordsWithSpaces = (str) => {
  return str
    .replace(/([A-Z])/g, ' $1') // insert a space before all caps
    .replace('.', ' ')
    .replace(/^./, (strWithSpaces) => {
      return strWithSpaces.toUpperCase();
    });
};

export const useValidationErrorsModal = () => {
  const [showValidationErrorsModal, setShowValidationErrorsModal] = useState(false);
  return { showValidationErrorsModal, setShowValidationErrorsModal };
};

const ValidationErrorsModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { errors } = useFormikContext();
  const flatErrors = flat(errors);

  return (
    <BaseDialog open={open} onClose={onClose}>
      <Label type="greenBig" className={classes.modalHeader}>
        Validation Errors Found
      </Label>
      <Label type="darkGreenSmall" className={classes.modalContent}>
        Please close this modal and fix the following errors to continue.
        {Object.keys(flatErrors).map((fieldName) => {
          const friendlyFieldName = camelCaseToWordsWithSpaces(fieldName);
          const validationText = flatErrors[fieldName];
          return (
            <Grid item className={classes.validationError} key={fieldName}>
              <Label type="infoLabel" xs={12}>
                {friendlyFieldName}
              </Label>
              <Label type="infoValue" className={classes.modalContent} xs={12}>
                {validationText}
              </Label>
            </Grid>
          );
        })}
      </Label>
    </BaseDialog>
  );
};

ValidationErrorsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ValidationErrorsModal;
