import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function Suggestion({ city, state, streetLine, zipcode }) {
  const address = [
    streetLine ? `${streetLine},` : '',
    city ? `${city},` : '',
    state || zipcode ? `${state} ${zipcode}` : ''
  ].join(' ');

  return <MenuItem component="div">{address}</MenuItem>;
}

Suggestion.propTypes = {
  city: PropTypes.string,
  state: PropTypes.string,
  streetLine: PropTypes.string,
  zipcode: PropTypes.string
};

Suggestion.defaultProps = {
  city: null,
  state: null,
  streetLine: null,
  zipcode: null
};

export default Suggestion;
