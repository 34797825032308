import React, { memo, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { Grid } from '@material-ui/core';

import { Card } from 'core/components/card';
import { getPolicyType } from 'core/helpers/policy-type';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import Section from 'core/components/section';
import { FormField } from 'core/components/form';
import { awsDateTimeFormatter } from 'core/helpers/formatters';
import { getCoverage } from 'offer/helpers/get-coverage';
import useStyles from './cancel-current-policy.styles';

const CancelCurrentPolicy = ({ offer, formik }) => {
  const classes = useStyles();
  const selectedOption = getQuoteFriendlySelectedOption(offer?.quote?.selectedOption || 'HA');

  const { hasHome, hasAuto, isRenters } = getPolicyType(
    selectedOption,
    offer?.quote?.noBindHome,
    offer?.quote?.noBindAuto
  );
  const { hasUmbrellaCoverage, hasJewelryCoverage } = useMemo(() => getCoverage(offer), [offer]);

  const {
    setFieldValue,
    values: { breakupWithAuto, breakupWithHomeowners }
  } = formik;
  const onChangeBreakupSignature = useCallback(
    (_, checked) => {
      setFieldValue('breakupESignature', checked ? awsDateTimeFormatter(new Date()) : null);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (!breakupWithAuto && !breakupWithHomeowners) {
      setFieldValue('breakupESignature', null);
    }
  }, [setFieldValue, breakupWithAuto, breakupWithHomeowners]);

  const homeLabel = useMemo(() => {
    const umbrellaLabel = hasUmbrellaCoverage ? '/Umbrella' : '';
    const jewelryLabel = hasJewelryCoverage ? '/Jewelry' : '';
    return `Cancel current Home${umbrellaLabel}${jewelryLabel} Insurance`;
  }, [hasUmbrellaCoverage, hasJewelryCoverage]);

  const autoLabel = useMemo(() => {
    const rentersLabel = isRenters ? '/Renters' : '';
    return `Cancel current Auto${rentersLabel} Insurance`;
  }, [isRenters]);

  return (
    <Section title="Stress - Free Switch" showSeparator={false} type="SubSection">
      <Card className={classes.cardContainer} type="primaryEditable">
        <Grid container item xs={12} spacing={4}>
          {hasHome && (
            <Grid item xs={12}>
              <Grid container item xs={12} spacing={4}>
                <FormField
                  name="currentHomeownersCarrier"
                  label="Home Insurance provider"
                  type="string"
                  mode="light"
                  xs={6}
                />
                <FormField
                  className={classes.formwithPadding}
                  name="currentHomeownersCarrierPolicyNumber"
                  label="Policy Number From Insurance provider"
                  type="string"
                  mode="light"
                  xs={6}
                />
              </Grid>
              <FormField name="breakupWithHomeowners" label={homeLabel} type="checkbox" mode="light" />
            </Grid>
          )}
          {hasAuto && (
            <Grid item xs={12}>
              <Grid container item xs={12} spacing={4}>
                <FormField
                  xs={6}
                  name="currentAutoCarrier"
                  label="Auto Insurance provider"
                  type="string"
                  mode="light"
                />
                <FormField
                  xs={6}
                  className={classes.formwithPadding}
                  name="currentAutoCarrierPolicyNumber"
                  label="Policy Number From Insurance provider"
                  type="string"
                  mode="light"
                />
              </Grid>
              <FormField name="breakupWithAuto" label={autoLabel} type="checkbox" mode="light" />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.sectionWithLine}>
          <FormField
            name="breakupESignature"
            type="checkbox"
            disabled={!breakupWithHomeowners && !breakupWithAuto}
            onChange={onChangeBreakupSignature}
            label="You authorize Branch to contact the above insurer(s) on your behalf to request cancellation of your current policy or policies, and acknowledge that this process will require that Branch obtain and use your electronic signature. You acknowledge that your electronic signature shall have the same force and effect of a handwritten signature for purposes of validity, enforcement, and admissibility. You also acknowledge that Branch cannot guarantee that the other insurer(s) will process the cancellation request and that you should confirm cancellation directly with the insurer(s) as needed."
            mode="light"
            fast={false}
            topAlignCheckbox
          />
        </Grid>
      </Card>
    </Section>
  );
};

CancelCurrentPolicy.propTypes = {
  offer: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired
};

export default connect(memo(CancelCurrentPolicy));
