import * as Yup from 'yup';

const numericString = (field) => Yup.string().matches(/^\d+$/, `${field} can only be composed of digits`);

export const bankInfoValidation = Yup.object()
  .shape({
    accountHolder: Yup.string().required('Account Holder is required'),
    accountType: Yup.string().required('Account Type is required'),
    accountNumber: numericString('Account number').min(5).required('Account Number is required'),
    routingNumber: numericString('Routing number')
      .length(9, 'Routing number should have only 9 digits')
      .required('Routing number is required')
  })
  .nullable()
  .default(null);
