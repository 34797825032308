const styles = (theme) => ({
  ticketsTable: {
    height: 624,
    overflow: 'scroll'
  },
  noData: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ticketCommentCard: {
    marginTop: 8,
    padding: 24
  },
  ticketCommentAuthor: {
    marginTop: 16
  },
  description: {
    paddingTop: 10,
    paddingBottom: 10
  }
});

export default styles;
