import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  withErrors: {
    marginBottom: 340
  },
  container: {
    marginBottom: 120
  },
  loaderContainer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 32,
    minHeight: 600
  },
  loader: {
    color: theme.palette.secondary.main
  },
  tab: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  cardLoadingContainer: {
    width: '100%',
    marginTop: 16,
    backgroundColor: theme.colorPalette.green_20,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: 16
  }
}));

export default useStyles;
