import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Label } from 'core/components/label';
import BaseDialog from 'core/components/base-dialog';
import { NotificationCard } from 'core/components/notification-card';
import ResidentsValues from '../residents/residents-uneditable';
import DriverCards from './components/driver-cards';
import NonDriverCards from './components/non-driver-cards';

const useStyles = makeStyles(() => ({
  button: {
    width: 150,
    padding: '15px 0px',
    marginTop: 20
  }
}));

const DriverAssignment = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    // When the driver assignment modal is open, we want updates to total counts to happen automatically when checkboxes are changed in components below
    if (open) {
      const pipAllResidents = values.drivers.length + (values.nonDrivers?.length || 0);
      setFieldValue('auto.pipAllResidents', pipAllResidents);

      const pipAllResidentsArray = [...values.drivers, ...(values.nonDrivers || [])];
      const pipResidentsWithQHC = pipAllResidentsArray.filter((resident) => resident.pipHasQHC).length;
      setFieldValue('auto.pipResidentsWithQHC', pipResidentsWithQHC);

      const pipAdditionalResidents = values.nonDrivers?.filter((resident) => !resident.pipEligible).length || 0;
      setFieldValue('auto.pipAdditionalResidents', pipAdditionalResidents);

      const pipExcludedResidents = pipAllResidentsArray.filter((resident) => resident.pipOptOut).length;
      setFieldValue('auto.pipExcludedResidents', pipExcludedResidents);
    }
  }, [values, setFieldValue, open]);

  return (
    <BaseDialog open={open} onClose={onClose}>
      <Label type="subtitle">PIP Assignment</Label>
      <NotificationCard type="secondary">
        <Grid container alignItems="center">
          Making changes to PIP assignment can have an impact on price.
        </Grid>
      </NotificationCard>
      <ResidentsValues />
      <DriverCards />
      <NonDriverCards />
      <Grid container justify="space-around">
        <Button type="submit" variant="contained" color="secondary" className={classes.button} onClick={onSave}>
          Save Changes
        </Button>
      </Grid>
    </BaseDialog>
  );
};

export default DriverAssignment;
