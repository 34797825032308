import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './card-line.styles';

const CardLine = ({ children, className }) => {
  const classes = useStyles();
  return <div className={classNames(classes.item, className)}>{children}</div>;
};

CardLine.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

CardLine.defaultProps = {
  className: null
};

export default CardLine;
