import { paymentMethod as PaymentMethod } from '@ourbranch/lookups';
import _omit from 'lodash-es/omit';
import _findIndex from 'lodash-es/findIndex';
import _isEmpty from 'lodash-es/isEmpty';

export const getPrimaryMortgageIndex = (mortgageDetails) => {
  if (mortgageDetails && mortgageDetails.length) {
    return _findIndex(mortgageDetails, (mortgageDetail) => {
      return mortgageDetail.primary;
    });
  }
  return -1;
};

export const pushNewBlankPrimaryMortageDetail = (mortgageDetails) => {
  return [
    ...mortgageDetails,
    {
      primary: true,
      loanNumber: undefined,
      mortgageHolderAddress: undefined,
      mortgageHolderName: undefined,
      mortgageHolderId: undefined
    }
  ];
};

export const removeBlankMortgageDetail = (mortgageDetails) => {
  if (mortgageDetails.length) {
    const newMortgageDetailsArray = [];
    mortgageDetails.forEach((mortgageDetail) => {
      if (mortgageDetail.mortgageHolderName) {
        newMortgageDetailsArray.push(mortgageDetail);
      }
    });
    return newMortgageDetailsArray;
  }
  return mortgageDetails;
};

export const removeOldPrimaryMortgageDetails = (mortgageDetails) => {
  const newMortgageDetailsArray = [];
  mortgageDetails.forEach((mortgageDetail) => {
    if (!mortgageDetail.primary) {
      newMortgageDetailsArray.push(mortgageDetail);
    }
  });
  return newMortgageDetailsArray;
};

export const getPrimaryOrBlankMortgageDetails = (mortgageDetails) => {
  const index = getPrimaryMortgageIndex(mortgageDetails);
  if (index < 0) {
    return {
      primary: true,
      loanNumber: undefined,
      mortgageHolderAddress: undefined,
      mortgageHolderName: undefined,
      mortgageHolderId: undefined
    };
  }
  return mortgageDetails[index];
};

export const updatePrimaryMortgageDetails = (currentMortgageDetails, updatedPrimaryMortgageDetail, paymentMethod) => {
  let mortgageDetails = currentMortgageDetails ? [...currentMortgageDetails] : [];
  if (getPrimaryMortgageIndex(currentMortgageDetails) >= 0) {
    mortgageDetails = removeOldPrimaryMortgageDetails(mortgageDetails);
  }
  const primaryMortgageDetailCopy = _omit(updatedPrimaryMortgageDetail, ['primary', '__typename']);
  if (
    Object.values(primaryMortgageDetailCopy).some((detail) => !_isEmpty(detail)) ||
    paymentMethod === PaymentMethod.Escrow
  ) {
    mortgageDetails.push(updatedPrimaryMortgageDetail);
  }

  // remove mortgages that aren't properly filled out with a lender name
  return mortgageDetails.filter((md) => md.mortgageHolderName);
};
