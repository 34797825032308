import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { cancelCodeInformationalArray } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import useStyles from './non-renew-form.styles';

const NonRenewForm = () => {
  const styles = useStyles();

  const nonRenewOptions = cancelCodeInformationalArray
    .filter((opt) => opt.nonRenew)
    .map((code) => ({
      id: code.cancelCode,
      value: code.cancelReasonPlainText
    }));

  return (
    <Grid container item className={styles.container}>
      <FormField name="nonRenewReason" type="select" label="Reason" mode="light" options={nonRenewOptions} xs={6} />
    </Grid>
  );
};

export default flowRight(connect, withDatePicker)(NonRenewForm);
