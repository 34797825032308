import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.colorPalette.orange_50
  },
  containerSmall: {
    width: '100%',
    height: 32,
    fontSize: 14
  },
  notificationContentContainerSmall: {
    display: 'flex',
    alignItems: 'center'
  },
  iconSmall: {
    margin: '8px 8px 8px 32px',
    height: 16
  }
}));

export default useStyles;
