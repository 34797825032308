export default (theme) => ({
  errorsContainer: {
    padding: 0
  },
  errorContainer: {
    position: 'absolute',
    width: 260,
    left: -300,
    background: theme.colorPalette.red_20,
    padding: 32,
    paddingTop: 24,
    borderRadius: 6,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  errorContent: {
    textAlign: 'left'
  },
  errorMessage: {
    color: theme.colorPalette.beige_10
  },
  rejectionsContainer: {
    zIndex: 99
  },
  rejectionContainer: {
    width: '100%',
    background: theme.colorPalette.red_20,
    paddingTop: 32,
    paddingBottom: 32,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  rejectionContent: {
    maxWidth: 1280,
    width: '100%',
    textAlign: 'left',
    margin: '0 auto'
  },
  nonFcraContainer: {
    background: theme.colorPalette.beige_20
  },
  topInfo: {
    color: theme.colorPalette.white_10
  },
  nonFcraTopInfo: {
    color: theme.colorPalette.green_20
  },
  title: {
    color: theme.colorPalette.white_10
  },
  nonFcraTitle: {
    color: theme.colorPalette.green_20
  },
  message: {
    marginTop: 8,
    wordBreak: 'break-word'
  },
  fcraMessage: {
    color: theme.colorPalette.white_10
  },
  nonFcraMessage: {
    color: theme.colorPalette.green_20
  },
  rejectionMessage: {
    flexDirection: 'column'
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '11px solid transparent',
    borderLeft: `16px solid ${theme.colorPalette.red_20}`,
    borderBottom: '11px solid transparent',
    position: 'absolute',
    right: -16
  }
});
