import Grid from '@material-ui/core/Grid';
import { FastField, Field, getIn } from 'formik';
import noop from 'lodash-es/noop';
import React, { memo } from 'react';
import ErrorBoundary from '../error-boundary';
import withDatePicker from '../with-date-picker';
import fieldTypes from './deprecated-field-types';
import { FormPropTypes } from './propTypes';

const parseDisabled = (field, ctx) => {
  const props = field.props || {};
  return {
    ...field,
    props: {
      ...props,
      disabled: ctx.disabled || props.disabled
    }
  };
};

const parseField = (element, ctx) => {
  const value = getIn(ctx.values, element.name || element.id);
  const field = {
    ...element,
    value
  };
  if (!field.globalDisable && field.globalDisable !== undefined) {
    return field;
  }
  return parseDisabled(field, ctx);
};

const getField = (element, ctx) => {
  const field = parseField(element, ctx);
  if (field.width) {
    if (field.width !== Math.floor(field.width)) {
      const customWidth = `${8.333333 * field.width}%`;
      return (
        <Grid
          item
          xs={Math.floor(field.width)}
          key={field.id}
          style={{
            maxWidth: customWidth,
            flexBasis: customWidth
          }}
        >
          {fieldTypes[field.type]({ field, ctx })}
        </Grid>
      );
    }
    return (
      <Grid item xs={field.width} key={field.id}>
        {fieldTypes[field.type]({ field, ctx })}
      </Grid>
    );
  }
  return fieldTypes[field.type]({ field, ctx });
};

const process = (data, { classes, disabled }) =>
  data.map((element) => {
    const key = element.name || element.id;

    if (element.type) {
      if (element.disableFast) {
        return (
          <Field key={element.props ? element.props.key || key : key} name={key}>
            {({ form }) =>
              getField(
                {
                  ...element,
                  onChange: element.onChange || noop
                },
                {
                  ...form,
                  classes,
                  disabled
                }
              )
            }
          </Field>
        );
      }
      return (
        <FastField key={element.props ? element.props.key || key : key} name={key}>
          {({ form }) =>
            getField(
              {
                ...element,
                onChange: element.onChange || noop
              },
              {
                ...form,
                classes,
                disabled
              }
            )
          }
        </FastField>
      );
    }
    if (typeof element === 'string') {
      return element;
    }
    const { component: Comp, withContext } = element;
    const props = {
      ...element.props,
      ...(element.children ? { children: process(element.children, { classes, disabled }) } : {})
    };
    if (withContext) {
      return (
        <Field key={element.props ? element.props.key || element.id : key} name={key}>
          {({ form }) => <Comp {...props} {...form} {...{ classes, disabled }} />}
        </Field>
      );
    }
    return <Comp {...props} />;
  });

// const createValidation = (field) => {
//   if (Yup[field.type]) {
//     const validation = Yup[field.type]();
//     (field.validations || []).map(([func, ...params]) =>
//       validation[func].apply(validation[func], params)
//     );
//     return validation;
//   }
// };

// const crawlFormElements = (arr, func) =>
//   arr.map((e) => (e.type ? func(e) : e.children && crawlFormElements(e.children, func)));

// const processValidation = (data) => {
//   const shape = {};

//   crawlFormElements(data, (field) => {
//     const validation = createValidation(field);
//     if (validation) {
//       shape[field.id] = validation;
//     }
//   });

//   return Yup.object().shape(shape);
// };

function Form({ classes, data, className, disabled }) {
  return (
    <div className={className}>
      <ErrorBoundary>{process(data, { classes, disabled })}</ErrorBoundary>
    </div>
  );
}

Form.propTypes = FormPropTypes;

Form.defaultProps = {
  classes: {},
  className: undefined
};

export default withDatePicker(memo(Form));
