import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  icon: {
    height: 24,
    marginRight: 12,
    width: 24
  }
});

export default useStyles;
