import React from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import { policyType as PolicyType } from '@ourbranch/lookups';
import { Button } from 'core/components/button';
import { useStore } from 'core/store';

import useStyles from './verify-property-container.styles.js';

const VerifyPropertyFooter = observer(({ setShowNoHomeModal }) => {
  const classes = useStyles();

  const { handleSubmit, values, setFieldValue, setFieldTouched } = useFormikContext();

  const { offer: offerStore } = useStore();
  const onClick = async ({ skipHome = false }) => {
    if (offerStore.priorQuoteWithPreBindUWRejections?.ineligibleForHome && !skipHome) {
      setShowNoHomeModal(true);
      return;
    }

    await setFieldValue(
      'global.preBindUWCheck.homeVerification.signedPropertyUWAttestation',
      !skipHome && values.global.preBindUWCheck.homeVerification.signedPropertyUWAttestation
    );
    await setFieldValue('global.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport', skipHome);
    if (skipHome || offerStore.priorQuoteWithPreBindUWRejections?.ineligibleForHome) {
      setFieldValue('selectedOption', PolicyType.Auto);
      setFieldTouched('selectedOption');
    }

    await setFieldTouched('global.preBindUWCheck.homeVerification.signedPropertyUWAttestation');
    await setFieldTouched('global.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport');

    handleSubmit();
  };

  return (
    <Grid className={classes.floatingContainer}>
      <Grid className={classes.footerContainer}>
        <Grid className={classes.footerContent}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.footerBtn}
            onClick={() => onClick({ skipHome: true })}
          >
            Skip home for now
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.footerBtn}
            onClick={() => onClick({ skipHome: false })}
          >
            Continue to modify offer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default VerifyPropertyFooter;
