import camelCase from 'lodash-es/camelCase';

/**
 * ElemType - works as an enum for possible values used as the type argument calling
 * MapClassesToElem, however this function could be called with any string
 */
export const ElemType = {
  Label: 'label',
  Input: 'input',
  FormHelper: 'formHelper',
  Button: 'button',
  Select: 'select',
  Paper: 'paper',
  LinearProgress: 'linearProgress',
  CircularProgress: 'circularProgress',
  IconButton: 'iconButton',
  TextField: 'textField',
  TableCell: 'tableCell',
  TableRow: 'tableRow',
  TableSortLabel: 'tableSortLabel',
  InputAdornment: 'inputAdornment',
  Checkbox: 'checkbox',
  Modal: 'modal',
  Radio: 'radio',
  FormControlLabel: 'controlLabel'
};

/**
 * MapClassesToElem - extract a set of style classes to overwrite MUI components styles
 * if you need to overwrite 'root' and 'label' classes for 'input'
 * you can call the function like this:
 *
 * elemType: ElemType.Input
 * classes: { inputRoot: { ...rootStyles }, inputLabel: { ...labelStyles } }
 * output: { root: { ...rootStyles }, label: { ...labelStyles } }
 *
 * @param type one of the elements from the ElemType enum
 * @param classes JSS Classes object with namespaces
 * @returns JSS classes object for one specific namespace
 */
export const MapClassesToElem = (type, classes) =>
  Object.entries(classes)
    .filter(([key]) => key.startsWith(type))
    .reduce(
      (ret, [key, val]) => ({
        ...ret,
        [camelCase(key.substr(type.length))]: val
      }),
      {}
    );
