import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { Label } from 'core';
import AddButton from 'core/components/add-button';
import RemoveButton from 'core/components/remove-button';
import { Tooltip } from 'core/components/tooltip';
import { numberFormatter } from 'core/helpers/formatters';
import editIcon from 'core/components/editable-card/edit.svg';
import { CardLine } from '../../card-line';
import useStyles from './discount.styles';

const money = (value) => `$${numberFormatter(value)}`;
const percentage = (value) => `${value}%`;

const Discount = ({
  label,
  description,
  amount,
  possibleSavings,
  isActive,
  canEdit,
  onRemove,
  onAdd,
  connectedHomeInfo = {},
  disabled
}) => {
  const classes = useStyles();

  const discountLabel = () => {
    if (label === 'Windstorm Mitigation') {
      return 'Applied';
    }
    if (label === 'Connected Home') {
      if (
        (connectedHomeInfo && connectedHomeInfo.includeConnectedHome && !isActive) ||
        (!connectedHomeInfo.includeConnectedHome && isActive)
      ) {
        return 'Update to see...';
      }
    }
    return isActive && amount ? `Saving ${money(amount)}` : `Save up to ${percentage(possibleSavings)}`;
  };

  return (
    <CardLine className={!isActive ? classes.inactive : null}>
      <div className={classes.section}>
        <Label type="discount">{label}</Label>
        {description && <Tooltip className={classes.info} text={description} light={!isActive} />}
      </div>
      <div className={classes.section}>
        <Label className={classes.savings} type="discount">
          {discountLabel()}
        </Label>
        {isActive && canEdit && (
          <IconButton onClick={onAdd} className={classes.iconContainer}>
            <img src={editIcon} alt="edit" size="small" className={classes.editIcon} />
          </IconButton>
        )}
        {isActive && onRemove && <RemoveButton onClick={onRemove} />}
        {!isActive && onAdd && (
          <AddButton type="full" light className={classes.button} onClick={onAdd} disabled={disabled} />
        )}
      </div>
    </CardLine>
  );
};

Discount.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  amount: PropTypes.number.isRequired,
  possibleSavings: PropTypes.number,
  isActive: PropTypes.bool,
  canEdit: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
};

Discount.defaultProps = {
  isActive: false,
  canEdit: false,
  description: null,
  onAdd: null,
  onRemove: null,
  possibleSavings: 0
};

export default Discount;
