const styles = (theme) => ({
  container: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: 32
  },

  linearProgressRoot: {
    position: 'absolute',
    height: 4,
    width: '100%',
    marginBottom: 0,
    backgroundColor: theme.colors.loading_background
  },

  linearProgressBarColorPrimary: {
    backgroundColor: theme.colors.loading_bar
  },

  header: {
    height: 48
  },
  'dark-tableRowHeaderRoot': {
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`
  },
  scrollable: {
    height: 630,
    overflow: 'scroll'
  }
});

export default styles;
