import React, { useState, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import BaseDialog from 'core/components/base-dialog';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { useStyles } from './car-assignment.styles';

const AssignmentSteps = {
  Cars: 1,
  Drivers: 2
};

export const CarAssignmentModal = ({ open, onClose }) => {
  const { values, setFieldTouched } = useFormikContext();
  const classes = useStyles();
  const [step, setStep] = useState(AssignmentSteps.Cars);

  const eligibleDrivers = useMemo(() => values.drivers.filter((driver) => !driver.excludeDriver), [values.drivers]);

  const driversOptions = eligibleDrivers.map((driver) => ({
    id: driver.id,
    value: `${driver.firstName} ${driver.lastName}`
  }));

  const carsOptions = values.cars.map((car) => ({
    id: car.VIN,
    value: `${car.year || ''} ${car.make || ''} ${car.model || `New Car ${car.VIN}`}`
  }));

  const carsOptionsFilter = (driverId) => {
    const opts = carsOptions.filter(
      (carOption) => values.cars.find((car) => car.VIN === carOption.id).assignedDriver === driverId
    );
    return opts.length ? opts : carsOptions;
  };
  const equalCarsAndDrivers = carsOptions.length === driversOptions.length;
  const skipDriversAssignment = equalCarsAndDrivers || carsOptions.length === 1;
  const skipCarsAssignment = driversOptions.length === 1;

  // only need to assign cars for drivers assigned to multiple cars
  const driversNeedsAssignment = eligibleDrivers
    .filter((driver) => {
      return (
        values.cars.filter((car) => car.assignedDriver === driver.id).length > 1 ||
        !values.cars.map((car) => car.assignedDriver).includes(driver.id)
      );
    })
    .map((driver) => driver.id);

  useEffect(() => {
    // dont get stuck on a step if it's getting skipped
    if (skipDriversAssignment) {
      setStep(AssignmentSteps.Cars);
    }
    if (skipCarsAssignment) {
      setStep(AssignmentSteps.Drivers);
    }
  }, [skipDriversAssignment, skipCarsAssignment]);

  // because of the way the validations works each field must have been touched
  useEffect(() => {
    values.cars.forEach((car, index) => {
      setFieldTouched(`cars[${index}].assignedDriver`);
    });
    values.drivers.forEach((driver, index) => {
      setFieldTouched(`drivers[${index}].assignedCar`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DialogTitle = () => {
    const dialogTitles = () => {
      let title;
      let subtitle;
      switch (step) {
        case AssignmentSteps.Cars: {
          title = 'Assign Drivers to Cars';
          if (equalCarsAndDrivers || eligibleDrivers.length > values.cars.length) {
            subtitle = 'Please note that a driver cannot be assigned to more than one car.';
          } else {
            subtitle = 'Every driver must be assigned to at least one car';
          }
          break;
        }
        case AssignmentSteps.Drivers: {
          title = 'Assign Cars to Drivers';
          if (values.cars.length > 1) {
            subtitle = 'A Car may not have more than one primary driver';
          }
          break;
        }
        default: {
          title = 'Assign Drivers and Cars';
        }
      }
      return { title, subtitle };
    };

    const { title, subtitle } = dialogTitles();

    return (
      <div className={classes.assignDriversTitle}>
        <Label type="greenBig">{title}</Label>
        <Label type="infoSubtitle" className={classes.assignDriversSubtitle}>
          {subtitle}
        </Label>
      </div>
    );
  };

  return (
    <BaseDialog open={open} onClose={onClose} title={<DialogTitle />}>
      <div className={classes.modal}>
        {step === AssignmentSteps.Cars && !skipCarsAssignment && (
          <>
            {values.cars.map((car, index) => (
              <FormField
                id={`cars[${index}].assignedDriver`}
                name={`cars[${index}].assignedDriver`}
                type="select"
                options={driversOptions}
                mode="light"
                fast={false}
                label={`Who primarily drives the ${car.year || ''} ${car.make || ''} ${
                  car.model || `New Car ${car.VIN}`
                } ?`}
                className={classes.assignmentField}
                key={car.VIN}
              />
            ))}
            <div className={classes.assignDriversFooter}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.footerButton}
                onClick={() => {
                  if (skipDriversAssignment) {
                    onClose();
                  } else {
                    setStep(AssignmentSteps.Drivers);
                  }
                }}
              >
                {skipDriversAssignment ? 'Save Changes' : 'Next'}
              </Button>
            </div>
          </>
        )}
        {step === AssignmentSteps.Drivers && !skipDriversAssignment && (
          <>
            {values.drivers.map(
              (driver, index) =>
                !driver.excludeDriver &&
                driversNeedsAssignment.includes(driver.id) && (
                  <FormField
                    id={`drivers[${index}].assignedCar`}
                    name={`drivers[${index}].assignedCar`}
                    type="select"
                    // only the cars with this assigned driver
                    options={carsOptionsFilter(driver.id)}
                    mode="light"
                    fast={false}
                    label={`Which car does ${driver.firstName} ${driver.lastName} primarily drive?`}
                    className={classes.assignmentField}
                    key={driver.id}
                  />
                )
            )}
            <div className={classes.assignDriversFooter}>
              {!skipCarsAssignment && (
                <Button
                  onClick={() => {
                    setStep(AssignmentSteps.Cars);
                  }}
                  color="secondary"
                  type="submit"
                  className={classes.footerButton}
                >
                  Go Back
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                color="secondary"
                type="submit"
                className={classes.footerButton}
              >
                Save Changes
              </Button>
            </div>
          </>
        )}
      </div>
    </BaseDialog>
  );
};
