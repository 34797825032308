import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RepeatField extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    initialLength: PropTypes.number,
    control: PropTypes.func.isRequired,
    maxSize: PropTypes.number
  };

  static defaultProps = {
    initialLength: 1,
    maxSize: 5
  };

  state = {
    length: 1,
    focus: -1
  };

  constructor(props) {
    super(props);
    this.state = {
      length: props.initialLength
    };
  }

  onAdd = () => this.setState(({ length }) => ({ focus: length, length: length + 1 }));

  onRemove = () => this.setState(({ length }) => ({ length: length - 1 }));

  render() {
    const { state, onAdd, onRemove } = this;
    const { children, control, maxSize } = this.props;
    const { length, focus } = state;
    return (
      <>
        {Array(length)
          .fill({ onRemove, focus })
          .map(children)}
        {length < maxSize && control(onAdd)}
      </>
    );
  }
}
