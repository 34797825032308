import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import NotificationType from 'core/helpers/notifications';
import { useStore } from 'core/store';
import { NotificationCard } from 'core/components/notification-card';
import { Button } from 'core/components/button';
import useStyles from './notification.styles';

const AbodeProPlan = ({ classes }) => (
  <Label className={classes.styledText}>
    {`This offer was generated using a special link that includes `}
    <strong>{`an additional $100 charge for the Abode Pro plan.`}</strong>
    {` This charge is separate from your premium and must be paid by credit card. If you do not want the Abode Pro Plan included, please turn off the
        Connected Home discount in the Settings tab and update the offer.`}
  </Label>
);

const NewConnectedHomeSignUpCustomer = ({ classes }) => (
  <Label className={classes.styledText}>
    {`A new sign up will prompt the home security company to contact the customer to buy the home security system necessary to retain the Connected Home Discount. Available with select home security partnerships only.`}
  </Label>
);

const ConnectedHome = ({ classes }) => (
  <Label className={classes.styledText}>
    {`This offer has an affinity discount from a Connected Home partner, but does not include a Connected Home discount,
        resulting in an incorrect price. To correct this, go to the Settings tab and `}
    <strong>{`make sure the Connected Home discount is turned on and has devices
        added, or remove the affinity discount.`}</strong>
  </Label>
);

const OfferNeedsUWReviewForHome = ({ classes, store }) => (
  <Grid container className={classes.UWContainer}>
    <Label className={classes.UWStyledText}>
      {`This offer needs an underwriting review from a sales agent to be eligible for home.`}
    </Label>
    <Button
      className={classes.verifyBtn}
      variant="contained"
      color="primary"
      onClick={() => store.setShowPreBindUWForm(true)}
    >
      Verify
    </Button>
  </Grid>
);

const PriorQuoteWithSelfReportedUWFlags = ({ classes }) => (
  <Grid container className={classes.UWContainer}>
    <Label className={classes.title}>Quoter self-reported underwriting disqualification.</Label>
    <Label className={classes.subText}>
      During this or a prior sales underwriting review, this member reported a home characteristic that does not meet
      Branch underwriting guidelines.
    </Label>
  </Grid>
);

const OfferNotifications = observer(({ type }) => {
  const { offer: offerStore } = useStore();
  const classes = useStyles();
  return (
    <Grid id="notificationsContainer">
      {offerStore.notifications.map((notificationType) => (
        <NotificationCard key={notificationType} type={type}>
          {notificationType === NotificationType.Offer.AbodeProPlan && <AbodeProPlan classes={classes} />}
          {notificationType === NotificationType.Offer.ConnectedHome && <ConnectedHome classes={classes} />}
          {notificationType === NotificationType.Offer.NewConnectedHomeSignUpCustomer && (
            <NewConnectedHomeSignUpCustomer classes={classes} />
          )}
          {notificationType === NotificationType.Offer.OfferNeedsUWReviewForHome && (
            <OfferNeedsUWReviewForHome classes={classes} store={offerStore} />
          )}
          {notificationType === NotificationType.Offer.PriorQuoteWithSelfReportedUWFlags && (
            <PriorQuoteWithSelfReportedUWFlags classes={classes} store={offerStore} />
          )}
        </NotificationCard>
      ))}
    </Grid>
  );
});

export default OfferNotifications;
