import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    marginBottom: 8
  },
  chipRoot: {
    height: 36,
    padding: '9px 20px 10px',
    backgroundColor: theme.colorPalette.white_30,
    borderRadius: 0,
    marginLeft: 16
  },
  modal: {
    padding: '0 50px'
  },
  assignDriversTitle: {
    padding: `56px 40px 28px`
  },
  assignDriversSubtitle: {
    marginTop: 24
  },
  assignDriversFooter: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  assignmentField: {
    marginBottom: `24px !important` // sorry
  },
  footerButton: {
    padding: '0 44px'
  }
}));
