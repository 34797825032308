import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

import styles from './header-tab.styles';

const MenuItem = ({ classes, iconSrc, label, ...props }) => {
  const tabClasses = useMemo(() => {
    const { img, label: labelClass, ...otherClasses } = classes;
    return otherClasses;
  }, [classes]);
  return (
    <Tab
      classes={tabClasses}
      label={
        <>
          <span className={classes.label}>{label}</span>
          <img className={classes.img} src={iconSrc} alt={label} />
        </>
      }
      {...props}
    />
  );
};

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  iconSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(MenuItem);
