import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../auth';

export const ProtectedRoute = (props) => {
  const session = useContext(AuthContext);
  const { permission, negatePermission } = props;
  const sessionPermission = negatePermission ? !session[permission] : session[permission];
  if (session.isLoggedIn && sessionPermission) {
    return <Route {...props} />;
  }
  return <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  permission: PropTypes.string,
  negatePermission: PropTypes.bool
};

ProtectedRoute.defaultProps = {
  permission: 'isService',
  negatePermission: false
};
