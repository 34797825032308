const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    justifyContent: 'center',
    marginBottom: 32
  },
  circularProgressRoot: {
    color: theme.colors.loading_bar
  },
  centered: {
    margin: 'auto'
  }
});

export default styles;
