import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import useStyles from './clear-udrs-modal.styles';

const ClearUDRsModal = observer(({ setOpenClearUDRViolationModal, fieldName, item }) => {
  const classes = useStyles();
  const { offer: offerStore } = useStore();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const goBack = () => {
    setFieldValue(`${fieldName}.clearUDRViolation`, false);
    setOpenClearUDRViolationModal(false);
  };

  const confirmClearingUDRs = () => {
    if (item.recheckDrivingRecord) {
      // eslint-disable-next-line no-unused-expressions
      offerStore.drivers.get(item.id)?.toggleWillRecheckDrivingRecord();
      setFieldValue(`${fieldName}.recheckDrivingRecord`, false);
      setFieldTouched(`${fieldName}.recheckDrivingRecord`, true);
    }
    setOpenClearUDRViolationModal(false);
  };

  return (
    <BaseDialog onClose={goBack} open>
      <Label className={classes.mainLabel} type="subtitle">
        Are you sure you want to clear this driver&apos;s unverifiable driving record violation?
      </Label>
      <Label className={classes.subLabel}>
        This driver&apos;s MVR will not be checked and therefore not taken into account for rating purposes. Only do
        this if you have manually verified their MVR.
      </Label>
      <Grid className={classes.buttonContainer}>
        <Button className={classes.buttons} variant="contained" color="secondary" onClick={goBack}>
          No, go back
        </Button>

        <Button className={classes.buttons} variant="contained" color="primary" onClick={confirmClearingUDRs}>
          I have manually verified MVR
        </Button>
      </Grid>
    </BaseDialog>
  );
});

export default ClearUDRsModal;
