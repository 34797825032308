export default () => ({
  form: {
    width: '100%'
  },
  endDate: {
    paddingRight: 18
  },
  checkbox: {
    width: 185,
    alignItems: 'center',
    paddingTop: '7px'
  }
});
