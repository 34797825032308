import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Collapse from 'core/components/collapsible-card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { getCategory } from 'core/helpers/scheduled-pp-helper';
import PropertyForm from './form';
import styles from './schedule-pp.styles';
import { icons } from './icons';

const Property = ({
  classes,
  item,
  index,
  onRemove,
  removeFromList,
  disabled,
  fieldId,
  fieldName,
  previous,
  count
}) => {
  const { description } = item;
  const category = getCategory(item);
  return (
    <>
      <Collapse
        classes={{ content: classes.header }}
        content={
          <>
            <img alt={`icon-${index}`} className={classes.categoryIcon} src={icons[category]} />
            <Label type="infoSubtitle">
              {`${description || ''}`.substring(0, 20)}
              {description && description.length > 20 && '...'}
            </Label>
          </>
        }
        contentRight={
          <FormField
            type="remove"
            name="sppRemove"
            disabled={disabled}
            mode="big"
            onClick={() => onRemove(index, removeFromList)}
          />
        }
      >
        <PropertyForm values={item} id={fieldId} name={fieldName} classes={classes} index={index} disabled={disabled} />
      </Collapse>
    </>
  );
};

Property.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  removeFromList: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  previous: PropTypes.object,
  count: PropTypes.number.isRequired
};

Property.defaultProps = {
  disabled: false,
  previous: undefined
};

export default withStyles(styles)(Property);
