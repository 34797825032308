import * as Yup from 'yup';
import { localToUtcTime } from '../dates';

export const validationSchema = ({ values, isTeamLeader, geographicState }) => {
  const baseSchema = Yup.object()
    .shape({
      billingHoldUntil: Yup.string()
        .nullable()
        .test('invalid', 'Cannot set hold date more than 7 days from next payment due date', function test(value) {
          return isTeamLeader
            ? true
            : localToUtcTime(new Date(value), geographicState).setHours(24, 0, 0, 0) <= values.maxBillingHoldDate;
        })
    })
    .nullable()
    .default(null);
  return baseSchema;
};
