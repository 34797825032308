import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  additionalsContainer: {
    marginTop: 30
  },
  cardContainer: {
    width: '100%',
    marginTop: 16,
    backgroundColor: theme.colorPalette.green_20,
    marginBottom: 16,
    padding: '30px 30px'
  },
  labelAndTooltipContainer: {
    width: 135
  },
  payment: {
    marginLeft: 5
  }
}));

export default useStyles;
