import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  spinner: {
    width: 96,
    height: 96,
    marginTop: 60,
    marginBottom: 20
  },
  container: {
    width: 600,
    margin: '0 auto'
  },
  button: {
    width: 288,
    marginBottom: 16
  },
  subtitle: {
    marginTop: 32,
    marginBottom: 50,
    textAlign: 'center'
  }
});

export default makeStyles(styles);
