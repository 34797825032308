import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { Label } from 'core';
import styles from './no-results.styles';

const NoResults = ({ classes, message, className }) => (
  <Paper className={classNames(classes.container, className)}>
    <Label type="infoValue" data-cy="no-results">
      {message}
    </Label>
  </Paper>
);

NoResults.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.string
};

NoResults.defaultProps = {
  message: 'No data available for your search.',
  className: null
};

export default withStyles(styles)(NoResults);
