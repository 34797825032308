const styles = (theme) => ({
  sliderContainer: {
    paddingBottom: 32,
    paddingTop: 24,
    paddingLeft: 8,
    paddingRight: 8
  },
  sliderLblsContainer: {
    position: 'relative',
    width: '100%',
    padding: '0'
  },
  sliderLblsContent: {
    position: 'relative',
    width: '100%'
  },
  sliderTrack: {
    '&$sliderDisabled': {
      backgroundColor: theme.colorPalette.orange_10,
      cursor: 'auto'
    }
  },
  sliderThumb: {
    '&$sliderDisabled': {
      backgroundColor: theme.colorPalette.orange_10,
      width: 14,
      height: 14,
      cursor: 'auto'
    },
    '&:hover': {
      boxShadow: 'none'
    }
  },
  sliderRoot: {
    '&$sliderDisabled': {
      cursor: 'auto'
    }
  },
  sliderDisabled: {
    color: theme.colorPalette.orange_10,
    cursor: 'auto'
  },
  labels: {
    color: theme.colorPalette.beige_10,
    fontSize: 10,
    position: 'absolute',
    cursor: 'pointer',
    transform: 'translate(-50%, 8px)'
  },
  labelThumb: {
    width: 6,
    height: 6,
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    borderRadius: '50%',
    backgroundColor: theme.colorPalette.orange_10,
    marginTop: '-3px',
    marginBottom: 8
  },
  border: {
    transform: 'translate(-8px, 8px)'
  },
  active: {
    extend: '$label',
    fontSize: 12,
    color: theme.colorPalette.orange_10,
    fontWeight: 'bold'
  },
  'dark-label': {
    textTransform: 'uppercase',
    color: theme.colorPalette.white_40_op_70,
    position: 'relative',
    left: -8,
    top: -20
  }
});

export default styles;
