import { apolloClient } from 'core/apollo-client';
import gql from 'graphql-tag';

const getMoratoriumZipCodesQuery = gql`
  query getMoratoriumZipCodesQuery {
    getMoratoriumZipCodes
  }
`;

let cachedMoratoriumZipCodes = [];
let lastCachedTime = null;
const cacheExpiryTimeMs = 600000; // 10 minutes

export async function getMoratoriumZipCodes({ forceRefresh } = { forceRefresh: false }) {
  const now = new Date();

  if (!lastCachedTime || forceRefresh || now - lastCachedTime > cacheExpiryTimeMs) {
    const getMoratoriumZipCodesResponse = await apolloClient.query({ query: getMoratoriumZipCodesQuery });
    cachedMoratoriumZipCodes = getMoratoriumZipCodesResponse.data.getMoratoriumZipCodes;
    lastCachedTime = now;
  }

  return cachedMoratoriumZipCodes;
}
