export default (theme) => ({
  table: {
    background: theme.colorPalette.white_30,
    marginBottom: 10
  },
  bigTable: {
    boxShadow: 'none',
    marginTop: 32
  },
  tableHeading: {
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: 10,
    textTransform: 'uppercase',
    paddingBottom: 14,
    paddingTop: 27,
    '&:first-child': {
      paddingLeft: 24
    }
  },
  bigTableHeading: {
    composes: '$tableHeading',
    paddingBottom: 8,
    paddingTop: 0,
    '&:first-child': {
      paddingLeft: 32
    }
  },
  tableHeader: {
    height: 'auto'
  },
  icon: {
    left: -30,
    width: 20,
    height: 20
  },
  tableCell: {
    paddingRight: 0,
    paddingLeft: 0,
    maxWidth: 160,
    color: theme.colorPalette.green_20,
    verticalAlign: 'top',
    paddingTop: 16,
    paddingBottom: 26,
    '&:first-child': {
      paddingLeft: 24
    }
  },
  bigTableCell: {
    composes: '$tableCell',
    padding: '16px 32px 16px 0',
    '&:first-child': {
      paddingLeft: 32
    }
  },
  borderlessCell: {
    borderBottom: 'none',
    paddingBottom: 0
  },
  borderlessRow: {
    height: 'auto'
  },
  dateRow: {
    padding: '12px 0 12px 32px',
    fontSize: '10px !important',
    color: theme.colorPalette.green_10,
    textTransform: 'uppercase'
  },
  paginationCaption: {
    color: theme.colorPalette.green_20,
    fontWeight: 500
  },
  dateInfo: {
    display: 'inline-block'
  },
  fullCell: {
    width: '100%'
  },
  internalNotes: {
    color: theme.colorPalette.green_10
  },
  backdrop: {
    backgroundColor: `${theme.colorPalette.green_20}d9` // 'd9' is the alpha value
  },
  dialogRoot: {
    '@media(max-width: 560px)': {
      padding: '0px !important'
    }
  },
  dialogPaper: {
    width: '100%',
    maxWidth: 1208,
    backgroundColor: theme.colorPalette.white_30,
    borderRadius: 6,
    boxShadow: '0 2px 2px 0 #0000003d, 0 0 2px 0 #0000001e',
    flexDirection: 'column',
    overflowY: 'auto',
    minHeight: 560
  },
  scrollPaper: {
    alignItems: 'flex-start'
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  dialogHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'flex-end'
  },
  dialogCloseButton: {
    boxSizing: 'content-box',
    height: 48,
    width: 48,
    padding: 0,
    margin: '8px 10px 0'
  },
  dialogCloseButtonIcon: {
    height: 48,
    width: 48
  },
  dialogTitleContainer: {
    marginLeft: 48,
    marginRight: 48,
    lineHeight: 1,
    '@media(max-width: 560px)': {
      marginLeft: 24,
      marginRight: 24
    }
  },
  dialogTitle: {
    fontSize: 42,
    fontWeight: 100,
    color: theme.colorPalette.green_10,
    '@media(max-width: 560px)': {
      fontSize: 24,
      fontWeight: 300
    }
  }
});
