import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    padding: '25px 25px',
    marginBottom: 10
  },
  label: {
    paddingBottom: 5
  },
  checkbox: {
    display: 'block',
    paddingLeft: 5,
    paddingTop: 5
  },
  spacingTop: {
    marginTop: 10
  }
}));

export default useStyles;
