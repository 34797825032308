import { types, flow, getEnv } from 'mobx-state-tree';
import omit from 'lodash-es/omit';

import { mapRequestQuoteToInputType, shouldReject, hasRejection, formattedError } from 'core/helpers/quoter.service';
import { track } from 'core/helpers/analytics';
import { withRetry } from 'core/helpers/with-retry';
import { REQUEST_QUOTE, SEND_CLUSTER_LINK } from './quote.queries';

const Error = types.model({
  code: types.union(types.string, types.number),
  message: types.maybe(types.string)
});

export const QuoteStore = types
  .model({
    loading: types.boolean,
    errors: types.array(Error),
    prefillData: types.maybeNull(types.frozen()),
    offer: types.maybeNull(types.frozen())
  })
  .actions((self) => ({
    requestQuoteAction: flow(function* requestQuote(userInput, allowedStates, isAgency, retry = true) {
      self.errors = [];
      if (!isAgency || (isAgency && allowedStates.includes(userInput.state))) {
        // check that the user has a license for this state
        try {
          const { client } = getEnv(self);
          self.loading = true;
          track('Staff Generated Quote', { userInput });

          const {
            data: { offer },
            errors
          } = yield withRetry(
            client.query({
              query: REQUEST_QUOTE,
              variables: mapRequestQuoteToInputType(userInput, self.errors),
              fetchPolicy: 'no-cache'
            }),
            'requestQuote'
          );
          if (!offer && retry && errors?.[0]?.errorType === 'ExecutionTimeout') {
            self.requestQuoteAction(userInput, allowedStates, false);
            return;
          }
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            const { offerings } = offer.quote;
            const { autoRejectCode, homeownersRejectCode, monolineAutoRejectCode } = offerings;
            const filtered = [autoRejectCode, homeownersRejectCode, monolineAutoRejectCode].filter((err) => !!err);
            self.errors = filtered.map((code) => ({ code }));
            self.loading = false;

            // either home or auto rejected (not both) so we can still make an offer
            if (filtered.length === 1) {
              self.offer = offer;
              self.loading = false;
            }
          } else {
            self.offer = offer;
            self.loading = false;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error in requestQuote: ${error}`);
          self.loading = false;
          self.handleError(error);
        }
      } else {
        const notLicensedError = {
          message:
            'You are not licensed to quote in this state, if you beleive this is a mistake, please contact Branch at 833-4BRANCH (833-427-2624) support@ourbranch.com'
        };
        self.loading = false;
        self.handleError(notLicensedError);
      }
    }),
    sendClusterLink: flow(function* sendClusterLink(offerId, email, quote, agent) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: SEND_CLUSTER_LINK,
        variables: {
          offerId,
          revisedQuoteDetails: quote,
          email,
          agent
        }
      });
    }),
    handleError: (error) => {
      const parsed = formattedError(error);
      if (parsed) {
        self.errors = [...self.errors, parsed];
      }
    },
    setPrefillData(quote, skipRejectionCheck) {
      if (!skipRejectionCheck) {
        if (shouldReject(quote) || hasRejection(quote)) {
          const { offerings } = quote;
          const { autoRejectCode, homeownersRejectCode } = offerings;
          const filtered = [autoRejectCode, homeownersRejectCode].filter((err) => !!err);
          self.errors = filtered.map((code) => ({ code }));
        }
      }
      self.prefillData = omit(quote, '__typename');
    },
    clearPrefillData() {
      self.prefillData = null;
    }
  }))
  .views((self) => ({
    get hasRejections() {
      return self.errors.find((err) => err.code && err.code >= 10000);
    }
  }));
