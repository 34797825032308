import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useStore } from 'core/store/';
import { Discount } from '../discount';

const Paperless = ({ isActive, ...discount }) => {
  const { setFieldValue, values } = useFormikContext();
  const { offer: store } = useStore();
  const isApplied = values.global ? values.global.discountPaperless : isActive;

  const onAddClick = useCallback(() => {
    setFieldValue('global.discountPaperless', true);
    store.setIncludePaperless(true);
  }, [setFieldValue, store]);

  const onRemoveClick = useCallback(() => {
    setFieldValue('global.discountPaperless', false);
    store.setIncludePaperless(false);
  }, [setFieldValue, store]);

  return <Discount {...discount} isActive={isApplied} onAdd={onAddClick} onRemove={onRemoveClick} />;
};

export default Paperless;
