import React, { useState, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { Button } from 'core/components/button';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { useStyles } from './car-assignment.styles';
import { CarAssignmentModal } from './car-assignment-modal';

/**
 *
 * @param {Boolean} disabled disabled the assignment modal
 * @description Rules for driver assignment:
 * each car must have its primary driver assigned to it
 * each driver must have her primary car assigned so,
 * after assigning drivers to cars, if any driver is assigned to multiple cars, pick from those cars a primary for that driver
 *
 * see https://miro.com/app/board/o9J_lpylzDw=/
 */
function CarAssignment({ disabled }) {
  const [showAssignCarsModal, setShowAssignCarsModal] = useState(false);
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const { cars, drivers } = values || {};

  const eligibleDrivers = drivers.filter(({ excludeDriver }) => !excludeDriver);

  const skipAssignment = cars.length === 1 && eligibleDrivers.length === 1;

  const equalCarsAndDrivers = cars.length === eligibleDrivers.length;
  const skipDriversAssignment = equalCarsAndDrivers || cars.length === 1;

  useEffect(() => {
    const carsAssignDrivers = async (car, index) => {
      if (
        !car.assignedDriver ||
        !eligibleDrivers.find((driver) => driver.id === car.assignedDriver) ||
        (equalCarsAndDrivers &&
          car.VIN !== eligibleDrivers.find((driver) => driver.id === car.assignedDriver).assignedCar)
      ) {
        const unassignedDriver = eligibleDrivers.find((d) => !d.assignedCar)?.id;

        // find a driver who has an assigned car but is not assigned to that car
        const unmatchedDriver = eligibleDrivers.find(
          (driver) => !cars.map((car) => car.assignedDriver).includes(driver.id)
        )?.id;

        // have to do this cars===drivers separate or you won't be able change anything because there will always be unmatched driver
        if (equalCarsAndDrivers) {
          if (unassignedDriver) {
            await setFieldValue(`cars[${index}].assignedDriver`, unassignedDriver);
            // breaking the rule here otherwise we will assign the new driver multiple times
            const indexOfDriver = drivers.map((d) => d.id).indexOf(unassignedDriver || unmatchedDriver);
            if (indexOfDriver) {
              await setFieldValue(`drivers[${indexOfDriver}].assignedCar`, car.VIN);
            }
          } else if (!car.assignedDriver) {
            await setFieldValue(`cars[${index}].assignedDriver`, eligibleDrivers[eligibleDrivers.length - 1].id);
          }
        }
        if (!equalCarsAndDrivers && (unassignedDriver || unmatchedDriver)) {
          await setFieldValue(`cars[${index}].assignedDriver`, unassignedDriver || unmatchedDriver);
        } else if (!equalCarsAndDrivers) {
          await setFieldValue(`cars[${index}].assignedDriver`, eligibleDrivers[eligibleDrivers.length - 1].id);
        }
      }
    };
    const driversAssignCars = async (driver, index) => {
      if (!driver.excludeDriver) {
        if (skipDriversAssignment) {
          let carToMatch;
          if (cars.length === 1) {
            carToMatch = cars[0].VIN;
          } else {
            carToMatch = cars.find((car) => car.assignedDriver === driver.id)?.VIN;
          }
          // no assigned car or a car was assigned this driver
          if (driver.assignedCar !== carToMatch && carToMatch) {
            setFieldValue(`drivers[${index}].assignedCar`, carToMatch);
          }
        } else if (
          !driver.assignedCar ||
          driver.assignedCar !== cars.find((car) => car.assignedDriver === driver.id)?.VIN
        ) {
          // if a car was assigned this driver then match it
          const carToAssign = cars.find((car) => car.assignedDriver === driver.id)?.VIN;

          if (
            carToAssign &&
            driver.assignedCar !== carToAssign &&
            (eligibleDrivers.length >= cars.length ||
              cars.filter((car) => car.assignedDriver === driver.id).length === 1) // if the driver is only assigned to one car then match it
          ) {
            await setFieldValue(`drivers[${index}].assignedCar`, carToAssign);
          } else {
            // look for an car that hasn't been assigned to any drivers
            const unassignedCar = cars.find(
              (car) => !drivers.map((driver) => driver.assignedCar).includes(car.VIN)
            )?.VIN;
            // if we can have a car assigned to multiple drivers then do nothing
            if (unassignedCar && eligibleDrivers.length >= cars.length) {
              await setFieldValue(`drivers[${index}].assignedCar`, unassignedCar);
            } else if (!driver.assignedCar || !cars.find((car) => driver.assignedCar === car.VIN)) {
              // no assigned car or car was dropped
              await setFieldValue(`drivers[${index}].assignedCar`, cars[cars.length - 1].VIN);
            }
          }
        }
      } else if (driver.assignedCar) {
        // excluded drivers should have no cars
        await setFieldValue(`drivers[${index}].assignedCar`, '');
      }
    };

    const reassign = async () => {
      for (const [index, car] of cars.entries()) {
        await carsAssignDrivers(car, index);
      }
      for (const [index, driver] of drivers.entries()) {
        await driversAssignCars(driver, index);
      }
    };

    reassign();
  }, [cars, drivers, eligibleDrivers, skipDriversAssignment, equalCarsAndDrivers, setFieldValue]);

  return (
    <>
      <Section
        title="Car Assignments"
        type="SubSection"
        right={
          !skipAssignment &&
          !disabled && (
            <Button color="secondary" onClick={() => setShowAssignCarsModal(true)}>
              Edit Car Assignments
            </Button>
          )
        }
      >
        {cars.map((car) => (
          <Card className={classes.card} type="primary" key={car.VIN}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Label>{`${car.year || ''} ${car.make || ''} ${car.model || `New Car ${car.VIN}`}`}</Label>
              {
                //   if cars > drivers need to indicate which car is a primary
                // if this cars assigned driver is assigned to another car and this drivers assigned car is this car
                eligibleDrivers.length < cars.length &&
                  cars.filter((c) => c.assignedDriver === car.assignedDriver).length > 1 &&
                  eligibleDrivers.find((d) => d.assignedCar === car.VIN) && (
                    <Chip label="PRIMARY" classes={{ root: classes.chipRoot }} key={car.VIN} />
                  )
              }
            </div>
            <div>
              {drivers
                .filter(
                  (driver) =>
                    !driver.excludeDriver && (driver.assignedCar === car.VIN || car.assignedDriver === driver.id)
                )
                .map((driver) => (
                  <Chip
                    label={`${driver.firstName} ${driver.lastName}`}
                    classes={{ root: classes.chipRoot }}
                    key={driver.id}
                  />
                ))}
            </div>
          </Card>
        ))}
      </Section>

      {!disabled && showAssignCarsModal && (
        <CarAssignmentModal open={showAssignCarsModal} onClose={() => setShowAssignCarsModal(false)} />
      )}
    </>
  );
}

CarAssignment.propTypes = {
  disabled: PropTypes.bool
};

CarAssignment.defaultProps = {
  disabled: false
};

export default CarAssignment;
