import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  inline: {
    display: 'inline-block'
  },
  tooltipLabel: {
    fontSize: 14
  },
  paymentSchedules: {
    marginBottom: theme.spacing(2),
    fontSize: 14
  },
  tooltip: {
    fontWeight: 300,
    fontSize: 14
  }
});

export default makeStyles(styles);
