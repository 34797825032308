import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

class HeaderCell extends Component {
  static propTypes = {
    columnId: PropTypes.string,
    tooltipText: PropTypes.string,
    onSort: PropTypes.func,
    children: PropTypes.node,
    initialActive: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    tooltipText: '',
    columnId: '',
    onSort: null,
    children: null,
    initialActive: false,
    className: null
  };

  state = {
    direction: 'desc',
    active: false
  };

  constructor(props) {
    super(props);
    this.onSort = this.onSort.bind(this);
    this.state.active = props.initialActive;
  }

  onSort() {
    let { direction, active } = this.state;

    if (!active) {
      active = true;
      direction = 'desc';
    } else if (direction === 'desc') {
      direction = 'asc';
    } else {
      active = false;
    }

    this.setState({ direction, active });
    this.props.onSort(this.props.columnId, active, direction);
  }

  render() {
    const { children, tooltipText, onSort, className } = this.props;
    const { direction, active } = this.state;
    return (
      <TableCell>
        {children && (
          <Tooltip title={tooltipText} placement="bottom-end" enterDelay={300}>
            {onSort ? (
              <TableSortLabel
                className={className}
                active={active}
                direction={direction}
                onClick={this.onSort}
                IconComponent={KeyboardArrowDownIcon}
              >
                {children}
              </TableSortLabel>
            ) : (
              <>{children}</>
            )}
          </Tooltip>
        )}
      </TableCell>
    );
  }
}

export default HeaderCell;
