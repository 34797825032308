import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import ScreenHeader from 'core/components/screen-header';
import { get, policyTypes } from 'core/helpers/lookups';

import { useParams } from 'react-router-dom';
import { AuthContext } from 'core/components/auth';

const getPolicyType = get(policyTypes);

const CustomerHeader = observer(function CustomerHeader({ history }) {
  const { canEdit } = useContext(AuthContext);
  const store = useStore();
  const { account: accountStore } = store;
  const policy = accountStore.policies.policy.policy;
  const accountId = accountStore.id;
  const { policyId } = useParams();

  const titleText = () => {
    if (!policyId) {
      return accountStore.accountLoading ? 'Loading...' : `${accountStore.fname} ${accountStore.lname}`;
    }
    return policy?.id && policy?.id === policyId
      ? `${accountStore.fname} ${accountStore.lname}/${getPolicyType(policy.policyType)} Policy`
      : accountStore.accountLoading
      ? 'Loading...'
      : `${accountStore.fname} ${accountStore.lname}/Loading...`;
  };

  const title = titleText();

  const rightLink = accountStore.loading || !policy || !canEdit ? '' : `/quote/detail/${policy.offerId}`;

  const back = useCallback(() => {
    if (policyId) {
      history.push(`/customer/${accountId}`);
    } else {
      history.push(`/search/customers`);
    }
  }, [policyId, accountId, history]);

  return (
    <ScreenHeader
      title={title}
      rightLabel="PolicyId"
      rightValue={policyId}
      rightLink={rightLink}
      onBack={back}
      policy={policy}
    />
  );
});

export default CustomerHeader;
