import { Form, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { getSnapshot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { withStore } from 'core/store';
import { useErrors } from '../../helpers/error-hooks';
import { Collapsible } from '../collapsible';
import { addAutoCode } from '../quote-forms/quote-forms.constants';
import { Errors } from '../errors';

const addAutoCondition = (code) => addAutoCode.includes(code);

const AddCar = ({ store, sectionsToShow }) => {
  const {
    quote: { errors: quoteErrors }
  } = store;
  const errors = useErrors(getSnapshot(quoteErrors), addAutoCondition);
  const carErrors = useRef(null);
  const { setFieldValue, values: formValues } = useFormikContext();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (errors.length && carErrors.current) {
      carErrors.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    setFieldValue('VIN', formValues.VIN.toUpperCase().trim());
  }, [formValues.VIN, setFieldValue]);

  useEffect(() => {
    if (sectionsToShow && sectionsToShow?.length > 0) {
      const shouldBeShown = addAutoCode.every((element) => sectionsToShow.includes(element));
      setShouldShow(shouldBeShown);
    }
  }, [sectionsToShow]);

  return (
    <Form>
      {(errors.length > 0 || shouldShow) && (
        <>
          <Errors errors={errors} ref={carErrors} />
          <Collapsible title="Add Car">
            <>
              <Grid item xs={6}>
                <Field type="string" id="VIN" name="VIN" mode="light" label="Vin Number" />
              </Grid>
            </>
          </Collapsible>
        </>
      )}
    </Form>
  );
};

AddCar.propTypes = {
  store: PropTypes.object.isRequired
};

export default flowRight(withDatePicker, withStore)(AddCar);
