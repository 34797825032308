import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Formik, Form } from 'formik';
import { Grid, InputAdornment, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer } from 'mobx-react';
import { stripeBankStatus } from '@ourbranch/lookups';

import { track } from 'core/helpers/analytics';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';
import { FormField } from 'core/components/form';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import useStyles from './microdeposits.styles';

const Microdeposits = observer(({ toast, stripeCustomerId, policyId }) => {
  const classes = useStyles();

  const { account } = useStore();
  const { policies: store } = account;

  const StripeBankStatus = stripeBankStatus;
  const stripeLast4 = store.getStripeBankInfo(policyId)?.last4;
  const stripeBankAccountId = store.getStripeBankInfo(policyId)?.stripeBankAccountId;
  const duplicateBankAccounts = store.list?.filter(
    (policy) => policy.stripeBankInfo?.stripeBankAccountId === stripeBankAccountId
  );

  const [verifying, setVerifying] = useState(false);

  const handleVerifyMicrodeposits = useCallback(
    async (values) => {
      if (stripeCustomerId && stripeBankAccountId) {
        setVerifying(true);

        // ex: 0.32 -> 32 because that's how stripe expects it
        const amountOne = Math.floor(values.amountOne * 100);
        const amountTwo = Math.floor(values.amountTwo * 100);

        const verificationDetails = {
          accountId: account.id,
          stripeCustomerId,
          stripeBankAccountId,
          amountOne,
          amountTwo
        };

        try {
          await store.verifyBankAccount(verificationDetails);
          store.setVerifiedStatus(StripeBankStatus.Verified, policyId);
          track('Microdeposits Successfully Verified', { verifiedInStaff: true, policy: policyId });
          toast.notify({
            type: 'success',
            message: `Bank Account successfully validated`
          });
        } catch (error) {
          toast.notify({
            type: 'error',
            message: error.message
          });
        }

        setVerifying(false);
      }
    },
    [store, setVerifying, stripeBankAccountId, stripeCustomerId, toast, StripeBankStatus, policyId, account.id]
  );

  const policyOrPolicies = () => {
    if (duplicateBankAccounts?.length > 1) {
      return `policies: ${duplicateBankAccounts.map((e) => `${e.id}`).join(', ')}`;
    }
    return `policy ${policyId}`;
  };

  if (verifying) {
    return (
      <Card className={classes.loadingCard}>
        <CircularProgress classes={{ colorPrimary: classes.circleLoader }} size={20} />
        <Label className={classes.verifyingLabel}>Verifying Bank Account...</Label>
      </Card>
    );
  }

  if (!stripeLast4) {
    return (
      <Card className={classes.card}>
        <ErrorOutlineIcon className={classes.icon} />
        <Label type="subtitleLight" className={classes.bottomSpacing}>
          Error getting bank account
        </Label>
        <Label type="smallSubtitleLight" className={classes.subtitle}>
          Cannot verify microdeposits because there was an error retrieving the bank account details from Stripe.
        </Label>
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <ErrorOutlineIcon className={classes.icon} />
      <Label type="subtitleLight" className={classes.bottomSpacing}>
        {"This customer's bank account has not been validated."}
      </Label>
      <Label type="smallSubtitleLight" className={classes.subtitle}>
        {`Please enter the customer's received microdeposit amounts for ${policyOrPolicies()} with the account ending in ...${stripeLast4}.`}
      </Label>
      <Formik initialValues={{ amountOne: undefined, amountTwo: undefined }} onSubmit={handleVerifyMicrodeposits}>
        <Form>
          <Grid container justify="space-between" direction="column">
            <FormField
              type="numeric"
              name="amountOne"
              label="Amount One"
              className={classes.bottomSpacing}
              format={{
                thousandSeparator: true,
                allowNegative: false,
                fixedDecimalScale: true,
                decimalScale: 2
              }}
              mode="red"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.adornment}>
                    $
                  </InputAdornment>
                )
              }}
            />
            <FormField
              type="numeric"
              name="amountTwo"
              label="Amount Two"
              className={classes.bottomSpacing}
              format={{
                thousandSeparator: true,
                allowNegative: false,
                fixedDecimalScale: true,
                decimalScale: 2
              }}
              mode="red"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.adornment}>
                    $
                  </InputAdornment>
                )
              }}
            />
            <Button type="submit" className={classes.button} key="verify" variant="contained" color="secondary">
              Verify Bank Account
            </Button>
          </Grid>
        </Form>
      </Formik>
    </Card>
  );
});

Microdeposits.propTypes = {
  toast: PropTypes.object.isRequired,
  stripeCustomerId: PropTypes.string,
  policyId: PropTypes.string
};

Microdeposits.defaultProps = {
  stripeCustomerId: undefined,
  policyId: undefined
};

export default withToast(Microdeposits);
