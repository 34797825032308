import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: 32
  },
  section: {
    paddingBottom: 32
  },
  column: {
    width: '50%'
  },
  carDetails: {
    paddingBottom: 32,
    '&:last-child': {
      paddingBottom: 0
    }
  }
}));

export default useStyles;
