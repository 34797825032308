import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import HoldCard from './hold-card';
import styles from './hold-cards.styles';
import Modal from './modal';

const HoldCards = ({ allActiveHoldCards, activeHoldCards, state, classes }) => {
  const [open, setModalState] = useState(false);
  const onClose = useCallback(() => {
    setModalState(!open);
  }, [setModalState, open]);
  return (
    <>
      <div className={classes.dialogPaper}>
        {activeHoldCards.map(({ createdBy, createdDate, reason, holdCardId, policyId }, index) => {
          return (
            <HoldCard
              key={holdCardId}
              holdCardId={holdCardId}
              policyId={policyId}
              createdBy={createdBy}
              createdDate={createdDate}
              reason={reason}
              state={state}
              cardCount={allActiveHoldCards.length - index}
            />
          );
        })}
      </div>
      {activeHoldCards.length > 0 && (
        <Button variant="text" color="secondary" onClick={() => setModalState(true)}>
          See all
        </Button>
      )}
      <Modal state={state} open={open} allActiveHoldCards={allActiveHoldCards} onClose={onClose} />
    </>
  );
};

HoldCards.propTypes = {
  allActiveHoldCards: PropTypes.array.isRequired,
  activeHoldCards: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HoldCards);
