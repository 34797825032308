import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import flowRight from 'lodash-es/flowRight';
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson, offerExcessUM, getOptions } from '@ourbranch/lookups';
import { observer } from 'mobx-react';

import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import Section from 'core/components/section';
import { cleanObject } from 'core/helpers/formatters';
import { selectOptions } from 'core/helpers/select';
import { canAddUmbrellaUMUIM, canAddUmbrella } from 'core/helpers/quoter.service';
import { useStore } from 'core/store';
import styles from '../styles';

const id = 'umbrellaCoverage';

export const formatUmbrellaValues = (values, includeUmbrellaCoverage = true) => {
  if (!values) {
    return null;
  }

  if (!includeUmbrellaCoverage) {
    return null;
  }

  const otherPropertyAddresses = (values.otherPropertyAddresses || []).filter((item) => !!item);
  const rentalPropertyAddresses = (values.rentalPropertyAddresses || []).filter((item) => !!item);
  const watercraftHullLengths = (values.watercraftHullLengths || []).filter((item) => !!item);
  return {
    ...values,
    watercraftHullLengths,
    rentalPropertyAddresses,
    numRentalProperties: rentalPropertyAddresses.length,
    otherPropertyAddresses,
    numOtherProperties: otherPropertyAddresses.length
  };
};

const mockUmbrellaValues = {
  watercraftHullLengths: [],
  rentalPropertyAddresses: [],
  otherPropertyAddresses: [],
  numRVs: 0,
  numMotorcyclesScooters: 0,
  numATVs: 0,
  numPersonalWatercraft: 0,
  liabilityCoverageLimit: 0
};

const Umbrella = ({ fromPolicy, classes, disabled }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const {
    umbrellaCoverage,
    includeUmbrella,
    autoCoverage,
    homeCoverage,
    home: {
      homeLocation: { state }
    },
    drivers
  } = values;

  const [cleaned, setCleaned] = useState(false);
  const { offer: offerStore } = useStore();

  const { quote } = offerStore?.offer || {};

  const { policyLimitUMBI, policyLimitUIMBI, policyLimitBIPD } = autoCoverage || {};
  const { coverageX } = homeCoverage || {};

  // for all of the below, if autoCoverage doesn't exist, this is a policy, and we don't
  // re-validate on policies for umbrella eligibility based upon auto.
  // in a Bix offer or state Bix policy and has 250/500 umbi and uimbi;
  const canAddUM = canAddUmbrellaUMUIM({ policyLimitUMBI, policyLimitUIMBI, state }) && offerExcessUM[state];
  const canOfferUmbrella = canAddUmbrella({
    policyLimitBIPD,
    coverageX,
    drivers: drivers || quote?.drivers
  });
  const validUMUIMOptions = getOptions('uninsuredMotoristLimit', state);

  // always show umbrella in policy (but will be disabled if not correct auto coverages)
  // only show umbrella in offer if it's a Home with Bundle Discount or a Home and Auto Bundle
  const offerOptionsAllowingUmbrella = ['HB', 'HA'];
  const showUmbrellaSection =
    fromPolicy || (offerOptionsAllowingUmbrella.includes(offerStore?.selectedOption) && quote?.offerings.offerUmbrella);

  const umbrellaOpts = quote?.isBix
    ? lookupsJson.umbrellaLimit.filter((d) => d.id <= 2000000)
    : lookupsJson.umbrellaLimit;

  useEffect(() => {
    // if we add umbrella we add the defaults to the form
    if (includeUmbrella) {
      if (!umbrellaCoverage) {
        setCleaned(false);
        setFieldValue(id, mockUmbrellaValues, false);
      } else if (!cleaned) {
        setCleaned(true);
        setFieldValue(id, { ...mockUmbrellaValues, ...cleanObject({ ...umbrellaCoverage }) }, false);
      }
    }
  }, [includeUmbrella, setFieldValue, umbrellaCoverage, setCleaned, cleaned]);

  return (
    <>
      {showUmbrellaSection && (
        <Section title="Umbrella Coverage" type="SubSection">
          <div className={classes.containerDark}>
            <Form disabled={disabled}>
              <Grid container spacing={4} alignItems="flex-end">
                <Field
                  id="includeUmbrella"
                  name="includeUmbrella"
                  type="switch"
                  label="Umbrella Coverage"
                  mode="dark"
                  xs={12}
                  disabled={!canOfferUmbrella && !errors.includeUmbrella}
                  fast={false}
                />
                {!canOfferUmbrella && (!errors.includeUmbrella || !touched.includeUmbrella) && (
                  <Grid item xs={12}>
                    <Label type="smallLightLabel">
                      Policy does not meet requirements to add Umbrella: Auto BI and UM/UIM BI need to be at least
                      250/500, Homeowner&lsquo;s Liability needs to be at least $300K and there must not be any excluded
                      drivers
                    </Label>
                  </Grid>
                )}
                {includeUmbrella && umbrellaCoverage && (
                  <>
                    <Field
                      id={`${id}.liabilityCoverageLimit`}
                      name={`${id}.liabilityCoverageLimit`}
                      type="select"
                      label="Umbrella Limit"
                      mode="dark"
                      xs={6}
                      options={umbrellaOpts}
                    />
                    <Field
                      id={`${id}.highRisk`}
                      name={`${id}.highRisk`}
                      type="checkbox"
                      label="High risk coverage?"
                      mode="dark"
                      xs={6}
                    />
                    <Field
                      id={`${id}.numVehiclesTotal`}
                      name={`${id}.numVehiclesTotal`}
                      type="select"
                      label="Vehicles"
                      mode="dark"
                      xs={6}
                      options={selectOptions(0, 10)}
                    />
                    <Field
                      id={`${id}.numLicensedDriversUnder25`}
                      name={`${id}.numLicensedDriversUnder25`}
                      type="select"
                      label="Licensed Drivers under 25 in your home"
                      mode="dark"
                      options={selectOptions(0, 10)}
                      xs={6}
                    />
                    <Field
                      id={`${id}.numRVs`}
                      name={`${id}.numRVs`}
                      type="select"
                      label="Number of golf carts and snowmobiles"
                      mode="dark"
                      xs={8}
                      options={selectOptions(0, 2)}
                    />
                    <Field
                      id={`${id}.presenceOfBusiness`}
                      name={`${id}.presenceOfBusiness`}
                      type="checkbox"
                      label="Business at home"
                      mode="dark"
                      xs={4}
                    />
                    {canAddUM && (
                      <Grid container item xs={12}>
                        <Field
                          id={`${id}.uninsuredMotoristLimit`}
                          name={`${id}.uninsuredMotoristLimit`}
                          type="select"
                          options={validUMUIMOptions}
                          label="Un/Underinsured Motorist BI"
                          mode="dark"
                          xs={6}
                        />
                      </Grid>
                    )}
                    {canAddUM && validUMUIMOptions.length === 1 && (
                      <>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                          <Label type="smallLightLabel">
                            For additional UM options, a &quot;dev escalation&quot; is required.
                          </Label>
                        </Grid>
                      </>
                    )}
                    {!offerExcessUM[state] && (
                      <>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                          <Label type="smallLightLabel">
                            IMPORTANT NOTICE: PERSONAL UMBRELLA LIABILITY COVERAGE DOES NOT PROVIDE UNINSURED OR
                            UNDERINSURED MOTORISTS COVERAGE
                          </Label>
                        </Grid>
                      </>
                    )}
                    <Field
                      id={`${id}.numMotorcyclesScooters`}
                      name={`${id}.numMotorcyclesScooters`}
                      type="select"
                      label="Number of Motorcycles/Scooters"
                      mode="dark"
                      xs={4}
                      options={selectOptions(0, 5)}
                    />
                    <Field
                      id={`${id}.numATVs`}
                      name={`${id}.numATVs`}
                      type="select"
                      label="Number of ATV"
                      mode="dark"
                      xs={4}
                      options={selectOptions(0, 5)}
                    />
                    <Field
                      id={`${id}.numPersonalWatercraft`}
                      name={`${id}.numPersonalWatercraft`}
                      type="select"
                      label="Personal Watercraft"
                      mode="dark"
                      xs={4}
                      options={selectOptions(0, 5)}
                    />
                    <Grid container item spacing={2} className={classes.coverageContainer}>
                      <Grid item xs={12}>
                        <Label type="formSubTitle">
                          Number of Boats: {(umbrellaCoverage?.watercraftHullLengths?.filter(Boolean) || []).length}
                        </Label>
                      </Grid>
                      <Field
                        id={`${id}.watercraftHullLengths`}
                        name={`${id}.watercraftHullLengths`}
                        fast={false}
                        type="array"
                        label="Watercraft Hull Length"
                        mode="dark"
                        fieldProps={{ type: 'numeric', xs: 4, max: 5 }}
                      />
                    </Grid>
                    <Grid container item spacing={2} className={classes.coverageContainer}>
                      <Grid item xs={12}>
                        <Label type="formSubTitle">
                          Number of Rental Properties:{' '}
                          {(umbrellaCoverage?.rentalPropertyAddresses?.filter(Boolean) || []).length}
                        </Label>
                      </Grid>
                      <Field
                        id={`${id}.rentalPropertyAddresses`}
                        name={`${id}.rentalPropertyAddresses`}
                        type="array"
                        mode="dark"
                        label="Rental Property address"
                        fieldProps={{
                          xs: 6,
                          type: 'address',
                          max: 5
                        }}
                      />
                    </Grid>
                    <Grid container item spacing={2} className={classes.coverageContainer}>
                      <Grid item xs={12}>
                        <Label type="formSubTitle">
                          Number of Non-Rental Property Addreses:{' '}
                          {(umbrellaCoverage?.otherPropertyAddresses?.filter(Boolean) || []).length}
                        </Label>
                      </Grid>
                      <Field
                        id={`${id}.otherPropertyAddresses`}
                        name={`${id}.otherPropertyAddresses`}
                        type="array"
                        mode="dark"
                        label="Non-Rental Property Address"
                        fieldProps={{
                          xs: 6,
                          type: 'address',
                          max: 5
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          </div>
        </Section>
      )}
    </>
  );
};

Umbrella.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fromPolicy: PropTypes.bool
};

Umbrella.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default flowRight(withStyles(styles), observer)(Umbrella);
