/* eslint-disable */
const overrideFormGroup = (theme) => {
  theme.overrides.MuiFormGroup = {
    root: {
      flexDirection: 'row'
    }
  };

  theme.overrides.MuiFormHelperText = {
    root: {
      fontSize: 11,
      lineHeight: 1,
      marginTop: 4
    }
  };
};

export default overrideFormGroup;
