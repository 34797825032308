import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  downloadHeader: {
    height: 30,
    margin: '40px 0px 16px 0px',
    fontSize: 24,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: theme.colorPalette.green_20
  },
  downloadLoader: {
    marginLeft: 6,
    marginRight: 6,
    color: theme.colorPalette.orange_10
  },
  pdfDownloadContainer: {
    padding: 32
  },
  downloadButton: {
    width: '100%',
    cursor: 'pointer',
    '&:first-child': {
      marginBottom: 8
    }
  },
  downloadLabel: {
    alignItems: 'center',
    padding: 8,
    width: '100%',
    color: theme.colorPalette.orange_10,
    fontSize: 16,
    fontWeight: 500
  },
  checkbox: {
    paddingTop: 10
  }
}));

export default useStyles;
