import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  priceBreakdownTable: {
    '& td': {
      borderBottom: 'none',
      padding: 15
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`
    },
    '& tr:last-of-type': {
      borderBottom: 'none'
    }
  },
  inaccurateValueNote: {
    color: 'red'
  }
}));

export default useStyles;
