import React, { createContext, useContext, useState, memo } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  message: undefined,
  type: undefined,
  label: undefined,
  action: () => {},
  notify: undefined,
  clean: undefined
};
const cleanState = (notify) => {
  notify({});
};

const ToastContext = createContext(initialState);

export const withToast = (Component) => (props) => {
  const { notify } = useContext(ToastContext);
  return <Component toast={{ notify }} {...props} />;
};

export const useToast = () => useContext(ToastContext);

export const ToastProvider = memo(({ children }) => {
  const [notification, notify] = useState(initialState);
  const clean = cleanState.bind(this, notify);
  return <ToastContext.Provider value={{ ...notification, notify, clean }}>{children}</ToastContext.Provider>;
});

ToastProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ToastContext;
