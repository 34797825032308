import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './errors.styles';
import ErrorItem from './error-item';

const Errors = forwardRef(({ classes, errors, rejection }, ref) => {
  return (
    <div className={rejection ? classes.rejectionsContainer : classes.errorsContainer} ref={ref}>
      {errors.map((error) => (
        <ErrorItem key={error.code} error={error} isRejection={rejection} />
      ))}
    </div>
  );
});

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  rejection: PropTypes.bool
};

Errors.defaultProps = {
  rejection: false
};

export default withStyles(styles)(Errors);
