import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { enhancedRoofWindstormValues } from '@ourbranch/lookups';

import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import useStyles from './enhanced-roof-windstorm-info-modal.styles';

const titles = {
  roofDeck: 'Roof Deck',
  roofCover: 'Roof Cover',
  roofDeckAttachment: 'Roof Deck Attachment',
  roofToWallConnection: 'Roof to Wall Connection',
  openingProtection: 'Window Opening Protection',
  roofSWR: 'Secondary Water Resistance'
};

const EnhancedRoofWindstormInfoModal = ({ open, onClose, name }) => {
  const classes = useStyles();

  return (
    <BaseDialog open={open} onClose={onClose}>
      <Label type="greenBig" className={classes.modalHeader}>
        {titles[name]}
      </Label>
      <Label type="darkGreenSmall" className={classes.modalContent}>
        {enhancedRoofWindstormValues[name].map((item) => {
          return (
            <Grid item className={classes.infoContainer} key={item.id}>
              <Label type="infoLabel" xs={12}>
                {item.value}
              </Label>
              <Label type="infoValue" className={classes.modalContent} xs={12}>
                {item.hint}
              </Label>
            </Grid>
          );
        })}
      </Label>
      <NotificationCard type="quinary">
        This structure must be verified through a licensed inspector or licensed general contractor.
      </NotificationCard>
    </BaseDialog>
  );
};

EnhancedRoofWindstormInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EnhancedRoofWindstormInfoModal;
