import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';

import { Card } from '../card';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import styles from './loading.styles';
import animationData from './animated-loader.json';

const Loading = ({ className, noBackground, classes, ...props }) => {
  if (noBackground) {
    return (
      <CircularProgress
        className={classNames(classes.centered, className)}
        classes={MapClassesToElem(ElemType.CircularProgress, classes)}
      />
    );
  }
  return (
    <Card className={classNames(classes.container, className)} {...props}>
      <CircularProgress classes={MapClassesToElem(ElemType.CircularProgress, classes)} />
    </Card>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  noBackground: PropTypes.bool
};

Loading.defaultProps = {
  className: '',
  noBackground: false
};

export const LottieLoader = memo(({ loop, autoplay, height, width }) => (
  <Lottie options={{ animationData, loop, autoplay }} height={height} width={width} />
));

LottieLoader.propTypes = {
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number
};

LottieLoader.defaultProps = {
  loop: true,
  autoplay: true,
  height: 102,
  width: 102
};

export default withStyles(styles)(Loading);
