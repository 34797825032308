import { useObserver } from 'mobx-react';
import React from 'react';

import { Loading } from 'core';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { useStore } from 'core/store';

import FeeBreakdown from '../fee-breakdown';
import PriceBreakdown from '../price-breakdown';
import PriceSummary from '../price-summary';
import useStyles from './price-details.styles';

function PriceDetails() {
  const store = useStore();
  const policyStore = store.account.policies.policy;
  const classes = useStyles();

  return useObserver(() => (
    <Section title="Price Details" type="SubSection">
      <Card type="secondary">
        <PriceSummary
          fees={policyStore.totalFeeCost}
          premium={policyStore.premium}
          surplus={policyStore.surplusContribution}
        />
        <div className={classes.subSection}>
          {policyStore.policyEquityStatus.status !== 'done' ? (
            <Loading className={classes.loadingWheel} type="secondary" />
          ) : (
            <PriceBreakdown />
          )}
        </div>
        <div className={classes.subSection}>
          <FeeBreakdown />
        </div>
      </Card>
    </Section>
  ));
}

export default PriceDetails;
