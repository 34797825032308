import commonStyles from '../common.styles';

export default (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: '2px solid white',
    backgroundColor: theme.colors.page__background_primary,
    padding: '24px 32px',
    marginBottom: 8
  },
  bottom: {
    justifyContent: 'space-between'
  },
  singleBottom: {
    justifyContent: 'flex-end'
  },
  breakage: {
    display: 'flex'
  },
  description: {
    height: 80
  },
  ...commonStyles
});
