import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required('Payment method required'),
  amount: Yup.number()
    .required('Amount to bill required')
    .nullable(),
  billType: Yup.string().required('Bill type required'),
  internalDescription: Yup.string().required('Description required')
});
