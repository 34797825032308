/* eslint-disable */
const overrideTable = (theme) => {
  theme.overrides.MuiTableSortLabel = {
    root: {
      color: theme.colors.text_secondary,
      fontSize: 10,
      textTransform: 'uppercase',
      lineHeight: '14px',
      height: 14,
      '&:hover': {
        color: theme.colors.text_primary
      }
    },
    icon: {
      position: 'absolute',
      margin: 0,
      left: -20
    },
    active: {
      color: theme.colors.text_primary
    }
  };
  theme.overrides.MuiTableCell = {
    root: {
      padding: '4px 18px 4px 18px',
      '&$body': {
        fontSize: 16
      },
      '&$head': {
        color: theme.colorPalette.green_10
      }
    },
    body: {},
    head: {}
  };
  (theme.overrides.MuiTableRow = {
    root: {
      position: 'relative'
    }
  }),
    (theme.overrides.MuiTablePagination = {
      caption: {
        textTransform: 'uppercase'
      },
      selectIcon: {
        top: 11
      }
    });
};

export default overrideTable;
