import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    width: 720,
    margin: '0 auto',
    padding: theme.spacing(8, 8, 0)
  },
  title: {
    fontWeight: 300,
    fontSize: 14
  },
  content: {
    margin: theme.spacing(4, 0, 6)
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    margin: theme.spacing(4, 0),
    paddingLeft: theme.spacing(4)
  },
  footer: {
    padding: theme.spacing(4, 8),
    backgroundColor: theme.colorPalette.white_30
  }
});

export default makeStyles(styles);
