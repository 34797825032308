import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'js-cookie';

import { Loading } from 'core';
import { getFriendBuyData } from 'core/helpers/friendbuy';
import { useStore } from 'core/store';
import useStyles from './referral-redirect.styles';

const ReferralRedirect = () => {
  const classes = useStyles();
  const history = useHistory();
  const { offer: store } = useStore();
  const queryParamsMap = queryString.parse(window.location.search);

  useEffect(() => {
    const { offerId } = Cookies.getJSON('offerData');
    if (offerId !== store?.offer?.id) {
      store.getOffer(offerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only load fbuy when we actually have the param in the url
    if (queryParamsMap.fbuy && queryParamsMap.utm_medium === 'referral') {
      import('../configure-services').then(({ initializeFB }) => {
        initializeFB().then(() => {
          if (queryParamsMap.fbuy && window.friendbuyAPI) {
            getFriendBuyData({
              fbuy: queryParamsMap.fbuy,
              referralCode: queryParamsMap.referralCode,
              campaignName: queryParamsMap.utm_campaign
            }).then((inviter) => {
              const { offerId, option, email } = Cookies.getJSON('offerData');
              let quoteDetails = {
                inviter,
                selectedOption: option,
                leadSource: 'Referral'
              };
              if (email) {
                quoteDetails = { ...quoteDetails, email };
              }
              Cookies.remove('offerData');
              store.recalculateQuoteToCluster(offerId, quoteDetails, history);
            });
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Loading className={classes.centered} noBackground />;
};

export default ReferralRedirect;
