import { useMemo } from 'react';
import { allowedOfferOptions } from 'core/helpers/quoter.service';

export const useOfferAvailability = (offer) => {
  return useMemo(() => {
    if (offer) {
      return allowedOfferOptions(offer);
    }
    return {
      auto: undefined,
      home: undefined,
      autoRenters: undefined,
      bundle: undefined
    };
  }, [offer]);
};
