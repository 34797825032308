export default (theme) => ({
  resetContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 16,
    padding: 0,
    height: 'auto',
    '&:hover': {
      background: 'transparent'
    }
  },
  accountCard: {
    padding: 32,
    position: 'relative',
    overflowWrap: 'anywhere'
  },
  resetIcon: {
    width: 24,
    height: 24,
    marginRight: 6
  },
  disabledIcon: {
    opacity: 0.5
  },
  buttonContainer: {
    marginLeft: 4,
    marginRight: 4,
    width: '100%'
  },
  offerInfo: {
    borderTop: '1px solid #ccc',
    marginTop: '1em',
    paddingTop: '1rem'
  },
  inviterLink: {
    color: theme.colorPalette.orange_10
  },
  secondaryPhoneContainer: {
    border: '3px solid white',
    borderRadius: 3,
    padding: '10px 20px 10px 20px',
    margin: '15px 0px'
  },
  chip: {
    marginLeft: 10,
    marginTop: 10
  },
  phonesWrapper: {
    marginTop: 25,
    marginBottom: 10
  },
  secondaryPhone: {
    marginBottom: 15
  }
});
