const policyWithBundleDiscount = ['HB', 'AB'];

const getQuoteFriendlySelectedOption = (selectedOption) => {
  if (policyWithBundleDiscount.includes(selectedOption)) {
    return 'HA';
  }
  return selectedOption;
};

export default getQuoteFriendlySelectedOption;
