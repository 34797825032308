import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import { ExpandableCard } from 'core/components/expandable-card';
import { useStore } from 'core/store/store.mobx';
import { useDiscounts } from 'offer/hooks/use-discounts';
import { Discount } from './discount';
import { Paperless } from './paperless';
import { Inventory } from './inventory';
import { ConnectedHomeDiscount } from './connected-home-discount';

const DiscountToComponent = {
  paperless_savings: Paperless,
  imagery_collection_savings: Inventory,
  connected_home_discount_savings: ConnectedHomeDiscount
};

const DiscountsCard = observer(() => {
  const discounts = useDiscounts();
  const activeDiscounts = discounts.filter(({ isActive }) => isActive).length;
  const { offer: store } = useStore();
  const session = useContext(AuthContext);
  const agentIsLicensedForState = store.getIsLicensedForState(session);

  return (
    <ExpandableCard title="Discounts" badge={`${activeDiscounts} of ${discounts.length}`}>
      {discounts.map(({ label, isActive, amount, description, possibleSavings, id }, ix) => {
        const Component = DiscountToComponent[id] || Discount;
        return (
          <Component
            key={ix}
            label={label}
            amount={amount}
            possibleSavings={possibleSavings}
            description={description}
            isActive={isActive}
            disabled={!agentIsLicensedForState}
          />
        );
      })}
    </ExpandableCard>
  );
});

export default DiscountsCard;
