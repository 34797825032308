import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  title: {
    paddingBottom: theme.spacing(6),
    fontSize: 16
  },
  listContainer: {
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  disclaimer: {
    marginBottom: theme.spacing(8)
  }
});

export default makeStyles(styles);
