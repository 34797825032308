export const CognitoPermissionGroups = {
  // Multi feature access
  isService: 'isService',
  isSales: 'isSales',
  isInternalSales: 'isInternalSales',
  isExternalSales: 'isExternalSales',
  isTeamLeader: 'isTeamLeader',
  isAgency: 'isAgency',
  isInternalAgent: 'isInternalAgent',
  isUnlicensed: 'isUnlicensed', // completely unlicensed, no access to quote in any state

  // Individual feature access
  canQuote: 'canQuote',
  canEdit: 'canEdit',
  canBind: 'canBind',
  canReinstate: 'canReinstate',
  canSeeRatePriceDetails: 'canSeeRatePriceDetails',
  canScrubIncidents: 'canScrubIncidents',
  canModifyAffinityAndLeadSource: 'canModifyAffinityAndLeadSource',
  canBackDate: 'canBackDate',
  canModifyBillingId: 'canModifyBillingId',
  canViewClarionDoorData: 'canViewClarionDoorData',
  canAddCarsManually: 'canAddCarsManually',
  canAddRemoveInstallments: 'canAddRemoveInstallments',
  canAutoRenew: 'canAutoRenew',
  canAddHoldCards: 'canAddHoldCards',
  canChangeExclusions: 'canChangeExclusions',
  canToggleEmployeeDiscount: 'canToggleEmployeeDiscount',
  canClearUDRs: 'canClearUDRs'
};

export const PermissionRequireTypes = {
  All: 'all',
  AtLeastOne: 'atLeastOne'
};
