import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash-es/get';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import useStyles from './confirm-checkout.styles';

const ConfirmCheckout = observer(({ onCheckout, CheckOutAction, showValidationModal, validateCheckout }) => {
  const classes = useStyles();
  const {
    matchSearch,
    offer: {
      offer: { quote }
    }
  } = useStore();
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);
  const numberOfUsers = get(matchSearch.customersResults, 'nbHits', 0);

  const onCheckoutConfirm = async () => {
    // forcing validation errors modal to show first
    const checkoutValidationErrors = await validateCheckout();
    if (
      numberOfUsers > 0 &&
      !showValidationModal &&
      !Object.keys(checkoutValidationErrors).length &&
      !quote?.existingCustomer?.id
    ) {
      return setOpenModal(true);
    }

    return onCheckout();
  };

  return (
    <>
      <BaseDialog open={openModal} onClose={closeModal} size="lg">
        <Label type="greenBig" className={classes.modalHeader}>
          Are you sure this is a new customer?
        </Label>
        <Label type="darkGreenSmall" className={classes.modalContent}>
          {"We've found"} <b>{numberOfUsers} </b>existing customers named {matchSearch.searchString}. Creating a new one
          will add an additional {matchSearch.searchString} to the database.
        </Label>
        <div className={classes.modalBtnContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classNames(classes.modalCloseButton, classes.btn)}
            onClick={closeModal}
          >
            No, go back
          </Button>

          <Button variant="contained" color="secondary" className={classes.btn} onClick={onCheckout}>
            Yes, create a new customer
          </Button>
        </div>
      </BaseDialog>
      <CheckOutAction onClick={onCheckoutConfirm} />
    </>
  );
});

ConfirmCheckout.propTypes = {
  onCheckout: PropTypes.func.isRequired,
  CheckOutAction: PropTypes.func.isRequired,
  showValidationModal: PropTypes.bool,
  validateCheckout: PropTypes.func.isRequired
};

ConfirmCheckout.defaultProps = {
  showValidationModal: false
};

export default ConfirmCheckout;
