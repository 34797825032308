import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 32,
    backgroundColor: theme.colorPalette.red_20,
    width: 352,
    marginBottom: 12
  },
  loadingCard: {
    padding: 32,
    backgroundColor: theme.colorPalette.white_30,
    display: 'flex',
    width: 352
  },
  icon: {
    color: '#fff',
    width: '2em',
    height: '2em'
  },
  bottomSpacing: {
    paddingBottom: 10
  },
  subtitle: {
    paddingBottom: 15
  },
  adornment: {
    marginLeft: 10,
    marginRight: 2
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: theme.colorPalette.orange_10,
    color: '#fff',
    width: 296,
    '&:hover': {
      backgroundColor: theme.colorPalette.orange_10_op_70
    }
  },
  circleLoader: {
    color: theme.colorPalette.orange_10,
    marginRight: 10
  },
  verifyingLabel: {
    marginTop: 3
  }
}));

export default useStyles;
