const styles = (theme) => ({
  container: {
    height: theme.sizes.footerHeight,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    boxShadow: '0 -2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    width: '100%',
    backgroundColor: theme.colors.page__background_primary,
    zIndex: 100
  },
  footerInner: {
    minWidth: 1160,
    maxWidth: 1380
  },
  subtitle: {
    fontWeight: 300
  },
  footerItem: {
    display: 'flex',
    alignItems: 'center'
  },
  right: {
    flexGrow: 1,
    maxWidth: 1060,
    justifyContent: 'space-between'
  },
  btn: {
    maxWidth: 350,
    marginLeft: 10,
    '&$label': {}
  },
  btnLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: '#fff'
  },
  label: {
    fontSize: 40,
    fontWeight: 100,
    color: theme.colorPalette.green_10
  },
  leftFormLabel: {
    fontSize: 24,
    fontWeight: 500
  },
  withLeftForm: {
    maxWidth: 400,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: 24
  },
  rightWithLeft: {
    alignItems: 'flex-end',
    marginBottom: 32
  },
  checkbox: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 250
  }
});

export default styles;
