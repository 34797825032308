import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { DialogBodyPropTypes } from './prop-types';
import styles from './error-dialog.styles';

const DialogBody = ({ title, errors, onClick, label, classes }) => (
  <div className={classes.body}>
    <div>
      <span className={classes.title}>{title || 'Power outage!'}</span>
      {errors &&
        errors.map((message) => (
          <span key={message} className={classes.message}>
            {message}
          </span>
        ))}
    </div>
    {label && onClick && (
      <Button onClick={onClick} className={classes.button} variant="contained" color="primary">
        {label}
      </Button>
    )}
  </div>
);

DialogBody.propTypes = DialogBodyPropTypes;

export default withStyles(styles)(DialogBody);
