const styles = (theme) => ({
  'dark-inputAdornmentRoot': {
    color: theme.colors.button__background_secondary
  },
  'dark-inputAdornmentCaret': {
    cursor: 'pointer',
    width: 24,
    height: 24
  },
  'light-inputAdornmentCaret': {
    cursor: 'pointer',
    width: 24,
    height: 24,
    zIndex: 99
  },
  button: {
    right: -6,
    padding: 4
  }
});

export default styles;
