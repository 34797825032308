import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errorContent: {
    paddingBottom: 24
  },
  errorsLabel: {
    marginTop: 40,
    display: 'block',
    marginBottom: 8
  },
  errorCard: {
    minHeight: 215,
    padding: 32,
    display: 'flex',
    alignItems: 'stretch'
  },
  requoteButton: {
    backgroundColor: theme.colorPalette.orange_10,
    width: '100%'
  },
  errorMessage: {
    paddingTop: 16,
    textTransform: 'none'
  }
}));

export default useStyles;
