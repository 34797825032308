import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Label } from '../label';
import classNames from 'classnames';

import styles from './section.styles';

const leftTypes = {
  SubSection: 'infoSubtitle',
  Section: 'subtitle',
  InnerSection: 'greenSmall'
};

const rightLabelTypes = {
  InnerSection: 'greenSmall'
};

const rightValueTypes = {
  InnerSection: 'darkSmall'
};

function Section({ classes, title, type, rightLabel, rightValue, right, children, className }) {
  return (
    <div className={classNames(classes.container, className)}>
      <Grid container className={classes.header} justify="space-between">
        {title && (
          <Grid item>
            <Label type={leftTypes[type]} data-cy="section-title">
              {title}
            </Label>
          </Grid>
        )}
        <Grid item>
          {rightLabel && rightValue && (
            <>
              <Label type={rightLabelTypes[type] || 'infoLabel'} className={classes.rightLabel}>
                {rightLabel}
              </Label>
              <Label type={rightValueTypes[type] || 'infoValue'} className={classes.rightValue}>
                {rightValue}
              </Label>
            </>
          )}
          {right}
        </Grid>
      </Grid>
      {children}
    </div>
  );
}

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(['Section', 'SubSection', 'InnerSection']),
  rightLabel: PropTypes.string,
  rightValue: PropTypes.string,
  className: PropTypes.string,
  right: PropTypes.node,
  children: PropTypes.node
};

Section.defaultProps = {
  type: 'Section',
  title: null,
  rightLabel: '',
  rightValue: '',
  className: null,
  right: null,
  children: null
};

export default withStyles(styles)(Section);
