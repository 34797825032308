import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import uuidv4 from 'uuid/v4';

import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import useStyles from './add-non-driver.styles';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required!')
});

const AddNonDriver = ({ onAdd }) => {
  const classes = useStyles();

  const addNewNonDriver = (values, formik) => {
    onAdd(values);
    formik.resetForm();
  };

  return (
    <Formik
      initialValues={{
        id: uuidv4(),
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        pipEligible: true,
        pipOptOut: false,
        pipHasQHC: false,
        waivedPIPWL: false
      }}
      onSubmit={addNewNonDriver}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container justify="space-around" alignItems="center" className={classes.container}>
          <FormField type="string" id="firstName" name="firstName" label="First Name" xs={3} mode="light" />
          <FormField type="string" id="lastName" name="lastName" label="Last Name" xs={3} mode="light" />
          <FormField type="date" id="dateOfBirth" name="dateOfBirth" label="Date Of Brith" xs={3} mode="light" />
          <Button type="submit" variant="contained" color="secondary" className={classes.addButton}>
            Add resident
          </Button>
        </Grid>
      </Form>
    </Formik>
  );
};

export default withDatePicker(AddNonDriver);
