import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import styles from './editable-card.styles';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import { Card } from '../card';
import editIcon from './edit.svg';
import closeIcon from './close.svg';

const EditableCard = ({ classes, children, editing, setEditing, type, ...props }) => {
  return (
    <Card className={classes.container} type={`${type}${editing ? 'Editable' : ''}`} {...props}>
      <IconButton
        classes={MapClassesToElem(ElemType.IconButton, classes)}
        onClick={() => setEditing(!editing)}
        data-cy="edit-button"
      >
        <img className={classes.icon} src={editing ? closeIcon : editIcon} alt={editing ? 'close' : 'edit'} />
      </IconButton>
      {children}
    </Card>
  );
};

EditableCard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary'])
};

EditableCard.defaultProps = {
  type: 'primary'
};

export default withStyles(styles)(EditableCard);
