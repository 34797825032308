import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { FormField } from 'core/components/form';
import { getPolicyType } from 'core/helpers/policy-type';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';

const ownHomeText =
  'You assert that you or your spouse own this home, you are not running a business on the property, and there are no underground storage tanks are on the property.';
const ownCarstext =
  'You assert that you or your spouse own these car(s) and you receive no payment for their use. You also attest that these cars do not have any existing damage caused by accident, storm, flood or fire.';

const Attestation = ({ offer }) => {
  const selectedOption = getQuoteFriendlySelectedOption(offer?.quote?.selectedOption || 'HA');
  const { hasHome, hasAuto } = getPolicyType(selectedOption, offer?.quote?.noBindHome, offer?.quote?.noBindAuto);
  return (
    <Grid container>
      {hasHome && (
        <FormField name="attestationsHomeAccepted" type="checkbox" label={ownHomeText} mode="light" topAlignCheckbox />
      )}
      {hasAuto && (
        <FormField name="attestationsAutoAccepted" type="checkbox" label={ownCarstext} mode="light" topAlignCheckbox />
      )}
    </Grid>
  );
};

Attestation.propTypes = {
  offer: PropTypes.object.isRequired
};

export default Attestation;
