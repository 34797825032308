import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

import { TextField } from '../text-field';
import styles from './address-suggest.styles';

function renderInput(inputProps) {
  const { ref, ...other } = inputProps;
  return <TextField inputRef={ref} fullWidth {...other} />;
}

function renderSuggestion(suggestion) {
  return <MenuItem component="div">{suggestion.description}</MenuItem>;
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.description;
}

class AddressSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.value && !(state.touched || props.inputProps.touched)) {
      return {
        ...state,
        value: props.inputProps.initialValue || ''
      };
    }

    return null;
  }

  getAutocompleteService = () => {
    if (!this.autocompleteService) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
    }
    return this.autocompleteService;
  };

  getGeocoderService = () => {
    if (!this.geocoderService) {
      this.geocoderService = new window.google.maps.Geocoder();
    }
    return this.geocoderService;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.getAutocompleteService().getPlacePredictions(
      { input: value, types: ['geocode'], componentRestrictions: { country: 'us' } },
      (results) => {
        this.setState({
          suggestions: results || []
        });
      }
    );
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    if (newValue === '') {
      this.props.onSelection(this.props.name, null);
    }
    this.setState({
      value: newValue,
      touched: true
    });
  };

  handleSelection = (name, suggestion) => {
    this.getGeocoderService().geocode({ placeId: suggestion.place_id }, ([place]) => {
      const addressObject = {};

      let streetNumber = '';
      let street = '';

      for (const component of place.address_components) {
        if (component.types.includes('street_number')) {
          streetNumber = component.short_name;
        } else if (component.types.includes('route')) {
          street = component.short_name;
        } else if (component.types.includes('locality')) {
          addressObject.city = component.short_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          addressObject.state = component.short_name;
        } else if (component.types.includes('postal_code')) {
          addressObject.zip = component.short_name;
        }
      }

      addressObject.address = `${streetNumber} ${street}`;
      this.props.onSelection(name, addressObject);
    });
  };

  render() {
    const { name, className, inputProps, classes } = this.props;
    const { value, initialValue, ...otherProps } = inputProps;

    return (
      <Autosuggest
        theme={{
          container: classNames(classes.container, className),
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(event, { suggestion }) => {
          this.handleSelection(name, suggestion);
        }}
        inputProps={{
          value: this.state.value,
          onChange: this.handleChange,
          className: classes.address,
          ...otherProps
        }}
      />
    );
  }
}

AddressSuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  onSelection: PropTypes.func
};

AddressSuggest.defaultProps = {
  inputProps: { initialValue: '' },
  className: '',
  onSelection: () => {}
};

export default withStyles(styles)(AddressSuggest);
