import gql from 'graphql-tag';

export const GET_ACCOUNT_POLICIES = gql`
  query ($id: ID!) {
    account: getAccount(id: $id) {
      id
      policies {
        id
        policyType
        state
        effectiveDate
        endDate
        premium
        surplusContribution
        fromStaff
        stripeCustomerId
        paymentMethod
        salesRep
        offer {
          options {
            id
            name
            annual
            monthly
            type
            homeBill
            homeTotal
            autoBill
            autoTotal
            homePremium
            autoPremium
          }
          quote {
            leadSource
            rep
            offerings {
              autoRejectCode
              monolineAutoRejectCode
              homeownersRejectCode
            }
          }
        }
        versionHistory {
          updatedDateTime
        }
        segments {
          global {
            affinity
          }
        }
        policyDetails {
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            petInjury
            uimAddedOn
          }
          homeCoverage {
            coverageA
            minimumDeductibleValue
            minimumWindHailDeductibleValue
            interestedInSeparateWindHail
            externalPolicyId
          }
          includeUmbrella
          includeEarthquake
          includeFlood
          earthquakeCoverage {
            policyId
          }
          floodCoverage {
            policyId
          }
          drivers {
            id
            excludeDriver
          }
        }
        fees {
          amount
        }
      }
    }
    documents: getDocs(accountId: $id) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
  }
`;

export const GET_PAYMENT_METHOD_DETAILS = gql`
  query ($policyId: ID!, $accountId: ID!) {
    paymentMethods: getBillingDetails(accountId: $accountId, policyId: $policyId) {
      allPaymentMethods {
        id
        last4
        bankName
      }
    }
  }
`;

export const GET_VERIFIED_STATUS = gql`
  query ($policyId: String!, $stripeCustomerId: String!, $stripeBankAccountId: String!) {
    getVerifiedStatus(
      policyId: $policyId
      stripeCustomerId: $stripeCustomerId
      stripeBankAccountId: $stripeBankAccountId
    ) {
      status
    }
  }
`;
export const VERIFY_BANK_ACCOUNT = gql`
  query ($verificationDetails: VerifyMicrodepositsInput!) {
    verifyMicrodeposits(verificationDetails: $verificationDetails) {
      status
    }
  }
`;
