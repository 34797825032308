import { useMemo } from 'react';

function joinIgnoringFalsy(strings, separator = ' ') {
  return strings.filter((str) => !!str).join(separator);
}

const useFormattedAddres = (addressIn) => {
  return useMemo(() => {
    if (!addressIn) {
      return undefined;
    }
    const { address, address2, city, state, zip } = addressIn;
    return joinIgnoringFalsy([address, address2, city, state, zip], ', ');
  }, [addressIn]);
};

export default useFormattedAddres;
