export const claimStatusMap = {
  O: 'OPEN',
  R: 'REOPENED',
  C: 'CLOSED',
  VOID: 'VOID'
};

export const labelStyleMap = {
  OPEN: 'statusOpen',
  CLOSED: 'statusClosed',
  REOPENED: 'statusWarning',
  VOID: 'statusClosed'
};

export const causeOfLossMap = {
  auto_claim_collision_with_motor_vehicle: 'Collision',
  auto_claim_fire: 'Fire',
  auto_claim_collision_with_bus_or_train: 'Collision',
  auto_claim_hail: 'Hail',
  auto_claim_flood: 'Flood',
  auto_claim_falling_object: 'Falling Object',
  auto_claim_collision_with_fixed_object: 'Collision',
  auto_claim_glass: 'Glass',
  auto_claim_collision_with_bicycle: 'Collision',
  auto_claim_theft_of_parts: 'Theft',
  auto_claim_collision_with_pedestrian: 'Collision',
  auto_claim_storm: 'Storm',
  auto_claim_animal: 'Animal',
  auto_claim_vandalism: 'Vandalism',
  auto_claim_riot_or_civil_unrest: 'Riot or Civil Unrest',
  auto_claim_theft_of_vehicle: 'Theft'
};
