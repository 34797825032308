import React from 'react';
import PropTypes from 'prop-types';
import MUIRadio from '@material-ui/core/Radio';
import { FormControlLabel } from '@material-ui/core';

const MortgageRadio = ({ onChange, checked }) => {
  return <FormControlLabel onChange={onChange} control={<MUIRadio checked={checked} />} label="Primary Mortgage?" />;
};

MortgageRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default MortgageRadio;
