import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { lookupsJson } from '@ourbranch/lookups';

import { tooltipHoverTexts } from 'core/helpers/constants';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import ResidentsValues from './residents-uneditable';
import useStyles from '../pip-mi.styles';

const ResidentsQuestions = ({ editable }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  if (!editable) {
    return <ResidentsValues />;
  }
  return (
    <Grid container spacing={2}>
      <FormField
        id="auto.pipAllResidents"
        name="auto.pipAllResidents"
        type="numeric"
        label="Residents living in household"
        mode="light"
        xs={4}
      />
      <FormField
        id="auto.pipEveryoneOnSamePlan"
        name="auto.pipEveryoneOnSamePlan"
        type="checkbox"
        label="Are all home residents on same insurance plan?"
        mode="light"
        xs={8}
        className={classes.checkbox}
        fast={false}
        disabled={values?.auto?.pipAllResidents === 1}
      />
      <FormField
        id="auto.pipHealthInsuranceType"
        name="auto.pipHealthInsuranceType"
        type="select"
        label="Type of health insurance"
        options={lookupsJson.pipHealthInsuranceOptions}
        mode="light"
        xs={6}
        fast={false}
      />
      <Grid item xs={6}>
        <LabelTooltip
          label="Residents with QHC"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.pipResidentsWithQHC }}
          mode="light"
        >
          <FormField id="auto.pipResidentsWithQHC" name="auto.pipResidentsWithQHC" type="numeric" mode="light" />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Residents with pip from other auto insurance"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.pipAdditionalResidents }}
          mode="light"
        >
          <FormField id="auto.pipAdditionalResidents" name="auto.pipAdditionalResidents" type="numeric" mode="light" />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="Residents excluded"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.pipExcludedResidents }}
          mode="light"
        >
          <FormField id="auto.pipExcludedResidents" name="auto.pipExcludedResidents" type="numeric" mode="light" />
        </LabelTooltip>
      </Grid>
    </Grid>
  );
};

export default ResidentsQuestions;
