import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import styles from './collapsible.styles';
import { Label } from '../../../core';
import CollapsibleCard from '../../../core/components/collapsible-card';

const Collapsible = ({ classes, title, children, ...props }) => {
  return (
    <CollapsibleCard content={<Label type="infoSubtitle">{title}</Label>} {...props}>
      <Grid spacing={4} container justify="space-between" className={classes.cardBody}>
        {children}
      </Grid>
    </CollapsibleCard>
  );
};

Collapsible.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(Collapsible);
