import React from 'react';
import PropTypes from 'prop-types';

import People from 'common/components/people';
import Trailer from './trailer';
import AddTrailer from './add-trailer';

const Trailers = ({ disabled, ...rest }) => {
  return (
    <People
      disabled={disabled}
      id="trailers"
      person={Trailer}
      addForm={AddTrailer}
      title="Trailers"
      singular="trailer"
      plural="trailers"
      {...rest}
    />
  );
};

Trailers.propTypes = {
  disabled: PropTypes.bool
};

Trailers.defaultProps = {
  disabled: false
};

export default Trailers;
