import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Loading } from 'core';
import { dateTimeFormatter } from 'core/helpers/formatters';
import styles from '../tickets.styles';

// url coming from api formatted like: https://team-branch.zendesk.com/api/v2/tickets/35869.json
// we want this: https://team-branch.zendesk.com/agent/tickets/35869
const formatTicketUrl = (ticketId) => {
  return `https://team-branch.zendesk.com/agent/tickets/${ticketId}`;
};

const TicketDetail = ({ classes, ticketId, setTicketDetailModalOpen, ticketDetailModalOpen, fetchTicketDetail }) => {
  const [ticketDetail, setTicketDetail] = useState({});
  const getTicketDetail = useCallback(async () => {
    const ticketDetailRes = await fetchTicketDetail(ticketId);
    setTicketDetail(ticketDetailRes);
  }, [ticketId, fetchTicketDetail]);

  if (!ticketDetail.comments) {
    getTicketDetail();
    return (
      <BaseDialog open={ticketDetailModalOpen} onClose={() => setTicketDetailModalOpen(false)} size="lg">
        <Loading noBackground classes={classes.centered} />
      </BaseDialog>
    );
  }
  return (
    <BaseDialog open={ticketDetailModalOpen} onClose={() => setTicketDetailModalOpen(false)} size="lg">
      <Label type="subtitle">{ticketDetail.subject}</Label>
      <Label type="action" onClick={() => window.open(formatTicketUrl(ticketDetail.id), '_blank')}>
        Open this ticket in Zendesk
      </Label>
      {ticketDetail?.description && (
        <div className={classes.description}>
          <Label type="infoValueFormatted">{ticketDetail.description}</Label>
        </div>
      )}
      {ticketDetail.comments.map((comment) => {
        return (
          <div key={comment.id}>
            <Label type="darkSmall" className={classes.ticketCommentAuthor}>
              {comment.author.toUpperCase()} - {dateTimeFormatter(comment.date)}
            </Label>
            <Card className={classes.ticketCommentCard}>
              <Label type="infoValue">{comment.body}</Label>
            </Card>
          </div>
        );
      })}
    </BaseDialog>
  );
};

TicketDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  ticketId: PropTypes.string.isRequired,
  setTicketDetailModalOpen: PropTypes.func.isRequired,
  ticketDetailModalOpen: PropTypes.bool.isRequired,
  fetchTicketDetail: PropTypes.func.isRequired
};

export default flowRight(withStyles(styles))(TicketDetail);
