import React, { Suspense, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Dialog, Grid } from '@material-ui/core';

import DialogContent from '@material-ui/core/DialogContent';

import { SearchBar } from 'core/components/search-bar';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { ActionButton } from 'core/components/action-button';
import { useStore } from 'core/store/';
import x from 'core/assets/svg/x.svg';
import CustomersResults from 'core/components/search-results/customers-results';
import useStyles from './search-dialog.styles';

const CustomerSearchDialog = observer(() => {
  const classes = useStyles();
  const {
    matchSearch: {
      showCustomerMatchesModal,
      customersResults,
      clearInput,
      searchCustomersAction,
      currentResultsPage,
      setCurrentResultsPage,
      setShowCustomerMatches,
      loading,
      searchString,
      setSearchString
    },
    offer
  } = useStore();
  const history = useHistory();
  const { handleSubmit, values } = useFormikContext();

  useEffect(() => {
    searchCustomersAction();
  }, [searchString, searchCustomersAction]);

  const onSearchStringChange = useCallback(
    (event) => {
      setSearchString(event.target.value);
    },
    [setSearchString]
  );

  const onChangePage = useCallback(
    (page) => {
      setCurrentResultsPage(page);
      searchCustomersAction(searchString);
    },
    [searchString, setCurrentResultsPage, searchCustomersAction]
  );

  return (
    <Dialog maxWidth="lg" fullWidth open={showCustomerMatchesModal} onClose={() => setShowCustomerMatches(false)}>
      <DialogContent className={classes.searchDialog}>
        <Suspense fallback={null}>
          <Grid container>
            <Grid item xs={12} className={classes.searchHeader}>
              <Label type="greenBig">{customersResults.nbHits} Existing customers</Label>
              <Label type="infoValue" className={classes.searchSubtitle}>
                Select a match to link to customer or search more
              </Label>
            </Grid>
            <Grid item xs={7} className={classes.searchBar}>
              <SearchBar
                onCloseIconClick={clearInput}
                onInputChange={onSearchStringChange}
                value={searchString}
                label="Search"
              />
            </Grid>
          </Grid>
          <CustomersResults
            loading={loading}
            hits={customersResults.hits}
            totalHits={customersResults.nbHits}
            page={currentResultsPage}
            onChangePage={onChangePage}
            history={history}
            mode="modal"
            showTitle={false}
            columnsToUse={['select', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'id']}
            selectFieldName="existingCustomer.id"
          />
          <Grid container alignItems="center" justify="flex-end" className={classes.searchFooter}>
            <Grid item className={classes.buttonContainer}>
              <Button
                className={classes.linkButton}
                type="submit"
                disabled={loading || offer.loading || !values.existingCustomer?.id}
                loading={loading || offer.loading}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                <Label>Link To Existing Customer</Label>
              </Button>
            </Grid>
          </Grid>
        </Suspense>
        <ActionButton
          size="big"
          type="edit"
          icon={x}
          className={classes.closeButton}
          onClick={() => {
            setShowCustomerMatches(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
});
export default CustomerSearchDialog;
