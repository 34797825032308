/* eslint-disable */
const overrideDialog = (theme) => {
  theme.overrides.MuiDialog = {
    paperWidthMd: {},
    paperWidthLg: {
      width: 720,
      minHeight: 290
    },
    paperWidthSm: {
      width: 418
    }
  };
};

export default overrideDialog;
