import { policyType as PolicyType } from '@ourbranch/lookups';

export const getPolicyType = (policyType, noBindHome = false, noBindAuto = false) => {
  const hasHome = (policyType === PolicyType.HABundle && !noBindHome) || policyType === PolicyType.Home;
  const hasAuto =
    (policyType === PolicyType.HABundle && !noBindAuto) ||
    policyType === PolicyType.Auto ||
    policyType === PolicyType.ARBundle;
  const isBundle = policyType === PolicyType.HABundle && !noBindHome && !noBindAuto;
  const isRenters = policyType === PolicyType.ARBundle;
  const isHome = policyType === PolicyType.Home;
  const isAuto = policyType === PolicyType.Auto;
  const isAnyBundle = isBundle || isRenters;

  return { hasHome, hasAuto, isBundle, isRenters, isHome, isAuto, isAnyBundle };
};
