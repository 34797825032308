import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '20px 30px'
  },
  divider: {
    margin: '20px 0px'
  },
  checkbox: {
    marginTop: '15px',
    marginLeft: '5px'
  },
  section: {
    paddingTop: 10
  },
  driverCard: {
    padding: '10px 15px',
    marginTop: 10
  },
  topSpacing: {
    marginTop: 20
  },
  valuesRow: {
    marginTop: 20,
    height: 40
  },
  valuesRowItem: {
    '&:not(:first-child)': {
      paddingLeft: 15,
      borderLeft: '1px solid #d5cdc1'
    }
  }
}));

export default useStyles;
