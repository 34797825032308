import React from 'react';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';

import { Card } from 'core/components/card';
import AlertIconWhite from 'core/assets/svg/alert-white.svg';
import CheckIconWhite from 'core/assets/svg/check-white.svg';
import useStyles from './banner.styles';

const ICONS = {
  alert: AlertIconWhite,
  check: CheckIconWhite
};

function Banner({ color, children, show, iconType }) {
  const classes = useStyles();
  const icon = ICONS[iconType];
  return (
    <Card className={classnames({ [classes.bannerShow]: show, [classes.bannerHidden]: !show, [classes[color]]: show })}>
      <Grid container alignItems="center">
        <img src={icon} className={classes.icon} alt="Banner Icon" />
        <Grid container alignItems="center" className={classes.bannerContent}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
}

export default Banner;
