import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tabs } from '@material-ui/core';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import CustomersResults from 'core/components/search-results/customers-results';
import styles from './header.styles';
import { AuthContext } from '../auth';
import AccountMenu from '../account-menu';
import logo from './logo.svg';
import plus from '../../assets/svg/add-green.svg';
import search from '../../assets/svg/inventory.svg';
import { HeaderTab } from '../header-tab';
import { SearchBar } from '../search-bar';

const Header = observer(({ classes }) => {
  const session = useContext(AuthContext);
  const currentRoute = window.location.pathname.includes('/quote') || !session.canQuote ? 0 : 1;
  const onSearchPage = window.location.pathname.includes('/search');
  const history = useHistory();

  const [tab, setTab] = useState(currentRoute);

  const [dropdownVisible, setDropdownVisible] = useState(true);

  const {
    search: { searchString, loading, customersResults, clearInput, searchCustomersAction, setSearchString }
  } = useStore();

  const routes = useMemo(() => {
    if (!session.canQuote) {
      return ['/search/offers'];
    }
    return ['/quote', '/search/offers'];
  }, [session]);

  useEffect(() => {
    if (!window.location.pathname.includes('/quote')) {
      setTab(routes.length > 1 && onSearchPage ? 1 : 0);
    }
  }, [setTab, routes, currentRoute, onSearchPage]);

  // if newTab that you're trying to navigate to is the same as the current tab (aka the variable currentRoute)
  // then we can manually trigger a reload with code - window.location.reload()
  // Else, push to the selected tab
  const handleTabChange = useCallback(
    (e, newTab) => {
      if (currentRoute === newTab) {
        window.location.href = window.location.pathname; // drop the query on refresh
      } else {
        setTab(newTab);
        history.push(routes[newTab]);
      }
    },
    [setTab, history, routes, currentRoute]
  );

  const hideOrShowDropdown = useCallback(
    (value) => {
      setDropdownVisible(value);
    },
    [setDropdownVisible]
  );

  const handleClickAway = useCallback(() => {
    hideOrShowDropdown(false);
  }, [hideOrShowDropdown]);

  const handleRowClick = useCallback(
    (Username) => {
      hideOrShowDropdown(false);
      history.push(`/customer/${Username}`);
    },
    [history, hideOrShowDropdown]
  );

  const clearSearchInput = useCallback(() => {
    clearInput();
  }, [clearInput]);

  const onSearchInputFocus = useCallback(() => {
    hideOrShowDropdown(true);
  }, [hideOrShowDropdown]);

  const onSearchStringChange = useCallback(
    (event) => {
      setSearchString(event.target.value);
      searchCustomersAction();
    },
    [setSearchString, searchCustomersAction]
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <AppBar position="absolute" color="inherit" className={classes.header} elevation={1}>
          <Toolbar className={classes.toolbar}>
            {session && (
              <>
                <Link to="/">
                  <img src={logo} alt="gobranch" className={classes.logo} data-cy="logo" />
                </Link>
                {session.isLoggedIn && (
                  <Grid container wrap="nowrap" direction="row" justify="flex-end" alignItems="center">
                    {!onSearchPage && (
                      <Grid container direction="row" alignItems="center" className={classes.searchContainer}>
                        <Grid container item xs={12} spacing={4} alignItems="center" justify="center">
                          <Grid item>
                            <Label type="formTitle">Search</Label>
                          </Grid>
                          <Grid item xs={8}>
                            <SearchBar
                              hideOrShowDropdown={hideOrShowDropdown}
                              onCloseIconClick={clearSearchInput}
                              onInputFocus={onSearchInputFocus}
                              onInputChange={onSearchStringChange}
                              value={searchString}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Tabs
                      value={tab}
                      onChange={handleTabChange}
                      indicatorColor="secondary"
                      textColor="primary"
                      variant="fullWidth"
                      className={classes.tabs}
                    >
                      {session.canQuote && <HeaderTab label="New Quote" iconSrc={plus} />}
                      {onSearchPage && <HeaderTab label="Search" iconSrc={search} />}
                    </Tabs>
                    <AccountMenu />
                  </Grid>
                )}
              </>
            )}
          </Toolbar>
          {!onSearchPage && searchString && dropdownVisible && (
            <CustomersResults
              loading={loading}
              hits={customersResults.hits}
              history={history}
              hideOrShowDropdown={hideOrShowDropdown}
              rowClickHandler={handleRowClick}
              showTitle={false}
              mode="dropdown"
              useLink
            />
          )}
        </AppBar>
      </ClickAwayListener>
    </>
  );
});

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
