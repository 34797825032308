export const styles = (theme) => ({
  content: {
    width: 1128,
    maxWidth: '100%',
    display: 'flex',
    minHeight: 58,
    background: theme.colors.green_dark_90,
    fontWeight: 600,
    '& > .MuiSnackbarContent-message': {
      maxWidth: 990
    }
  },
  error: {
    width: 1128,
    maxWidth: '100%',
    display: 'flex',
    minHeight: 58,
    background: theme.colors.red_dark_90
  },
  bottom: {
    bottom: 48
  },
  button: {
    fontSize: 16,
    fontWeight: 600,
    background: 'none',
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },
  icon: {
    marginRight: 5,
    width: 25,
    height: 25
  },
  toastLabel: {
    display: 'flex',
    alignItems: 'center'
  }
});
