import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: '2px solid white',
    padding: '18px 32px 24px 32px',
    marginBottom: 8
  },
  addButton: {
    marginTop: 16
  }
}));

export default useStyles;
