const styles = (theme) => ({
  container: {
    padding: 32,
    position: 'relative'
  },
  iconButtonRoot: {
    position: 'absolute',
    color: theme.colors.button__background_secondary,
    padding: 8,
    top: 0,
    right: 0
  },
  iconButtonLabel: {
    height: 32,
    width: 32
  },
  icon: {
    display: 'block',
    height: 32,
    width: 32
  }
});

export default styles;
