import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { differenceInYears } from 'date-fns';

import { tooltipHoverTexts } from 'core/helpers/constants';
import Checkbox from 'core/components/checkbox';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import withDatePicker from 'core/components/with-date-picker';
import RemoveButton from 'core/components/remove-button';
import AddNonDriver from './add-non-driver';
import useStyles from '../driver-cards/driver-cards.styles';

const NonDriverCards = () => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const removeFromResidentsList = (residentIdToRemove) => {
    const newNonDriversArray = values.nonDrivers.filter((resident) => resident.id !== residentIdToRemove);
    setFieldValue('nonDrivers', newNonDriversArray);
    setFieldTouched('nonDrivers');
  };

  return (
    <Grid>
      <Section
        type="InnerSection"
        title="Non Drivers Residents"
        rightLabel="Total"
        rightValue={`${values.nonDrivers?.length || 0} residents`}
      >
        {values?.nonDrivers?.length > 0
          ? values.nonDrivers?.map((resident, index) => {
              const disablePipOptOut = !resident.pipHasQHC;
              const disablePipHasQHC = !resident.pipEligible;
              const disablePipCoveredByOtherAuto = resident.pipHasQHC;
              const dateOfBirth = new Date(resident.dateOfBirth);
              const age = differenceInYears(new Date(), dateOfBirth);

              if (!resident.pipHasQHC && resident.pipOptOut) {
                // a resident is only able to opt out if they have QHC
                setFieldValue(`nonDrivers[${index}].pipOptOut`, false);
              }

              return (
                <Card
                  key={`${resident.firstName}${resident.lastName}${resident.dateOfBirth}`}
                  type="secondary"
                  className={classes.card}
                >
                  <Grid container justify="space-between">
                    <Label type="coverageValue" className={classes.label}>
                      Resident #{index + 1}
                    </Label>
                    <RemoveButton onClick={() => removeFromResidentsList(resident.id)} />
                  </Grid>
                  <Grid container spacing={2}>
                    <FormField
                      name={`nonDrivers[${index}].firstName`}
                      type="string"
                      label="First Name"
                      mode="dark"
                      xs={3}
                    />
                    <FormField
                      name={`nonDrivers[${index}].middleName`}
                      type="string"
                      label="Middle Name"
                      mode="dark"
                      xs={3}
                    />
                    <FormField
                      name={`nonDrivers[${index}].lastName`}
                      type="string"
                      label="Last Name"
                      mode="dark"
                      xs={3}
                    />
                    <FormField
                      name={`nonDrivers[${index}].dateOfBirth`}
                      type="date"
                      label="Date Of Birth"
                      mode="dark"
                      xs={3}
                    />
                  </Grid>
                  <Grid className={classes.spacingTop}>
                    <Grid item>
                      <FormField
                        type="checkbox"
                        label="Qualified Health Coverage"
                        id={`nonDrivers[${index}].pipHasQHC`}
                        name={`nonDrivers[${index}].pipHasQHC`}
                        className={classes.checkbox}
                        mode="dark"
                        fast={false}
                        disabled={disablePipHasQHC}
                        tooltipText={tooltipHoverTexts.pipResidentsWithQHC}
                        tooltipLabel="More Info"
                      />
                    </Grid>
                    <Grid item>
                      <Checkbox
                        id="pipCoveredByOtherAuto"
                        type="checkbox"
                        mode="dark"
                        value={!values.nonDrivers[index].pipEligible}
                        label="PIP Coverage from Another Auto Insurance"
                        className={classes.checkbox}
                        tooltipText={tooltipHoverTexts.pipAdditionalResidents}
                        tooltipLabel="More Info"
                        disabled={disablePipCoveredByOtherAuto}
                        onChange={(e) => {
                          setFieldValue(`nonDrivers[${index}].pipEligible`, !e.target.checked);
                          setFieldTouched('nonDrivers');
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormField
                        type="checkbox"
                        id={`nonDrivers[${index}].pipOptOut`}
                        name={`nonDrivers[${index}].pipOptOut`}
                        label="Exclude from PIP"
                        className={classes.checkbox}
                        disabled={disablePipOptOut}
                        mode="dark"
                        fast={false}
                        tooltipText={tooltipHoverTexts.pipExcludedResidents}
                        tooltipLabel="More Info"
                      />
                    </Grid>
                    <Grid item>
                      <Checkbox
                        id="includePIPWL"
                        type="checkbox"
                        mode="dark"
                        value={!values.nonDrivers[index].waivedPIPWL}
                        label="Work Loss Coverage"
                        disabled={age < 60}
                        className={classes.checkbox}
                        tooltipText={tooltipHoverTexts.waivedPIPWL}
                        tooltipLabel="More Info"
                        onChange={(e) => {
                          setFieldValue(`nonDrivers[${index}].waivedPIPWL`, !e.target.checked);
                          setFieldTouched('nonDrivers');
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              );
            })
          : null}
      </Section>
      <AddNonDriver
        onAdd={(newDriver) => {
          const newNonDriversArray = [...(values.nonDrivers || []), newDriver];
          setFieldValue('nonDrivers', newNonDriversArray);
          setFieldTouched('nonDrivers');
        }}
      />
    </Grid>
  );
};

export default withDatePicker(NonDriverCards);
