import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    height: '500',
    width: '500'
  }
});

export default makeStyles(styles);
