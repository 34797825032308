import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  inactive: {
    backgroundColor: theme.colorPalette.orange_50
  },
  section: {
    display: 'flex',
    alignItems: 'center'
  },
  info: {
    height: 24,
    width: 24
  },
  savings: {
    fontSize: 12,
    marginRight: theme.spacing(2)
  },
  button: {
    boxShadow: 'none'
  },
  editIcon: {
    height: 24,
    width: 24,
    '& > img': {
      height: 24,
      width: 24
    }
  },
  iconContainer: {
    height: 24,
    width: 24,
    padding: '0'
  }
});

export default makeStyles(styles);
