import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ActionButton } from 'core/components/action-button';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';

import closeIcon from './icon-orange-close.svg';
import useStyles from './renewal-notice.styles';

function RenewalNotice({ onClose, open, renewalPolicyId }) {
  const classes = useStyles();
  const history = useHistory();
  const {
    account: { fetchFullAccountAndPolicy }
  } = useStore();

  const viewRenewalPolicy = () => {
    const accountId = renewalPolicyId.slice(0, 9);

    history.push(`/customer/${accountId}/policy/${renewalPolicyId}`);
    onClose();
    fetchFullAccountAndPolicy(renewalPolicyId, accountId);
  };

  return (
    <Dialog open={open} classes={{ paperWidthSm: classes.paperWidthSm }}>
      <DialogContent className={classes.modal}>
        <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        <Label className={classes.title} type="greenBig">
          The renewal for this policy has processed
        </Label>
        <Label className={classes.subtitle} type="infoSubtitle">
          Do you want to make changes to the renewal policy as well?
        </Label>
        <Button
          className={classes.viewPolicyButton}
          variant="contained"
          color="primary"
          type="button"
          onClick={viewRenewalPolicy}
        >
          Go to renewal policy
        </Button>
        <Button className={classes.dismissButton} color="secondary" type="button" onClick={onClose}>
          Skip changes in renewal policy
        </Button>
      </DialogContent>
    </Dialog>
  );
}

RenewalNotice.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  renewalPolicyId: PropTypes.string.isRequired
};

export default RenewalNotice;
