/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const withSpacing = (Component) => ({ xs, sm, md, lg, xl, classes, ...props }) => {
  if (xs || sm || md || lg || xl) {
    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.noPadding}>
        <Component {...props} />
      </Grid>
    );
  }
  return <Component {...props} />;
};

withSpacing.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number
};

export default withSpacing;
