import { makeStyles } from '@material-ui/core/styles';

export const styles = (theme) => ({
  cardElementStyle: {
    base: {
      fontSize: '16px',
      fontFamily: '"Libre Franklin", sans-serif',
      color: theme.colorPalette.green_10,
      fontWeight: '400',
      lineHeight: '20px',
      '::placeholder': {
        fontSize: '16px',
        color: theme.colorPalette.green_10
      }
    }
  }
});

const materialStyles = (theme) => ({
  '@global': {
    '.StripeElement': {
      backgroundColor: theme.palette.common.white,
      height: 37,
      border: `1px solid ${theme.colorPalette.beige_15_op_25}`,
      padding: '10px 5px',
      margin: '5px 0px 15px 0px'
    },
    '.StripeElement--invalid': {
      border: '1px solid red'
    },
    '.StripeElement-custom-error': {
      border: '1px solid red'
    },
    '.StripeElement-error-message': {
      color: 'red',
      fontSize: 12,
      fontWeight: 300
    },
    '.CardBrandIcon-container': {
      display: 'none'
    },
    '.test + .StripeElement': {
      color: 'black'
    }
  },
  root: {
    width: '100%'
  }
});

export default makeStyles(materialStyles);
