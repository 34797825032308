/* eslint-disable */
const overrideRadio = (theme) => {
  theme.overrides.MuiRadio = {
    root: {
      color: theme.colorPalette.orange_10,
      padding: 4
    },
    disabled: {
      color: theme.colors.white
    }
  };
};

export default overrideRadio;
