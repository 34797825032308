export default (theme) => ({
  dropzoneButton: {
    width: '100%',
    height: 60
  },
  dropzoneContainer: {
    position: 'relative',
    height: 60
  },
  progressBar: {
    position: 'absolute',
    width: '100%'
  },
  error: {
    color: theme.colorPalette.red_10,
    '&:hover': {
      background: 'none'
    }
  }
});
