import { useEffect, useMemo } from 'react';
import { lookupsJson, newCustomersDefaultDevices, affinityGroups } from '@ourbranch/lookups';
import { isEqual, uniqBy, find } from 'lodash-es';
import { useFormikContext } from 'formik';

import { emptyConnectedHome } from 'offer/helpers/empty-connected-home';
import { NEW_CUSTOMER_HOME_SECURITY, CONNECTED_HOME_MONITORED_TYPES } from 'core/helpers/constants';

const securityProviders = lookupsJson.securityProviders;

// security providers allowing new monitoring sign up
const providersAllowingSignUp = securityProviders.filter((provider) => provider.signUpThroughBranch);

// all of our security provider options but with a key of id and value that is needed for an autocomplete field type
const advancedConnectedHomeOptions = securityProviders.map((option) => ({ id: option.id, text: option.value }));

// all security providers that are home security affinity partners
const regularConnectedHomeOptions = uniqBy(
  lookupsJson.affinityPartners
    .filter((partner) => affinityGroups[partner.id]?.homeSecurity)
    .map((option) => ({ id: option.value, value: option.value })),
  (item) => item.value
);

export const useHomeSecurityPartnerEffects = ({ isAdvancedConnectedHome }) => {
  const { setFieldValue, values } = useFormikContext();
  const { isMonitoredByPartner, isHomeSecurityAffinity } = useConnectedHomeSecurityProviderHelpers({
    isAdvancedConnectedHome
  });

  useEffect(() => {
    if (isMonitoredByPartner) {
      setFieldValue(
        'connectedHome.monitored',
        isAdvancedConnectedHome
          ? CONNECTED_HOME_MONITORED_TYPES.ADVANCED.MONITORED_BY_PARTNER
          : CONNECTED_HOME_MONITORED_TYPES.REGULAR.CENTRAL
      );
    } else if (!isMonitoredByPartner) {
      setFieldValue('connectedHome.monitored', CONNECTED_HOME_MONITORED_TYPES.ADVANCED.MONITORED_BY_NON_PARTNER);
    }

    if (isHomeSecurityAffinity) {
      const affinityName = lookupsJson.affinityPartners.find(
        (partner) => partner.id === values?.global?.affinity
      )?.value;
      if (values?.connectedHome?.providerName !== affinityName) {
        setFieldValue('connectedHome.providerName', affinityName);
      }
    }
  }, [isMonitoredByPartner, isAdvancedConnectedHome, values, setFieldValue, isHomeSecurityAffinity]);
};

export const useNewMonitoringServiceEffects = () => {
  const { setFieldValue, values } = useFormikContext();
  const { canAffinityAllowNewCustomerSignUp, canProviderAllowNewCustomerSignup, isSigningUpForHomeSecurity } =
    useNewMonitoringServiceHelpers();

  useEffect(() => {
    if (canProviderAllowNewCustomerSignup && isSigningUpForHomeSecurity) {
      const updatedConnectedHome = {
        ...values.connectedHome,
        ...newCustomersDefaultDevices[values.connectedHome.providerName]
      };
      if (!isEqual(updatedConnectedHome, values.connectedHome)) {
        setFieldValue('connectedHome', updatedConnectedHome);
      }
    }

    if (!canAffinityAllowNewCustomerSignUp && isSigningUpForHomeSecurity) {
      setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
      setFieldValue('connectedHome.homeSecurityPartnerCustomerTypeOption', 'new');
    }
  }, [
    canProviderAllowNewCustomerSignup,
    canAffinityAllowNewCustomerSignUp,
    isSigningUpForHomeSecurity,
    values,
    setFieldValue
  ]);
};

export const useConnectedHomeSecurityProviderHelpers = ({ isAdvancedConnectedHome }) => {
  const { values } = useFormikContext();
  const affinity = useMemo(() => values?.global?.affinity, [values.global.affinity]);
  const isHomeSecurityAffinity = useMemo(() => affinityGroups[affinity]?.homeSecurity, [affinity]);

  const isHomeSecurityProvider = useMemo(
    () => find(securityProviders, (provider) => provider.value === values?.connectedHome?.providerName)?.isPartner,
    [values.connectedHome]
  );

  const isMonitoredByPartner = useMemo(
    () =>
      !emptyConnectedHome(values?.connectedHome) &&
      (isHomeSecurityAffinity ||
        isHomeSecurityProvider ||
        (!isAdvancedConnectedHome && values.connectedHome.providerName)),
    [values.connectedHome, isHomeSecurityAffinity, isHomeSecurityProvider, isAdvancedConnectedHome]
  );

  const disableSecurityProvider = useMemo(
    () => isHomeSecurityAffinity && !emptyConnectedHome(values?.connectedHome),
    [isHomeSecurityAffinity, values.connectedHome]
  );

  return { isHomeSecurityAffinity, isHomeSecurityProvider, isMonitoredByPartner, disableSecurityProvider };
};

export const useNewMonitoringServiceHelpers = () => {
  const { values } = useFormikContext();
  const affinity = useMemo(() => values?.global?.affinity, [values.global.affinity]);

  const canAffinityAllowNewCustomerSignUp = useMemo(
    () =>
      !affinity ||
      affinity === 'None' ||
      affinityGroups[affinity]?.signUpThroughBranch ||
      !affinityGroups[affinity]?.homeSecurity,
    [affinity]
  );

  const canProviderAllowNewCustomerSignup = useMemo(
    () =>
      !values?.connectedHome?.providerName ||
      find(securityProviders, (provider) => provider.value === values?.connectedHome?.providerName)
        ?.signUpThroughBranch,
    [values.connectedHome]
  );

  const homeSecurityCustomerType = useMemo(
    () =>
      values?.global?.homeSecurityPartnerCustomerType === NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE ? 'new' : 'existing',
    [values.global]
  );

  return {
    canAffinityAllowNewCustomerSignUp,
    canProviderAllowNewCustomerSignup,
    isSigningUpForHomeSecurity: homeSecurityCustomerType === 'new',
    homeSecurityCustomerType
  };
};

export const useConnectedHomeSecurityProviders = ({ isAdvancedConnectedHome }) => {
  const { isSigningUpForHomeSecurity } = useNewMonitoringServiceHelpers();

  const securityProviderOptions = useMemo(() => {
    return isSigningUpForHomeSecurity
      ? providersAllowingSignUp
      : isAdvancedConnectedHome
      ? advancedConnectedHomeOptions
      : regularConnectedHomeOptions;
  }, [isSigningUpForHomeSecurity, isAdvancedConnectedHome]);
  return securityProviderOptions;
};
