import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import useSystemStatus from 'core/hooks/use-system-status';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { Tooltip } from 'core/components/tooltip';
import { Banner } from 'core/components/banner';
import SystemStatusGraph from './components/graph';
import useStyles from './system-status.styles';

const ERRORS_INFO = {
  major: {
    threshold: 50,
    bannerColor: 'red'
  },
  moderate: {
    threshold: 20,
    bannerColor: 'orange'
  },
  minor: {
    threshold: 10,
    bannerColor: 'green'
  }
};

function getSeverity(systemStatus) {
  if (!systemStatus) return null;

  const major = systemStatus?.clarionDoor?.find((status) => status.errorRate >= ERRORS_INFO.major.threshold);
  const moderate = systemStatus?.clarionDoor?.find((status) => status.errorRate >= ERRORS_INFO.moderate.threshold);
  const minor = systemStatus?.clarionDoor?.find((status) => status.errorRate >= ERRORS_INFO.minor.threshold);

  const errorRateToShow = major || moderate || minor;
  if (!errorRateToShow) {
    return null;
  }
  const severityDescription = major ? 'major' : moderate ? 'moderate' : minor ? 'minor' : null;
  const addlInfo = ERRORS_INFO[severityDescription];
  return {
    errorRate: `${errorRateToShow.errorRate}%`,
    timePeriod: `${errorRateToShow.lookbackInMin} min`,
    description: severityDescription,
    bannerColor: addlInfo.bannerColor
  };
}

function SystemStatus() {
  const classes = useStyles();
  const { systemStatus, prevSystemStatus } = useSystemStatus();
  const [graphOpen, setGraphOpen] = useState(false);

  const [dismissed, setDismissed] = useState(false);
  const severity = systemStatus ? getSeverity(systemStatus) : null;
  const previousSeverity = prevSystemStatus ? getSeverity(prevSystemStatus) : null;

  const recovered = !severity && previousSeverity && !dismissed;
  const bannerColor = severity ? severity?.bannerColor : 'greenLight';
  const bannerIcon = severity ? 'alert' : 'check';

  if (recovered) {
    setTimeout(() => {
      setDismissed(true);
    }, 5000);
  }

  useEffect(() => {
    if (severity) {
      setDismissed(false);
    }
  }, [severity]);

  return (
    <>
      <Banner color={bannerColor} show={recovered || severity} iconType={bannerIcon}>
        {recovered && (
          <Grid container alignItems="center">
            <Label type="smallWhiteBold">Rater has recovered</Label>
          </Grid>
        )}
        {severity && (
          <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={8}>
              <Grid xs={12} className={classes.bannerContent}>
                <Label type="smallWhiteBold">
                  Rater seems to experiencing some
                  <Tooltip
                    label={`${severity?.description} delays`}
                    text={`${severity?.errorRate} over past ${severity?.timePeriod}`}
                    placement="top"
                    className={classes.tooltipText}
                  />
                </Label>
              </Grid>
              <Grid xs={12} className={classes.bannerContent}>
                <Label type="smallWhite">
                  This may cause delays or errors when generating prices. Please try back shortly.
                </Label>
              </Grid>
            </Grid>
            <Button color="secondary" onClick={() => setGraphOpen(true)}>
              More Info
            </Button>
          </Grid>
        )}
      </Banner>
      <SystemStatusGraph graphOpen={graphOpen} onClose={() => setGraphOpen(false)} />
    </>
  );
}

export default SystemStatus;
