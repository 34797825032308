import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: 16,
    backgroundColor: theme.colorPalette.green_20,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: 16
  },
  loaderContainer: {
    marginTop: 32,
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 450
  },
  loader: {
    color: theme.palette.secondary.main
  },
  tab: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  bottomSpacing: {
    marginBottom: 20
  },
  factorRow: {
    padding: '15px 0px',
    '&:first-child': {
      paddingTop: 0
    }
  },
  homeFactorRows: {
    color: theme.colorPalette.green_15
  },
  collapseCardRows: {
    backgroundColor: theme.colorPalette.beige_10_op_13,
    color: theme.colorPalette.green_15
  },
  collapseCardRow: {
    padding: '15px 30px'
  },
  divider: {
    borderBottom: '1px solid #E3E4D6',
    '&:last-child': {
      borderBottom: 0
    }
  },
  personCardLabel: {
    marginRight: 20
  },
  buttons: {
    paddingTop: 20
  }
}));

export default useStyles;
