import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import styles from './selectable-row.styles';

const SelectableRow = ({ id, onClick, children, classes, value, ...rest }) => {
  const handleClick = () => {
    onClick(id, value);
  };

  return (
    <TableRow hover id={id} classes={{ hover: classes.hover, root: classes.root }} onClick={handleClick} {...rest}>
      {children}
    </TableRow>
  );
};

SelectableRow.propTypes = {
  id: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.any
};

SelectableRow.defaultProps = {
  value: null
};

export default withStyles(styles)(SelectableRow);
