import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.colors.light_text,
    opacity: 0.6
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    }
  }
}));

export default useStyles;
