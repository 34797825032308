import { differenceWith } from 'lodash-es';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useObserver } from 'mobx-react';
import { getOptions } from '@ourbranch/lookups';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddButton from 'core/components/add-button';
import { useStore } from 'core/store';

import useStyles from './styles';
import useFeeUpdater from './use-fee-updater';

function FeeSelector({ geographicState, policyType, disabled }) {
  const { updateFees } = useFeeUpdater();
  const [feeToAdd, setFeeToAdd] = useState('');
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const classes = useStyles();

  const allFeeOptions = getOptions('fees', geographicState, null, policyType);
  const feeComparator = (option, fee) => option.id === fee.type;

  const selectFee = (event) => {
    setFeeToAdd(event.target.value);
  };

  function determineFeeOptions() {
    // create feeOptions object that can be passed through to both policy-store and fee-utils functions
    const feeOptions = {
      policyType,
      state: geographicState,
      inBundle: false
    };
    const segmentsArray = store.policy.segments;
    // looking at first policy version because we don't drop the discount/fee if customer drops the bundle mid-term
    const firstPolicySegment = segmentsArray[0];

    if (firstPolicySegment.includeRenters) {
      feeOptions.inBundle = true;
    }

    if (
      (policyType === policyType.Auto && firstPolicySegment.attachedHomeowners) ||
      (policyType === policyType.Home && firstPolicySegment.attachedAuto)
    ) {
      feeOptions.inBundle = true;
    }

    return feeOptions;
  }

  const createFee = () => {
    // uses:
    // staff-fe/src/core/store/policy-store/policy-store.js
    // packages/fee-utils/index.js

    if (feeToAdd === 'imagery') {
      const feeOptions = determineFeeOptions();
      store.addFee(feeToAdd, feeOptions);
    } else {
      store.addFee(feeToAdd, { state: geographicState, policyType });
    }

    updateFees();
    setFeeToAdd('');
  };

  function bixNoImageryFee(availableFeeOptions) {
    const indexOfImageryFee = availableFeeOptions.findIndex((val) => val.id === 'imagery');
    if (indexOfImageryFee > -1) {
      availableFeeOptions.splice(indexOfImageryFee, 1);
    }
  }

  return useObserver(() => {
    const availableFeeOptions = differenceWith(allFeeOptions, store.fees, feeComparator);

    if (store.policy.isBix) {
      bixNoImageryFee(availableFeeOptions);
    }

    return (
      <div className={classes.feeSelectorContainer}>
        <FormControl className={classes.formControl} disabled={disabled}>
          <InputLabel id="feeSelectLabel">Select a New Fee</InputLabel>
          <Select value={feeToAdd} labelId="feeSelectLabel" onChange={selectFee}>
            {availableFeeOptions.map(({ id, value: description }) => (
              <MenuItem key={id} value={id}>
                {description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <AddButton type="full" mode="sm" actionType="button" onClick={createFee} disabled={disabled || !feeToAdd} />
      </div>
    );
  });
}

FeeSelector.propTypes = {
  geographicState: PropTypes.string.isRequired,
  policyType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default FeeSelector;
