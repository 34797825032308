/* eslint-disable */
const overrideCollapse = (theme) => {
  theme.overrides.MuiCollapse = {
    container: {
      width: '100%'
    }
  };
};

export default overrideCollapse;
