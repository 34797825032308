import React, { createContext, useContext } from 'react';

const VersionContext = createContext({});

export const withVersionUtils = (Component) => (props) => {
  const { setOrder, order } = useContext(VersionContext);
  return <Component {...props} versionsUtils={{ setOrder, order }} />;
};

export const { Provider } = VersionContext;
