import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modalBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25
  },
  modalHeader: {
    marginBottom: 25
  },
  modalContent: {
    lineHeight: 1.2,
    display: 'block'
  },
  modalCloseButton: {
    marginRight: 30
  },
  btn: {
    fontWeight: 500,
    fontSize: 16,
    padding: 15
  }
}));

export default useStyles;
