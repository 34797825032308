import _isEqual from 'lodash-es/isEqual';

// whether or not it's a bix or non-bix connected home, if the following values
// are present, then the connectedHome is empty
export const empty = {
  autoWaterShutoffDevices: false,
  theftPreventionDevices: null,
  smokeDetectingDevices: null,
  moistureDevices: null
};

export const emptyConnectedHome = (connectedHome) => {
  if (!connectedHome) {
    return true;
  }
  const { autoWaterShutoffDevices, theftPreventionDevices, smokeDetectingDevices, moistureDevices } = connectedHome;
  return _isEqual(empty, {
    autoWaterShutoffDevices,
    theftPreventionDevices,
    smokeDetectingDevices,
    moistureDevices
  });
};
