/* eslint-disable */
const overrideListItems = (theme) => {
  theme.overrides.MuiListItem = {
    root: {
      '&$selected': {
        backgroundColor: theme.colorPalette.white_10,
        fontWeight: 600,
        '&:hover': {
          backgroundColor: theme.colorPalette.green_10_op_10
        }
      }
    },
    button: {
      '&:hover': {
        color: theme.palette.common.green_30,
        backgroundColor: theme.colorPalette.green_10_op_10
      }
    }
  };
};

export default overrideListItems;
