import React from 'react';
import Section from 'core/components/section';

import { BillingForm } from './billing-form';

const BillingInformation = () => {
  return (
    <Section title="Billing Information" type="SubSection">
      <BillingForm />
    </Section>
  );
};

export default BillingInformation;
