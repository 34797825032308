import { useFormikContext } from 'formik';
import { getSnapshot } from 'mobx-state-tree';
import { useStore } from 'core/store';

function useFeeUpdater() {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  return {
    updateFees() {
      const currentFees = getSnapshot(store.fees)
        .filter((fee) => fee.status !== 'removed')
        .map(({ snapshot, status, ...rest }) => ({ ...rest }));
      setFieldValue('fees', currentFees);
      setFieldTouched('fees');
      store.setChanged(true);
    }
  };
}

export default useFeeUpdater;
