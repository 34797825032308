import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchDialog: {
    minWidth: 720,
    minHeight: 290,
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  searchFooter: {
    height: 125
  },
  linkButton: {
    backgroundColor: theme.colorPalette.orange_10,
    color: theme.colors.white,
    width: 296
  },
  buttonContainer: {
    marginRight: 48
  },
  searchHeader: {
    padding: theme.spacing(7, 0, 0, 12)
  },
  searchBar: {
    padding: theme.spacing(6, 12)
  },
  searchSubtitle: {
    paddingTop: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  footer: {
    alignItems: 'baseline',
    display: 'grid',
    gridTemplateColumns: '1fr 210px',
    gap: theme.spacing(3, 6),
    padding: theme.spacing(6, 12, 12)
  },
  email: {
    width: 400
  },
  notificationCard: {
    boxShadow: 'none',
    margin: 0,
    gridColumn: '1 / span 2'
  },
  notification: {
    fontSize: 12,
    display: 'inline',
    color: theme.colorPalette.green_20,
    alignItems: 'center'
  }
}));

export default useStyles;
