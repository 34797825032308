import { useState, useEffect } from 'react';
import { useSubscription, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import _isEqual from 'lodash-es/isEqual';

const SYSTEM_STATUS_SUBSCRIPTION = gql`
  subscription onUpdateSystemStatus {
    onUpdateSystemStatus {
      clarionDoor {
        lookbackInMin
        errorRate
      }
      date
      time
    }
  }
`;

const GET_LATEST_SYSTEM_STATUS = gql`
  query getLatestSystemStatus {
    getLatestSystemStatus {
      clarionDoor {
        lookbackInMin
        errorRate
      }
      date
      time
    }
  }
`;

const useSystemStatus = () => {
  const [systemStatus, setSystemStatus] = useState();
  const [prevSystemStatus, setPrevSystemStatus] = useState();
  const {
    data: subscriptionData,
    error: subscriptionError,
    loading: subscriptionLoading
  } = useSubscription(SYSTEM_STATUS_SUBSCRIPTION);
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(GET_LATEST_SYSTEM_STATUS);

  useEffect(() => {
    if (!systemStatus && !queryError && !queryLoading && queryData?.getLatestSystemStatus) {
      // If subscription hasn't fired yet, get the latest system status from dynamo until
      // the subscription fires with the most recent data
      setSystemStatus(queryData.getLatestSystemStatus);
    }
  }, [queryData, queryLoading, queryError, systemStatus]);

  useEffect(() => {
    // Subscription received new data from the system status cron that runs every minute
    if (
      !subscriptionError &&
      !subscriptionLoading &&
      subscriptionData?.onUpdateSystemStatus &&
      !_isEqual(systemStatus, subscriptionData.onUpdateSystemStatus)
    ) {
      setPrevSystemStatus(systemStatus);
      setSystemStatus(subscriptionData.onUpdateSystemStatus);
    }
  }, [subscriptionError, subscriptionLoading, subscriptionData, systemStatus]);

  return { systemStatus, prevSystemStatus };
};

export default useSystemStatus;
