import gql from 'graphql-tag';

export const GET_DOCUMENTS_AND_APPLICATIONS = gql`
  query($accountId: ID!) {
    documents: getDocs(accountId: $accountId) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
    unsignedApplications: getUnsignedApplications(accountId: $accountId) {
      policyId
      applicationUrl
    }
  }
`;
export const REMOVE_DOCUMENT = gql`
  mutation($accountId: ID!, $fileName: String!) {
    documents: removeDoc(accountId: $accountId, fileName: $fileName) {
      success
    }
  }
`;
export const GET_UPLOAD_URL = gql`
  query($accountId: ID!, $fileName: String!, $mimeType: String!) {
    uploadUrl: getUploadUrl(accountId: $accountId, fileName: $fileName, mimeType: $mimeType) {
      url
    }
  }
`;

export const REGENERATE_POLICY_DOCUMENTS = gql`
  mutation regeneratePolicyDocuments($policyId: ID!) {
    documents: regeneratePolicyDocuments(policyId: $policyId) {
      success
    }
  }
`;
