import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import BaseDialog from '../base-dialog/base-dialog';
import { Label } from '../label';
import styles from './leave-page-dialog.styles';

const LeavePageDialog = ({ open, onClose, cb, classes, title }) => {
  return (
    <BaseDialog open={open} onClose={onClose} size="sm">
      <Grid container direction="column" alignItems="center">
        <Label type="greenBig">{title}?</Label>
        <Label className={classes.smallLabel} type="darkGreenMedium">
          Changes you made aren&apos;t saved
        </Label>
        <Button className={classes.button} onClick={cb} variant="contained" color="primary">
          {title}
        </Button>
        <Button onClick={onClose} variant="text" color="secondary">
          Go back
        </Button>
      </Grid>
    </BaseDialog>
  );
};

LeavePageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  cb: PropTypes.func,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

LeavePageDialog.defaultProps = {
  onClose: undefined,
  cb: undefined,
  title: 'Leave Page'
};

export default withStyles(styles)(LeavePageDialog);
