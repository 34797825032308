import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';

const Fee = types
  .model({
    amount: types.number,
    appliedDate: types.string,
    description: types.string,
    snapshot: types.maybe(types.frozen()),
    status: types.optional(types.enumeration(['pre-existing', 'added', 'removed']), 'pre-existing'),
    type: types.string
  })
  .actions((self) => ({
    toggleMarkedForRemoval() {
      if (self.status !== 'removed') {
        self.snapshot = getSnapshot(self);
        self.status = 'removed';
      } else {
        applySnapshot(self, self.snapshot);
      }
    }
  }));

export default Fee;
