import React, { useEffect, useContext } from 'react';
import ReactJson from 'react-json-view';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { omit, mapValues, isPlainObject, isArray, map } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { useAsObservableSource } from 'mobx-react';
import { autorun } from 'mobx';

import { withStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { AuthContext } from 'core/components/auth';
import OfferHeader from '../header';

const quoteViolationDates = ['homeClaimDates', 'homeViolationDates', 'homeSingleViolationDates', 'homeViolationPeople'];

const quoteFieldsForUnderwriters = ['homeViolations', 'homeClaimsForUW', 'offerings', ...quoteViolationDates];

/*
 * Cleans off the ssn and __typename keys off the object
 * Move to a helpers function in the future if it's needed elsewhere
 * and refactor to take in a array of keys to omit
 */
const cleanObj = (obj) => {
  return mapValues(omit(obj, ['ssn', '__typename']), (val, key) => {
    if (isPlainObject(val)) {
      return cleanObj(omit(val, ['ssn', '__typename']));
    }
    if (isArray(val)) {
      return map(val, cleanObj);
    }
    /*
    The keys of violationDates are dynamic since they are the dates of the violation.
    So, the schema represents it as AWSJSON which stringifies it, so we parse here before showing in details tree
    */
    if (['autoViolationDates', ...quoteViolationDates].includes(key)) {
      try {
        return JSON.parse(val);
      } catch (err) {
        return null;
      }
    }
    return val;
  });
};

const OfferDetails = ({
  store: {
    offer: { offer, loading, getOffer }
  }
}) => {
  const urlParams = useParams();
  const { isTeamLeader } = useContext(AuthContext);

  if (!urlParams.offerId) {
    throw new Error('Invalid URL');
  }

  const params = useAsObservableSource(urlParams);

  useEffect(() => {
    autorun(() => {
      if (!loading && !offer) {
        getOffer(params.offerId);
      }
    });
  }, [params, getOffer, loading, offer]);

  // Underwriter Managers can see the whole offer
  // Underwriters can see only information related to auto violations & drivers
  // https://github.com/gobranch/branch/issues/5180
  const cleanedOfferForTeamLeader = cleanObj(offer);
  const cleanedOfferForUW = {
    quote: {
      drivers: cleanedOfferForTeamLeader.quote?.drivers.map((driver) => {
        const { firstName, middleName, lastName, age, suffix, autoViolationDates, autoViolationsForUW } = driver;
        return {
          firstName,
          middleName,
          lastName,
          age,
          suffix,
          autoViolationDates,
          autoViolationsForUW
        };
      }),
      ...quoteFieldsForUnderwriters.reduce(
        (acc, field) =>
          !cleanedOfferForTeamLeader.quote ? acc : { ...acc, [field]: cleanedOfferForTeamLeader.quote[field] },
        {}
      )
    }
  };

  const autoOpenSections = [
    'offer details',
    'quote',
    'drivers',
    'autoViolationDates',
    'autoViolationsForUW',
    'autoViolations'
  ];
  const shouldCollapse = (field) => {
    return !autoOpenSections.includes(field.name);
  };

  if (loading || !offer) {
    return <Loading noBackground />;
  }

  return (
    <>
      <OfferHeader
        offerId={offer.id}
        options={{ showAddress: true, hideBackButton: true, showCustomerMatches: false }}
      />
      <ReactJson
        src={isTeamLeader ? cleanedOfferForTeamLeader : cleanedOfferForUW}
        displayDataTypes={false}
        name="offer details"
        collapsed={false}
        theme="bright:inverted"
        shouldCollapse={shouldCollapse}
      />
    </>
  );
};

OfferDetails.propTypes = {
  store: PropTypes.object.isRequired
};

export default flowRight(withStore)(OfferDetails);
