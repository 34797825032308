/* eslint-disable */
const overridePaper = (theme) => {
  theme.overrides.MuiPaper = {
    root: {
      position: 'relative'
    },
    rounded: {
      borderRadius: 6
    }
  };
};

export default overridePaper;
