import { useEffect, useState } from 'react';
import { lookupsJson } from '@ourbranch/lookups';
import { useFormikContext } from 'formik';
import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';

export const usePIPMECoverageOptionsHelper = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    auto: { pipExcludedResidents, pipAllResidents, pipEveryoneOnSamePlan, pipHealthInsuranceType, pipResidentsWithQHC }
  } = values;
  const { policyLimitPIPME } = lookupsJson;
  const [PIPMECoverageOptions, setPIPMECoverageOptions] = useState([]);

  useEffect(() => {
    // default to all options besides OPTOUT
    let filteredOptions = policyLimitPIPME.filter((option) => {
      return option.id !== 'OPTOUT';
    });

    if (
      (pipExcludedResidents === 0 && pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.PERSONAL) ||
      pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE
    ) {
      filteredOptions = policyLimitPIPME.filter((option) => {
        return option.id !== 'OPTOUT' && option.id !== '50000';
      });
    }

    if (pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE && pipExcludedResidents === pipAllResidents) {
      filteredOptions = policyLimitPIPME.filter((option) => {
        return option.id === 'OPTOUT';
      });
    }

    if (
      pipHealthInsuranceType !== HEALTH_INSURANCE_TYPE.MEDICARE &&
      pipExcludedResidents > 0 &&
      pipAllResidents >= pipExcludedResidents
    ) {
      filteredOptions = policyLimitPIPME.filter((option) => {
        return option.id === '250000';
      });
    }

    if (pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICAID) {
      if (pipExcludedResidents === 0 && pipAllResidents > pipResidentsWithQHC) {
        filteredOptions = policyLimitPIPME.filter((option) => {
          return option.id !== 'OPTOUT' && option.id !== '50000';
        });
      }
    }

    /**
     * if the current dropdown value is no longer available, set it to undefined so
     * the validation runs to notify the user to choose a new value
     *
     * if we don't do this, formik's value for this field will be sent with the value
     * that is no longer available even though the field is visibly blank
     */
    const filteredOptionsIds = filteredOptions.map(({ id }) => id);
    if (!filteredOptionsIds.includes(values.autoCoverage.policyLimitPIPME)) {
      setFieldTouched('autoCoverage.policyLimitPIPME');
      setFieldValue('autoCoverage.policyLimitPIPME', undefined);
    }

    setPIPMECoverageOptions(filteredOptions);
  }, [
    setPIPMECoverageOptions,
    values,
    pipExcludedResidents,
    pipAllResidents,
    pipEveryoneOnSamePlan,
    pipHealthInsuranceType,
    pipResidentsWithQHC,
    policyLimitPIPME,
    setFieldValue,
    setFieldTouched
  ]);

  return PIPMECoverageOptions;
};

export const usePIPACROptionsHelper = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    autoCoverage: { policyLimitPIPME, policyLimitPIPACR }
  } = values;
  const { policyLimitPIPACR: policyLimitPIPACROptions } = lookupsJson;
  const [PIPACRCoverageOptions, setPIPACRCoverageOptions] = useState(policyLimitPIPACROptions);

  useEffect(() => {
    let filteredOptions = policyLimitPIPACROptions;

    if (['OPTOUT', 'UNLIMITED', 'NONE'].includes(policyLimitPIPME)) {
      filteredOptions = policyLimitPIPACROptions.filter((option) => option.id === 'NONE');
    } else {
      filteredOptions = policyLimitPIPACROptions;
    }
    setPIPACRCoverageOptions(filteredOptions);

    /**
     * if the current dropdown value is no longer available, set it to undefined so
     * the validation runs to notify the user to choose a new value
     *
     * if we don't do this, formik's value for this field will be sent with the value
     * that is no longer available even though the field is visibly blank
     */
    const filteredOptionsIds = filteredOptions.map(({ id }) => id);
    if (!filteredOptionsIds.includes(policyLimitPIPACR)) {
      setFieldTouched('autoCoverage.policyLimitPIPACR');
      setFieldValue('autoCoverage.policyLimitPIPACR', undefined);
    }
  }, [policyLimitPIPME, setFieldValue, setFieldTouched, policyLimitPIPACR, policyLimitPIPACROptions]);

  return PIPACRCoverageOptions;
};
