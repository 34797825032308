import React, { useCallback, useEffect, useContext } from 'react';
import { connect } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { policyType, lookupsJson } from '@ourbranch/lookups';
import { policyTypes as pTypes } from '@ourbranch/policy-types';

import { withStore } from 'core/store';
import People, {
  AddInterestedPartyForm,
  AddLienHoldersForm,
  InterestedParty,
  LienHolders
} from 'common/components/people';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { Button } from 'core/components/button';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Loading from 'core/components/loading/loading';
import withDatePicker from 'core/components/with-date-picker';
import { dateFormatter } from 'core/helpers/formatters';
import { withDisabledState } from '../../../disabled-context';
import { AuthContext } from 'core/components/auth';
import styles from './policy-settings.styles';
import BankWithdrawal from '../payment-methods/bank-withdrawal/bank.withdrawal';
import Mortgage from '../payment-methods/mortgage';
import CreditCard from '../payment-methods/credit.cards';

const padWithZeros = (str) => str.toString().padStart(3, 0);

function getLastModifiedBy(policy) {
  return Array.isArray(policy.versionHistory)
    ? `${dateFormatter(new Date(policy.versionHistory[policy.versionHistory.length - 1].updatedDateTime))} by ${
        policy.versionHistory[policy.versionHistory.length - 1].username
      }`
    : 'Unknown';
}
function PolicySettings({
  classes,
  originalEndDate,
  cancelPolicy,
  handleCancellation,
  changed,
  disabled,
  segment,
  setDisabled,
  formik,
  handleAddHoldCard,
  accountId,
  minDate,
  maxDate,
  store: {
    account: {
      policies: {
        policy: { allActiveHoldCards, policy: policyFromStore, getAllowLicensedActions }
      }
    }
  }
}) {
  const { values: policy } = formik;

  const minEndDate = new Date(formik.values.effectiveDate);
  minEndDate.setDate(new Date(formik.values.effectiveDate).getDate() + 1);

  const { autoPaymentType: autoPaymentTypeOptions, paymentType: paymentTypeOptions } = lookupsJson;
  const hasHoldCards = !!allActiveHoldCards().length;
  const cancelled = new Date(policy.endDate) < new Date(originalEndDate);

  /* Only disable when checkbox checked or policy loaded with those dates
    or if there are any hold cards on the policy
  */
  const session = useContext(AuthContext);
  const disabledForm = formik.values.cancel || (cancelled && !changed) || !session.canEdit;

  // only allow users in CanAutoRenew cognito group to allow toggling off auto renew
  const disableAutoRenewToggle = policyFromStore?.autoRenew && !session.canAutoRenew;
  const showCancelPolicyButton = !cancelled && getAllowLicensedActions(session);

  useEffect(() => {
    setDisabled(disabledForm || hasHoldCards);
  }, [disabledForm, hasHoldCards, setDisabled]);

  const onCancel = useCallback(() => {
    formik.setFieldValue('cancel', !formik.values.cancel);
    formik.setFieldTouched('cancel');
  }, [formik]);

  useEffect(() => {
    if (!cancelPolicy && cancelled && !changed) {
      handleCancellation(cancelled, false);
    }
  }, [cancelPolicy, cancelled, changed, handleCancellation]);

  useEffect(() => {
    if (!formik.values.expirationDate) {
      const vals = {
        renew: policy.autoRenew,
        expirationDate: policy.endDate,
        lastModified: getLastModifiedBy(policy),
        cancel: disabled
      };
      formik.setValues({ ...formik.values, ...vals });
    }

    if (typeof formik.values.sendPaperDocuments === 'undefined') {
      const segments = [...policy.segments].reverse();
      const segmentWithDiscountInfo = segments.find((item) => typeof item?.global?.discountPaperless === 'boolean');
      const sendPaperDocuments = !segmentWithDiscountInfo.global.discountPaperless;

      formik.setValues({ ...formik.values, sendPaperDocuments });
    }
  }, [formik, policy, disabled]);

  const endDateDisabled = useCallback(
    (effectiveDate) => {
      if (session.canBackDate) return false;
      const currentDateTime = new Date(new Date().toISOString().slice(0, 10)).getTime();
      const effectiveDateTime = new Date(effectiveDate).getTime();
      if (effectiveDateTime >= currentDateTime) {
        return false;
      }
      return true;
    },
    [session.canBackDate]
  );

  if (!segment || !formik.values.expirationDate) {
    return <Loading type="secondary" />;
  }

  const paymentMethodComponentMap = {
    E: BankWithdrawal,
    W: Mortgage,
    C: CreditCard
  };
  const defaultPaymentMethod = policy.policyType === policyType.Auto ? 'C' : 'W';
  const PaymentMethod = paymentMethodComponentMap[formik.values.paymentMethod || defaultPaymentMethod];
  const frequencyType = policy.policyType === policyType.Auto ? autoPaymentTypeOptions : paymentTypeOptions;
  const frequencyOptions = frequencyType.filter((opt) => opt.value !== 'Mortgage');

  return (
    <>
      <Card key={`settings-${disabled}`} type="secondary" className={classes.card}>
        {formik.values.expirationDate && (
          <>
            <Grid container justify="space-around" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} key="labelPolicyTerm">
                <Label key="lblTop" type="formSubTitle" className={classes.label}>
                  Policy Term
                </Label>
              </Grid>
              <Grid container key="row1" justify="space-between" spacing={2} className={classes.row}>
                <FormField
                  name="effectiveDate"
                  type="date"
                  label="Start Date"
                  mode="dark"
                  xs={3}
                  disableFuture={false}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <FormField
                  name="endDate"
                  type="date"
                  label="End Date"
                  mode="dark"
                  minDate={minEndDate}
                  xs={3}
                  disabled={endDateDisabled(policy.effectiveDate)}
                  disableFuture={false}
                  fast={false}
                />
                <FormField
                  name="rewriteReason"
                  type="select"
                  label="Rewrite"
                  mode="dark"
                  xs={6}
                  options={lookupsJson.rewriteReason}
                />
              </Grid>
              <Grid container item xs={12} key="labelPaymentDetails" spacing={2}>
                <Label key="lblPaymentDetails" type="formSubTitle" className={classes.label}>
                  Billing Details
                </Label>
              </Grid>

              <Grid container key="row2" justify="space-between" className={classes.row} spacing={4}>
                <FormField
                  name="paymentMethod"
                  type="select"
                  label="Payment Method"
                  options={
                    policy.policyType === pTypes.Auto
                      ? lookupsJson.autoPaymentMethod
                      : lookupsJson.homeownersPaymentMethod
                  }
                  mode="dark"
                  xs={4}
                  permissions={{ isLicensedAction: false }}
                />

                <PaymentMethod frequencyOptions={frequencyOptions} />
              </Grid>
              <Grid item xs={12} key="labelPolicyInformation">
                <Label key="lblPolicyInformation" type="formSubTitle" className={classes.label}>
                  Policy Information
                </Label>
              </Grid>
              <Grid container key="policyInformation" className={classes.row} justify="space-between">
                <FormField name="state" type="value" label="State" mode="dark" xs={4} />
                <FormField name="term" type="value" label="Term" mode="dark" xs={1} formatter={padWithZeros} />
                <FormField name="version" type="value" label="Version" mode="dark" xs={2} formatter={padWithZeros} />
                <FormField name="lastModified" type="value" label="Last Modified" mode="dark" xs={5} />
              </Grid>
              <Grid container key="policyOfferInformation" className={classes.row}>
                <FormField
                  name="offer.quote.rep"
                  type="value"
                  label="Producer"
                  mode="dark"
                  xs={4}
                  formatter={(producer) => producer || 'N/A'}
                />
                <FormField
                  name="offer.quote.leadSource"
                  type="select"
                  label="Lead Source"
                  mode="dark"
                  options={lookupsJson.leadSources}
                  xs={4}
                />
              </Grid>
              <Grid item xs={12} key="labelPolicyActions">
                <Label key="lblPolicyActions" type="formSubTitle" className={classes.labelWithDivider}>
                  Policy Actions
                </Label>
              </Grid>
              <Grid container key="policyActions" justify="space-between" className={classes.row}>
                <Grid container item xs={8} key="policyActionsLeft">
                  <FormField
                    xs={12}
                    type="switch"
                    name="renew"
                    label="Auto Renew"
                    mode="dark"
                    className={classes.toggleRow}
                    disabled={disableAutoRenewToggle}
                    permissions={{
                      isLicensedAction: false
                    }}
                  />
                  <FormField
                    xs={12}
                    type="switch"
                    name="renewalCreditPull"
                    label="Run Credit/Insurance Score at Renewal"
                    mode="dark"
                    className={classes.toggleRow}
                    permissions={{
                      edit: { groups: [CognitoPermissionGroups.isService] }
                    }}
                  />
                  {!policy.isBix && (
                    <FormField
                      xs={12}
                      type="switch"
                      name="sendPaperDocuments"
                      label="Send Paper Documents"
                      mode="dark"
                      className={classes.toggleRow}
                    />
                  )}
                </Grid>
                <Grid container item xs={4} key="policyActionsRight" justify="flex-end">
                  <Button
                    className={classes.button}
                    disabled={disabledForm || !session?.canAddHoldCards}
                    onClick={handleAddHoldCard}
                  >
                    Add Hold Card
                  </Button>
                  {showCancelPolicyButton && (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={onCancel}
                      className={classes.secondaryButton}
                      disabled={disabled}
                    >
                      Cancel Policy
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      {policy.policyType === pTypes.Home ? (
        <People
          key={`parties-${disabled}`}
          id="additionalParties"
          person={InterestedParty}
          addForm={AddInterestedPartyForm}
          title="Additional interested party"
          addLabel="Add People"
          disabled={disabled}
        />
      ) : (
        <People
          key={`lien-holders-${disabled}`}
          id="additionalParties"
          person={LienHolders}
          addForm={AddLienHoldersForm}
          title="Lien Holders"
          addLabel="Add Lien Holder"
          disabled={disabled}
        />
      )}
    </>
  );
}

PolicySettings.propTypes = {
  classes: PropTypes.object.isRequired,
  originalEndDate: PropTypes.string.isRequired,
  cancelPolicy: PropTypes.bool.isRequired,
  handleCancellation: PropTypes.func.isRequired,
  changed: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  policy: PropTypes.shape({
    effectiveDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    billingDayOfMonth: PropTypes.number.isRequired,
    autoRenew: PropTypes.bool,
    state: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired
  }).isRequired,
  segment: PropTypes.object,
  handleAddHoldCard: PropTypes.func.isRequired
};

PolicySettings.defaultProps = {
  segment: null
};

export default flowRight(withDisabledState, withStyles(styles), withDatePicker, connect, withStore)(PolicySettings);
