import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Label } from 'core/components/label';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import styles from './add-button.styles';
import add from '../../assets/svg/add.svg';
import addFull from '../../assets/svg/add-full.svg';
import addFullLight from '../../assets/svg/add-full-light.svg';

const icons = {
  full: addFull,
  normal: add
};

function AddButton({ classes, onClick, mode, type, actionType, light, className, label, ...props }) {
  return (
    <Grid container alignItems="center" className={classes.buttonAndLabelWrapper}>
      <IconButton
        type={actionType}
        className={className}
        classes={
          mode
            ? MapClassesToElem(`${mode}-${ElemType.IconButton}`, classes)
            : MapClassesToElem(ElemType.IconButton, classes)
        }
        onClick={onClick}
        {...props}
      >
        <img src={light ? addFullLight : icons[type] || add} alt="add" />
      </IconButton>
      {label && <Label onClick={onClick}>{label}</Label>}
    </Grid>
  );
}

AddButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  mode: PropTypes.oneOf([null, 'big', 'xl', 'sm']),
  type: PropTypes.oneOf(['normal', 'full']),
  actionType: PropTypes.string,
  light: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
};

AddButton.defaultProps = {
  mode: null,
  light: false,
  type: 'normal',
  actionType: 'button',
  onClick: undefined,
  className: null,
  label: null
};

export default withStyles(styles)(AddButton);
