import gql from 'graphql-tag';

export const GET_ACCOUNT = gql`
  query ($id: ID!) {
    account: getAccount(id: $id) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
    }
  }
`;

export const RESET_PWD = gql`
  mutation resetPwd($username: String!) {
    resetPwd(username: $username) {
      success
      username
    }
  }
`;

export const SET_USER_PWD = gql`
  mutation setUserPassword($username: String!, $password: String!) {
    setUserPassword(username: $username, password: $password) {
      success
      username
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($account: AccountInput!) {
    updateAccount(account: $account) {
      id
      fname
      lname
      mailingAddress {
        address
        address2
        city
        state
        zip
        country
      }
      phoneNumber
      email
      additionalPhoneNumbers {
        phoneNumber
        note
        canText
      }
    }
  }
`;
