import Grid from '@material-ui/core/Grid';
import { lookupsJson } from '@ourbranch/lookups';
import CollapsibleCard from 'core/components/collapsible-card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import RemoveButton from 'core/components/remove-button';
import { withStore } from 'core/store';
import { connect, Form } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from '../person.styles';
import { getCarOptions } from './helper';

function LienHolders({
  index,
  fieldId,
  fieldName,
  item,
  removeFromList,
  onRemove,
  initialFold,
  onFold,
  store: {
    account: {
      policies: {
        policy: { segment }
      }
    }
  },
  disabled
}) {
  const classes = useStyles();

  return (
    <CollapsibleCard
      content={
        <>
          <Label
            type="infoSubtitle"
            style={{
              display: 'inline',
              marginRight: 8
            }}
          >
            {`${item.name || ''}`.substring(0, 35)}
            {item.name && item.name.length > 35 && '...'}
          </Label>
          <Label type="infoCardTitle">Loss Payee</Label>
        </>
      }
      contentRight={!item.isPrimary && <RemoveButton mode="big" onClick={() => onRemove(index, removeFromList)} />}
      initialOpen={initialFold}
      onChange={(val) => onFold(index, val)}
    >
      <Form disabled={disabled} className={classes.container}>
        <Grid key="container" container justify="space-around" alignItems="center" spacing={4}>
          <FormField
            mode="light"
            name={`${fieldName}.name`}
            id={`${fieldName}.name`}
            label="Name or Company"
            type="string"
            xs={8}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.loanNumber`}
            id={`${fieldName}.loanNumber`}
            label="Loan Number (optional)"
            type="string"
            optional
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.address`}
            id={`${fieldName}.address.address`}
            label="Address"
            type="string"
            xs={8}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.address2`}
            id={`${fieldName}.address.address2`}
            label="Address 2"
            type="string"
            optional
            xs={4}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.city`}
            id={`${fieldName}.address.city`}
            label="City"
            type="string"
            optional
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.state`}
            id={`${fieldName}.address.state`}
            label="State"
            type="autocomplete"
            options={lookupsJson.usStates}
            xs={4}
            stateSelectForm
            optional
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.zip`}
            id={`${fieldName}.address.zip`}
            label="Zip code"
            type="string"
            optional
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.VIN`}
            id={`${fieldName}.VIN`}
            label="Select associated Car (optional)"
            type="select"
            optional
            options={getCarOptions(segment)}
            xs={12}
            permissions={{ isLicensedAction: false }}
          />
        </Grid>
      </Form>
    </CollapsibleCard>
  );
}

LienHolders.propTypes = {
  index: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool,
    address: PropTypes.shape({
      address: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string
    }),
    loanNumber: PropTypes.string,
    VIN: PropTypes.string
  }).isRequired,
  store: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  initialFold: PropTypes.bool.isRequired,
  onFold: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

LienHolders.defaultProps = {
  disabled: false
};

export default flowRight(withStore, connect)(LienHolders);
