import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, IconButton } from '@material-ui/core';

import { ErrorDialogPropTypes } from './prop-types';
import DialogBody from './body';
import styles from './error-dialog.styles';

const svgProps = {
  fill: 'none',
  fillRule: 'evenodd',
  stroke: '#FFF',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: '1.5'
};
const WhiteClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g {...svgProps}>
      <path d="M13.225 13.225l21.55 21.55M34.775 13.225l-21.55 21.55" />
    </g>
  </svg>
);

// Separate body to use native lazy loading
const ErrorDialog = ({ open, classes, onClose, ...props }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
    <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
      <WhiteClose />
    </IconButton>
    <DialogBody {...props} />
  </Dialog>
);

ErrorDialog.propTypes = ErrorDialogPropTypes;

export default withStyles(styles)(ErrorDialog);
