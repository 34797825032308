export const mortgageStyles = (theme) => ({
  lastField: {
    width: 154
  },
  addLabel: {
    marginBottom: 16,
    marginTop: 16
  },
  itemContainer: {
    paddingBottom: 8
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 48,
    paddingBottom: 8
  },
  identifier: {
    paddingBottom: 8
  },
  buttonContainer: {
    paddingTop: 8
  },
  button: {
    width: 36,
    height: 36
  },
  line: {
    borderBottom: `1px solid ${theme.colorPalette.white_10}`,
    width: 'calc(100% - 8px)'
  }
});
