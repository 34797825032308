import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { rentersPolicyAvailable, editableHouseholdMembersStates, driverCarAssignmentStates } from '@ourbranch/lookups';
import { observer } from 'mobx-react';
import { policyTypes } from '@ourbranch/policy-types';

import { useStore } from 'core/store';
import AutoBreakdownPreview from 'customer/components/policy/auto-breakdown-preview/preview';
import { withDisabledState } from '../../../customer/disabled-context';
import Coverage from './auto-coverage';
import Cars from './cars';
import CarAssignment from 'common/components/auto/car-assignment';
import Trailers from './trailers';
import Renters from './renters';
import PriorCoverage from './prior-coverage';
import AutoPolicyDetails from './auto-policy-details';

function AutoOffer({ disabled }) {
  const { values } = useFormikContext();
  const { offer } = useStore();

  const showAutoPolicyDetails = editableHouseholdMembersStates.includes(offer.state);

  // When looking at home options, can only add Renters when HA is selected
  // When looking at renters options, the renters details will appear in a new Tab at top of offer
  // so that's why we're not including that as part of the rendering logic here in Auto Details
  const showRenters =
    rentersPolicyAvailable[offer.state] &&
    offer.selectedOption === policyTypes.HABundle &&
    values.offerings.offerRenters;

  return (
    <>
      <Cars disabled={disabled} offer={offer} fromOffer />
      {driverCarAssignmentStates[offer.state] && <CarAssignment />}
      <Trailers disabled={disabled} offer={offer} />
      <Coverage disabled={disabled} />
      {showAutoPolicyDetails && <AutoPolicyDetails disabled={disabled} />}
      <PriorCoverage />

      {showRenters && <Renters disabled={disabled} />}
      <AutoBreakdownPreview
        offer={offer?.offer}
        cars={getIn(values, 'cars')}
        autoCoverage={getIn(values, 'autoCoverage')}
        policyType={offer.selectedOption}
      />
    </>
  );
}

AutoOffer.propTypes = {
  disabled: PropTypes.bool
};

AutoOffer.defaultProps = {
  disabled: false
};

export default flowRight(withDisabledState, memo, observer)(AutoOffer);
