/* eslint-disable */
const overrideMenuItem = (theme) => {
  theme.overrides.MuiMenuItem = {
    root: {
      fontSize: '12px',
      '@media(min-width: 600px)': {
        minHeight: 46
      }
    }
  };
};

export default overrideMenuItem;
