import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import { BodyTableCell } from '../shared';
import { dateFormatter, toCapitalized, currencyFormatter } from '../../../../../core/helpers/formatters';
import styles from '../versions.styles';
import { Label } from '../../../../../core';
import { dateFromPolicy, getTimezone } from '../../../policy/dates';

const TableCell = withStyles(styles)(({ children, classes }) => (
  <BodyTableCell classes={{ tableCell: classes.bigTableCell }}>{children}</BodyTableCell>
));

TableCell.propTypes = {
  children: PropTypes.node.isRequired
};

export const ListBody = ({
  itemsChanged,
  coverageChange,
  version,
  updatedDateTime,
  effectiveDate,
  username,
  internalNotes,
  changeInPremium,
  classes,
  state
}) => {
  const date = dateFromPolicy(updatedDateTime, state);
  const label = getTimezone(state);
  return (
    <TableRow>
      <TableCell>{`00${version}`.substr(-3)}</TableCell>
      <TableCell>
        <span className={classes.dateInfo}>{dateFormatter(date)}</span>
        <Label type="infoLabel">{`${date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit'
        })} ${label}`}</Label>
      </TableCell>
      <TableCell>{username !== 'initial-purchase' ? toCapitalized(username) : 'Initial Purchase'}</TableCell>
      <TableCell>{dateFormatter(effectiveDate)}</TableCell>
      <TableCell>{toCapitalized(coverageChange) || 'None'}</TableCell>
      <TableCell>{toCapitalized(itemsChanged) || 'None'}</TableCell>
      <TableCell>
        <span className={classes.internalNotes}>{internalNotes || ''}</span>
      </TableCell>
      <TableCell>{currencyFormatter(changeInPremium)}</TableCell>
    </TableRow>
  );
};

ListBody.propTypes = {
  itemsChanged: PropTypes.string.isRequired,
  coverageChange: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  updatedDateTime: PropTypes.string.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  changeInPremium: PropTypes.number,
  username: PropTypes.string.isRequired,
  internalNotes: PropTypes.string,
  state: PropTypes.string,
  classes: PropTypes.object.isRequired
};

ListBody.defaultProps = {
  internalNotes: undefined,
  changeInPremium: 0.0,
  state: undefined
};

export default withStyles(styles)(ListBody);
