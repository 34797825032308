import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import { LabelTooltip } from 'core/components/label-tooltip';
import useStyles from './auto-policy-details.styles';

const AutoPolicyDetails = ({ disabled }) => {
  const classes = useStyles();

  const householdMembersOptions = Array.from({ length: 20 }, (_, ix) => ({
    id: ix + 1,
    value: `${ix + 1}`
  }));

  return (
    <Section title="Auto Policy Details" type="SubSection">
      <div className={classes.container}>
        <Grid item xs={6}>
          <LabelTooltip
            label="Total Household Members"
            tooltip={{
              label: 'More Info',
              onHoverText: 'This number includes drivers and non-driver residents, including children'
            }}
          >
            <FormField
              mode="dark"
              name="auto.householdMembers"
              id="auto.householdMembers"
              options={householdMembersOptions}
              type="select"
              optional
              disabled={disabled}
            />
          </LabelTooltip>
        </Grid>
      </div>
    </Section>
  );
};

AutoPolicyDetails.propTypes = {
  disabled: PropTypes.bool
};

AutoPolicyDetails.defaultProps = {
  disabled: false
};

export default AutoPolicyDetails;
