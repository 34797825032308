import overrideInput from './textfield';
import overrideButton from './button';
import overridePaper from './paper';
import overrideListItems from './list-items';
import overrideMenuItem from './menu-item';
import overrideFormLabel from './form-label';
import overrideFormGroup from './form-group';
import overrideSelect from './select';
import overrideRadio from './radio';
import overrideCheckbox from './checkbox';
import overrideDatePicker from './date-picker';
import overrideCollapse from './collapse';
import overrideTable from './table';
import overrideSwitch from './switch';
import overrideTabs from './tabs';
import overrideDialog from './dialog';
import { overrideGrid } from './grid';

const overrideStyles = (theme) => {
  overrideInput(theme);
  overrideButton(theme);
  overridePaper(theme);
  overrideListItems(theme);
  overrideMenuItem(theme);
  overrideFormLabel(theme);
  overrideFormGroup(theme);
  overrideSelect(theme);
  overrideRadio(theme);
  overrideCheckbox(theme);
  overrideDatePicker(theme);
  overrideCollapse(theme);
  overrideTable(theme);
  overrideSwitch(theme);
  overrideTabs(theme);
  overrideGrid(theme);
  overrideDialog(theme);
};

export default overrideStyles;
