import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import { numberFormatter } from 'core/helpers/formatters';
import { CoverageSublist } from './coverage-sublist';
import useStyles from './coverage-list.styles';

const CoverageList = ({ data, coverageA, flood }) => {
  const classes = useStyles();
  const listData = data.map(({ deductible, totalAmount }) => ({
    deductible,
    deductibleAmount: numberFormatter((coverageA * deductible) / 100),
    totalAmount
  }));

  const leftTableData = listData.slice(0, Math.round(listData.length / 2));
  const rightTableData = listData.slice(Math.round(listData.length / 2), listData.length);

  return (
    <>
      <Label type="discount" className={classes.title}>
        Coverage choices based on deductibles.
      </Label>
      <div className={classes.listContainer}>
        <CoverageSublist data={leftTableData} />
        <CoverageSublist data={rightTableData} />
      </div>
      {!flood && (
        <Label type="toast" className={classes.disclaimer}>
          Deductible applies to each coverage separately at the time of loss based on type of coverage.
        </Label>
      )}
    </>
  );
};

CoverageList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      deductible: PropTypes.number,
      totalAmount: PropTypes.number
    })
  ).isRequired,
  coverageA: PropTypes.number.isRequired,
  flood: PropTypes.bool
};

CoverageList.defaultProps = {
  flood: false
};

export default CoverageList;
