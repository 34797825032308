import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';

import { Card } from 'core/components/card';
import NotificationIconWhite from 'core/assets/svg/alert-white.svg';
import NotificationIconOrange from 'core/assets/svg/info-orange.svg';
import NotificationIconGreen from 'core/assets/svg/alert-green.svg';
import useStyles from './notification-card.styles';

const icon = {
  primary: NotificationIconWhite,
  secondary: NotificationIconOrange,
  tertiary: NotificationIconWhite,
  quaternary: NotificationIconWhite,
  quinary: NotificationIconOrange,
  light: NotificationIconGreen
};

const NotificationCard = ({ children, type, noBackground, className }) => {
  const classes = useStyles();
  // using noBackground prop to return an inline icon and text notification instead of the whole card
  if (noBackground) {
    return (
      <Grid className={classes.noBackground}>
        <img src={icon[type]} className={classes.icon} alt="Notification Icon" />
        {children}
      </Grid>
    );
  }
  return (
    <Card className={classnames(classes.container, classes[type], className)}>
      <Grid className={classes.notificationContentContainer}>
        <img src={icon[type]} className={classes.icon} alt="Notification Icon" />
        {children}
      </Grid>
    </Card>
  );
};

NotificationCard.defaultProps = {
  type: 'primary'
};

NotificationCard.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'light'])
};

export default NotificationCard;
