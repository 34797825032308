import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const PotentialCustomerMatches = ({ matchCustomer }) => {
  return <Grid container>{matchCustomer}</Grid>;
};

PotentialCustomerMatches.propTypes = {
  matchCustomer: PropTypes.element.isRequired
};

export default PotentialCustomerMatches;
