import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import { Button, LinearProgress } from '@material-ui/core';
import classNames from 'classnames';

import styles from './dropzone.styles';

function Dropzone({ percent, onDrop, children, dragging, buttonClass, activeButtonClass, classes, error, deleting }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: percent > 0
  });

  return (
    <div {...getRootProps()}>
      <LinearProgress
        className={classes.progressBar}
        color="secondary"
        variant={deleting ? 'indeterminate' : 'determinate'}
        value={percent}
      />
      <input {...getInputProps()} />
      {isDragActive ? (
        <Button className={activeButtonClass} variant="text" color="secondary">
          {dragging}
        </Button>
      ) : (
        <Button
          disabled={percent > 0}
          className={classNames(buttonClass, { [classes.error]: error })}
          variant="text"
          color={percent > 0 ? 'primary' : 'secondary'}
        >
          {children}
        </Button>
      )}
    </div>
  );
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dragging: PropTypes.node.isRequired,
  buttonClass: PropTypes.string.isRequired,
  activeButtonClass: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  percent: PropTypes.number.isRequired,
  error: PropTypes.bool,
  deleting: PropTypes.bool
};

Dropzone.defaultProps = {
  error: false,
  deleting: false
};

export default withStyles(styles)(Dropzone);
