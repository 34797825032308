import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import { tooltipHoverTexts } from 'core/helpers/constants';
import { LabelTooltip } from 'core/components/label-tooltip';
import { Label } from 'core/components/label';
import useStyles from '../pip-mi.styles';

const ResidentValue = ({ label, value, name }) => {
  const classes = useStyles();
  return (
    <Grid item xs={3} className={classes.valuesRowItem}>
      <Label type="infoValue">{value || 0}</Label>
      <Label type="formLabelLightLarge">{label}</Label>
      {tooltipHoverTexts[name] && (
        <LabelTooltip tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts[name] }} />
      )}
    </Grid>
  );
};

const ResidentsValues = () => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <Grid container className={classes.topSpacing}>
      <ResidentValue
        name="pipAllResidents"
        label="Residents living in household"
        value={values?.auto?.pipAllResidents}
      />
      <ResidentValue
        name="pipResidentsWithQHC"
        label="Residents with other qhc"
        value={values?.auto?.pipResidentsWithQHC}
      />
      <ResidentValue
        name="pipAdditionalResidents"
        label="Residents with pip from other auto insurance"
        value={values?.auto?.pipAdditionalResidents}
      />
      <ResidentValue
        name="pipExcludedResidents"
        label="Residents excluded"
        value={values?.auto?.pipExcludedResidents}
      />
    </Grid>
  );
};

export default ResidentsValues;
