import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { ActionButton } from 'core/components/action-button';
import { Label } from 'core/components/label';
import closeIcon from '../preview/close.svg';
import useStyles from './unsigned-bix-conversion-warning.styles';

const UnsignedBixConversionWarning = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogContent className={classes.modal}>
        <ActionButton type="edit" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        <Label type="titleSecondary" className={classes.title}>
          This BIX conversion offer has not been signed
        </Label>
        <Label type="darkGreenMedium" className={classes.subtitle}>
          This is an unsigned BIX conversion offer. Any endorsements to this policy will be overwritten at midnight
          unless the member signs their conversion documents or this endorsement is also made to the existing GSNIC
          policy.
        </Label>
      </DialogContent>
    </Dialog>
  );
};

export default UnsignedBixConversionWarning;
