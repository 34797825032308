export default (theme) => ({
  title: {
    height: 34,
    alignItems: 'flex-end'
  },
  footerContainer: {
    height: 98,
    display: 'flex',
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  floatingContainer: {
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 99
  },
  footerContent: {
    width: '100%',
    maxWidth: 1280,
    height: 98,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  btn: {
    width: 164
  },
  btnLabel: {
    fontWeight: 600,
    fontSize: 16
  },
  footerRightContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 12
  },
  form: {
    display: 'flex',
    marginRight: 24,
    alignItems: 'baseline'
  },
  emailBtn: {
    width: 164,
    marginLeft: 8
  }
});
