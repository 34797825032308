import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Formik, Form as FormikForm } from 'formik';

import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import done from 'core/assets/svg/done.svg';
import { checkboxes, options, Sports, categories, nesting, getLabel } from 'core/helpers/scheduled-pp-helper';
import { icons } from './icons';
import { validationSchema } from './form/validation';
import styles from './form/form.styles';

const initialValues = {
  category: '',
  value: '',
  description: '',
  subCategory: '',
  gunsUsaWorld: '',
  exTheftOption: false
};

const AddProperty = ({ classes, disabled, onAdd }) => {
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onAdd}
      validateOnBlur
    >
      {({ values, touched, errors }) => {
        const { subCategory } = values;
        const checkbox = checkboxes(classes)[subCategory || values.category];
        const label = getLabel(values.category);
        const subCategorySportsOptions = categories
          .concat(nesting) // To include sub nesting, only guns for now
          .filter((item) => Sports.options.includes(item.id))
          .map((option) => ({
            ...option,
            value: option.value.split('/').pop()
          }));
        return (
          <FormikForm className={classes.container} disabled={disabled}>
            <Grid container justify="space-between" alignItems="flex-start">
              <FormField
                name="category"
                type="select"
                label="Property Category"
                mode="light"
                xs={4}
                options={options}
                icons={icons}
                fast={false}
              />
              {values.category === 'Sports' && (
                <FormField
                  type="select"
                  name="subCategory"
                  options={subCategorySportsOptions}
                  label="Sub Category"
                  mode="light"
                  xs={4}
                  icons={icons}
                />
              )}
              <FormField
                name="value"
                type="numeric"
                label="Amount"
                mode="light"
                xs={2}
                format={{ thousandSeparator: true, prefix: '$' }}
              />
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <FormField
                name="description"
                type="string"
                label="Description"
                mode="light"
                rows={4}
                multiline
                fullWidth
              />
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={checkbox || label ? classes.bottom : classes.singleBottom}
            >
              {checkbox && (
                <FormField
                  type="checkbox"
                  mode="light"
                  classnames={{ container: classes.checkbox, 'light-checkboxColorPrimary': classes.checkboxIcon }}
                  {...checkbox}
                />
              )}
              {label && (
                <div className={classes.breakage}>
                  <img key="tickIcon" alt="tick" className={classes.tickIcon} src={done} />
                  <Label key="breakage" type="darkSmall" className={classes.checkbox}>
                    {label}
                  </Label>
                </div>
              )}
              <Button
                key="addButton"
                type="submit"
                mode="big"
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Add Personal Property
              </Button>
            </Grid>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

AddProperty.propTypes = {
  classes: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
AddProperty.defaultProps = {
  disabled: false
};

export default withStyles(styles)(AddProperty);
