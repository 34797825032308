import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 16,
    padding: '24px 32px'
  },
  equitySummaryTable: {
    '& td': {
      borderBottom: 'none',
      padding: 15
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`
    },
    '& tr:last-of-type': {
      borderBottom: 'none'
    }
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    }
  },
  red: {
    '& span:last-of-type': {
      color: `${theme.colorPalette.red_20}`
    }
  }
}));

export default useStyles;
