import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    width: `calc(50% - ${theme.spacing(5)}px)`
  },
  list: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2, 4),
    alignItems: 'center',
    '& > *': {
      width: 'calc(100% / 2)'
    },
    '& :last-child': {
      display: 'block',
      textAlign: 'end'
    }
  },
  listContent: {
    '&:nth-child(even)': {
      backgroundColor: theme.colorPalette.green_30,
      borderRadius: theme.spacing(1)
    }
  },
  field: {
    fontSize: 16
  }
});

export default makeStyles(styles);
