import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useObserver } from 'mobx-react';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';

import useStyles from './styles';
import FeeRow from './fee-row';
import FeeSelector from './fee-selector';

function ColumnLabel({ children }) {
  const classes = useStyles();

  return (
    <TableCell>
      <Label className={classes.columnLabel} type="infoLabel">
        {children}
      </Label>
    </TableCell>
  );
}

ColumnLabel.propTypes = {
  children: PropTypes.node.isRequired
};

function FeeBreakdown() {
  const session = useContext(AuthContext);
  const classes = useStyles();
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();

  return useObserver(() => (
    <>
      <div className={classes.header}>
        <Label type="coverageValue">Fee Breakdown</Label>
        <FeeSelector
          geographicState={store.geographicState}
          policyType={store.policyType}
          disabled={!session.isService || !session.isInternalAgent}
        />
      </div>
      {store.fees.length > 0 && (
        <>
          <TableContainer>
            <Table className={classes.feeBreakdownTable}>
              <TableHead>
                <TableRow>
                  <ColumnLabel>To Remove</ColumnLabel>
                  <ColumnLabel>Fee Type</ColumnLabel>
                  <ColumnLabel>Amount</ColumnLabel>
                </TableRow>
              </TableHead>
              <TableBody>
                {store.fees.map((fee, index) => (
                  <FeeRow key={index} fee={fee} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.feeSummary}>
            <Label className={classes.totalFeesLabel} type="infoLabel">
              Total Fees
            </Label>
            <ValueField
              className={classes.valueField}
              formatter={currencyFormatter}
              value={store.totalFeeCost}
              mode="dark"
            />
          </div>
        </>
      )}
    </>
  ));
}

export default FeeBreakdown;
