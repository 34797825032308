import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    width: 720,
    margin: '0 auto',
    marginTop: 30
  }
});

export default useStyles;
