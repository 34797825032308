export default () => ({
  form: {
    width: '100%'
  },
  endDate: {
    paddingRight: 18
  },
  checkbox: {
    width: 185,
    paddingLeft: 30,
    paddingTop: 10
  }
});
