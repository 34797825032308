import { theme } from 'core/components/theme/theme';

export const styles = ({ colorPalette, sizes }) => ({
  timelineTitle: {
    color: colorPalette.green_20,
    fontSize: 16,
    margin: 0,
    fontWeight: 'normal',
    textShadow: '0 2px 4px 0 rgba(0,0,0,0.14)'
  },
  sectionHeader: {
    marginBottom: 22
  },
  timelineSubtitle: {
    color: colorPalette.green_35,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: 8,
    marginTop: 3
  },
  versionsList: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  versionsListItem: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    paddingLeft: 8
  },
  vListItemRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  vListItemCards: {
    marginBottom: 20,
    flexGrow: 1
  },
  vListItemContent: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column'
  },
  valueField: {
    marginBottom: 0
  },
  valueFieldInline: {
    marginBottom: 0,
    display: 'inline-block'
  },
  icon: {
    height: 24,
    width: 24,
    margin: '0px 8px',
    verticalAlign: 'bottom'
  },
  timesIcon: {
    composes: '$icon'
  },
  equalsIcon: {
    composes: '$icon'
  },
  valueFieldContent: {
    marginTop: 0
  },
  dot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 18,
    border: `2px solid ${colorPalette.orange_10}`,
    background: colorPalette.beige_10,
    borderRadius: '50%'
  },
  selectedDot: {
    background: colorPalette.orange_10
  },
  timelineDot: {
    left: 0,
    position: 'absolute',
    zIndex: 5
  },
  segmentDot: {
    background: colorPalette.beige_10,
    width: 20,
    height: 20,
    left: -1,
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      boxShadow: `0px 0px 0px 10px ${colorPalette.orange_10_op_08}`,
      background: colorPalette.beige_10
    }
  },
  segmentsListItem: {
    paddingLeft: 0
  },
  plusDot: {
    position: 'absolute',
    top: 7,
    left: -11
  },
  vItemCard: {
    padding: 24,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    marginBottom: 7
  },
  vItemSegment: {
    marginBottom: 24
  },
  segmentsList: {
    paddingLeft: 0,
    margin: 0,
    listStyle: 'none'
  },
  segmentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  segmentsBottomWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24
  },
  segmentsBottomLine: {
    display: 'inline-block',
    width: 11,
    height: 16
  },
  segmentsBottomTracker: {
    display: 'inline-block',
    height: '100%',
    borderLeft: `2px solid ${colorPalette.orange_10}`
  },
  segmentTimelineTracker: {
    position: 'relative',
    width: 11,
    marginRight: 24,
    borderRight: `2px solid ${colorPalette.orange_10}`,
    display: ' inline-block'
  },
  segmentContent: {
    width: '100%',
    cursor: 'pointer'
  },
  button: {
    width: 288,
    float: 'right'
  },
  activeDot: {
    background: colorPalette.orange_10,
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
    '&:hover': {
      boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
      background: colorPalette.orange_10
    }
  },
  activeCard: {
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)'
  },
  innerActive: {
    display: 'inline-block'
  },
  firstContainer: {
    marginBottom: 26
  },
  changed: {
    marginBottom: sizes.footerHeight + 50
  },
  clarionDoorLink: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});
