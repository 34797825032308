import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Radio } from 'core/components/radio';
import styles from './segment-form.styles';

const radioId = 'change_option';
const SegmentForm = memo(({ onRadioChange, value, options, classes }) => {
  return (
    <div className={classes.container}>
      <Radio
        options={options}
        id={radioId}
        name={radioId}
        defaultValue={value}
        value={value}
        onChange={onRadioChange}
        classes={{ formControl: classes.formControl, controlLabel: classes.controlLabel }}
      />
    </div>
  );
});

SegmentForm.propTypes = {
  options: PropTypes.array.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SegmentForm);
