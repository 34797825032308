import PropTypes from 'prop-types';

export const FieldPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeHolder: PropTypes.string,
  mode: PropTypes.oneOf([null, 'light', 'dark', 'red', 'big']),
  default: PropTypes.node,
  width: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      value: PropTypes.string
    })
  ),
  props: PropTypes.object
});

export const FormikCtxPropTypes = PropTypes.shape({
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  classes: PropTypes.object
});

export const FieldElementPropTypes = {
  field: FieldPropTypes.isRequired,
  ctx: FormikCtxPropTypes
};

const ElementPropTypeShape = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  props: PropTypes.object
};

const childrenPropType = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.shape(ElementPropTypeShape), FieldPropTypes, PropTypes.string])
);
ElementPropTypeShape.children = childrenPropType;

export const FormPropTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  data: childrenPropType.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
