import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
/**
 * Mask component for text input
 *
 * telephone mask sample :
 * ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
 * @export
 * @param {any} props
 * @returns
 */
export function CustomMaskedInput(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={!!props.value}
    />
  );
}

CustomMaskedInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  mask: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired
};

CustomMaskedInput.defaultProps = {
  inputRef: null
};

export const MaskInput = ({ label, mask, value, onChange }) => (
  <FormControl>
    <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
    <Input
      value={value}
      onChange={onChange}
      id="formatted-text-mask-input"
      mask={mask}
      inputComponent={CustomMaskedInput}
    />
  </FormControl>
);

MaskInput.propTypes = {
  InputProps: PropTypes.shape({
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
  }).isRequired,
  value: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(CustomMaskedInput);
