import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';

import { Table, TableHeaderCell } from 'core';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { awsDateToDateFormatter } from 'core/helpers/formatters';
import { Link } from 'react-router-dom';
import NoResults from '../no-results';
import SelectableRow from './row';
import useStyles from './customer-results.styles';

const CustomersResults = ({
  loading,
  hits,
  totalHits,
  page,
  onChangePage,
  history,
  rowClickHandler,
  columnsToUse,
  showTitle,
  useLink,
  selectFieldName,
  mode,
  noDataMessage
}) => {
  const classes = useStyles({ mode });
  const handleChangePage = useCallback((_, newPage) => onChangePage(newPage), [onChangePage]);

  const customerLink = useCallback((Username) => {
    return `/customer/${Username}`;
  }, []);

  const headerTypes = {
    select: (
      <TableHeaderCell key="select" columnId="select">
        To Select
      </TableHeaderCell>
    ),
    id: (
      <TableHeaderCell key="id" columnId="id">
        ID
      </TableHeaderCell>
    ),
    fname: (
      <TableHeaderCell key="fname" columnId="fname">
        First Name
      </TableHeaderCell>
    ),
    lname: (
      <TableHeaderCell key="lname" columnId="lname">
        Last Name
      </TableHeaderCell>
    ),
    address: (
      <TableHeaderCell key="address" columnId="address">
        Address
      </TableHeaderCell>
    ),
    city: (
      <TableHeaderCell key="city" columnId="city">
        City
      </TableHeaderCell>
    ),
    state: (
      <TableHeaderCell key="state" columnId="state">
        State
      </TableHeaderCell>
    ),
    zip: (
      <TableHeaderCell key="zip" columnId="zip">
        Zip
      </TableHeaderCell>
    ),
    phone: (
      <TableHeaderCell key="phone" columnId="phone">
        Phone
      </TableHeaderCell>
    ),
    created: (
      <TableHeaderCell key="created" columnId="created">
        Created
      </TableHeaderCell>
    )
  };

  const columnTypes = {
    select: (hit) => (
      <TableCell key="select">
        <FormField
          type="radio"
          name={selectFieldName}
          mode="light"
          ignoreGlobalDisabledState
          fast={false}
          options={[{ value: hit?.Username }]}
        />
      </TableCell>
    ),
    id: (hit) => <TableCell key="username">{hit?.Username}</TableCell>,
    fname: (hit) => <TableCell key="id">{hit?.attributes ? hit.attributes.name : ''}</TableCell>,
    lname: (hit) => <TableCell key="fname">{hit?.attributes ? hit.attributes.family_name : ''}</TableCell>,
    address: (hit) => <TableCell key="lname">{hit?.attributes ? hit.attributes.address : ''}</TableCell>,
    city: (hit) => <TableCell key="city">{hit?.attributes ? hit.attributes.custom_city : ''}</TableCell>,
    state: (hit) => <TableCell key="state">{hit?.attributes ? hit.attributes.custom_state : ''}</TableCell>,
    zip: (hit) => (
      <TableCell key="zip" align={mode === 'dropdown' ? 'right' : 'inherit'}>
        {hit.attributes ? hit.attributes.custom_zip : ''}
      </TableCell>
    ),
    phone: (hit) => <TableCell key="phone">{hit?.attributes ? hit.attributes.phone_number : ''}</TableCell>,
    created: (hit) => (
      <TableCell key="created" align={mode === 'dropdown' ? 'right' : 'inherit'}>
        {awsDateToDateFormatter(new Date(hit.UserCreateDate))}
      </TableCell>
    )
  };

  const Header = () =>
    columnsToUse.map((name) => {
      return headerTypes[name];
    });

  const Row = ({ hit }) =>
    columnsToUse.map((name) => {
      return columnTypes[name](hit);
    });

  const handleRowClick = () => {
    // no-op
  };
  return (
    <>
      {showTitle && (
        <Label type="subtitle" style={{ marginBottom: 16 }}>
          Customers
        </Label>
      )}
      <Paper
        square={mode === 'dropdown'}
        elevation={mode === 'dropdown' ? 0 : null}
        className={classes.resultsContainer}
      >
        {Boolean(loading || hits.length) && (
          <Table
            selfContained={false}
            loading={loading}
            paginationEnabled={mode !== 'dropdown'}
            count={totalHits}
            rowsPerPage={hits.length}
            currentPage={page}
            onChangePage={handleChangePage}
            header={
              mode !== 'dropdown' ? (
                <>
                  <Header />
                </>
              ) : null
            }
            body={
              <>
                {Boolean(hits && hits.length) &&
                  hits.map((hit) =>
                    useLink ? (
                      <Link to={customerLink(hit?.Username)} key={hit?.Username} disabled>
                        <SelectableRow
                          hover
                          id={hit?.Username}
                          value={hit}
                          onClick={rowClickHandler || handleRowClick}
                          data-cy={`customer-${hit?.Username}`}
                        >
                          <Row hit={hit} />
                        </SelectableRow>
                      </Link>
                    ) : (
                      <SelectableRow
                        hover
                        id={hit?.Username}
                        value={hit}
                        onClick={rowClickHandler || handleRowClick}
                        data-cy={`customer-${hit?.Username}`}
                        key={hit?.Username}
                      >
                        <Row hit={hit} />
                      </SelectableRow>
                    )
                  )}
              </>
            }
          />
        )}
        {Boolean(!loading && hits.length === 0) && (
          <NoResults className={classes.resultsContainer} message={noDataMessage} />
        )}
      </Paper>
    </>
  );
};

CustomersResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  hits: PropTypes.array,
  totalHits: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  history: PropTypes.object.isRequired,
  rowClickHandler: PropTypes.func,
  columnsToUse: PropTypes.arrayOf(
    PropTypes.oneOf(['select', 'id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'phone', 'created'])
  ),
  showTitle: PropTypes.bool,
  useLink: PropTypes.bool,
  mode: PropTypes.string,
  selectFieldName: PropTypes.string,
  noDataMessage: PropTypes.string
};

CustomersResults.defaultProps = {
  hits: [],
  page: 1,
  onChangePage: null,
  totalHits: 0,
  rowClickHandler: null,
  columnsToUse: ['id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'created'],
  showTitle: true,
  useLink: false,
  mode: '',
  selectFieldName: null,
  noDataMessage: null
};

export default CustomersResults;
