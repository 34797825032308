export default () => ({
  container: {
    width: 720,
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative'
  },
  title: {
    justifyContent: 'center',
    marginBottom: 40
  },
  submit: {
    marginTop: 32,
    width: 260,
    fontWeight: 600,
    fontSize: 16
  }
});
