import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useToast } from 'core/components/toast';

import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { useStore } from 'core/store';
import { CheckoutStatus } from 'core/store/offer-store';
import { Label } from 'core/components/label';
import DownloadButton from './download-button';
import useStyles from './downloads.styles';

const Downloads = observer(() => {
  const { offer: offerStore } = useStore();
  const toast = useToast();

  const { offer, downloadQuote, downloadOffer, status } = offerStore;

  const { clusterId, code, id: offerId } = offer;

  const selectedOption = offerStore?.selectedOption;
  const quoteFriendlyOption = getQuoteFriendlySelectedOption(selectedOption);

  const classes = useStyles();

  const onDownloadQuote = useCallback(() => {
    downloadQuote({ bypassCache: false });
    toast.notify({
      type: 'success',
      message: 'PDF not correct?',
      label: 'Regenerate from scratch',
      action: () => downloadQuote({ bypassCache: true }),
      durationInSeconds: 30
    });
  }, [downloadQuote, toast]);

  const onDownlaodOffer = useCallback(() => {
    downloadOffer({ offerId, clusterId, code, policyType: quoteFriendlyOption, bypassCache: false });
    toast.notify({
      type: 'success',
      message: 'PDF not correct?',
      label: 'Regenerate from scratch',
      action: () => downloadOffer({ offerId, clusterId, code, policyType: quoteFriendlyOption, bypassCache: true }),
      durationInSeconds: 30
    });
  }, [downloadOffer, offerId, clusterId, code, quoteFriendlyOption, toast]);

  return (
    <>
      <Label variant="body3" className={classes.downloadHeader}>
        Downloads
      </Label>
      <Card className={classes.pdfDownloadContainer}>
        <DownloadButton
          loading={status === CheckoutStatus.DownloadingQuote}
          classes={classes}
          onClick={onDownloadQuote}
          downloadText="Application PDF"
        />
        <DownloadButton
          loading={status === CheckoutStatus.DownloadingOffer}
          classes={classes}
          onClick={onDownlaodOffer}
          downloadText="Offer PDF"
        />
      </Card>
    </>
  );
});

export default Downloads;
