// takes output object of yupToFormikErrors and returns an array of error dot paths
// handles nested and non-nested input
import { isObject, toPairs, isEmpty } from 'lodash-es';

export const getNestedErrors = (errors, errorPaths = []) => {
  const nestedErrors = {};
  for (const err of toPairs(errors)) {
    if (isObject(err[1])) {
      nestedErrors[err[0]] = err[1];
    } else {
      errorPaths.push(err[0]);
    }
  }
  const nestedErrorsUnfolded = {};
  if (nestedErrors) {
    for (const errorGroup of toPairs(nestedErrors)) {
      for (const error of toPairs(errorGroup[1])) {
        nestedErrorsUnfolded[`${errorGroup[0]}.${error[0]}`] = error[1];
      }
    }
  }
  return isEmpty(nestedErrors) ? errorPaths : getNestedErrors(nestedErrorsUnfolded, errorPaths);
};
