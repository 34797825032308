export default () => ({
  root: {
    fontWeight: 300,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  selected: {
    fontWeight: 500
  },
  wrapper: {
    flexDirection: 'row'
  },
  img: {
    minWidth: 32,
    marginLeft: 4
  },
  label: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  }
});
