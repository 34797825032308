import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { styles } from './layout-with-sidebar.styles';

const LayoutWithSidebar = ({
  header: HeaderComponent,
  content: ContentComponent,
  side: SideComponent,
  classes,
  ...props
}) => {
  return (
    <>
      {HeaderComponent && <HeaderComponent {...props} />}
      <Grid container justify="space-between" alignItems="flex-start" className={classes.container}>
        <Grid className={classes.side}>{SideComponent && <SideComponent {...props} />}</Grid>
        <Grid className={classes.content}>
          <ContentComponent {...props} />
        </Grid>
      </Grid>
    </>
  );
};

LayoutWithSidebar.propTypes = {
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  side: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  classes: PropTypes.object.isRequired
};
LayoutWithSidebar.defaultProps = {
  header: undefined,
  content: undefined,
  side: undefined
};

export default withStyles(styles)(LayoutWithSidebar);
