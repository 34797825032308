import { makeStyles } from '@material-ui/core/styles';
import arrowMatch from './arrow-matches.svg';

const useStyles = makeStyles((theme) => ({
  customerLink: {
    fontSize: 16,
    color: theme.colorPalette.green_20
  },
  matchCustomer: {
    background: theme.colorPalette.white_30,
    padding: '8px 20px 7px 5px',
    borderRadius: '3px 3px 3px 3px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    right: '7%'
  },
  potentialMatches: {
    backgroundImage: `url(${arrowMatch})`,
    width: 320,
    height: 57,
    objectFit: 'contain',
    borderRadius: 6,
    padding: '8px 0 8px 24px',
    marginRight: 15
  },
  noMatchOffer: {
    background: 'transparent',
    borderRadius: '3px 3px 3px 3px',
    borderStyle: 'solid',
    borderColor: 'white'
  },
  matchLabel: {
    whiteSpace: 'pre'
  },
  matchAction: {
    color: theme.colorPalette.white_10,
    fontSize: 12
  },
  matchAction2: {
    color: theme.colorPalette.orange_10,
    fontSize: 12
  },
  subtitle: {
    paddingRight: 10
  },
  subtitleOrange: {
    paddingRight: 10,
    color: theme.colorPalette.orange_10
  },
  hover: {
    cursor: 'pointer'
  },
  noPotentialMatches: {
    background: 'transparent',
    padding: '20px',
    marginRight: '20px',
    borderRadius: '3px 3px 3px 3px',
    borderStyle: 'solid',
    borderColor: 'white'
  }
}));

export default useStyles;
