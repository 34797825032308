import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    height: 67,
    margin: '7px 0 8px',
    padding: '16px 16px 15px',
    borderRadius: 8,
    backgroundColor: theme.colorPalette.white_10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  disabledSuggestions: {
    pointerEvents: 'none',
    opacity: 0.6
  },
  age: {
    fontSize: 10
  },
  name: {
    paddingBottom: 4
  },
  iconButtonLabel: {
    height: 32,
    width: 32
  },
  addDrivers: {
    marginBottom: 16
  }
}));

export default useStyles;
