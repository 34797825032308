import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { ActionButton } from '../action-button';
import closeIcon from '../../assets/svg/x.svg';
import styles from './base-dialog.styles';

const BaseDialog = ({ children, size, open, classes, onClose, title, ...props }) => {
  return (
    <Dialog
      maxWidth={size}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.container
      }}
      {...props}
    >
      {title && (
        <DialogTitle>
          {onClose && (
            <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
          )}
          {title}
        </DialogTitle>
      )}
      <DialogContent className={classes.mContent}>
        {!title && onClose && (
          <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};

BaseDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['xs', 'md', 'sm', 'lg', 'xl']),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

BaseDialog.defaultProps = {
  size: 'lg',
  onClose: undefined,
  title: ''
};

export default withStyles(styles)(BaseDialog);
