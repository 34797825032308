/* eslint-disable func-names */
import { object, boolean } from 'yup';
import { getPreSaleChecklistConditions } from '../../helper';

export default (policyType, quote, values) => {
  // Always required
  let schema = object().shape({
    authorizePayments: boolean().required().oneOf([true]),
    branchCommunicationConsent: boolean().required().oneOf([true]),
    confirmDiscounts: boolean().required().oneOf([true]),
    confirmCoverages: boolean().required().oneOf([true])
  });

  const preSaleChecklistConditions = getPreSaleChecklistConditions(quote, policyType, values);
  // Conditionally required
  Object.entries(preSaleChecklistConditions).forEach(([key, value]) => {
    if (!['payingInFull', 'payingMonthly'].includes(key) && value) {
      schema = schema.concat(
        object().shape({
          [`${key}`]: boolean().required().oneOf([true])
        })
      );
    }
  });

  return schema;
};
