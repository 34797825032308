const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    justifyContent: 'center'
  }
});

export default styles;
