import { numberFormatter } from 'core/helpers/formatters';
import { mineSubsidenceOptional, getValue } from '@ourbranch/lookups';

export const getShowMineSubsidence = (offer) => {
  if (!offer) {
    return false;
  }

  const { home, correctedAddress } = offer.quote;

  return (
    mineSubsidenceOptional[correctedAddress.state] &&
    (!!mineSubsidenceOptional[correctedAddress.state][home.county.toUpperCase()] ||
      !!mineSubsidenceOptional[correctedAddress.state].all)
  );
};

export const getShowDayCare = (state) => state === 'MD';

export const getShowMSALE = (state) => state === 'IN';

export const getShowSinkhole = (state) => state === 'TN';

// currently not offering Oil Storage Tank Coverage
export const getShowOilStorageTank = (state) => false;

const getShowMSALEFromQuote = (offer) => {
  if (!offer) {
    return false;
  }

  const {
    correctedAddress: { state }
  } = offer.quote;

  return getShowMineSubsidence(offer) && getShowMSALE(state);
};

const money = (value) => `$${numberFormatter(value, 2)}`;
const percentage = (value) => `${value}%`;
const getPercentage = (value, perc) => (value * perc) / 100;
const boolCoverage = (value, hideIfFalse) => (value ? 'Covered' : hideIfFalse ? null : 'No Coverage');

export const getCoverage = (offer) => {
  const hideMineSubsidence = !getShowMineSubsidence(offer);
  const hideMSALE = !getShowMSALEFromQuote(offer);

  if (!offer) {
    return {};
  }

  const {
    umbrellaCoverage,
    scheduledPersonalProperty,
    homeCoverage,
    autoCoverage,
    cars,
    rentersCoverage,
    includeUmbrella
  } = offer.quote;
  const hasJewelryCoverage =
    scheduledPersonalProperty &&
    Array.isArray(scheduledPersonalProperty.items) &&
    scheduledPersonalProperty.items.length;
  const [personalProperty, livingExpense] = rentersCoverage.coverageCD.split('/');

  return {
    hasUmbrellaCoverage: includeUmbrella,
    hasJewelryCoverage,
    homeCoverage: {
      dwelling: money(homeCoverage.coverageA),
      incrementalDwelling: percentage(homeCoverage.coverageIBSC),
      otherStructuresLimit: money(getPercentage(homeCoverage.coverageA, homeCoverage.coverageBPctOfA)),
      waterBackup: getValue('waterBackupCoverageOptions', homeCoverage.coverageWaterBackup),
      additionalLivingExpense: money(getPercentage(homeCoverage.coverageA, homeCoverage.coverageDPctOfA)),
      homeSharing: boolCoverage(homeCoverage.homeownerProtection),
      roofSurfacesExtended: boolCoverage(homeCoverage.coverageRSExtended),
      coverageMS: boolCoverage(homeCoverage.coverageMS, hideMineSubsidence),
      coverageMSALE: boolCoverage(homeCoverage.coverageMSALE, hideMSALE),
      guestMedicalLimit: money(homeCoverage.coverageY),
      personalPropertyLimit: money(getPercentage(homeCoverage.coverageA, homeCoverage.coverageCPctOfA)),
      liabilityLimit: money(homeCoverage.coverageX),
      deductibleWindHail:
        homeCoverage.deductibleWindHail < 1
          ? `${percentage(homeCoverage.deductibleWindHail * 100)} (${money(
              homeCoverage.deductibleWindHail * homeCoverage.coverageA
            )})`
          : money(homeCoverage.deductibleWindHail),
      deductibleAllOther:
        homeCoverage.deductibleAllOther < 1
          ? `${percentage(homeCoverage.deductibleAllOther * 100)} (${money(
              homeCoverage.deductibleAllOther * homeCoverage.coverageA
            )})`
          : money(homeCoverage.deductibleAllOther),
      interestedInSeparateWindHail: boolCoverage(homeCoverage.interestedInSeparateWindHail)
    },
    autoCoverage: {
      policyLimitBIPD: getValue('policyLimitBIPD', autoCoverage.policyLimitBIPD),
      policyLimitMedicalPayments: getValue('policyLimitMedicalPayments', autoCoverage.policyLimitMedicalPayments),
      policyLimitUMBI: getValue('policyLimitUMBI', autoCoverage.policyLimitUMBI)
    },
    carsCoverages: cars.reduce((obj, car) => {
      obj[car.VIN] = {
        deductibleCollision: getValue('deductibleCollision', car.deductibleCollision),
        deductibleComprehensive: getValue('deductibleComprehensive', car.deductibleComprehensive),
        limitRental: getValue('limitRental', car.limitRental),
        roadsideAssistance: boolCoverage(car.roadsideAssistance),
        limitUMPD: getValue('limitUMPD', car.limitUMPD),
        rideSharing: boolCoverage(car.rideSharing),
        coverageLoan: boolCoverage(car.coverageLoan)
      };
      return obj;
    }, {}),
    scheduledPersonalProperty: {
      deductible: hasJewelryCoverage ? money(scheduledPersonalProperty.deductible) : 'No Coverage',
      items:
        scheduledPersonalProperty && Array.isArray(scheduledPersonalProperty.items)
          ? scheduledPersonalProperty.items.map((item) => {
              return {
                ...item,
                valueFormatted: money(item.value)
              };
            })
          : []
    },
    umbrellaCoverage: {
      liabilityLimit: includeUmbrella ? money(umbrellaCoverage.liabilityCoverageLimit) : 'No Coverage'
    },
    rentersCoverage: {
      liabilityLimit: money(rentersCoverage.coverageX),
      deductible: money(rentersCoverage.deductible),
      guestMedicalLimit: money(rentersCoverage.coverageY),
      personalProperty: money(parseFloat(personalProperty)),
      livingExpense: money(parseFloat(livingExpense))
    }
  };
};
