import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton, Collapse } from '@material-ui/core';

import caret from '../../assets/svg/caretOrange.svg';
import { Badge } from '../badge';
import { Card } from '../card';
import { Label } from '../label';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import useStyles from './expandable-card.styles';

const ExpandableCard = ({ title, badge, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const onToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  return (
    <Card className={classes.container}>
      <div className={classes.header}>
        <Label type="infoSubtitleWhite">{title}</Label>
        <div className={classes.right}>
          {badge && <Badge>{badge}</Badge>}
          <IconButton classes={MapClassesToElem(ElemType.IconButton, classes)} onClick={onToggle}>
            <img
              className={classNames(classes.icon, { [classes.collapse]: expanded })}
              src={caret}
              alt={expanded ? 'fold' : 'unfold'}
            />
          </IconButton>
        </div>
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </Card>
  );
};

ExpandableCard.propTypes = {
  title: PropTypes.string.isRequired,
  badge: PropTypes.string,
  children: PropTypes.node.isRequired
};

ExpandableCard.defaultProps = {
  badge: null
};

export default ExpandableCard;
