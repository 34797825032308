import React, { useMemo, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { Loading } from 'core/components/loading';
import Section from 'core/components/section';
import withMoratorium from 'core/hooks/with-moratorium';
import { withStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import HoldCards from './hold-cards';
import { PropertyInspection } from './property-inspection';
import SegmentList from './segments/segment-list';
import { Provider, Versions } from './versions';
import { styles } from './policy-sidebar.styles';

const initialOrder = {
  order: 'desc',
  orderBy: (a, b) => (a.version < b.version ? 1 : -1)
};

const sort = (list, orderBy) => list.sort(orderBy);

const processOrder = (list, { order, orderBy }) => {
  if (order === 'desc') {
    return sort(list, orderBy);
  }
  return sort(list, orderBy).reverse();
};

const SegmentGroup = {
  renewal: 'renewal',
  history: 'history'
};

const getSegmentGroup = (segment, policy) =>
  new Date(segment.startDate) > new Date(policy && policy.endDate) ? SegmentGroup.renewal : SegmentGroup.history;

const groupSegments = (segments = [], policy) =>
  segments.reduce(
    (acc, segment) => {
      const group = getSegmentGroup(segment, policy);

      return { ...acc, [group]: [...acc[group], segment] };
    },
    { [SegmentGroup.history]: [], [SegmentGroup.renewal]: [] }
  );

const PolicySidebar = ({
  store: {
    account: {
      policies: {
        policy: { loading, policy, changed, reversedSegments, activeHoldCards, allActiveHoldCards }
      }
    }
  },
  classes
}) => {
  const { canEdit } = useContext(AuthContext);
  const initialVersionHistoryList = policy && policy.versionHistory ? policy.versionHistory : [];

  const [order, setOrder] = useState(initialOrder);
  const [activeSection, setActiveSection] = useState(SegmentGroup.history);
  const list = processOrder(initialVersionHistoryList.slice(), initialOrder);
  const versions = list.slice(0, 5);
  const total = list.length;

  const showHoldCards = activeHoldCards().length > 0 && policy && policy.state && canEdit;

  const segments = reversedSegments();
  const { history, renewal } = useMemo(() => groupSegments(segments, policy), [segments, policy]);

  return (
    <div className={changed ? classes.changed : null}>
      <Provider value={{ setOrder, order }}>
        {canEdit && policy?.policyType === PolicyType.Home && (
          <Section classes={{ container: classes.firstContainer }} title="Property Inspection">
            {loading || !policy ? <Loading /> : <PropertyInspection />}
          </Section>
        )}
        <Section
          classes={{ container: classes.firstContainer }}
          title="Version History"
          right={`${versions.length}/${total} Versions`}
        >
          {loading || !policy ? <Loading /> : <Versions versions={versions} state={policy.state} allVersions={list} />}
        </Section>
        {showHoldCards && (
          <Section title="Hold Cards" right={`${activeHoldCards().length}/${allActiveHoldCards().length} Hold Cards`}>
            <HoldCards
              state={policy.state}
              activeHoldCards={activeHoldCards()}
              allActiveHoldCards={allActiveHoldCards()}
            />
          </Section>
        )}
        <Section
          title="Changes History"
          right={`${policy ? (history || []).length : 0} Segments`}
          classes={{ header: classes.sectionHeader }}
        >
          <div onClick={() => setActiveSection(SegmentGroup.history)}>
            {!loading && (
              <SegmentList
                segments={history} // Slice to prevent original object mutation
                policyType={policy ? policy.policyType : ''}
                isSectionActive={activeSection === 'history'}
              />
            )}
          </div>
        </Section>
        {renewal.length > 0 && (
          <Section
            title="Renewal Changes"
            right={`${policy ? (renewal || []).length : 0} Segments`}
            classes={{ header: classes.sectionHeader }}
          >
            <div onClick={() => setActiveSection(SegmentGroup.renewal)}>
              {!loading && (
                <SegmentList
                  segments={renewal} // Slice to prevent original object mutation
                  policyType={policy ? policy.policyType : ''}
                  isSectionActive={activeSection === 'renewal'}
                />
              )}
            </div>
          </Section>
        )}
      </Provider>
    </div>
  );
};

PolicySidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withMoratorium, withStore)(PolicySidebar);
