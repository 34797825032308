import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import config from 'aws-exports';
import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { OfferNotifications } from 'offer/components/notifications';
import { CheckoutForm } from './components/checkout-form';

const stripePromise = loadStripe(config.stripeKey);

const Checkout = observer(({ option }) => {
  const { offer: offerStore } = useStore();
  const { offer, getOffer } = offerStore;
  const { offerId } = useParams();
  if (!offer) {
    getOffer(offerId);
    return <Loading noBackground />;
  }

  return (
    <Elements stripe={stripePromise}>
      {offerStore.notifications?.length && <OfferNotifications />}
      <CheckoutForm offer={offer} optionFromRoute={option} />
    </Elements>
  );
});

Checkout.propTypes = {
  offerStore: PropTypes.object
};

export default Checkout;
