const styles = (theme) => ({
  iconButtonRoot: {
    color: theme.colors.button__background_secondary,
    padding: 0
  },
  iconButtonLabel: {
    height: 24,
    width: 24,
    '& > img': {
      height: 24,
      width: 24
    }
  },
  'big-iconButtonRoot': {
    color: theme.colors.button__background_secondary,
    padding: 8
  },
  'big-iconButtonLabel': {
    height: 32,
    width: 32,
    '& > img': {
      height: 32,
      width: 32
    }
  }
});

export default styles;
