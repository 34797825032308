export const withRetry = async (promise, requestName = 'request', maxRetries = 5) => {
  let currentAttempt = 0;
  while (currentAttempt < maxRetries) {
    const response = await promise;
    if (currentAttempt) {
      console.log(`${requestName} attempt retried ${currentAttempt} time(s)`); // eslint-disable-line no-console
    }
    if (currentAttempt < maxRetries && response?.errors?.[0]?.errorType === 'ExecutionTimeout') {
      currentAttempt += 1;
      continue;
    }
    return response;
  }
};
