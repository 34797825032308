import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';

import useStyles from './row.styles';

function Row({ rowName, costBreakdown }) {
  const classes = useStyles();

  const { fees, premium, surplus, total } = costBreakdown;

  return (
    <TableRow>
      <TableCell>
        <Label className={classes.label} type="infoLabel">
          {rowName}
        </Label>
      </TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={currencyFormatter}
          label={`Premium ${rowName}`}
          value={premium}
          mode="dark"
        />
      </TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={currencyFormatter}
          label={`Fees ${rowName}`}
          value={fees}
          mode="dark"
        />
      </TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={currencyFormatter}
          label={`Surplus ${rowName}`}
          value={surplus}
          mode="dark"
        />
      </TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={currencyFormatter}
          label={`Total ${rowName}`}
          value={total}
          mode="dark"
        />
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  rowName: PropTypes.string.isRequired,
  costBreakdown: PropTypes.shape({
    fees: PropTypes.number.isRequired,
    premium: PropTypes.number.isRequired,
    surplus: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired
};

export default Row;
