import { GET_POLICY_FROM_OFFER_ID } from './offer-queries';

export const pollPolicyTable = async (client, offerId, { maxRetries }) => {
  let currentAttempt = 0;
  while (currentAttempt < maxRetries) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    try {
      currentAttempt += 1;
      const { data, error } = await client.query({
        query: GET_POLICY_FROM_OFFER_ID,
        variables: { offerId }
      });
      if (error) {
        throw error;
      }
      const { accountId } = data.getPolicyFromOfferId;
      return { data: { accountId } };
    } catch (error) {
      if (currentAttempt >= maxRetries) {
        throw error;
      }
    }
  }
};
