/* eslint-disable */
const overrideCheckbox = (theme) => {
  theme.overrides.MuiCheckbox = {
    colorSecondary: {
      color: theme.colorPalette.green_10
    },
    colorPrimary: {
      color: theme.colorPalette.orange_10,
      position: 'relative',
      '&::before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        float: 'left'
      },
      '& >span': {
        position: 'absolute'
      },
      '&$checked': {
        color: theme.colorPalette.orange_10
      },
      '&$checked::before': {
        backgroundColor: 'white'
      }
    },
    root: {
      marginTop: 0,
      marginLeft: -10
    }
  };
};

export default overrideCheckbox;
