import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import useStyles from './swap-button.styles';
import { ReactComponent as SwapIcon } from './swap.svg';

function SwapButton({ onClick, mode, disabled }) {
  const classes = useStyles();

  return (
    <IconButton
      disabled={disabled}
      classes={
        mode
          ? MapClassesToElem(`${mode}-${ElemType.IconButton}`, classes)
          : MapClassesToElem(ElemType.IconButton, classes)
      }
      onClick={onClick}
    >
      <SwapIcon />
    </IconButton>
  );
}

SwapButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([null, 'big']),
  disabled: PropTypes.bool
};

SwapButton.defaultProps = {
  mode: null,
  disabled: false
};

export default SwapButton;
