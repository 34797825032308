import {
  violationCodeToDescription,
  homeClaimCodeToDescription,
  fpcClassesImpactingPrice,
  financialResponsibilityTiersImpactingPrice,
  getFinancialResponsibilityTier,
  getInsuranceScoreGroup,
  pullAutoDataOnlyForAutoPolicyStates
} from '@ourbranch/lookups';

const getHomeFactors = (offer) => {
  const homeFactors = [];
  const homeClaims = offer?.quote?.homeClaimDates ? JSON.parse(offer.quote.homeClaimDates) : {};

  Object.keys(homeClaims).forEach((date) => {
    const claimDetails = homeClaims[date][0];
    homeFactors.push(
      `A Home Claim on ${date} of type ${claimDetails.class}: ${
        homeClaimCodeToDescription[claimDetails.class]
      } has been identified`
    );
  });

  if (offer.quote?.home?.fpc && fpcClassesImpactingPrice.includes(offer.quote.home.fpc)) {
    homeFactors.push(
      `The Public Protection Class, which is determined by the Insurance Services Organization, for this home is  ${offer.quote.home.fpc}. This code indicates how quickly and easily the local fire department will be able to address a fire at a home’s location. A higher protection class can result in an increased cost to insure a home.`
    );
  }

  const today = new Date();
  if (offer.quote?.home?.roofYear && today.getFullYear() - offer.quote.home.roofYear > 5) {
    homeFactors.push(
      `The age of this home’s roof is: ${offer.quote.home.roofYear}. The age, type of materials used, and shape of a roof can have an impact on the cost to insure a home.`
    );
  }

  if (offer.quote?.home?.constructionType === 'B') {
    homeFactors.push(
      "The home's construction type is primarily Brick, as opposed to wood studs. Brick construction can cost more to repair or replace than wood frame construction."
    );
  }
  return homeFactors.length ? homeFactors : null;
};

const getPersonFactors = ({ person, includeHomeFactors, includeAutoFactors, state }) => {
  const personFactors = [];
  const autoViolations = person.autoViolationDates ? JSON.parse(person.autoViolationDates) : {};
  const onlyChoosingHome = includeHomeFactors && !includeAutoFactors;
  const dontShowUDR = onlyChoosingHome && pullAutoDataOnlyForAutoPolicyStates[state];

  Object.keys(autoViolations).forEach((violationDate) => {
    const violation = autoViolations[violationDate][0];
    if (violation.class === 'UDR' && dontShowUDR) {
      return;
    }
    if (violationCodeToDescription[violation.class]) {
      personFactors.push(
        `A driving violation of type ${violation.class}: ${
          violationCodeToDescription[violation.class]
        } was identified for driver ${person.firstName} ${person.lastName} on ${violationDate}`
      );
    } else {
      personFactors.push(
        `A driving violation for driver ${person.firstName} ${person.lastName} was identified on ${violationDate} with code ${violation.class}. Please ask Branch's Insurance Product team for more details on this violation code.`
      );
    }
  });

  if (person.isPrimary) {
    const autoInsuranceScore = person.insuranceScore?.autoTotal?.toFixed(1) || person.insuranceScore?.total?.toFixed(1);
    const homeInsuranceScore = person.insuranceScore?.homeTotal?.toFixed(1) || person.insuranceScore?.total?.toFixed(1);
    const financialResponsibilityTier = includeAutoFactors && getFinancialResponsibilityTier(autoInsuranceScore);
    const insuranceScoreGroup = includeHomeFactors && getInsuranceScoreGroup(homeInsuranceScore);
    if (!(includeHomeFactors && includeAutoFactors)) {
      if (
        financialResponsibilityTier &&
        financialResponsibilityTiersImpactingPrice.includes(financialResponsibilityTier)
      )
        personFactors.push(
          `Your Financial Responsibility Tier for auto insurance is ${financialResponsibilityTier}. The tier is based on a range of A1 to R1. Tier placement is based on multiple factors, including your insurance score, that are a part of your credit history and can be used to predict the likelihood of a claim. Lower tier placement can impact the cost to insure a vehicle. An insurance score is not the same thing as a credit score and they are calculated differently.`
        );
      if (insuranceScoreGroup) {
        personFactors.push(
          `Your Insurance Score Group for homeowners insurance is ${insuranceScoreGroup}/50. The insurance group is based on multiple factors that are a part of your credit history and can be used to predict the likelihood of a claim. Lower scores can impact the cost to insure a home. An insurance score is not the same thing as a credit score and they are calculated differently.`
        );
      }
    } else {
      personFactors.push(
        `Your Insurance Score Group for homeowners insurance is ${insuranceScoreGroup}/50 and your Financial Responsibility Tier for auto insurance is ${financialResponsibilityTier}. The auto tier range is from A1 to R1 and score grouping is from 1 to 50. Insurance Score Groups and tiers are determined based on multiple factors, including your insurance score, and can be used to predict the likelihood of a claim. Lower scores or tier placement can have an impact on the price of your bundled insurance. An insurance score is not the same thing as a credit score and they are calculated differently.`
      );
    }
  }

  return personFactors.length ? personFactors : null;
};

const getPeopleFactors = ({ people, includeHomeFactors, includeAutoFactors, state }) => {
  const peopleFactors = {};

  if (people) {
    people.forEach((person) => {
      const personFactors = getPersonFactors({ person, includeHomeFactors, includeAutoFactors, state });
      if (personFactors) {
        peopleFactors[person.id] = personFactors;
      }
    });
  }

  return Object.keys(peopleFactors).length ? peopleFactors : null;
};

const getCarFactors = (car) => {
  const carFactors = [];

  // mock data for dev bc severe incident data comes from carfax and we don't run carfax in dev
  if (window.location.host.includes('dev') || window.location.host.includes('localhost')) {
    car.lastSevereIncidentDate = '2020-09-14';
  }

  if (car && car.lastSevereIncidentDate) {
    carFactors.push(
      `We identified a severe incident on your ${car.make} ${car.model} ${car.year} on ${car.lastSevereIncidentDate}`
    );
  }
  return Object.keys(carFactors).length ? carFactors : null;
};

const getCarsFactors = (offer) => {
  const carsFactors = {};
  const { cars } = offer?.quote;

  if (cars && cars.length) {
    cars.forEach((car) => {
      const carFactors = getCarFactors(car);
      if (carFactors) {
        carsFactors[car.VIN] = carFactors;
      }
    });
  }
  return Object.keys(carsFactors).length ? carsFactors : null;
};

export const getFactorsImpactingPrice = ({ offer, includeHomeFactors, includeAutoFactors }) => {
  const homeFactors = includeHomeFactors ? getHomeFactors(offer) : null;
  const carFactors = includeAutoFactors ? getCarsFactors(offer) : null;
  const people = offer?.quote?.drivers?.length ? offer.quote.drivers : offer?.quote?.people;
  const peopleFactors = getPeopleFactors({
    people,
    includeHomeFactors,
    includeAutoFactors,
    state: offer.quote.correctedAddress.state
  });

  if (!homeFactors && !carFactors && !peopleFactors) {
    return null;
  }

  return {
    homeFactors,
    peopleFactors,
    carFactors
  };
};
