import Cookies from 'js-cookie';
import { values } from 'mobx';
import { omit, mapValues, isPlainObject, isEqual, get } from 'lodash-es';
import { flow, getEnv, types } from 'mobx-state-tree';
import { formattedError, hasRejection, mapRevisedQuoteDetails, shouldReject } from 'core/helpers/quoter.service';
import {
  ABODE_PRO_PLAN_AFFINITY,
  ABODE_PRO_PLAN_PROVIDER_NAME,
  NEW_CUSTOMER_HOME_SECURITY
} from 'core/helpers/constants';
import Notification from 'core/helpers/notifications';
import { phoneNumberFormatter } from 'core/helpers/formatters';

import { withRetry } from 'core/helpers/with-retry';
import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import { toDate } from 'date-fns-tz';
import { isSameDay, parse } from 'date-fns';
import { emptyConnectedHome } from 'offer/helpers/empty-connected-home';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import awsExports from '../../../aws-exports';

import {
  bixStates,
  affinityGroups,
  advancedConnectedHomeStates,
  discountInventoryScoreStates,
  discountPaperlessStates,
  leaseLoanStates,
  policyType
} from '@ourbranch/lookups';

import {
  GET_OFFER,
  RECALCULATE_QUOTE,
  RECALCULATE_QUOTE_IN_CLUSTER,
  RECALCULATE_QUOTE_TO_CLUSTER,
  REQUEST_BIND,
  DOWNLOAD_QUOTE,
  ADD_DRIVERS_CARS_AND_RECALCULATE,
  DOWNLOAD_OFFER,
  GET_CAR_DRIVER_SUGGESTIONS,
  ADD_TO_PRE_BIND_UW_REJECTIONS,
  GET_PRE_BIND_UW_REJECTIONS_BY_WEB_USER_ID,
  CLEAR_REJECT_CODES,
  ADD_REFERRAL
} from './offer-queries';

import { pollPolicyTable } from './offer-functions';

export const OfferPageTabs = {
  SETTINGS: 'SETTINGS',
  AUTO: 'AUTO',
  HOME: 'HOME',
  PEOPLE: 'PEOPLE',
  PIP: 'PIP',
  RENTERS: 'RENTERS'
};

export const CheckoutStatus = {
  Initial: 'Initial',
  Purchasing: 'Purchasing',
  PurchaseFailed: 'PurchaseFailed',
  DownloadingQuote: 'DownloadingQuote',
  DownloadingOffer: 'DownloadingOffer',
  FetchingPolicyFromOffer: 'FetchingPolicyFromOffer',
  FetchSuccessful: 'FetchSuccessful',
  FetchFailed: 'FetchFailed',
  MVRRetry: 'MVRRetry',
  MVRRejected: 'MVRRejected',
  MVRDriversChanged: 'MVRDriversChanged'
};

export const MVRErrors = {
  MVR_RETRY: 'MVRRetry',
  MVR_REJECTED: 'MVRRejected',
  MVR_DRIVERS_CHANGED: 'MVRDriversChanged'
};

export const FormAction = {
  Update: 'Update',
  Checkout: 'Checkout',
  UpdateEffectiveDates: 'UpdateEffectiveDates',
  PreBindUWValidation: 'PreBindUWValidation'
};

const FETCH_POLICY_ATTEMPTS = 30;

const Error = types.model({
  code: types.union(types.string, types.number),
  message: types.maybeNull(types.string),
  data: types.maybeNull(types.frozen())
});

const Driver = types
  .model({
    id: types.identifier,
    isPrimary: types.boolean,
    isCoApplicant: types.boolean,
    isSwappable: types.boolean,
    hasUDR: types.boolean,
    willRecheckDrivingRecord: false,
    excludeDriverDisabled: types.maybeNull(types.boolean)
  })
  .actions((self) => ({
    toggleCoApplicantStatus() {
      self.isCoApplicant = !self.isCoApplicant;
    },
    toggleWillRecheckDrivingRecord() {
      self.willRecheckDrivingRecord = !self.willRecheckDrivingRecord;
    }
  }));

const cleanInput = (input) => {
  return mapValues(omit(input, ['__typename']), (val, key) => {
    if (isPlainObject(val)) {
      return cleanInput(omit(val, ['__typename']));
    }
    return val;
  });
};

const mapBindRequestToInput = (bindRequest) => {
  return cleanInput(bindRequest);
};

const OfferStore = types
  .model({
    currentTab: types.optional(types.enumeration('OfferPageTabs', Object.values(OfferPageTabs)), OfferPageTabs.PEOPLE),
    errors: types.array(Error),
    loading: types.boolean,
    addingReferral: types.boolean,
    offer: types.maybeNull(types.frozen()),
    openDialog: types.boolean,
    drivers: types.map(Driver),
    mortgageOptions: types.maybeNull(types.frozen()),
    selectedOption: types.maybeNull(types.string),
    status: types.string,
    purchasedAccountId: types.maybeNull(types.string),
    formAction: types.string,
    includeConnectedHome: types.boolean,
    includeInventory: types.boolean,
    includePaperless: types.boolean,
    notifications: types.maybeNull(types.array(types.string)),
    vinDiscrepancies: types.maybeNull(types.array(types.frozen())),
    carAndDriverSuggestions: types.maybeNull(types.frozen()),
    checkoutFormData: types.maybeNull(types.frozen()),
    savingCheckoutFormData: types.boolean,
    showConectedHomeModal: types.boolean,
    showPreBindUWForm: types.boolean,
    priorQuoteWithPreBindUWRejections: types.model({
      checkedDBForThisUser: false,
      ineligibleForHome: false,
      ineligibleForAuto: false
    }),
    areRejectCodesCleared: types.boolean
  })
  .views((self) => ({
    get shouldShowAutoTab() {
      return self?.selectedOption?.includes('A');
    },

    get shouldShowRentersTab() {
      return self?.selectedOption?.includes('AR');
    },

    get shouldShowHomeTab() {
      return self?.selectedOption?.includes('H');
    },
    get shouldShowPIPTab() {
      return self?.selectedOption?.includes('A') && self.state === 'MI';
    },
    get shouldShowAdditionalPaymentsSection() {
      /* right now, the only additional price that can be added is for Abode Pro Plan, noted by the abode affinity code */
      const affinity = self.offer?.quote?.global?.affinity;
      const connectedHomeEnabled = self.offer?.quote?.connectedHome && self.offer?.quote?.connectedHome?.providerName;
      const providerIsAbodeProPlan = self.offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME;
      return affinity === ABODE_PRO_PLAN_AFFINITY && connectedHomeEnabled && providerIsAbodeProPlan;
    },
    get state() {
      return self.offer?.quote.correctedAddress.state;
    },
    get timezone() {
      return stateToTzMap[self.state];
    },
    get isBixOfferOrState() {
      return self.offer.quote.isBix || bixStates[self.state];
    },
    get isBix() {
      return self.offer?.quote?.isBix;
    },
    get showLeaseLoan() {
      return self.isBix ? leaseLoanStates[self.state]?.isBix : leaseLoanStates[self.state]?.isNotBix;
    },
    get isAdvancedConnectedHome() {
      return self.isBix
        ? advancedConnectedHomeStates[self.state]?.isBix
        : advancedConnectedHomeStates[self.state]?.isNotBix;
    },
    get allowInventoryDiscount() {
      return self.isBix
        ? discountInventoryScoreStates[self.state]?.isBix
        : discountInventoryScoreStates[self.state]?.isNotBix;
    },
    get allowPaperlessDiscount() {
      return self.isBix ? discountPaperlessStates[self.state]?.isBix : discountPaperlessStates[self.state]?.isNotBix;
    },
    get coApplicant() {
      return values(self.drivers).find((driver) => driver.isCoApplicant);
    },
    get excludeDriverDisabled() {
      return values(self.drivers).find((driver) => driver.excludeDriverDisabled);
    },
    isCoApplicantButtonDisabled(driverID, formValues) {
      /*
      if the driver is in the form values but not in the store,
      then the offer hasn't been updated to add the driver yet.
      updating needs to happen before swap is enabled
      */
      const driverInStore = self.offer?.quote.drivers.find((driver) => driver.id === driverID);
      const driverInFormValues = formValues?.drivers.find((driver) => driver.id === driverID);
      if (driverInFormValues && !driverInStore) {
        return true;
      }
      return false;
    },
    get defaultSelectedOption() {
      return self.offer?.options.find(({ name }) => name === 'Bundle')?.type || self.offer?.options[0]?.type;
    },
    get isStale() {
      if (self.offer) {
        const rateControlDate = toDate(parse(self.offer?.quote.global.rateControlDate, 'yyyy-MM-dd', new Date()), {
          timeZone: stateToTzMap[self.state]
        });
        const today = toDate(new Date(), { timeZone: stateToTzMap[self.state] });
        return !isSameDay(rateControlDate, today);
      }
      return false;
    },
    get triggerDrivingRecordRecheck() {
      return values(self.drivers).some((driver) => driver.willRecheckDrivingRecord);
    },
    get driverIdsToRecheck() {
      return values(self.drivers)
        .filter((driver) => driver.willRecheckDrivingRecord)
        .map((driver) => driver.id);
    },
    get hasOneOrMoreUDRs() {
      return values(self.drivers).some((driver) => driver.hasUDR);
    }
  }))
  .actions((self) => ({
    setFormAction({ dirty, stale, fromHomeVerification = false }) {
      if (fromHomeVerification) {
        self.formAction = FormAction.PreBindUWValidation;
        return;
      }

      if (stale) {
        self.formAction = FormAction.UpdateEffectiveDates;
      } else if (dirty) {
        self.formAction = FormAction.Update;
      } else {
        self.formAction = FormAction.Checkout;
      }
    },
    setIncludeConnectedHome(include) {
      self.includeConnectedHome = include;
    },
    setIncludeInventory(include) {
      self.includeInventory = !!include;
    },
    setIncludePaperless(include) {
      self.includePaperless = !!include;
    },
    setDrivers(drivers) {
      self.drivers.clear();
      drivers.forEach((driver) => {
        const { id, isPrimary, isCoApplicant, excludeDriver: excludeDriverDisabled } = driver;
        const isSwappable = isPrimary || isCoApplicant;
        const hasUDR = driver.autoViolations?.UDR >= 1;
        self.drivers.set(id, { id, isPrimary, isCoApplicant, isSwappable, hasUDR, excludeDriverDisabled });
      });
    },
    getOffer: flow(function* getOffer(offerId) {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const response = yield client.query({
          query: GET_OFFER,
          variables: { offerId },
          fetchPolicy: 'no-cache'
        });
        if (response.data) {
          self.errors = [];
          const { offer } = response.data;
          const selectedOption =
            offer.quote.selectedOption ||
            offer.options
              .map((option) => option.type)
              .sort((a, b) => {
                if (a === 'HA') {
                  return -1;
                }

                if (b === 'HA') {
                  return 1;
                }

                if (a === 'AR') {
                  return -1;
                }

                if (b === 'AR') {
                  return 1;
                }

                return 0;
              })[0];
          self.setSelectedOption(selectedOption);
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            self.rejectOffer(offer);
          } else {
            self.offer = offer;
            self.checkForPriorQuoteWithPreBindUWRejections();
            self.errors = [];
            self.includeConnectedHome = !emptyConnectedHome(offer?.quote?.connectedHome);
            self.includeInventory = !!offer?.quote?.global?.discountInventoryScore;
            self.includePaperless = !!offer?.quote?.global?.discountPaperless;
            self.setDrivers(offer.quote.drivers);
            self.getDriverAndCarSuggestions(self.offer.id);
            self.setNotifications();
            self.setShowPreBindUWForm();
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    handleError(error) {
      const parsed = formattedError(error);
      if (parsed) {
        self.loading = false;
        const errors = [...self.errors, parsed];
        self.errors = errors;
        self.triggerOfferDialog(true);
      }
      return parsed;
    },
    recalculateQuote: flow(function* recalculateQuote(offerId, details, history) {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const response = yield withRetry(
          client.query({
            query: RECALCULATE_QUOTE,
            variables: {
              offerId,
              revisedQuoteDetails: mapRevisedQuoteDetails(details)
            },
            fetchPolicy: 'no-cache'
          }),
          'recalculateQuote'
        );
        if (response.data) {
          self.errors = [];
          const { offer } = response.data;
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            const { redirect } = self.rejectOffer(offer);
            if (redirect) {
              history.push(`/offer/${offer.id}`);
            }
          } else {
            self.offer = offer;
            self.setNotifications();
            self.setShowPreBindUWForm();
            history.push(`/offer/${offer.id}`);
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      }
      self.loading = false;
    }),
    addDriversAddCarsAndRecalculateCluster: flow(function* addDriversAddCarsAndRecalculateCluster({
      newDrivers,
      newVins,
      newTrailerVins,
      revisedQuoteDetails,
      offerId,
      history
    }) {
      const { client } = getEnv(self);
      self.loading = true;

      try {
        const response = yield withRetry(
          client.mutate(
            {
              mutation: ADD_DRIVERS_CARS_AND_RECALCULATE,
              variables: {
                offerId,
                newDrivers,
                newVins,
                newTrailerVins,
                revisedQuoteDetails
              }
            },
            'addDriversAddCarsAndRecalculateCluster'
          )
        );

        if (response.data) {
          self.errors = [];
          const { cluster } = response.data;
          const offer =
            cluster.offers.find(({ code }) => code === 'C') || cluster.offers.find(({ code }) => code === 'S'); // try C or fallback to S
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            self.rejectOffer(offer);
            history.push(`/offer/${offer.id}`);
          } else {
            self.offer = offer;
            self.setDrivers(offer.quote.drivers);
            self.setNotifications();
            self.setVINDiscrepancies(newVins);
            history.push(`/offer/${offer.id}`);
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
        // Reset record recheck
      }
    }),
    recalculateQuoteInCluster: flow(function* recalculateQuoteInCluster(clusterId, offerId, details, history) {
      const { client } = getEnv(self);
      self.loading = true;

      try {
        const response = yield withRetry(
          client.query({
            query: RECALCULATE_QUOTE_IN_CLUSTER,
            variables: {
              clusterId,
              offerId,
              revisedQuoteDetails: mapRevisedQuoteDetails(details)
            },
            fetchPolicy: 'no-cache'
          }),
          'recalculateQuoteInCluster'
        );

        if (response.data) {
          self.errors = [];
          const { cluster } = response.data;
          const offer =
            cluster.offers.find(({ code }) => code === 'C') || cluster.offers.find(({ code }) => code === 'S'); // try C or fallback to S
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            const { redirect } = self.rejectOffer(offer);
            if (redirect) {
              history.push(`/offer/${offer.id}`);
            }
          } else {
            self.offer = offer;
            self.setNotifications();
            self.setShowPreBindUWForm();
            history.push(`/offer/${offer.id}`);
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    recalculateQuoteToCluster: flow(function* recalculateQuoteToCluster(offerId, details, history) {
      const { client } = getEnv(self);
      self.loading = true;

      try {
        const response = yield withRetry(
          client.query({
            query: RECALCULATE_QUOTE_TO_CLUSTER,
            variables: {
              offerId,
              revisedQuoteDetails: mapRevisedQuoteDetails(details)
            },
            fetchPolicy: 'no-cache'
          }),
          'recalculateQuoteToCluster'
        );

        if (response.data) {
          self.errors = [];
          const { cluster } = response.data;
          const offer =
            cluster.offers.find(({ code }) => code === 'C') || cluster.offers.find(({ code }) => code === 'S'); // try C or fallback to S
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            const { redirect } = self.rejectOffer(offer);
            if (redirect) {
              history.push(`/offer/${offer.id}`);
            }
          } else {
            self.offer = offer;
            if (self.formAction === FormAction.PreBindUWValidation) {
              self.addToPreBindUWRejections(offer); // check if offer should be added to UW rejections table
            }
            self.setDrivers(offer.quote.drivers);
            self.setNotifications();
            self.setShowPreBindUWForm();
            history.push(`/offer/${offer.id}?option=${details.selectedOption}`);
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    rejectOffer(offer) {
      const { offerings } = offer.quote;
      const { autoRejectCode, homeownersRejectCode, monolineAutoRejectCode } = offerings;
      const filtered = [autoRejectCode, homeownersRejectCode, monolineAutoRejectCode].filter((err) => !!err);
      self.loading = false;
      self.errors = filtered.map((code) => ({ code }));
      self.offer = offer;
      return { redirect: true };
    },
    setCurrentTab(newTab) {
      self.currentTab = newTab;
    },
    triggerOfferDialog(open = true) {
      self.openDialog = open;
    },
    setSelectedOption(option) {
      self.selectedOption = option;
    },
    bindRequest: flow(function* bindRequest(bindRequestInput) {
      const { client } = getEnv(self);
      self.errors = [];
      self.status = CheckoutStatus.Purchasing;
      const cleanedInput = mapBindRequestToInput(bindRequestInput);
      self.triggerOfferDialog(true);

      try {
        const response = yield client.query({
          query: REQUEST_BIND,
          variables: { request: cleanedInput }
        });
        if (response.data) {
          const { id, systemId } = response.data.requestBind;
          return { id, systemId };
        }

        if (response.errors) {
          self.handleError(response.errors);
        }
      } catch (error) {
        self.triggerOfferDialog(false);
        const parsedError = self.handleError(error);

        self.status = MVRErrors[parsedError.code] || CheckoutStatus.PurchaseFailed;
        return { id: undefined, systemId: undefined };
      }
      self.checkoutFormData = null;
      self.status = CheckoutStatus.Initial;
      self.triggerOfferDialog(false);
    }),
    downloadQuote: flow(function* downloadQuote({ bypassCache }) {
      const { client } = getEnv(self);
      self.errors = [];
      self.status = CheckoutStatus.DownloadingQuote;

      let formattedPhone = null;
      if (self.offer?.quote?.phone) {
        formattedPhone = phoneNumberFormatter({
          phoneNumber: self.offer.quote.phone.toString()
        });
      }

      const selectedOption = getQuoteFriendlySelectedOption(self.selectedOption);

      const quotePDFInput = {
        offerId: self.offer.id,
        optionId: self.offer.options.find((o) => o.type === selectedOption).id,
        bypassCache,
        bindDetails: {
          email: self.offer.quote.email,
          phoneNumbers: [{ number: formattedPhone }],
          mailingAddress: self.offer.quote.correctedAddress
        }
      };

      try {
        const response = yield client.query({
          query: DOWNLOAD_QUOTE,
          variables: { details: cleanInput(quotePDFInput) }
        });
        window.open(response.data.quotePdf, '_blank');
      } catch (error) {
        self.handleError(error);
      }

      self.status = CheckoutStatus.Initial;
    }),
    downloadOffer: flow(function* downloadOffer({ offerId, clusterId, code, policyType, bypassCache }) {
      const { protocol } = window.location;
      const { client } = getEnv(self);

      self.errors = [];
      self.status = CheckoutStatus.DownloadingOffer;

      const getClusterId = async (offerId, revisedQuoteDetails) => {
        const response = await client.query({
          query: RECALCULATE_QUOTE_TO_CLUSTER,
          variables: {
            offerId,
            revisedQuoteDetails
          },
          fetchPolicy: 'no-cache'
        });
        if (response?.data) {
          return response.data.cluster.id;
        }
      };

      let fetchedClusterId;
      try {
        if (!clusterId) {
          fetchedClusterId = yield getClusterId(offerId, {});
        }
      } catch {
        fetchedClusterId = null;
      }

      // have to give public url for docraptor to work properly so replace with dev environment url if localhost
      const host =
        awsExports.stackeryEnvironmentName === 'production'
          ? 'ourbranch.com'
          : `${awsExports.stackeryEnvironmentName}.ourbranch.dev`;

      try {
        const response = yield client.query({
          query: DOWNLOAD_OFFER,
          variables: {
            documentUrl: `${protocol}//${host}/review?cid=${
              clusterId || fetchedClusterId
            }&policyType=${policyType}&internal=true`,
            documentId: `offer_pdf_${offerId}`,
            bypassCache
          }
        });

        window.open(response.data.offerPdf, '_blank');
      } catch (error) {
        self.handleError(error);
      }

      self.status = CheckoutStatus.Initial;
    }),
    addReferral: flow(function* addReferral({ quoterEmail, ...referralDetails }) {
      const { client } = getEnv(self);
      self.addingReferral = true;
      try {
        const response = yield client.mutate({
          mutation: ADD_REFERRAL,
          variables: { referralDetails }
        });
        if (response.data.friendBuyLink) {
          Cookies.set(
            'offerData',
            {
              offerId: self.offer.id,
              option: self.selectedOption,
              email: quoterEmail
            },
            { expires: 24 } // expires within a day
          );
          window.open(response.data.friendBuyLink, '_self');
        } else {
          self.handleError({ message: 'Failed to generate referral link' });
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.addingReferral = false;
      }
    }),
    pollFetchPolicyFromOffer: flow(function* pollFetchPolicyFromOffer(offerId) {
      // waiting on brtoa to finish executing, usually takes around 2s
      const { client } = getEnv(self);
      self.errors = [];
      self.status = CheckoutStatus.FetchingPolicyFromOffer;

      try {
        const { data } = yield pollPolicyTable(client, offerId, {
          maxRetries: FETCH_POLICY_ATTEMPTS
        });
        if (data?.accountId) {
          self.status = CheckoutStatus.FetchSuccessful;
          self.purchasedAccountId = data.accountId;
          return data.accountId;
        }
      } catch (error) {
        self.status = CheckoutStatus.FetchFailed;
      }
    }),
    getDriverAndCarSuggestions: flow(function* getCarAndDriverSuggestions(offerId) {
      try {
        const { client } = getEnv(self);

        const { data } = yield client.query({
          query: GET_CAR_DRIVER_SUGGESTIONS,
          variables: {
            offerId
          }
        });
        if (data?.driverSuggestions || data?.carSuggestions) {
          self.carAndDriverSuggestions = data;
        }
      } catch (error) {
        self.handleError(error);
      }
    }),
    setNotifications: function setNotifications() {
      const notifications = [];
      const affinity = self.offer?.quote?.global?.affinity;
      const connectedHomeEnabled = self.offer?.quote?.connectedHome && self.offer?.quote?.connectedHome?.providerName;
      const providerIsAbodeProPlan = self.offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME;
      // abode pro plan
      if (affinity === ABODE_PRO_PLAN_AFFINITY && connectedHomeEnabled && providerIsAbodeProPlan) {
        notifications.push(Notification.Offer.AbodeProPlan);
      }

      // connected home partner, but connected home not on
      if (affinity && affinityGroups[affinity]?.homeSecurity && !connectedHomeEnabled) {
        notifications.push(Notification.Offer.ConnectedHome);
      }

      // signing up for home security
      const newConnectedHomeSignUpCustomer =
        self.offer?.quote?.global?.homeSecurityPartnerCustomerType === NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE;
      if (connectedHomeEnabled && newConnectedHomeSignUpCustomer) {
        notifications.push(Notification.Offer.NewConnectedHomeSignUpCustomer);
      }

      if (
        self.priorQuoteWithPreBindUWRejections?.ineligibleForHome === false &&
        get(self.offer, 'quote.global.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport', false) &&
        get(self.offer, 'quote.global.preBindUWCheck.homeVerification.signedPropertyUWAttestation') === false
      ) {
        notifications.push(Notification.Offer.OfferNeedsUWReviewForHome);
      }

      if (self.priorQuoteWithPreBindUWRejections?.ineligibleForHome) {
        // prior quote with self-reported UW home rejections found
        notifications.push(Notification.Offer.PriorQuoteWithSelfReportedUWFlags);
        self.setSelectedOption(policyType.Auto);
      }

      if (notifications.length) {
        self.notifications = notifications;
      } else {
        self.notifications = null;
      }
    },
    setShowPreBindUWForm: function setShowPreBindUWForm(setFormManually = false) {
      // for now, don't show the UW pre check form, but leave this logic in case we need to come back to it, as per conversation with Joe and PMs
      return false;

      // eslint-disable-next-line no-unreachable
      if (setFormManually) {
        self.showPreBindUWForm = true;
        return;
      }
      if (self.offer?.quote?.global?.preBindUWCheck?.homeVerification) {
        const { signedPropertyUWAttestation, ineligibleForHomeDueToUWSelfReport } =
          self.offer?.quote?.global?.preBindUWCheck?.homeVerification;
        // if signed or ineligible is true, don't show form again, else show form

        if (signedPropertyUWAttestation || ineligibleForHomeDueToUWSelfReport) {
          // if agent ineligible for home due to UW self report verification, set option to auto
          if (ineligibleForHomeDueToUWSelfReport || self.priorQuoteWithPreBindUWRejections?.ineligibleForHome) {
            self.setSelectedOption(policyType.Auto);
          }

          self.showPreBindUWForm = signedPropertyUWAttestation
            ? !signedPropertyUWAttestation
            : !ineligibleForHomeDueToUWSelfReport;
        }
      } else {
        self.showPreBindUWForm = true;
      }
    },
    setVINDiscrepancies: function setVINDiscrepancies(newVins) {
      let vinDiscrepancies = [];

      let carsAfterUpdatingOffer = [];
      carsAfterUpdatingOffer = self?.offer?.quote?.cars;

      // filter the newVins array to remove successfully added vins
      vinDiscrepancies = newVins.filter((newCar) => {
        let i;
        let vinSuccessful = false;
        // loop through carsAfterUpdatingOffer array to see if each newVin object's VIN exists
        // in carsAfterUpdatingOffer
        // if the VIN does exist, do not add that car to vinDiscrepancies
        for (i = 0; i < carsAfterUpdatingOffer.length; i += 1) {
          if (newCar.VIN === carsAfterUpdatingOffer[i].VIN) {
            vinSuccessful = true;
          }
        }
        return !vinSuccessful;
      });

      // if vinDiscrepancies contains objects, map to the store
      if (vinDiscrepancies.length) {
        const discrepancyError = {
          code: 5009
        };

        const errors = [...self.errors, discrepancyError];

        self.errors = errors;
        self.vinDiscrepancies = vinDiscrepancies;
        self.triggerOfferDialog(true);
      } else {
        self.vinDiscrepancies = null;
      }
    },

    clearVinDiscrepancies: function clearVinDiscrepancies() {
      self.vinDiscrepancies = [];
    },

    recheckDrivingRecord() {
      self.triggerDrivingRecordRecheck = true;
    },

    saveCheckoutFormData(values) {
      if (values?.id && isEqual(values?.id, self.checkoutFormData?.id)) {
        self.checkoutFormData = { ...self.checkoutFormData, ...values };
      } else {
        self.checkoutFormData = { ...values };
      }
    },

    setSavingCheckoutData(saving) {
      // used for async saving, like getting credit card stripe token
      self.savingCheckoutFormData = saving;
    },
    setShowConnectedHomeModal(open) {
      self.showConectedHomeModal = open;
    },
    addToPreBindUWRejections: flow(function* addToPreBindUWRejections(offer) {
      return;
      // for now, don't add to the table, since we are not rendering the form as per conversation with Joe and PMs
      // eslint-disable-next-line no-unreachable
      const { client } = getEnv(self);
      if (
        get(offer, 'quote.global.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport', false) &&
        get(offer, 'quote.global.preBindUWCheck.homeVerification.signedPropertyUWAttestation', false) &&
        awsExports.stackeryEnvironmentName !== 'production'
      ) {
        // if agent signed and ineligible, store the webUserId in dynamo
        try {
          const response = yield withRetry(
            client.mutate(
              {
                mutation: ADD_TO_PRE_BIND_UW_REJECTIONS,
                variables: {
                  webUserId: offer.webUserId,
                  ineligibleForHome: true,
                  ineligibleForAuto: false
                }
              },
              'addToPreBindUWRejections'
            )
          );
          if (response.data) {
            self.setPriorQuoteWithPreBindUWRejectionsData(response.data?.addToPreBindUWRejections);
          }
        } catch (error) {
          self.handleError(error);
        } finally {
          self.loading = false;
        }
      }
    }),
    clearRejectCodes: flow(function* clearRejectCodes() {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const response = yield client.query({
          query: CLEAR_REJECT_CODES,
          variables: { offerId: self.offer.id, invokedFromStaff: true }
        });
        if (response.data) {
          self.offer = response.data.offer;
          self.errors = [];
          self.areRejectCodesCleared = true;
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    getIsLicensedForState(session) {
      /*
      FormFields have this logic already built in via the permissions prop
      Therefore, only use this function if wanting to use this logic for items that are NOT form fields
      */
      const state = self.offer?.quote.correctedAddress.state;
      return session.allowedStates.includes(state);
    },
    setPriorQuoteWithPreBindUWRejectionsData(data) {
      if (data === null) {
        self.priorQuoteWithPreBindUWRejections = {
          checkedDBForThisUser: true,
          ineligibleForHome: false,
          ineligibleForAuto: false
        };
      } else {
        self.priorQuoteWithPreBindUWRejections = {
          checkedDBForThisUser: true,
          ineligibleForHome: data.ineligibleForHome,
          ineligibleForAuto: data.ineligibleForAuto
        };
      }
    },
    checkForPriorQuoteWithPreBindUWRejections: flow(function* checkForPriorQuoteWithPreBindUWRejections() {
      return;
      // for now, don't check for entries in the table, since we are not rendering the UW pre check form at this time as per conversation with Joe and PMs
      // eslint-disable-next-line no-unreachable
      const { client } = getEnv(self);
      self.loading = true;
      if (!self.priorQuoteWithPreBindUWRejections.checkedDBForThisUser) {
        try {
          const response = yield withRetry(
            client.query(
              {
                query: GET_PRE_BIND_UW_REJECTIONS_BY_WEB_USER_ID,
                variables: {
                  webUserId: self.offer.webUserId
                }
              },
              'getPreBindUWRejectionsByWebUserId'
            )
          );

          if (response.data?.getPreBindUWRejectionsByWebUserId) {
            self.setPriorQuoteWithPreBindUWRejectionsData(response.data?.getPreBindUWRejectionsByWebUserId);
          }
        } catch (error) {
          self.handleError(error);
        } finally {
          self.loading = false;
        }
      }
    })
  }));

export default OfferStore;
