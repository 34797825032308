import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'core';
import { FormField } from 'core/components/form';
import { numberFormatter } from 'core/helpers/formatters';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { CardLine } from '../../card-line';
import useStyles from './policy-type-option.styles';

const money = (value) => `$${numberFormatter(value, 2)}`;

const PriceBreakdown = ({ price, frequency, isDisabled }) => {
  const classes = useStyles();
  return (
    <div className={classes.price}>
      <Label type="toast" className={classNames({ [classes.disabled]: isDisabled })}>
        {' '}
        {money(price)}{' '}
      </Label>
      <Label className={classNames({ [classes.disabled]: isDisabled }, classes.priceNote)} type="noteSmall">
        {frequency}
      </Label>
    </div>
  );
};

PriceBreakdown.propTypes = {
  price: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const PolicyTypeOption = ({
  option,
  type,
  linkedToExistingCustomer,
  label,
  fieldOptions,
  onClick,
  noAutoWBundle,
  noAuto,
  noHome
}) => {
  const classes = useStyles();

  const hasHome = option.homePrice && option.homeFrequency && !noHome;
  const hasAuto = option.autoPrice && option.autoFrequency;
  const disableIfBundleAndNotLinked = (type === 'HB' || type === 'AB') && !linkedToExistingCustomer;

  const selectedOptionDisabled = noAutoWBundle || noAuto || disableIfBundleAndNotLinked || noHome;

  return (
    <CardLine>
      <div className={classes.section}>
        <FormField
          name="selectedOption"
          label={label}
          onClick={() => onClick(type, selectedOptionDisabled)}
          options={fieldOptions}
          type="radio"
          mode="dark"
          disabled={selectedOptionDisabled}
        />
      </div>
      <div className={classes.section}>
        {hasHome && (
          <PriceBreakdown
            price={option.homePrice}
            frequency={option.homeFrequency}
            isDisabled={noAutoWBundle || noAuto || disableIfBundleAndNotLinked}
          />
        )}
        {hasHome && hasAuto && (
          <Label className={classes.plus} type="toast">
            +
          </Label>
        )}
        {hasAuto &&
          ((type === 'A' && noAuto && (
            // not eligible for monoline auto
            <LabelTooltip tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.noMonolineAuto }} />
          )) ||
            (type === 'AB' && noAutoWBundle && (
              // not elligible for auto w/bundle option
              <LabelTooltip tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.noMonolineAutoWBundle }} />
            )) ||
            (!noAuto && !noAutoWBundle && !noHome && (
              <PriceBreakdown
                price={option.autoPrice}
                frequency={option.autoFrequency}
                isDisabled={noAutoWBundle || noAuto || disableIfBundleAndNotLinked}
              />
            )))}
      </div>
    </CardLine>
  );
};

PolicyTypeOption.propTypes = {
  option: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fieldOptions: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  linkedToExistingCustomer: PropTypes.bool.isRequired
};

export default PolicyTypeOption;
