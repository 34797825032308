import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.colorPalette.green_20
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 6)
  },
  right: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  iconButtonRoot: {
    color: theme.colors.button__background_secondary,
    padding: 0
  },
  iconButtonLabel: {
    height: 32,
    width: 32
  },
  icon: {
    height: 32,
    transition: 'transform 200ms'
  },
  collapse: {
    transform: 'rotate(180deg)'
  }
});

export default makeStyles(styles);
