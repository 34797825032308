import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { useErrors } from 'quote/helpers/error-hooks';
import ErrorDetail from './error-detail';
import useStyles from './error.styles';

const PolicyErrors = observer(() => {
  const router = useHistory();
  const classes = useStyles();
  const { offer: store, quote } = useStore();

  const getErrorsAndRejections = (code) => code >= 4003;
  const errorsAndRejections = useErrors(store.errors.toJS(), getErrorsAndRejections);

  const onClick = () => {
    quote.setPrefillData(store.offer.quote);
    router.push('/quote');
  };

  return (
    <>
      <Label type="darkGreenMedium" className={classes.errorsLabel} component="div">
        Policy Errors
      </Label>
      <Card className={classes.errorCard} type="primaryEditable">
        <Grid container alignItems="stretch" alignContent="space-between" justify="center">
          {errorsAndRejections.map((error) => (
            <ErrorDetail key={`${store.offer.id}-${error}`} error={error} state={store.state} />
          ))}
          <Button className={classes.requoteButton} variant="contained" color="primary" onClick={onClick}>
            <Label className={classes.btnLabel}>Requote to Solve</Label>
          </Button>
        </Grid>
      </Card>
    </>
  );
});

export default PolicyErrors;
