import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';
import ConnectedHome from '../connected-home';
import { useHomeSecurityPartnerEffects, useNewMonitoringServiceEffects } from '../connected-home/helpers/hooks';

/* 
Read about the business logic behind Connected Home here:
https://www.notion.so/branch/All-About-Affinity-and-Connected-Home-Discounts-c4f5abad218049e0a37991cd60157f41 
*/

const ConnectedHomeSettings = ({ isAdvancedConnectedHome, comingFromModal = false }) => {
  const { setFieldValue } = useFormikContext();

  /*
  if there's a home security affinity partner,
  then the following effects should happen:
  1. connectedHome.monitored must be VP or C depending on if it's 
  advanced connected home
  2. providerName must be the same as the affinity
  3. these cannot be changed, so fields should be disabled
   */
  useHomeSecurityPartnerEffects({
    isAdvancedConnectedHome
  });

  /*
  if signing up for new monitoring service, then 
  get the default count of devices given for the new service
  and fill those device counts into the form fields
  */
  useNewMonitoringServiceEffects();

  const setHomeSecurityPartnerCustomerType = (e) => {
    setFieldValue(
      'global.homeSecurityPartnerCustomerType',
      e.target.value === 'new' ? NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE : NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE
    );
    setFieldValue('connectedHome.homeSecurityPartnerCustomerTypeOption', e.target.value);
  };

  return (
    <ConnectedHome
      setHomeSecurityPartnerCustomerType={setHomeSecurityPartnerCustomerType}
      isAdvancedConnectedHome={isAdvancedConnectedHome}
      comingFromModal={comingFromModal}
    />
  );
};

ConnectedHomeSettings.propTypes = {
  isAdvancedConnectedHome: PropTypes.bool
};

ConnectedHomeSettings.defaultProps = {
  isAdvancedConnectedHome: false
};

export default ConnectedHomeSettings;
