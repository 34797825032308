import { pick } from 'lodash-es';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { branchLiveStates } from '@ourbranch/lookups';

import config from '../../aws-exports';

const Core = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
const credentials = new Core.SharedCredentials(config.smartyStreetsKey);

export default {
  client: Core.buildClient.usAutocompletePro(credentials),

  async search(value, allowedStates = []) {
    const lookup = new Lookup(value);
    lookup.maxResults = 5;
    lookup.includeOnlyStates = allowedStates;
    lookup.prefer = branchLiveStates;
    lookup.geolocate = true;
    lookup.geolocatePrecision = 'CITY';

    const { result: results } = await this.client.send(lookup);

    return results.map((result) => pick(result, ['city', 'entries', 'secondary', 'state', 'streetLine', 'zipcode']));
  }
};
