/* eslint-disable camelcase */
import Business from './business.svg';
import Camera from './camera.svg';
import Coin from './coins.svg';
import Sports_Deep_Sea from './deep-sea.svg';
import Fine_Arts from './fine-art.svg';
import Furs from './fur.svg';
import Jewelry from './jewelry.svg';
import Instrument from './instruments.svg';
import Silverware from './silverware.svg';
import Sports_Fish_Other from './fishing.svg';
import Golf from './golf.svg';
import Guns from './gun.svg';
import Sports_Other from './sports.svg';
import Stamp from './stamp.svg';

export const icons = {
  Business,
  Camera,
  Coin,
  Fine_Arts,
  Furs,
  Jewelry,
  Gemprint_Jewelry: Jewelry,
  Jewelry_Items: Jewelry,
  Musical_NonPro: Instrument,
  Musical_Pro: Instrument,
  Musical_Instrument: Instrument,
  Silverware,
  Sports_Deep_Sea,
  Sports_Fish_Other,
  Golf,
  Sports_USA_Guns: Guns,
  Sports_World_Guns: Guns,
  Sports_Guns: Guns,
  Sports_Other,
  Sports_Fishing: Sports_Fish_Other,
  Sports: Sports_Other,
  Stamp
};
