import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    marginBottom: 10,
    overflow: 'hidden'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    padding: '24px 32px',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  modal: {
    padding: '48px !important'
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'baseline'
  },
  subtitle: {
    composes: '$title',
    marginTop: 16
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8
  },
  viewPolicyButton: {
    display: 'block',
    fontSize: 16,
    fontWeight: 600,
    margin: '40px auto 0',
    padding: '0 24px',
    textAlign: 'center'
  },
  dismissButton: {
    display: 'block',
    fontSize: 16,
    margin: '10px auto 0'
  },
  largeLabel: {
    paddingLeft: 10,
    paddingBottom: 20
  }
}));

export default useStyles;
