import { Auth } from 'aws-amplify';
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator
} from 'aws-amplify-react';
import { AuthContext } from 'core/components/auth';
import debounce from 'lodash-es/debounce';
import React, { useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PolicyRoute, CustomerRoute } from 'customer/components/provider';
import awsConfig from '../../aws-exports';
import { OfferProvider, OfferDetails, OfferHeader, CheckoutSidebar, Checkout, Purchased } from '../../offer';
import LayoutWithSidebar from './layout-with-sidebar';
import PreviewClarionDoorRequestDifference from 'customer/components/policy/preview-clariondoor-request-diff';

import Quote from '../../quote';
import Search from '../../search/search';
import { ReferralRedirect } from '../../referral-redirect';
import ChangePassword from './change-password';
import { ProtectedRoute } from './protected-route';

const Routes = () => {
  const session = useContext(AuthContext);
  // Amplify doesn't have a straightforward way of checking that the refresh token has expired, so we resort to this.
  useEffect(() => {
    async function validateTokenOrLogout() {
      try {
        await Auth.currentSession();
      } catch (err) {
        await Auth.signOut();
      }
    }

    // Check that there's a valid session every 1 minute
    const interval = setInterval(validateTokenOrLogout, 1000 * 60);

    // On clicks, taps, or keypresses, check that there's a valid session -- debounced to 5 5 seconds for performance reasons
    const debouncedValidateTokenOrLogoutFunction = debounce(validateTokenOrLogout, 5000, {
      leading: true,
      trailing: false,
      maxWait: 5000
    });
    const events = ['mousedown', 'touchstart', 'keydown'];
    const eventListeners = events.map((event) =>
      document.addEventListener(event, debouncedValidateTokenOrLogoutFunction, true)
    );

    return () => {
      clearInterval(interval);
      events.forEach((event, idx) => {
        document.removeEventListener(event, eventListeners[idx]);
      });
    };
  }, []);

  if (session.loading) {
    return <></>;
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/search/offers" />
      <ProtectedRoute exact path="/quote" permission="canQuote" component={Quote} />
      <Route path="/search" component={Search} />
      <Route exact path="/account" component={ChangePassword} />
      <ProtectedRoute exact path="/offer/:offerId/purchased" permission="canBind">
        {({
          history,
          match: {
            params: { offerId }
          }
        }) => <Purchased offerId={offerId} history={history} />}
      </ProtectedRoute>
      <ProtectedRoute exact path="/offer/:offerId/:option/checkout" permission="canBind">
        {({
          history,
          match: {
            params: { offerId, option }
          }
        }) => (
          <LayoutWithSidebar
            offerId={offerId}
            header={OfferHeader}
            content={Checkout}
            side={CheckoutSidebar}
            history={history}
            onBack={() => history.push(`/offer/${offerId}`)}
            option={option}
          />
        )}
      </ProtectedRoute>
      <ProtectedRoute exact path="/offer/:offerId/details" permission="isService" component={OfferDetails} />
      <ProtectedRoute path="/offer/:offerId" permission="canQuote">
        {({
          match: {
            params: { offerId }
          }
        }) => <OfferProvider offerId={offerId} />}
      </ProtectedRoute>
      <ProtectedRoute path="/customer/:id/policy/:policyId/:policyPreviewId" permission="canViewClarionDoorData">
        {({
          history,
          match: {
            params: { id, policyId, policyPreviewId }
          }
        }) => (
          <PreviewClarionDoorRequestDifference
            accountId={id}
            policyId={policyId}
            history={history}
            policyPreviewId={policyPreviewId}
          />
        )}
      </ProtectedRoute>
      <Route path="/customer/:id/policy/:policyId">
        {({
          history,
          match: {
            params: { id, policyId }
          }
        }) => {
          if (id !== policyId.split('-')[0]) {
            const correctedId = policyId.split('-')[0];
            return <Redirect to={`/customer/${correctedId}/policy/${policyId}`} />;
          }
          return <PolicyRoute accountId={id} policyId={policyId} history={history} />;
        }}
      </Route>
      <Route path="/customer/:id">
        {({
          history,
          match: {
            params: { id, policyId }
          }
        }) => <CustomerRoute accountId={id} history={history} />}
      </Route>
      <ProtectedRoute path="/staff/referral" component={ReferralRedirect} />
    </Switch>
  );
};

export default withAuthenticator(Routes, false, [
  <SignIn federated={awsConfig.federated} />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <RequireNewPassword />,
  <ConfirmSignUp />,
  <ForgotPassword />
]);
