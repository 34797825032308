import Grid from '@material-ui/core/Grid';
import { lookupsJson } from '@ourbranch/lookups';
import classNames from 'classnames';
import { FormField, FormSubmit } from 'core/components/form';
import { withStore } from 'core/store';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import uuidv4 from 'uuid/v4';
import useStyles from '../add-form.styles';
import { validationSchema } from './add-lien-holders.validation';
import LienHolderAutoComplete from './lien-holder-autocomplete';
import { getCarOptions } from './helper';

function AddLienHoldersForm({
  onAdd,
  disabled,
  store: {
    account: {
      policies: {
        policy: { segment }
      }
    }
  }
}) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        id: uuidv4(),
        name: '',
        loanNumber: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        VIN: ''
      }}
      validationSchema={validationSchema}
      onSubmit={({ id, name, loanNumber, address, address2, city, state, zip, VIN }) =>
        onAdd({
          id,
          name,
          loanNumber,
          address: { address, address2, city, state, zip },
          VIN,
          lossPayee: true
        })
      }
    >
      {({ setValues, values }) => (
        <Form disabled={disabled} className={classes.container}>
          <Grid
            key="container"
            container
            justify="space-around"
            alignItems="flex-start"
            className={classes.formContainer}
            spacing={4}
          >
            <LienHolderAutoComplete xs={8} setValues={setValues} currentValues={values} />
            <FormField
              mode="light"
              name="loanNumber"
              id="loanNumber"
              label="Loan Number (optional)"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="address"
              id="address"
              label="Address"
              type="string"
              xs={8}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="address2"
              id="address2"
              label="Address 2"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="city"
              id="city"
              label="City"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="state"
              id="state"
              label="State"
              type="autocomplete"
              options={lookupsJson.usStates}
              optional
              xs={4}
              stateSelectForm
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="zip"
              id="zip"
              label="Zip code"
              type="string"
              optional
              xs={4}
              permissions={{ isLicensedAction: false }}
            />
            <FormField
              mode="light"
              name="VIN"
              id="VIN"
              label="Select associated Car (optional)"
              type="select"
              options={getCarOptions(segment)}
              optional
              xs={8}
              permissions={{ isLicensedAction: false }}
            />
            <FormSubmit
              mode="big"
              className={classNames(classes.button, classes.addButton)}
              variant="contained"
              color="secondary"
              xs={4}
            >
              Add Lien Holder
            </FormSubmit>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

AddLienHoldersForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  store: PropTypes.object.isRequired
};
AddLienHoldersForm.defaultProps = {
  disabled: false
};

export default withStore(AddLienHoldersForm);
