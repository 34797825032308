import { Paper, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Loading, Table, TableHeaderCell } from 'core';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { withToast } from 'core/components/toast';
import { dateFormatter } from 'core/helpers/formatters';
import flowRight from 'lodash-es/flowRight';
import orderBy from 'lodash-es/orderBy';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import { useStore } from 'core/store';
import styles from './pledges-details.styles';

const Pledges = observer(function Pledges({ classes, accountId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDirection, setSortDirection] = useState('asc');

  const { account } = useStore();
  const { pledges, accountLoading: loading } = account;

  const sortedPledges = useMemo(() => {
    if (pledges) {
      return orderBy(pledges.pledges, ['name'], [sortDirection]);
    }
    return [];
  }, [pledges, sortDirection]);

  const sort = (_columnId, _active, direction) => setSortDirection(direction);

  const handleChangePage = (_event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Section title="Pledges" rightLabel="Total" rightValue={`${loading ? '...' : sortedPledges.length} active pledges`}>
      {loading ? (
        <Loading />
      ) : (
        <Card className={classes.pledgesCard}>
          {sortedPledges.length > 0 ? (
            <Table
              selfContained={false}
              header={
                <>
                  <TableHeaderCell key={'name'} columnId={'name'} mode={null} onSort={sort}>
                    Name
                  </TableHeaderCell>
                  <TableCell align="right">Added date</TableCell>
                </>
              }
              body={
                <>
                  {sortedPledges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                    <TableRow key={index} hover classes={{ hover: classes.hover, root: classes.root }}>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="right">{dateFormatter(item.pledgedTimeStamp)}</TableCell>
                    </TableRow>
                  ))}
                </>
              }
              paginationEnabled={sortedPledges.length > rowsPerPage}
              count={sortedPledges.length}
              rowsPerPage={rowsPerPage}
              currentPage={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            <Paper classes={{ root: classes.noData }}>
              <p align="center">This customer has not received any pledges</p>
            </Paper>
          )}
        </Card>
      )}
    </Section>
  );
});

Pledges.propTypes = {
  classes: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired
};

export default flowRight(withStyles(styles), memo, withToast)(Pledges);
