/* eslint-disable */
import awsConfig from './aws-exports';
import Cookies from 'js-cookie';

const isCrawler = () => /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

function initializeProdPerfect() {
  // Only load prod perfect for environments that are not production
  if (process.env.NODE_ENV === 'production' && awsConfig.trackingEnabled) {
    !(function (name, path, ctx) {
      var latest,
        prev = name !== 'Keen' && window.Keen ? window.Keen : false;
      ctx[name] = ctx[name] || {
        ready: function (fn) {
          var h = document.getElementsByTagName('head')[0],
            s = document.createElement('script'),
            w = window,
            loaded;
          s.onload = s.onreadystatechange = function () {
            if ((s.readyState && !/^c|loade/.test(s.readyState)) || loaded) {
              return;
            }
            s.onload = s.onreadystatechange = null;
            loaded = 1;
            latest = w.Keen;
            if (prev) {
              w.Keen = prev;
            } else {
              try {
                delete w.Keen;
              } catch (e) {
                w.Keen = void 0;
              }
            }
            ctx[name] = latest;
            ctx[name].ready(fn);
          };
          s.async = 1;
          s.src = path;
          h.parentNode.insertBefore(s, h);
        }
      };
    })('ProdPerfectKeen', 'https://branch-staff.trackinglibrary.prodperfect.com/keen-tracking.min.js', window);

    ProdPerfectKeen.ready(function () {
      var client = new ProdPerfectKeen({
        projectId: 'mOxgOUWcCdc5DrVqnBYsLGhb',
        writeKey:
          'PY002M4M2FMYPIVB68NDW21PLTX78J01NL0KNH4VL5UVTO8Y7GM5B9IGS03OV6BOIMIV77AZ6X980J0FNYQ0IBMRXE48VKDYSSBL51KUNNA2BNB7L170WWAP21EI6KIAVHWPHNDU6TDMJ363NEV4KZFCZB0NXQI09FWGJVLLZLSP9B2TX5PMW2OY0LK7TCLO',
        requestType: 'beacon',
        host: 'branch-staff.datapipe.prodperfect.com/v1'
      });

      client.extendEvents({
        visitor: {
          user_id: null
        }
      });

      var options = {
        ignoreDisabledFormFields: false,
        recordClicks: true,
        recordFormSubmits: true,
        recordInputChanges: true,
        recordPageViews: true,
        recordPageUnloads: true,
        recordScrollState: true
      };
      client.initAutoTracking(options);
    });
  }
}

function initializeSegment() {
  if (isCrawler() || !awsConfig.trackingEnabled) {
    return;
  }
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on'
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(awsConfig.segmentKey, {
          integrations: {
            All: true
          }
        });
        const sessionId = Cookies.get('ampSession.id') || Date.now();
        Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 }); //session expires in 30 mins
      }
  })();
}

function initializeLogRocket() {
  if (awsConfig.trackingEnabled) {
    import('logrocket').then(({ default: LogRocket }) => LogRocket.init(awsConfig.logRocketApiKey));
  }
}

function initializeFriendBuy(merchantId) {
  if (process.browser && window) {
    window['friendbuyAPI'] = [];
    friendbuyAPI = window['friendbuyAPI'];

    friendbuyAPI.merchantId = merchantId;
    friendbuyAPI.push(['merchant', friendbuyAPI.merchantId]);
    // load the merchant SDK and your campaigns
    (function (f, r, n, d, b, u, y) {
      while ((u = n.shift())) {
        (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
        b.async = 1;
        b.src = u;
        y.parentNode.insertBefore(b, y);
      }
    })(document, 'script', [
      'https://static.fbot.me/friendbuy.js',
      'https://campaign.fbot.me/' + friendbuyAPI.merchantId + '/campaigns.js'
    ]);
  }
}

export async function initializeFB() {
  if (awsConfig.friendBuyMerchantId) {
    await initializeFriendBuy(awsConfig.friendBuyMerchantId);
  }
}

function initializeFrontChat() {
  window.FrontChat('init', { chatId: '3d698c17b45c1575b862ceb645b4b4bd', useDefaultLauncher: false });
}

export default function configureServices() {
  initializeProdPerfect();
  initializeLogRocket();
  initializeSegment();
  initializeFrontChat();
}
