import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import ToastContext from './context';
import { styles } from './toast.styles';
import { Label } from '../label';

const Toast = memo(({ classes }) => {
  const { type, message, action, label, clean, durationInSeconds = 6 } = useContext(ToastContext);
  if (!type || !message) {
    return null;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open
      autoHideDuration={1000 * durationInSeconds}
      onClose={clean}
      ContentProps={{
        className: type === 'error' ? classes.error : classes.content
      }}
      classes={{ anchorOriginBottomCenter: classes.bottom }}
      message={
        <Label type="toast" className={classes.toastLabel}>
          {type === 'error' && <ErrorOutlineIcon className={classes.icon} />}
          {message}
        </Label>
      }
      ClickAwayListenerProps={{
        mouseEvent: false
      }}
      action={
        action &&
        label && (
          <Button
            disableRipple
            className={classes.button}
            color="secondary"
            size="large"
            onClick={() => {
              clean();
              action();
            }}
          >
            {label}
          </Button>
        )
      }
    />
  );
});

Toast.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Toast);
