import { makeStyles } from '@material-ui/core';

const styles = () => ({
  checkImageHidden: {
    display: 'none'
  },
  checkImageShown: {
    display: 'block',
    width: 72,
    height: 36,
    marginTop: 25,
    marginLeft: 25
  },
  paymentBlock: {
    paddingBottom: 25
  }
});

export default makeStyles(styles);
