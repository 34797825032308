import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperWidthSm: {
    width: '500px'
  },
  modal: {
    padding: '48px !important'
  },
  title: {
    textAlign: 'center'
  },
  subtitle: {
    composes: '$title',
    marginTop: '16px'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px'
  },
  viewPolicyButton: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 600,
    margin: '40px auto 0',
    padding: '0 24px',
    textAlign: 'center'
  },
  dismissButton: {
    display: 'block',
    fontSize: '16px',
    margin: '10px auto 0'
  }
}));

export default useStyles;
