import { errorCodes, rejectCodes } from '@ourbranch/lookups';

import { messages } from './errorMessages';

const errorText = (error, codes) => {
  return Object.keys(codes).reduce((prev, key) => {
    if (prev === '' && codes[key] === Number(error.code)) {
      return key;
    }
    return prev;
  }, '');
};

export const getError = (err) => {
  const errObj = { ...err, message: messages[err?.code]?.message, title: messages[err?.code]?.title };
  if (errObj && errObj.message) {
    const isRejection = (code) => code >= 10000;
    const codes = isRejection ? rejectCodes : errorCodes;
    const isUnknownError = errObj.message.includes('[error_details');
    if (isUnknownError) {
      errObj.message = errObj.message.replace('[error_details]', err.message);
    }
    errObj.code = Number(err.code);
    errObj.fcra = errObj.code >= 10000 && errObj.code < 50000;
    errObj.text = errorText(err, codes);
    errObj.isRejection = isRejection(errObj.code);
  }
  return errObj;
};
