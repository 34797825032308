import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { getOptions } from '@ourbranch/lookups';
import { connect } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import { awsDateToJs } from 'core/helpers/formatters';
import styles from './cancellation-form.styles';

const FlatCancelReasons = { DuplicatePolicy: 'CNDP' };

const CancellationForm = ({ classes, originalEndDate, endDate, effectiveDate, formik }) => {
  const original = awsDateToJs(originalEndDate);
  const originalMaxDate = new Date(new Date(original).setDate(original.getUTCDate() - 1));
  const [maxDate, setMaxDate] = useState(originalMaxDate);
  const minDate = new Date(formik.values.effectiveDate);
  minDate.setDate(new Date(formik.values.effectiveDate).getDate() + 1);

  useEffect(() => {
    if (formik.values.cancelReason === FlatCancelReasons.DuplicatePolicy) {
      // default to flat cancel
      formik.setFieldValue('endDate', effectiveDate);
    } else {
      setMaxDate(originalMaxDate);
    }
    // adding formik alone to the deps causes the page to crash and this effect to run infinitely
    // so i've added explicit dependencies and just ignoring the lint error to add formik here
    // top level Formik for this is in policy-settings-container.js

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveDate, endDate, formik.values.cancelReason]);

  return (
    <Grid container item xs={8}>
      <Grid container key="details" justify="center" className={classes.form}>
        <FormField
          name="endDate"
          type="date"
          label="End Date"
          mode="light"
          disableFuture={false}
          maxDate={maxDate}
          minDate={minDate}
          initialFocusedDate={maxDate}
          className={classes.endDate}
          xs={4}
          ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        />
        <FormField
          name="cancelReason"
          type="select"
          label="Cancel Reason"
          mode="light"
          options={getOptions('cancelReason')}
          xs={5}
          ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        />
        <FormField
          type="checkbox"
          id="applyFee"
          name="applyFee"
          mode="light"
          label="Apply Fee"
          xs={3}
          className={classes.checkbox}
          ignoreGlobalDisabledState // Add fee
        />
      </Grid>
    </Grid>
  );
};

CancellationForm.propTypes = {
  originalEndDate: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  endDate: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  effectiveDate: PropTypes.string.isRequired
};

export default flowRight(withStyles(styles), connect, withDatePicker)(CancellationForm);
