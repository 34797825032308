import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    padding: 0
  },
  notAvailable: {
    color: theme.colorPalette.beige_10_op_60
  }
});

export default makeStyles(styles);
