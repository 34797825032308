import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.card_background_secondary,
    overflow: 'hidden',
    padding: theme.spacing(6, 8)
  }
});

export default makeStyles(styles);
