import { types, flow, getEnv } from 'mobx-state-tree';
import orderBy from 'lodash-es/orderBy';
import { isPast, parseISO } from 'date-fns';
import { fixableRejectionCodes } from '@ourbranch/lookups';

import Notification from 'core/helpers/notifications';
import { RESET_PWD, UPDATE_ACCOUNT } from 'customer/components/account-details/account-details.queries';
import { getMerged } from 'customer/components/policy/merge-segments';
import { clean } from '@ourbranch/policy-utils';
import {
  GET_ACCOUNT,
  GET_FULL_ACCOUNT,
  GET_ACCOUNT_POLICY,
  GET_TICKETS,
  GET_TICKET_DETAIL,
  GET_ACCOUNT_CLAIMS,
  SEND_EVENT_AS_MEMBER
} from './account.queries';
import { PoliciesStore } from './policies-store';

const AdditionalPhoneNumber = types.model({
  phoneNumber: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  canText: types.maybeNull(types.boolean)
});

export const AccountStore = types
  .model({
    id: types.string,
    fname: types.string,
    lname: types.string,
    phoneNumber: types.string,
    additionalPhoneNumbers: types.maybeNull(types.optional(types.array(AdditionalPhoneNumber), [])),
    email: types.string,
    created: types.string,
    mailingAddress: types.maybeNull(types.frozen()),
    accountLoading: types.optional(types.boolean, false),
    ticketsLoading: types.optional(types.boolean, false),
    tickets: types.maybeNull(types.frozen(), null),
    policies: types.maybe(PoliciesStore),
    pledges: types.frozen(),
    community: types.frozen(),
    inviter: types.maybeNull(types.frozen()),
    claims: types.optional(types.array(types.frozen()), [], [null, undefined]),
    claimsLoading: types.optional(types.boolean, false),
    notifications: types.maybeNull(types.array(types.string))
  })
  .actions((self) => ({
    fetchFullAccount: flow(function* fetchFullAcount(id) {
      const { client } = getEnv(self);
      self.accountLoading = true;
      self.policies.loading = true;
      try {
        const { data } = yield client.query({
          query: GET_FULL_ACCOUNT,
          variables: {
            id
          }
        });
        const { account, documents, community, pledges, unsignedApplications, unsignedBixConversions } = data;
        self.setAccount(account);
        self.policies.list = account.policies;
        self.policies.documents = documents;
        self.pledges = pledges;
        self.community = community;
        self.policies.unsignedApplications = unsignedApplications;
        self.policies.unsignedBixConversions = unsignedBixConversions || [];
      } finally {
        self.accountLoading = false;
        self.policies.loading = false;
        self.setNotifications();
      }
    }),
    fetchFullAccountAndPolicy: flow(function* fetchFullAccountAndPolicy(id, accountId) {
      const { client } = getEnv(self);
      self.accountLoading = true;
      self.policies.policy.loading = true;
      self.policies.policy.holdCardLoading = true;
      self.policies.loadingDocuments = true;
      try {
        const { data } = yield client.query({
          query: GET_ACCOUNT_POLICY,
          variables: {
            id,
            accountId
          }
        });
        const {
          account,
          billingDetails,
          writeOffTransactions,
          documents,
          holdcards,
          inspection,
          unsignedBixConversions
        } = data;
        self.policies.list = account.policies;
        self.setAccount(account);
        // fill out child PolicyStore info
        self.policies.policy.billingDetails = billingDetails;
        self.policies.policy.writeOffTransactions = writeOffTransactions;
        self.policies.policy.holdCards = holdcards;
        self.policies.policy.inspection = inspection;
        self.policies.documents = documents;
        const selectedPolicy = account.policies.find((p) => p.id === id);
        if (selectedPolicy?.billingHoldUntil && isPast(parseISO(selectedPolicy.billingHoldUntil))) {
          self.policies.policy.policy = { ...selectedPolicy, billingHoldUntil: null };
        } else {
          self.policies.policy.policy = selectedPolicy;
        }
        const { fees, segments } = selectedPolicy;
        self.policies.policy.fees = fees;
        const mappedSegments = segments.slice().map(clean);
        self.policies.policy.segments = mappedSegments;
        const currentlySelectedSegmentId =
          self.policies.policy?.segment?.segmentId || mappedSegments[mappedSegments.length - 1].segmentId;
        self.policies.policy.segment = getMerged(mappedSegments, currentlySelectedSegmentId);
        self.policies.unsignedBixConversions = unsignedBixConversions || {};
        const bixConversionDocsInfo = self.policies.getUnsignedBixConversion(id);
        if (!bixConversionDocsInfo.signedDocUploadedInS3) {
          // If entered, there are no signed documents in S3, but there could be a delay up to 3 hours from
          // when the person signed to when the signed pdfs are uploaded. so, check if that's the case
          // if so, the below function updates the policy store with the signed timestamp
          yield self.policies.policy.getBixConversationSignatureFromTable(id);
        }
      } finally {
        self.accountLoading = false;
        self.policies.policy.loading = false;
        self.policies.policy.holdCardLoading = true;
        self.policies.loadingDocuments = false;
        self.policies.policy.policyEquityStatus.status = 'done';
        self.policies.setNotifications();
        self.setNotifications();
      }
    }),
    fetchAccount: flow(function* getAccount(id) {
      const { client } = getEnv(self);
      self.accountLoading = true;
      try {
        const { data } = yield client.query({
          query: GET_ACCOUNT,
          variables: {
            id
          }
        });
        const { account } = data;
        self.setAccount(account);
      } finally {
        self.accountLoading = false;
      }
    }),
    fetchTickets: flow(function* getTickets() {
      const { client } = getEnv(self);
      self.ticketsLoading = true;
      const { id: accountId, fname, lname, email, phoneNumber } = self;
      try {
        const { data } = yield client.query({
          query: GET_TICKETS,
          variables: {
            params: {
              accountId,
              fname,
              lname,
              email,
              phoneNumber
            }
          }
        });
        self.tickets = data.tickets;
      } finally {
        self.ticketsLoading = false;
      }
    }),
    fetchTicketDetail: flow(function* getTicketDetail(ticketId) {
      const { client } = getEnv(self);
      self.ticketDetailLoading = true;

      try {
        const { data } = yield client.query({
          query: GET_TICKET_DETAIL,
          variables: {
            ticketId
          }
        });
        return data.ticketDetail;
      } finally {
        self.ticketDetailLoading = false;
      }
    }),
    resetPwd: flow(function* resetPwd(accountId) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: RESET_PWD,
        variables: {
          username: accountId
        }
      });
    }),
    sendEventAsMember: flow(function* sendEventAsMember(eventDetails) {
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: SEND_EVENT_AS_MEMBER,
          variables: {
            eventDetails
          }
        });
        return { data };
      } catch (error) {
        return { error: error.message };
      } finally {
        self.accountLoading = false;
      }
    }),
    updateAccount: flow(function* updateAccount(newAccountDetails) {
      self.accountLoading = true;
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: UPDATE_ACCOUNT,
          variables: {
            account: newAccountDetails
          }
        });
        self.setAccount(data.updateAccount);
        return { data };
      } catch (error) {
        return { error: error.message };
      } finally {
        self.accountLoading = false;
      }
    })
  }))
  .actions((self) => ({
    setAccount(account) {
      self.id = account.id || self.id;
      self.fname = account.fname || self.fname;
      self.lname = account.lname || self.lname;
      self.phoneNumber = account.phoneNumber || self.phoneNumber;
      self.email = account.email || self.email;
      self.created = account.created || self.created;
      self.mailingAddress = account.mailingAddress || self.mailingAddress;
      self.inviter = account.inviter || self.inviter;
      self.additionalPhoneNumbers = account.additionalPhoneNumbers || self.additionalPhoneNumbers;
    },
    setNotifications: function setNotifications() {
      const notifications = [];
      const isCrossSellEligible =
        self.policies.list.length === 1 &&
        self.policies.list.some(
          ({ offer }) =>
            !offer?.quote?.offerings?.autoRejectCode ||
            fixableRejectionCodes.includes(offer?.quote?.offerings?.autoRejectCode) ||
            !offer?.quote?.offerings?.homeownersRejectCode ||
            fixableRejectionCodes.includes(offer?.quote?.offerings?.homeownersRejectCode)
        );

      if (isCrossSellEligible) {
        notifications.push(Notification.Customer.CrossSellEligibility);
      }

      if (notifications.length) {
        self.notifications = notifications;
      } else {
        self.notifications = null;
      }
    },
    getClaims: flow(function* getClaims() {
      const { client } = getEnv(self);
      self.claimsLoading = true;
      try {
        const { data } = yield client.mutate({
          mutation: GET_ACCOUNT_CLAIMS,
          variables: {
            accountId: self.id
          }
        });
        self.claims = data.claims;
      } finally {
        self.claimsLoading = false;
      }
    })
  }))
  .views((self) => ({
    getAllTickets() {
      return self.tickets;
    },
    getZdTickets() {
      return self.tickets.zendeskTickets;
    },
    getSendgridTickets() {
      return self.tickets.sendGridEmails;
    },
    getFive9Tickets() {
      return self.tickets.five9Data;
    }
  }))
  .views((self) => ({
    sortedCommunity(sortDirection) {
      if (self.community) {
        return orderBy(self.community, ['name'], [sortDirection]);
      }
      return [];
    }
  }));
