import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './badge.styles';

const Badge = ({ children, className }) => {
  const classes = useStyles();
  return <div className={classNames(classes.badge, className)}>{children}</div>;
};

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Badge.defaultProps = {
  className: null
};

export default Badge;
