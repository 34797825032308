import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import VersionHistoryTable, { BodyTableCell } from '../shared';
import { dateFormatter } from '../../../../../core/helpers/formatters';
import { dateFromPolicy, getTimezone } from '../../../policy/dates';

const Header = ({ classes }) => (
  <>
    <TableCell size="small" className={classes.tableHeading}>
      Version
    </TableCell>
    <TableCell className={classes.tableHeading}>Effective Date</TableCell>
    <TableCell className={classes.tableHeading}>Internal Notes</TableCell>
  </>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Body = ({ internalNotes, version, updatedDateTime, effectiveDate, username, classes, state }) => {
  const date = dateFromPolicy(updatedDateTime, state);
  const label = getTimezone(state);
  return (
    <>
      <TableRow className={classes.borderlessRow}>
        <BodyTableCell className={classes.borderlessCell}>{`00${version}`.substr(-3)}</BodyTableCell>
        <BodyTableCell className={classes.borderlessCell}>{dateFormatter(effectiveDate)}</BodyTableCell>
        <BodyTableCell className={classes.borderlessCell}>{internalNotes}</BodyTableCell>
      </TableRow>
      <TableRow className={classes.borderlessRow}>
        <BodyTableCell colSpan={3} className={classes.dateRow}>
          {`Created ${date.toLocaleDateString()} @ ${date.toLocaleTimeString()} ${label} by ${username}`}
        </BodyTableCell>
      </TableRow>
    </>
  );
};

Body.defaultProps = {
  internalNotes: ''
};

Body.propTypes = {
  internalNotes: PropTypes.string,
  version: PropTypes.number.isRequired,
  updatedDateTime: PropTypes.string.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

const SummaryTable = ({ versions, onChangePage, count, page, state }) => (
  <VersionHistoryTable
    body={Body}
    header={Header}
    versions={versions}
    onChangePage={onChangePage}
    count={count}
    page={page}
    state={state}
  />
);

SummaryTable.propTypes = {
  versions: PropTypes.array.isRequired,
  onChangePage: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  state: PropTypes.string.isRequired
};

SummaryTable.defaultProps = {
  onChangePage: undefined,
  count: undefined,
  page: undefined
};

export default SummaryTable;
