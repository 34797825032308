export default () => ({
  container: {
    width: 450,
    backgroundColor: '#F8F4F4',
    padding: '5px 30px 5px 36px',
    margin: 'auto',
    position: 'relative',
    transform: 'translateY(50%)'
  },
  passwordInfo: {
    '& ul': {
      paddingLeft: '15px',
      margin: 0
    },
    '& p': {
      marginBottom: '1px',
      marginTop: '25px'
    }
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginTop: '10px'
  },
  button: {
    color: 'white',
    borderRadius: '4px',
    border: '1px solid #1b2514',
    outline: 'none',
    padding: '7px 16px 7px 16px',
    cursor: 'pointer',
    '&, &:hover': {
      background: '#1b2514'
    }
  },
  confirm: {
    background: '#990000',
    color: 'white',
    width: 380,
    borderRadius: '3px',
    padding: '15px 10px 15px 15px',
    marginBottom: '30px'
  },
  modalTitle: {
    color: '#727831',
    fontWeight: 'normal',
    marginTop: 40,
    fontSize: 30
  },
  close: {
    fontSize: '32px',
    cursor: 'pointer',
    color: '#f89521',
    position: 'absolute',
    right: 0,
    top: 0
  },
  info: {
    fontSize: '18px'
  },
  setPasswordTrigger: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 16,
    padding: 0,
    height: 'auto',
    '&:hover': {
      background: 'transparent'
    }
  }
});
