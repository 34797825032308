import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import styles from './text-field.styles';
import Mask from './mask';

const StyledTextField = ({ classes, inputRef, readOnly, inputProps, mode, labelProps, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputRef,
        readOnly,
        autoComplete: 'off',
        disableUnderline: true,
        classes: mode ? MapClassesToElem(`${mode}-${ElemType.TextField}`, classes) : null,
        inputComponent: InputProps.inputProps && InputProps.inputProps.mask && Mask,
        ...InputProps
      }}
      InputLabelProps={{
        classes: mode ? MapClassesToElem(`${mode}-${ElemType.Label}`, classes) : null,
        ...labelProps
      }}
    />
  );
};

StyledTextField.propTypes = {
  classes: PropTypes.object,
  inputRef: PropTypes.func,
  readOnly: PropTypes.bool,
  mode: PropTypes.oneOf([null, 'light', 'dark', 'red']),
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object
};

StyledTextField.defaultProps = {
  classes: null,
  inputRef: null,
  readOnly: null,
  labelProps: null,
  mode: null,
  inputProps: null,
  InputProps: {}
};

export default withStyles(styles)(StyledTextField);
