import React from 'react';
import { Label } from 'core/components/label';
import { connectedHomePromoDetails } from '@ourbranch/lookups';

export const SimpliSafeDisclaimer = ({ type, className }) => {
  return (
    <Label type={type}>
      By opting-in, you agree that Branch can share your contact information with our partner SimpliSafe so they can
      ship a smart home system to you and SimpliSafe can share your personal information with Branch for the sole
      purpose of determining your eligibility to receive a discount on your homeowners insurance premium. You also
      acknowledge and agree that the SimpliSafe system and services are governed by the{' '}
      <a target="_blank" rel="noopener noreferrer" className={className} href="https://simplisafe.com/terms-of-use">
        terms of use
      </a>{' '}
      and{' '}
      <a
        target="blank"
        rel="noopener noreferrer"
        className={className}
        href={connectedHomePromoDetails.SimpliSafe.privacyPolicyUrl}
      >
        privacy policies
      </a>{' '}
      of SimpliSafe.
    </Label>
  );
};

export const AdtDisclaimer = ({ type, className }) => {
  return (
    <Label type={type}>
      By choosing to &apos;Sign up with ADT &apos;, I agree that an ADT specialist may contact me about ADT offers via
      text messages or phone calls to the phone number I provide as part of this application using automated technology
      and consent is not required to make a purchase. Your information is collected and used in accordance with
      ADT&apos;s{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        href={connectedHomePromoDetails.ADT.privacyPolicyUrl}
      >
        privacy policy
      </a>
      .
    </Label>
  );
};

export const Disclaimers = {
  ADT: AdtDisclaimer,
  SimpliSafe: SimpliSafeDisclaimer
};
