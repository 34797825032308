import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import SummaryTable from './summary-table';
import Modal from './modal';

const Versions = ({ versions, allVersions, state }) => {
  const [open, setModalState] = useState(false);
  const onModalClick = useCallback(() => setModalState(true), [setModalState]);
  const onClose = useCallback(() => setModalState(!open), [open, setModalState]);
  return (
    <>
      <SummaryTable versions={versions} state={state} />
      <Button variant="text" color="secondary" onClick={onModalClick}>
        See full version history
      </Button>
      <Modal state={state} open={open} versions={allVersions} onClose={onClose} />
    </>
  );
};

Versions.propTypes = {
  versions: PropTypes.array.isRequired,
  allVersions: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired
};

export default Versions;
