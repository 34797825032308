import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store/';
import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';
import { Discount } from '../discount';

const ConnectedHomeDiscount = observer(({ isActive, ...discount }) => {
  const { offer: store } = useStore();
  const { setShowConnectedHomeModal, showConectedHomeModal, includeConnectedHome, setIncludeConnectedHome } = store;
  const { setFieldValue, values } = useFormikContext();

  const onAddClick = () => {
    if (values.connectedHome === null) {
      setFieldValue('connectedHome', {
        moistureDevices: undefined,
        motionDetectingDevices: undefined,
        smokeDetectingDevices: undefined,
        theftPreventionDevices: undefined,
        autoWaterShutoffDevices: false,
        providerName: undefined,
        ...values.connectedHome
      });
    }
    setIncludeConnectedHome(true);
    setShowConnectedHomeModal(true);
  };

  const onRemoveClick = useCallback(() => {
    setIncludeConnectedHome(false);
    setFieldValue('connectedHome', null);
    setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
  }, [setFieldValue, setIncludeConnectedHome]);

  const connectedHomeInfo = { includeConnectedHome, showConectedHomeModal, connectedHomeValues: values.connectedHome };

  return (
    <Discount
      {...discount}
      isActive={isActive}
      onAdd={onAddClick}
      onRemove={onRemoveClick}
      canEdit
      connectedHomeInfo={connectedHomeInfo}
    />
  );
});

export default ConnectedHomeDiscount;
