import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Form, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { useStore } from 'core/store';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import ScreenHeader from 'core/components/screen-header';
import { useToast } from 'core/components/toast';
import { NotificationCard } from 'core/components/notification-card';

import PropertyViews from './property-views';
import VerifyPropertyForm from './verify-property-form';
import VerifyPropertyFooter from './verify-property-footer';
import NoHomeModal from './no-home-modal';
import { OfferDialog } from '../components/dialog';

import useStyles from './verify-property-container.styles.js';

const VerifyPropertyContainer = observer(({ conditions }) => {
  const history = useHistory();
  const [showNoHomeModal, setShowNoHomeModal] = useState(false);
  const { offer: offerStore } = useStore();
  const classes = useStyles();
  const toast = useToast();

  const { dirty, errors, values, isSubmitting } = useFormikContext();

  useEffect(() => {
    offerStore.setFormAction({ dirty, stale: offerStore.isStale, fromHomeVerification: true });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      errors?.global?.preBindUWCheck?.homeVerification?.signedPropertyUWAttestation &&
      !values.global.preBindUWCheck.homeVerification.signedPropertyUWAttestation
    ) {
      toast.notify({
        type: 'error',
        message: 'You must sign off you verified the property before continuing'
      });
    }
    if (
      errors.global?.preBindUWCheck?.homeVerification?.hasOwnProperty('signedPropertyUWAttestation') === false &&
      values.global.preBindUWCheck.homeVerification.signedPropertyUWAttestation
    ) {
      setShowNoHomeModal(true);
    }
    // eslint-disable-next-line
  }, [errors?.global?.preBindUWCheck?.homeVerification, isSubmitting]);

  if (!offerStore.loading) {
    return (
      <Form>
        {offerStore.openDialog && <OfferDialog onClose={() => offerStore.triggerOfferDialog(false)} />}
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="flex-start"
          className={classes.container}
        >
          {showNoHomeModal && <NoHomeModal setShowNoHomeModal={setShowNoHomeModal} />}
          {offerStore.priorQuoteWithPreBindUWRejections?.ineligibleForHome && (
            <NotificationCard type="secondary">
              <Grid container alignItems="center">
                The potential member has an underwriting home rejection from a prior Quote.
              </Grid>
            </NotificationCard>
          )}
          <ScreenHeader
            title="Verify this property"
            subtitles="This helps us ensure that we can insure the right properties and quickly disqualify those we cannot."
            onBack={() => history.push('/search/offers')}
          />
          <PropertyViews />
          <VerifyPropertyForm {...conditions} />
          <Grid className={classes.attestationContainer}>
            <Label type="subtitle">Agent Attestation</Label>
            <Grid>
              <Field
                className={classes.attestationCheckbox}
                id="global.preBindUWCheck.homeVerification.signedPropertyUWAttestation"
                name="global.preBindUWCheck.homeVerification.signedPropertyUWAttestation"
                type="checkbox"
                label="I have viewed the imagery of the home or, if no images were available, discussed the property characteristics with the potential member."
                mode="light"
              />
            </Grid>
          </Grid>
          <VerifyPropertyFooter setShowNoHomeModal={setShowNoHomeModal} />
        </Grid>
      </Form>
    );
  }
});

export default VerifyPropertyContainer;
