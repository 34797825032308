import React, { useCallback, useContext, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';

import { Loading } from 'core';
import { useStore } from 'core/store';
import emailIcon from 'core/assets/svg/email.svg';
import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import EditableCard from 'core/components/editable-card';
import Section from 'core/components/section';
import { withToast } from 'core/components/toast';
import ValueField from 'core/components/value-field';
import { addressFormatter } from 'core/helpers/formatters';
import SetPassword from 'core/components/set-user-password/set-user-password';
import AccountDetailsForm from './account-details-form';
import styles from './account-details.styles';

const AccountDetail = observer(function AccountDetail({ classes, toast }) {
  const { account } = useStore();
  const [resetLoading, setResetStatus] = useState(false);
  const { canEdit } = useContext(AuthContext);
  const [editing, setEditing] = useState(false);

  const resetPassword = useCallback(async () => {
    setResetStatus(true);
    try {
      const { data, errors } = await account.resetPwd(account.id);
      if (data && data.resetPwd.success) {
        toast.notify({
          type: 'success',
          message: `We sent a temporary password to  ${account.email ? account.email : ''}`
        });
      } else if (errors) {
        toast.notify({
          type: 'error',
          message: 'There was an error resetting the password'
        });
      }
    } catch (error) {
      toast.notify({
        type: 'error',
        message: 'There was an error resetting the password'
      });
    }
    setResetStatus(false);
  }, [setResetStatus, account, toast]);

  const onUpdateSuccess = useCallback(() => {
    setEditing(false);
    toast.notify({
      type: 'success',
      message: 'Account updated successfully'
    });
  }, [toast, setEditing]);

  const onUpdateFailed = useCallback(
    (errorMessage) => {
      toast.notify({
        type: 'error',
        message: `There was an error updating the account - ${errorMessage}`
      });
    },
    [toast]
  );

  const CardComponent = useMemo(() => {
    if (canEdit) {
      return EditableCard;
    }
    return Card;
  }, [canEdit]);

  let noAccount = false;
  // reaction will trigger the second time tracked variable changes
  reaction(
    () => account.accountLoading,
    (loading) => {
      if (!loading && !account.id) {
        noAccount = true;
      }
    }
  );

  const inviterLabel = ({ firstName, lastName, id }) =>
    id || firstName ? (
      <>
        <Link to={`/customer/${id}`} className={classes.inviterLink}>
          {`${firstName} ${lastName}` || id}
        </Link>
      </>
    ) : (
      'No Referrer'
    );

  if (noAccount) {
    return <Redirect to="/search/customers" />;
  }

  return (
    <Section title="Account Details">
      {account.accountLoading || !account.id ? (
        <Loading />
      ) : (
        <CardComponent className={classes.accountCard} editing={editing} setEditing={setEditing}>
          {editing ? (
            <AccountDetailsForm onSuccess={onUpdateSuccess} onError={onUpdateFailed} />
          ) : (
            <>
              <ValueField value={`${account.fname} ${account.lname}`} />
              <ValueField
                label="Mailing Address"
                value={(account.mailingAddress && addressFormatter(account.mailingAddress)) || ''}
              />
              <ValueField label="Email" value={account.email} />
              <ValueField label="Primary Phone" value={account.phoneNumber} />
              {account.additionalPhoneNumbers?.length !== 0 && (
                <Grid container className={classes.phonesWrapper}>
                  {account.additionalPhoneNumbers?.map((phoneDetail, index) => (
                    <Grid key={`addtl-phone-number-${index}`} className={classes.secondaryPhone} container>
                      <Grid container alignItems="center">
                        <ValueField label="Secondary Phone" value={phoneDetail?.phoneNumber} xs={12} />
                        {phoneDetail.canText && (
                          <Chip
                            label="Text Msg Enabled"
                            color="secondary"
                            size="small"
                            className={classes.chip}
                            xs={6}
                          />
                        )}
                      </Grid>
                      {phoneDetail.note?.length > 0 && <ValueField label="note" value={phoneDetail?.note} xs={12} />}
                    </Grid>
                  ))}
                </Grid>
              )}
              <ValueField label="Customer Since" value={new Date(account.created).toLocaleDateString()} />
              {canEdit && <ValueField label="Referred By" value={inviterLabel(account.inviter)} />}
              {canEdit && (
                <>
                  <Button
                    disabled={resetLoading}
                    disableFocusRipple
                    className={classes.resetContainer}
                    onClick={resetPassword}
                    variant="text"
                    color="secondary"
                  >
                    <img
                      alt="email icon"
                      className={classNames(classes.resetIcon, { [classes.disabledIcon]: resetLoading })}
                      src={emailIcon}
                    />
                    Send temporary password
                  </Button>
                  <SetPassword account={account} />
                </>
              )}{' '}
            </>
          )}
        </CardComponent>
      )}
    </Section>
  );
});

AccountDetail.propTypes = {
  toast: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withToast)(AccountDetail);
